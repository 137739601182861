import React from "react";

import CategoryHistoryReportItems from './CategoryHistoryReportItems';
import LogoAlignLeft from './bigLogo.png';
import { formatDate, formatPrice } from "../../utils/Common";

const CategoryHistoryDetails = (props) => {
  const { actTab, detailsData, fullApiData } = props;
  let createdDate,updatedDate;
   detailsData.map(el =>{
    createdDate = el.created_at;
    updatedDate = el.updated_at;
   } )

  return (
    <div
      id="categoryHistory"
      className="bg-white rounded-3xl font-nunito p-[26px] mb-20"

    >
      <div className="flex justify-between items-center">
        {/* outlet logo */}
        <div className="w-20 cursor-pointer -pb-[4px]">
          <img src={LogoAlignLeft} className="w-full h-full object-contain" />
        </div>
        {/* title and date */}
        <div className="text-[#000232] text-right">
          <h2 className="text-sm font-semibold">Category Sale Report</h2>
          <p className="text-xs">{formatDate(createdDate)} - {formatDate(updatedDate)}</p>
        </div>
      </div>
      {/* table head */}
      <div className="grid text-center mt-4 pt-[4px] pb-[18px] items-center text-xs font-bold bg-[#000232] text-white px-3 grid-cols-10 border-b-[1px] border-[#E7EBF4]">
        <div className="text-left col-span-1">No</div>
        <div className=" col-span-2">Date/Times</div>
        <div className="col-span-1">Category Name</div>
        <div className="col-span-2">Product Name</div>
        <div className="col-span-2">Customer</div>
        <div className="col-span-1">Due</div>
        <div className="text-right col-span-1">Total</div>
      </div>
      {/* table body component */}
      {detailsData.map((cData, index) => {
        return (
          <CategoryHistoryReportItems
            categoryData={cData}
            tabActive={actTab}
            key={index}
            index={index}
          />
        );
      })}
      <hr className="border-[#000232] border-[1px]" />
      <div className="w-[38%] ml-auto text-xs font-[500]">
        <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Total Category</p>
          <p>{fullApiData?.total_categories}</p>
        </div>
        <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Sold Category</p>
          <p>{fullApiData?.sold_categories}</p>
        </div>
        <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Sold Amount</p>
          <p>{formatPrice(fullApiData?.sold_amount)}</p>
        </div>
        <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Paid Amount</p>
          <p>{formatPrice(fullApiData?.paid_amount)}</p>
        </div>
        <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Due Amount</p>
          <p>{formatPrice(fullApiData?.due_amount)}</p>
        </div>
      </div>
    </div>
  );
};

export default CategoryHistoryDetails;