import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import axios from "axios";
import { BeatLoader } from 'react-spinners';
const BASE_URL = process.env.REACT_APP_ROOT_URL;

function EmailVerify(props) {
  // const phoneNumber = localStorage.getItem("phoneNumber");
  // const phoneNumberWithoutPrefix = phoneNumber.replace(/^(\+88)/, '');

  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };

  // const sendOtp = () => {
  //   loadingCallback(true);
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_URL}send-otp`,
  //       { phone: phoneNumberWithoutPrefix },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       loadingCallback(false);
  //       console.log(response);
  //       if (response.data.status === 200) {
  //         props.continueSubmit();
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       loadingCallback(false);
  //     });
  // };
    const sendOtp = () =>{
      loadingCallback(true);
      props.continueSubmit();
    }
  return (
    <section style={{ background: "#000" }}>
      <div className="emailverify-container">
        <div className="emailverify-left">
          <img
            src={`${BASE_URL}/assets/registration/webp/emailverifyright.webp`}
            alt="emailverifyimage"
            className="img-fluid"
          />
        </div>
        <div className="emailverify-right ">
          <div className="emailverify-right-card emailverifycard">
            <div className="emailverify-log-img">
              <img
                src={`${BASE_URL}/assets/registration/webp/emailverify.webp`}
                alt="logo"
                className="img-fluid"
              />
            </div>
            <div className="emailverify-form-main">
              <p>Welcome To Outlet Expense</p>
            </div>
            <div className="emailverifyform-console">
              <p>

                <br></br> Go For Continue {" "}
              </p>
            </div>
            {loading && (

              <div className="custom-loader-logo">
                {/* <BeatLoader color={"#123abc"} size={20} /> */}
                <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
              </div>
            )}

            <div className="email-continues-button ">
              <button className="continue-form-button" onClick={sendOtp}>
                Continue
              </button>
            </div>
            {/* <div className="emailverify-progressbar-signup indicator-emailverify">
                            <div className="continues-button ">
                                <button className='continue-form-button' onClick={props.continueSubmit}>Continue</button>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default EmailVerify;
