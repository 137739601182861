import React, { useContext, useEffect, useState } from "react";

import { AppContext } from "../../context/context";
import { formatPrice, formatDate } from '../../utils/Common';
import manIcon from "../../assets/images/man2.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Modal } from './../../utils/Modal';
import axios from "axios";
import Swal from "sweetalert2";

const AccountsListItem = ({account, loadingCallback}) => {
  const { config, state, updatePaymentList } = useContext(AppContext);
  const navigate = useNavigate()
  const token = localStorage.getItem("token");
  const url = process.env.REACT_APP_API_URL;
  const [showViewModal, setShowViewModal] = useState(false);
  return (
    <div>
      <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] font-nunito font-[500] text-[#000232] grid grid-cols-6 items-start">
        <div>
          <div className="flex flex-col sm:flex-row gap-2">
            <div className="font-nunito text-[14px]">
              <div className="font-[500] text-[#000232]">#{account.id}</div>
              <div className="font-[700] text-[#87889E]">{account?.transaction_name}</div>
            </div>
          </div>
        </div>
        <div className="text-center">{account.transaction_date}</div>
        <div className="text-center">{account.transaction_amount || 0  }{" BDT"}</div>
        <div className="text-center">{
          account?.multiple_payments.map((item,idx)=>(
            item?.payment_type?.type_name =='cash' ?
            <div key={idx}>
              <p className='text-center'>{item?.payment_type?.type_name}</p>
            </div>
            :
            <div key={idx}>
              <p className='whitespace-nowrap text-center'>{item?.payment_type?.type_name}</p>
              <p className='font-semibold whitespace-nowrap text-center'>{item?.payment_type_category?.payment_category_name}</p>
              <p className='text-center'>{item?.payment_type_category?.account_number}</p>
            </div>
          ))
        }</div>
        <div className={`text-center ${account?.transaction_category === "In" ? 'text-[#0B8B00]':"text-[#FF0303]"}`}>{account.transaction_category}</div>
        <div className="flex gap-2  text-xs justify-end">
        <label
          htmlFor="viewChartOfAcc_modal"
          onClick={()=>setShowViewModal(val=>!val)}

          className="border-[1px] border-solid border-[#407BFF] text-[#407BFF] rounded-[5px] px-[6px] py-1"
        >
         VIEW
        </label>
        <label
          htmlFor="editAccTxn_modal"
          onClick={()=> Swal.fire("Edit option coming soon")}
          className="border-[1px] border-solid bg-[#407BFF] border-[#407BFF] text-white rounded-[5px] px-[7px] py-1"
        >
         EDIT
        </label>
        </div>
      </div>
      {showViewModal ? (
        <Modal
        formData={account}
          title={"Details"}
          formId="viewChartOfAcc_modal"
          isOpen={showViewModal}
          setOpen={setShowViewModal}
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="viewFinance"
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default AccountsListItem;