import html2pdf from 'html2pdf.js';
import React, { useState } from 'react';
import QRCode from "react-qr-code";
import { formatPrice, numberToWords,invoiceCalculate, formatPhoneNumber } from '../../utils/Common';
import PdfHtmlContent from './PdfHtmlContent/PdfHtmlContent';
import PdfHtmlFor2mm from './PdfHtmlContentFor2mm/PdfHtmlFor2mm';
import PdfHtmlFor3mm from './PdfHtmlContentFor3mm/PdfHtmlContentFor3mm';
import { FaChevronDown } from "react-icons/fa";
import Watermark from './Watermark/Watermark';
import downloadIcon from './downloadicon.png';
import printerIcon from './printer 1.png';
import { useNavigate } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_ROOT_URL;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const PurchaseInvoiceDetail = (props) => {
  const [pageSelect, setpageSelect] = useState(null);
  const [dropdownPdf, setDropdownPdf] = useState(false)
  const [dropdownPrint, setDropdownPrint] = useState(false)
  const [printContentVisible, setPrintContentVisible] = useState(false);
  const navigate = useNavigate();

  const {
    invoice,
    invoiceSetting,
    paperSize,
    currentUser
  } = props;



  const invoiceDate = new Date(invoice.created_at);

  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  const monthName = invoiceDate.toLocaleString("en-US", { month: "long" });
  const day = String(invoiceDate.getDate()).padStart(2, '0');
  const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';


  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day} ${monthName} ${year} ( ${hours}:${minutes}${ampm} )`;

  const shop_logo = invoiceSetting?.shop_logo ? invoiceSetting?.shop_logo : `${BASE_URL}/layoutlogo.svg`;
  //const shop_logo = invoice?.user_info?.invoice_settings?.shop_logo ? invoice?.user_info?.invoice_settings?.shop_logo : `${BASE_URL}/layoutlogo.svg`;
  //const shop_logo = `${BASE_URL}/layoutlogo.svg`;
  let totalQty = 0;
  let totalItems = 0;
  let totalPrice = 0;

  const originalContents = document.body.innerHTML;
  const handlePrint = (elementId) => {
    setPrintContentVisible(true);

    const printContents = document.getElementById(elementId).innerHTML;
    document.body.innerHTML = printContents;
    window.print();
  };

  window.onafterprint = () => {
    setPrintContentVisible(false);
    document.body.innerHTML = originalContents;
     // Restore original content
      window.location.reload();
  };

  const handleGeneratePdf = async (pageSize, pageSizeNumber) => {
     const element = document.getElementById('pdfContentAreas');
    const element2 = document.getElementById('pdfContentAreas3');
    const element3 = document.getElementById('pdfContentAreas2');

    if (pageSizeNumber === 1) {
      console.log("page size", pageSizeNumber)
      setpageSelect(pageSizeNumber);
    }
    else if (pageSizeNumber === 2) {
      console.log("page size", pageSizeNumber)
      setpageSelect(pageSizeNumber);
    }
    else if (pageSizeNumber === 3) {
      console.log("page size", pageSizeNumber)
      setpageSelect(pageSizeNumber);
    }

    const formatOptions = {
      'a4': 'a4',
      '3mm': [80, 297], // Custom size for '3mm' format
      '2mm': [56, 297], // Custom size for '2mm' format
    };
    const shopLogoUrlWithProxy = `<span class="math-inline">${API_BASE_URL}</span>${shop_logo}`; // Prepend proxy URL

    const opt = {
      margin: 0.1,
      filename: 'myPdf.pdf',
      image: { type: 'jpeg', quality: 1 },
      //image: { type: 'jpeg', quality: 0.98, baseURL: 'https://api-outlet-expense.squadinnovators.com' },

      html2canvas: {
        scale: 900/96,
        proxy: shopLogoUrlWithProxy,
        useCORS: true,
        allowTaint: false,
        logging: false,
      },
      jsPDF: {
        unit: 'mm',
        format: formatOptions[pageSize], // 'a4', '3mm', or '2mm'
        orientation: 'portrait',
        marginLeft: 10,
        marginRight: 10,
        image: { type: 'jpeg', quality: 1 },
        fontFaces: [
          { family: 'Arial', src: 'path/to/arial.ttf' },
        ]
      },
    };

    //const cssUrl = window.location.origin + '/static/pdfstyles.module.css';
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    //link.href = cssUrl;
    head.appendChild(link);
    const images = element?.querySelectorAll('img');
    // const images2 = element2.querySelectorAll('img');
    // const images3 = element3.querySelectorAll('img');
    let loadedImages = 0;

    // const checkImagesLoaded = () => {
    //   loadedImages++;

      //if (loadedImages === images?.length) {
        // All images are loaded, proceed to generate the PDF

        const generatePdf = () => {
          html2pdf()
            .set(opt)
            .from(element)
            .toPdf()
            .get('pdf')
            .then(pdf => {
              pdf.save(`${invoice.invoice_id}_invoice_${pageSize}.pdf`);
              link.remove();
            });
        };
        const generatePdf2 = () => {
          html2pdf()
            .set(opt)
            .from(element2)
            .toPdf()
            .get('pdf')
            .then(pdf => {
              pdf.save(`${invoice.invoice_id}_invoice_${pageSize}.pdf`);
              link.remove();
            });
        };
        const generatePdf3 = () => {
          html2pdf()
            .set(opt)
            .from(element3)
            .toPdf()
            .get('pdf')
            .then(pdf => {
              pdf.save(`${invoice.invoice_id}_invoice_${pageSize}.pdf`);
              link.remove();
            });
        };

        if (pageSizeNumber === 1) {
          generatePdf();
        }
        else if (pageSizeNumber === 2) {
          generatePdf2();
        }
        else if (pageSizeNumber === 3) {
          generatePdf3();
        }

      //}
      // if (loadedImages === images2.length) {
      //   // All images are loaded, proceed to generate the PDF
      //   generatePdf2();
      // }
      // if (loadedImages === images3.length) {
      //   // All images are loaded, proceed to generate the PDF
      //   generatePdf3();
      // }

    //};



    // Add event listener to each image to check when they are loaded
    // if(images !=null){
    // images.forEach(img => {
    //   img.addEventListener('load', checkImagesLoaded);
    // })};

    // // Start the PDF generation process
    // if(images !=null){
    // images.forEach(img => {
    //   if (img.complete) {
    //     // If the image is already cached, trigger the load event manually
    //     checkImagesLoaded();
    //   }
    // })};


  };

  const handleNavigate =() =>{
    navigate('/purchase-invoice')
  }



  return (
    <div>
      <div id="invoice-detail-page" className="invoice-detail-page font-nunito">
        {/* <div className="invoice-info">
          <h1>Invoice</h1>
          <p>{invoice.invoice_id}</p>
          <p>{formattedDate}</p>
        </div> */}
        <div className="invoice-header">
          <div className="invoice-logo font-nunito  mx-auto w-[100px] md:w-[551px] text-center text-12 font-semibold">
            <img
            src={shop_logo}
            alt={invoice.invoice_id}
            className="mx-auto p-3" />
            <div className='my-2  space-y-1.5'>
            {currentUser?.outlet_name &&
                <p className='capitalize text-[22px] text-center font-semibold'>{invoice?.user_info?.outlet_name} </p>
              }
              {currentUser?.address &&
                <p>{currentUser?.address}</p>
              }
              {invoiceSetting?.mobile_number &&
                <p>Mobile : {formatPhoneNumber(invoiceSetting?.mobile_number) }</p>
              }
               <div className='flex flex-col '>
           <span>{invoiceSetting?.web_address ?
              invoiceSetting?.web_address
            : (
              ""
            )}</span>
            <span>
            {invoiceSetting?.social_link ?
              invoiceSetting?.social_link
            : (
              ""
            )}
            </span>

            </div>
            </div>
          </div>
        </div>
        {/* <DashedLine /> */}

        <div className="invoice-customer flex justify-between m-3 items-center">
          <div className="text-black font-nunito text-24 leading-140">
            <p className='mb-2'> <span className='font-semibold'>Vendor Name</span>  :  {invoice.vendor_name} </p>
            <p className='mb-2'><span className='font-semibold'>Vendor Phone</span> :  {invoice.country_code}{invoice.vendor_phone}</p>
            <p className='mb-2'><span className='font-semibold'> Vendor ID </span> : {invoice.vendor_id}</p>
            <p className='mb-2'><span className='font-semibold'> Invoice Date </span>: {formattedDateTime} </p>
          </div>
          <div className="w-[100px]">
            <QRCode className="w-full object-contain" value={BASE_URL + '/purchase-invoice/' + invoice.invoice_id} size={100} />
          </div>
        </div>

        <div style={{ width: '100%', backgroundColor: '#3056FA', padding: '10px', textAlign: 'center', margin: '15px auto' }}>
          <p className="text-white font-nunito text-14 font-bold uppercase">Invoice No: {invoice.invoice_id}</p>
        </div>

        {/* <div className={`invoice-items max-w-[calc(100vw-28vw)] w-[calc(100vw-28vw)] mx-auto md:w-full md:max-w-full overflow-x-auto`}> */}
        <div className={`invoice-items`}>
          {/* <table className={`table ${'h-[600px]' ? 'overflow-x-scroll' : 'overflow-x-hidden'}`}> */}
          <table className={`table`}>
            <thead>
              <tr className="text-gray-900 font-nunito text-base font-semibold leading-normal tracking-wide relative">
                <th><span className="hidden md:inline-block">Item</span> Name</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Warranties</th>
                <th className=' right-0'>Total</th>
              </tr>
            </thead>
            {/* <Watermark watermark_text={invoiceSetting?.watermark_text} watermark_type={invoiceSetting?.watermark_type}></Watermark> */}
            {invoice.purchase_details ?
              invoice.purchase_details.map((item) => {
                totalQty += Number(item.qty);
                totalItems += 1;
                const subtotalPrice = formatPrice(item.qty * item.price);
                totalPrice += Number(subtotalPrice);
                return (
                  <tbody key={item.id}>
                    <tr className="text-blue-gray-500 font-nunito text-[16px]">
                      <td>{item?.product_info?.name} <br /><span className='text-[13px]'>{item?.product_info?.serial}</span></td>
                      <td>{item.qty}</td>
                      <td>{item.price}</td>
                      <td>{item?.product_info?.warrenty ? item?.product_info?.warrenty : '        '}</td>
                      <td className='text-gray-900 font-nunito  font-bold '>{subtotalPrice}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</td>
                    </tr>
                  </tbody>
                );
              })
              : null}
          </table>
        </div>
        {/* <DashedLine2 /> */}
        <br />
        <div className='flex justify-between gap-2'>
          <div className="terms-condition w-1/2 font-normal pt-2 md:pt-0 pr-4  md:font-medium text-[12px] md:text-[18px]">
            {invoiceSetting?.purchase_condition ?
              invoiceSetting?.purchase_condition
              : (
                ""
                // <p>Damage caused by misuse, abuse, or neglect.<br />
                //   Unauthorized modifications or repairs.<br />
                //   Acts of nature, such as floods, earthquakes, or other disasters.<br />
                //   This may include proper use.</p>
              )}
          </div>
          <div className="invoice-summary w-1/2 text-[14px] md:text-[18px]">

            <p className='flex mt-2 justify-between text-navy-800 font-nunito font-semibold gap-5'>
              <span>TOTAL ITEMS</span>
              <span>{totalItems}</span>
            </p>

            <p className='flex mt-2 justify-between text-navy-800 font-nunito font-semibold gap-5'>
              <span>SUB TOTAL</span>
              <span>{totalPrice}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <p className='flex mt-2 justify-between text-blue-gray-500 font-nunito font-semibold gap-5'>
              <span>Vat {''}</span>
              <span>{invoice.vat ? formatPrice(invoice.vat) : 0.00}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <p className='flex mt-2 justify-between text-blue-gray-500 font-nunito font-semibold gap-5'>
              <span>Service Charge {''}</span>
              <span>{invoice.tax ? formatPrice(invoice.tax) : 0.00}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <hr></hr>
            <p className='flex mt-2 justify-between text-navy-800 font-nunito font-semibold gap-5'>
              <span>Grand Total</span>
              <span>{formatPrice(invoiceCalculate(invoice,'purchase').grandTotal)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <p className='flex mt-2 justify-between text-navy-800 font-nunito font-semibold gap-5'>
              <span>Paid Amount</span>
              <span>{invoice.paid_amount && invoice.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(0)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <hr></hr>
            <p className='flex mt-2 justify-between text-navy-800 font-nunito font-semibold gap-5'>
              <span>Due Amount</span>
              {/* <span>{formatPrice(invoice.sub_total - invoice.paid_amount)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span> */}
              <span>{formatPrice(invoiceCalculate(invoice,'purchase').dueAmount) }{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
          </div>
        </div>
        <p className='text-gray-400 mt-5 font-nunito text-[20px] font-semibold leading-normal text-center'>In word:<span className='text-gray-900'> {numberToWords(formatPrice(invoiceCalculate(invoice,'purchase').grandTotal))} taka only</span></p>
        <div style={{ width: '100%', backgroundColor: '#034AFF', padding: '10px', textAlign: 'center', margin: '25px auto' }}>
          <p className="text-white font-nunito text-14 font-bold">You saved {formatPrice(invoice.discount ? invoice.discount : 0)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'} in this purchase </p>
        </div>
        <div>
          <div className='flex justify-between'>
            <p className="invisible">.</p>
            {invoiceSetting?.sign_authority && <div className='w-[120px] h-[120px] rounded-lg'>
              {/* <img src={invoiceSetting.sign_authority} className='object-cover' /> */}
            </div>}
          </div>
          <div className="flex justify-between m-2">
            <p ><hr></hr> Vendor Signature</p>
            <p ><hr></hr> Customer Signature </p>
          </div>
        </div>
        <div className='text-center font-nunito'>

          <h2 className='text-orange-500  text-xl font-semibold '>Thanks for purchase</h2>
          <p className='text-black mt-3 text-xs '>A Product of SQUAD INNOVATORS </p>
        </div>
      </div>
      {<div style={{ display: 'none' }} >
        <PdfHtmlContent
          shop_logo={shop_logo}
          invoice={invoice}
          invoiceSetting={invoiceSetting}
          id="pdfContentAreas"

        />
      </div>}
      {<div style={{ display: 'none' }}>
        <PdfHtmlFor3mm
          shop_logo={shop_logo}
          invoice={invoice}
          invoiceSetting={invoiceSetting}
          id="pdfContentAreas3"

        />
      </div>}
      {<div style={{ display: 'none' }}>
        <PdfHtmlFor2mm
          shop_logo={shop_logo}
          invoice={invoice}
          invoiceSetting={invoiceSetting}
          id="pdfContentAreas2"

        />
      </div>}
      <div className='flex gap-12 justify-between text-xs md:text-sm lg:text-base mt-6'>
        {/* pdf buttons */}
        <div className={`flex-1 transform duration-300 ${dropdownPdf || dropdownPrint ? "!h-[270px] md:!h-[300px]" : "h-[90px]"} space-y-2 relative`}>
          {/* pdf drop down button */}
          <button onClick={() => setDropdownPdf(!dropdownPdf)} className=" flex justify-center gap-1 md:gap-3 items-center bg-[#f1effc] text-[#034AFF] absolute z-10 !px-1 py-2 w-full rounded-xl font-bold shadow-md">
            <span><img src={downloadIcon} alt="" /></span>  Download PDF <div className={`transform duration-300 ${dropdownPdf ? "-rotate-180" : ""}`}> <FaChevronDown className='text-base md:text-lg' /></div>
          </button>
          {/* child buttons */}
          <button onClick={() => handleGeneratePdf('a4', 1)} className={`text-[#034AFF] font-semibold flex gap-1 md:gap-3  items-center justify-center ${dropdownPdf ? "translate-y-[3.7rem] " : "-translate-y-[0.6rem] opacity-0"} hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}>
            <img className='rounded-md' src={downloadIcon} /> <span>Download A4 PDF</span>
          </button>
          <button onClick={() => handleGeneratePdf('3mm', 2)} className={`text-[#034AFF] font-semibold flex gap-1 md:gap-3 items-center justify-center ${dropdownPdf ? "translate-y-[3.7rem] " : "-translate-y-[4.8rem] opacity-0"}  hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}>
            <img className='rounded-md' src={downloadIcon} /> <span>Download 3mm PDF</span>
          </button>
          <button onClick={() => handleGeneratePdf('2mm', 3)} className={` text-[#034AFF] font-semibold flex gap-1 md:gap-3 items-center justify-center ${dropdownPdf ? "translate-y-[3.7rem] " : "-translate-y-[8.4rem] opacity-0"} border hover:bg-[#034AFF] hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}>
            <img className='rounded-md' src={downloadIcon} /> <span>Download 2mm PDF</span>
          </button>
        </div>

        {/* print buttons */}
        <div className={`flex-1 space-y-2 transform duration-300 ${dropdownPdf || dropdownPrint ? "!h-[270px] md:!h-[300px]" : "h-[90px]"} relative`}>
          {/* print drop down button */}
          <button onClick={() => setDropdownPrint(!dropdownPrint)} className=" bg-[#f1effc] flex justify-center items-center gap-1 md:gap-3 text-[#034AFF] font-bold shadow-md absolute z-10 !px-1 py-2 w-full rounded-xl">
            <span><img src={printerIcon} alt="" /></span>  Print <div className={`transform duration-300 ${dropdownPrint ? "-rotate-180" : ""}`}> <FaChevronDown className='text-base md:text-lg' /></div>
          </button>
          {/* child buttons */}
          <button onClick={() => { handlePrint('pdfContentAreas') }} className={`flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${dropdownPrint ? "translate-y-[3.7rem] " : "-translate-y-[0.6rem] opacity-0"} hover:text-[#eaeffb] hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300  py-3 w-full  rounded-xl`}>
            <img className='rounded-md' src={printerIcon} /> <span>Print A4</span>
          </button>
          <button onClick={() => { handlePrint('pdfContentAreas3') }} className={`flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${dropdownPrint ? "translate-y-[3.7rem] " : "-translate-y-[4.8rem] opacity-0"}  hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}>
            <img className='rounded-md' src={printerIcon} /> <span>Print 3 inch</span>
          </button>
          <button onClick={() => { handlePrint('pdfContentAreas2') }} className={` flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${dropdownPrint ? "translate-y-[3.7rem] " : "-translate-y-[8.4rem] opacity-0"} border hover:bg-[#034AFF] hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}>
            <img className='rounded-md' src={printerIcon} /> <span>Print 2 inch</span>
          </button>
        </div>
      </div>
      {/* back to list button */}
      <button onClick={()=>handleNavigate()} className=" bg-[#034AFF] py-3 w-1/2 mt-3 mx-auto flex justify-center text-white rounded-xl text-xs md:text-sm lg:text-base">
        Back to list
      </button>
    </div>
  );
};



export default PurchaseInvoiceDetail;
