import React, { useContext, useEffect, useState } from "react";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { AppContext } from '../../../context/context.js';
import { get } from "../../../services/apiService";
import PurchaseInvoiceItem from '../../Invoice/PurchaseInvoiceItem';
import { timeDiff,invoiceCalculate,formatPrice } from '../../../utils/Common';
import "./PurchaseTable.scss";


const BASE_URL = process.env.REACT_APP_ROOT_URL;

const PurchaseTable = (props) => {
    const { type, purchaseInvoice } = props;

    const { state, isLoading, updatePurchaseInvoiceList } = useContext(AppContext);
    const { purchaseInvoiceList,invoiceSetting } = state;
    const token = localStorage.getItem("token");

    const [currentPage, setCurrentPage] = useState(1);
    const perPage = 20;

    useEffect(() => {

        getPurchaseInvoicelist(currentPage);

    }, [currentPage]);

    const getPurchaseInvoicelist = (page) => {
        get(`purchase-invoice-list?page=${page}&limit=${perPage}`, token)
        .then((response) => {
            if(response.success)
            {
                const listData = response.data.data;

                const transformedInvoiceArray = listData.map(item => ({
                    id: item.id,
                    icon: <RiMoneyDollarCircleLine/>,
                    name: item.invoice_id,
                    sname: item.vendor ? item.vendor.name : '',
                    product: item.purchase_details
                    ? item.purchase_details
                        .filter(sale => sale.product_info)
                        .map(sale => sale.product_info.name)
                        .join(', ')
                    : '',
                    price: invoiceSetting?.currency_info?.code ? formatPrice(invoiceCalculate(item,'purchase').grandTotal) +' '+invoiceSetting.currency_info.code : formatPrice(invoiceCalculate(item,'purchase').grandTotal)+' BDT',
                    due: invoiceSetting?.currency_info?.code ? formatPrice(invoiceCalculate(item,'purchase').dueAmount) +' '+invoiceSetting.currency_info.code : formatPrice(invoiceCalculate(item,'purchase').dueAmount)+' BDT',
                    time: timeDiff(new Date(item.updated_at)),
                    date: item.updated_at,
                    colors: "#00A3EE"
                }));
                updatePurchaseInvoiceList(transformedInvoiceArray)
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };
    const onPageChange = (page) => {
        setCurrentPage(page);
    };
    const shouldRenderPagination = purchaseInvoiceList.total > perPage;



    return (
        <>
        <div className="flex flex-col w-full">
            <div className="title-link">
                <h1>Recent Purchase Invoice</h1>
                <Link to="/purchase-invoice">View All</Link>
            </div>
            <div className="table-conatiner">
                <div className="cursor-pointer">
                    <div className="table-data flex invoiceItemTable">
                        <div className="text-[14px] font-nunito font-bold">Name</div>
                        <div className="text-[14px] font-nunito font-bold text-center">
                            <h1>Amount</h1>
                        </div>
                        <div className="text-[14px] font-nunito font-bold text-center">
                            <h1>Due</h1>
                        </div>
                        <div className="text-[14px] font-nunito font-bold text-right">
                            <h1>Date/Time</h1>
                        </div>
                    </div>
                </div>

                {purchaseInvoiceList.map((invoice) => {
                    return (
                        <PurchaseInvoiceItem key={invoice.id} invoice={invoice} />
                    );
                })}
                <div className="hrtable"></div>

            </div>
        </div>
</>
    );
};

export default PurchaseTable;