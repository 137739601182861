import React, { useContext, useState, useEffect } from "react";
import "./index.css";
import axios from "axios";
import { BeatLoader } from 'react-spinners';
import { AppContext } from '../../../context/context.js';
// import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from "antd";
import { Link, useNavigate } from 'react-router-dom';
const BASE_URL = process.env.REACT_APP_ROOT_URL;
function Login() {
  const {
    state,
    isLoading,
    setCurrentUser,
    updateCurrentPage,
    updateNextPage
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [warningMessage, setWarningMessage] = useState(false);
  const token = localStorage.getItem("token");
  const isPinVerify = localStorage.getItem("isPinVerify");
  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };
  useEffect(() => {
    if (token== "false" || token== '' || token== null) {
      navigate("/login");
    }
    else if (token !='false' && isPinVerify === "false") {
      navigate("/verifypin");
    }
  }, []);

  const onFinish = async (values) => {
    //  console.log("Success:", values);

    loadingCallback(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}user-login`, credentials)
      .then((response) => {
        setCurrentUser(response.data.user);
        loadingCallback(false);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("token", response.data.authorisation.token);
        navigate("/verifypin");
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      });
  };
  // const onFinishFailed = (errorInfo) => {
  //     console.log('Failed:', errorInfo);
  // };
  const handleSignUpClick = () => {
    updateCurrentPage('login');
    updateNextPage('signup-3');
    navigate("/signup");
  };

  const onInputChange = (e) => {
    // alert();
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  return (
    <section style={{ background: "#032843", height: '100%' }}>
      <div className="login-container">
        <div className="login-left">
          <img src={`${BASE_URL}/assets/registration/webp/login.webp`} alt="loginimage" />
        </div>
        <div className="login-right">
          <div className="login-right-card">
            <div className="login-log-img">
              <img src={`${BASE_URL}/assets/registration/webp/logo1.webp`} alt="logo" />
              <h1>Log In</h1>
              <p className="mt-10 mb-6 px-3 sm:px-0">
              To create a store for you, and keep<br></br>  track your expense for you.
              </p>
              {warningMessage && (
                <div className="warning-message">
                  Invalid username or password!
                </div>
              )}
              {loading && (

                <div className="custom-loader-logo">
                  {/* <BeatLoader color={"#123abc"} size={20} /> */}
                  <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
                </div>
              )}
            </div>
            <div className="login-form-main">
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    value={credentials.email}
                    onChange={onInputChange}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    backgroundColor: "#FFFFFF"
                  }}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #F1F1FA",
                      borderRadius: "16px",
                      fontSize: "16px",
                      paddingLeft: "0px",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      transaction:"none"
                    }}
                    name="password"
                    type="password"
                    placeholder="Password"
                    value={credentials.password}
                    onChange={onInputChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" className="login-form-button">
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div className="loginform-console">
              <Link className="login-form-forgot" to="/forget-password">
                Forgot password
              </Link>
              {process.env.REACT_APP_OTP_DISABLED_SIGNUP == "true" ?
                null
                :
                <p>
                  Don’t have an account yet?{" "}
                  <span onClick={handleSignUpClick} style={{ color: "#034AFF", textDecoration: "underline" }}>
                    <Link>Sign Up</Link>
                  </span>
                </p> }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
