import React from 'react';

// ExporterProductReportList Component
const ExporterProductReportList = React.forwardRef((props, ref) => {
  const { data } = props;
  {console.log(data)}
  return (
  <div ref={ref}>
      {/*
      <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
        <thead>
          <tr>
            <th style={styles.th}>#</th>
            <th style={styles.th}>Product Name</th>
            <th style={styles.th}>IMEI</th>
            <th style={styles.th}>Carrier Name</th>
            <th style={styles.th}>Exporter Name</th>
            <th style={styles.th}>Date</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td style={styles.td}>{index + 1}</td>
              <td style={styles.td}>{item.product_name}</td>
              <td style={styles.td}>{item.imei}</td>
              <td style={styles.td}>{item.carrier_name}</td>
              <td style={styles.td}>{item.exporter_name}</td>
              <td style={styles.td}>{item.date}</td>
            </tr>
          ))}
        </tbody>
      </table>
 */}
  {Object.keys(data).map((brandName) => (
        <div key={brandName}>
        <h2 style={{fontSize: '15px'}}>{brandName}</h2>
          <table>
            <thead>
              <tr >
                <th style={styles.th}>Serial No.</th>
                {/* <th style={styles.th}>Brand Nam</th> */}
                <th style={styles.th}>Product Name</th>
                <th style={styles.th}>IMEI</th>
                <th style={styles.th}>Carrier Name</th>
                <th style={styles.th}>Exporter Name</th>
                <th style={styles.th}>Date</th>
              </tr>
            </thead>
            <tbody>
              {data[brandName].map((product, index) => (
                <tr key={index} >
                  <td style={styles.td}>{index + 1}</td>
                  {/* <td style={styles.td}>{product.brand_name}</td> */}
                  <td style={styles.td}>{product.product_name}</td>
                  <td style={styles.td}>{product.imei}</td>
                  <td style={styles.td}>{product.carrier_name}</td>
                  <td style={styles.td}>{product.exporter_name}</td>
                  <td style={styles.td}>{product.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
 </div>
  );
});

const styles = {
  th: {
    border: '1px solid black',
    padding: '8px',
    textAlign: 'left',
    backgroundColor: '#f2f2f2',
    fontSize: '12px',
  },
  td: {
    border: '1px solid black',
    padding: '8px',
    fontSize: '12px',
  },
  h2: {
    fontSize: '12px',
  }
};

export default ExporterProductReportList;