import React, { useContext, useState, useRef, useEffect } from "react";
import Breadcrumb from '../Settings_components/Breadcrumb';
import Table from '../Settings_components/Table';
import { AppContext } from '../../../context/context.js';
import Pagination from '../../../utils/pagination';
//import { units } from '../../../data/data';
import axios from "axios";
const Units = () => {
    const {
        state,
        isLoading,
        updateUnitList
    } = useContext(AppContext);
    const token = localStorage.getItem("token");
    const [currentPage, setCurrentPage] = useState(1);
    const perPage = 20;

    useEffect(() => {
        getUnits(currentPage);
    },[currentPage]);
    const onPageChange = (page) => {
        setCurrentPage(page);
    }


    const [loader, setLoader] = useState(true);
    const [currentTotal, setCurrentTotal] = useState([]);

    const getUnits = (page) => {

        axios
        .get(
            `${process.env.REACT_APP_API_URL}units?page=${page}&limit=${perPage}`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        )
        .then((response) => {

            //console.log(response.data.data.data)

            if(response.data.success)
            {
                setLoader(false)

                updateUnitList(response.data.data)
                setCurrentTotal(response.data.data.data.length);
            }

        })
        .catch((error) => {
            console.log(error);
        });
    };
    const shouldRenderPagination = state.unitList?.total != currentTotal && state.unitList?.total > perPage;

    return (
        <div className='rounded-[27px]'  style={{backgroundColor: 'rgba(0, 148, 255, 0.15)' }}>
            <Breadcrumb></Breadcrumb>
           <Table  data={state.unitList} getUnits={getUnits}></Table>
           {shouldRenderPagination && (
                <Pagination
                    currentPage={currentPage}
                    lastPage={state.unitList.last_page}
                    onPageChange={onPageChange}
                />
            )}
           <div className="w-full relative px-10 md:px-[27px] overflow-x-auto sm:rounded-lg">
                {loader && (
                    <div className="custom-loader"></div>
                )}
            </div>
         </div>
    );
};

export default Units;
