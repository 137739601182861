import React from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/footer/Index';
import AddProducts from '../../components/products/productsadd/Index';
import ContentLayout from '../../layout/ContentLayout';
import "./index.css";
const RecentlyAddProductsPage= () => {
const navigate = useNavigate();
    return (
    <>
        <ContentLayout>
            <div className="main-content">
                {/* <ProductsList /> */}
                <AddProducts />
                <Footer />
            </div>
        </ContentLayout>
    </>
    );
};
export default RecentlyAddProductsPage