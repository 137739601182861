import React from "react";
import { AiOutlineSearch } from "react-icons/ai";

import ContentLayout from "../../layout/ContentLayout";
import ProductsList from "../../components/CustomerProfile/ProductsList/Index";

const CustomerMostPurchaseProductPage = () => {
  return (
    <>
      <ContentLayout>
        <ProductsList
          type="customer"
        />
      </ContentLayout>
    </>
  );
};

export default CustomerMostPurchaseProductPage;
