import { AiOutlinePicLeft } from "react-icons/ai";
import {RiMoneyDollarCircleLine} from "react-icons/ri";
import {BsTextIndentLeft} from 'react-icons/bs';
import {TbTargetArrow} from 'react-icons/tb';
import {LuSettings,LuFileQuestion} from 'react-icons/lu';
import {BiCommentError} from 'react-icons/bi';
import {FaDraftingCompass,FaPercent} from 'react-icons/fa';
import product1 from "../assits/product1.png";
import product2 from "../assits/product2.png";
import product3 from "../assits/product3.png";
import product4 from "../assits/product4.png";

const productSlider = [
  { id: 1, name: "Food-1", foodImg: product1 },
  { id: 2, name: "Food-2", foodImg: product1 },
  { id: 3, name: "Food-3", foodImg: product3 },
  { id: 4, name: "Food-4", foodImg: product4 },
  { id: 5, name: "Food-5", foodImg: product1 },
  { id: 6, name: "Food-6", foodImg: product4 },
  { id: 7, name: "Food-7", foodImg: product3 },
  { id: 8, name: "Food-8", foodImg: product1 },
  { id: 9, name: "Food-9", foodImg: product4 }
];

const productItems=[
    {
        id:1,
        icon: <RiMoneyDollarCircleLine/>,
        name:"#2949",
        sname:"Amelia Marsha",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "1075.00BDT",
        time: "30 min ago",
        colors: "#00A3EE"
    },
    {
        id:2,
        icon: <RiMoneyDollarCircleLine/>,
        name:"#2949",
        sname:"Amelia Marsha",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "1075.00BDT",
        time: "30 min ago",
        colors: "#00A3EE"
    },
    {
        id:3,
        icon: <RiMoneyDollarCircleLine/>,
        name:"#2949",
        sname:"Amelia Marsha",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "1075.00BDT",
        time: "30 min ago",
        colors: "#00A3EE"
    },
    {
        id:4,
        icon: <RiMoneyDollarCircleLine/>,
        name:"#2949",
        sname:"Amelia Marsha",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "1075.00BDT",
        time: "30 min ago",
        colors: "#00A3EE"
    },
    {
        id:5,
        icon: <RiMoneyDollarCircleLine/>,
        name:"#2949",
        sname:"Amelia Marsha",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "1075.00BDT",
        time: "30 min ago",
        colors: "#00A3EE"
    }

]

const subcategory=[
    {
        id:1,
        icon: <BsTextIndentLeft/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#FD2253"
    },
    {
        id:2,
        icon: <BsTextIndentLeft/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#FD2253"
    },
    {
        id:3,
        icon: <BsTextIndentLeft/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#FD2253"
    },
    {
        id:4,
        icon: <BsTextIndentLeft/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#FD2253"
    },
    {
        id:5,
        icon: <BsTextIndentLeft/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#FD2253"
    }

]
const help=[
    {
        id:1,
        icon: <LuFileQuestion/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#833471"
    },
    {
        id:2,
        icon: <LuFileQuestion/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#833471"
    },
    {
        id:3,
        icon: <LuFileQuestion/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#833471"
    },
    {
        id:4,
        icon: <LuFileQuestion/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#833471"
    },
    {
        id:5,
        icon: <LuFileQuestion/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#833471"
    }

]

const setting=[
    {
        id:1,
        icon: <LuSettings/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#12CBC4"
    },
    {
        id:2,
        icon: <LuSettings/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#12CBC4"
    },
    {
        id:3,
        icon: <LuSettings/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#12CBC4"
    },
    {
        id:4,
        icon: <LuSettings/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#12CBC4"
    },
    {
        id:5,
        icon: <LuSettings/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#12CBC4"
    }

]
const discounts=[
    {
        id:1,
        icon: <FaPercent/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#FF6C02"
    },
    {
        id:2,
        icon: <FaPercent/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#FF6C02"
    },
    {
        id:3,
        icon: <FaPercent/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#FF6C02"
    },
    {
        id:4,
        icon: <FaPercent/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#FF6C02"
    },
    {
        id:5,
        icon: <FaPercent/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#FF6C02"
    }

]
const units=[
    {
        id:1,
        icon: <FaDraftingCompass/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#0094FF"
    },
    {
        id:2,
        icon: <FaDraftingCompass/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#0094FF"
    },
    {
        id:3,
        icon: <FaDraftingCompass/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#0094FF"
    },
    {
        id:4,
        icon: <FaDraftingCompass/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#0094FF"
    },
    {
        id:5,
        icon: <FaDraftingCompass/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#0094FF"
    }

]
const brands=[
    {
        id:1,
        icon: <TbTargetArrow/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#00B894"
    },
    {
        id:2,
        icon: <TbTargetArrow/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#00B894"
    },
    {
        id:3,
        icon: <TbTargetArrow/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#00B894"
    },
    {
        id:4,
        icon: <TbTargetArrow/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#00B894"
    },
    {
        id:5,
        icon: <TbTargetArrow/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#00B894"
    }

]
const send_feedback=[
    {
        id:1,
        icon: <BiCommentError/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#A3CB38"
    },
    {
        id:2,
        icon: <BiCommentError/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#A3CB38"
    },
    {
        id:3,
        icon: <BiCommentError/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#A3CB38"
    },
    {
        id:4,
        icon: <BiCommentError/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#A3CB38"
    },
    {
        id:5,
        icon: <BiCommentError/>,
        name:"Smart Phone",
        sname:"Phone",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "50,000Pcs",
        time: "Available Products",
        colors: "#A3CB38"
    }

]
const category=[
    {
        id:1,
        icon: <AiOutlinePicLeft></AiOutlinePicLeft>,
        name:"#2949",
        sname:"Amelia Marsha",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "1075.00BDT",
        time: "30 min ago",
        colors: "#551FFF"
    },
    {
        id:2,
        icon: <AiOutlinePicLeft></AiOutlinePicLeft>,
        name:"#2949",
        sname:"Amelia Marsha",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "1075.00BDT",
        time: "30 min ago",
        colors: "#551FFF"
    },
    {
        id:3,
        icon: <AiOutlinePicLeft></AiOutlinePicLeft>,
        name:"#2949",
        sname:"Amelia Marsha",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "1075.00BDT",
        time: "30 min ago",
        colors: "#551FFF"
    },
    {
        id:4,
        icon: <AiOutlinePicLeft></AiOutlinePicLeft>,
        name:"#2949",
        sname:"Amelia Marsha",
        product: "IPhone 13 pro, Laptop, Laptop bag, Camera, Gopro",
        price: "1075.00BDT",
        time: "30 min ago",
        colors: "#551FFF"
    }

]


export {productItems,category,send_feedback,brands,units,discounts,setting,help,subcategory,productSlider}