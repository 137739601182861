import React from "react";

const ExporterCarrierInfoViewForm = (props) => {
  const {
    formId,
    navigate,
    loadingCallback,
    setWarningMessage,
    setShowModal,
    carrierData,
    baseCurrency
  } = props;

  const groupedProducts2 = carrierData.carrier_product_imeis.reduce((groups, product) => {
    // Check if a product with the same ID exists in the group
    if (groups[product.id]) {
      // If the product with the same ID exists, push the current product under the imeis key of the existing product
      groups[product.id].imeis.push(product.selectedImei);
    } else {
      // If the product with the same ID does not exist, create a new product object and initialize the imeis key with an array containing the current product's selectedImei
      groups[product.id] = {
        ...product,
        imeis: [product.selectedImei]
      };
    }
    return groups;
  }, {});

  const modalClose = () => setShowModal(false);

  return (
    <>
      <style>
        {`
          .custom-scrollbar::-webkit-scrollbar {
            width: 7px;
          }

          .custom-scrollbar::-webkit-scrollbar-track {
            background-color: #F1F1FA;
          }

          .custom-scrollbar::-webkit-scrollbar-thumb {
            background-color: #034AFF;
            border-radius: 8px;
          }

          .custom-scrollbar::-webkit-scrollbar-thumb:hover {
            background-color: #6B7280;
          }
        `}
      </style>

      <div className="font-nunito font-bold text-[#16px] tex-[#000232] mb-2">
        Carrier Details
      </div>
      <div className="grid grid-cols-3 gap-3 mb-4">
        <div className="font-nunito text-[12px]">
          <div className="font-[700] text-[#6F8EB6]">Carrier Name</div>
          <div className="font-[400] text-[#000000]">{carrierData?.carrier_info?.name}</div>
        </div>

        <div className="font-nunito text-[12px]">
          <div className="font-[700] text-[#6F8EB6] text-center">Email</div>
          <div className="font-[400] text-[#000000]">{carrierData?.carrier_info?.email}</div>
        </div>

        <div className="font-nunito text-[12px]">
          <div className="font-[700] text-[#6F8EB6] text-right">Phone Number</div>
          <div className="font-[400] text-[#000000] text-[16px] text-right">{carrierData?.carrier_info?.phone_no}</div>
        </div>

        <div className="font-nunito text-[12px]">
          <div className="font-[700] text-[#6F8EB6]">Receiving QTY</div>
          <div className="font-[400] text-[#000000]">{carrierData?.carrier_info?.received_qty}</div>
        </div>

        <div className="font-nunito text-[12px]">
          <div className="font-[700] text-[#6F8EB6] text-center">Address</div>
          <div className="font-[400] text-[#000000] text-[16px]">{carrierData?.carrier_info?.address}</div>
        </div>

        <div className="font-nunito text-[12px]">
          <div className="font-[700] text-[#6F8EB6] text-right">ID/Pass</div>
          <div className="font-[400] text-[#000000] text-right">{carrierData?.carrier_info?.nid_passport_no}</div>
        </div>
      </div>

      <div className="rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[12px] py-[15px] mb-[50px]">
        <div className="font-lato font-[700] text-[12px] text-[#000232] mb-2">
          List of IMEI
        </div>

        <div className="max-h-[100px] overflow-y-auto custom-scrollbar">
          <div className="flex flex-row gap-2 font-nunito font-bold text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
            <div className="py-[5px] flex-1">
              SL
              <br />
              No
            </div>
            <div className="py-[5px] text-center flex-1">Condition</div>
            <div className="py-[5px] text-center flex-1">Ram</div>
            <div className="py-[5px] text-center flex-1">Storage</div>
            <div className="py-[5px] text-right w-[35%] pr-[7px]">
              IMEI
              <br />
              Number
            </div>
          </div>

           {carrierData.carrier_product_imeis ? carrierData.carrier_product_imeis?.map((item, index) => (
            <div key={index} className="flex flex-row gap-2 font-nunito font-[400] text-[14px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
              <div className="py-[5px] flex-1">{index+1}</div>
              <div className="py-[5px] text-center flex-1">{item?.condition}</div>
              <div className="py-[5px] text-center flex-1">{item?.ram}</div>
              <div className="py-[5px] text-center flex-1">{item?.storage}</div>
              <div className="py-[5px] text-right w-[35%] pr-[7px]">{item?.imei}</div>
            </div>
          )) : null}
        </div>
      </div>

      <div className="flex flex-row gap-3">
        <input
          onClick={modalClose}
          type="button"
          value="Back"
          className="btn w-[48%] text-[#034AFF] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none capitalize"
        />
        <input
          type="button"
          onClick={modalClose}
          value="Done"
          className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF] capitalize"
        />
      </div>
    </>
  );
};

export default ExporterCarrierInfoViewForm;
