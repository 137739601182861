import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { AppContext } from "../../../context/context.js";

import "./index.css";
const BASE_URL = process.env.REACT_APP_ROOT_URL;
const shop_logo = `${BASE_URL}/layoutlogo.svg`;
const MAX_MOBILE_SCREEN_WIDTH = 767;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="p-[6px] rounded-full absolute top-[46%] -right-[25px]"
      style={{ ...style, display: "inline-block", background: "#7F8098" }}
      onClick={onClick}
    >
      <AiOutlineArrowRight className="text-[#FFF] text-base font-bold"></AiOutlineArrowRight>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="p-[6px] rounded-full absolute top-[46%] -left-[25px] z-50"
      style={{ ...style, display: "inline-block", background: "#7F8098" }}
      onClick={onClick}
    >
      <AiOutlineArrowLeft className="text-[#FFF] text-base font-bold"></AiOutlineArrowLeft>
    </div>
  );
}

const SellingCard = (props) => {
  const { type, loadingCallback, mostSellingData } = props;
  const { state } = useContext(AppContext);
  const { invoiceSetting } = state;
  const token = localStorage.getItem("token");
  const [filterItem, setFilterItem] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const [isMobile, setIsMobile] = useState(false);

  const navigate = useNavigate();

  const getTopSaleProductlist = () => {
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}top-sales`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          setFilterItem(response.data.data.data);
          setFilteredProducts(response.data.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.setItem("token", false);
          localStorage.setItem("token", false);
          localStorage.setItem("user", "");
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };

  useEffect(() => {
    if(filterItem.length === 0 && filteredProducts.length === 0)
      getTopSaleProductlist();
    if (
      typeof window !== "undefined" &&
      document.documentElement.clientWidth <= MAX_MOBILE_SCREEN_WIDTH
    ) {
      setIsMobile(true);
    }

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const handleResize = () => {
    if (
      typeof window !== "undefined" &&
      document.documentElement.clientWidth <= MAX_MOBILE_SCREEN_WIDTH
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // Calculate the number of products to show in the carousel
  const numberOfProducts = Math.min(filteredProducts.length - 4, 5); // Set a maximum number of 7 products
  //const numberOfProducts = 5;
  const settings = {
    className: "sliderAreaHome md:min-w-[500px] lg:min-w-[700px] w-[100%]",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Adjust slidesToShow based on the number of products
    slidesToScroll: 2, // Adjust slidesToScroll as well
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const settingsMobile = {
    className: "sliderAreaHome md:min-w-[300px] w-[250px] sm-[300p]",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Adjust slidesToShow based on the number of products
    slidesToScroll: 1, // Adjust slidesToScroll as well
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const handleProduct = (item) => {
    navigate(`/products/${item.id}`);
  };

  return (
    <div className="RecentSalesTable  bg-[#FFF] rounded-[25px] px-[30px] pt-[28px] pb-[30px] mt-[30px]">
      {mostSellingData.length > 0 && (
        <div className="title-link">
          <h1>Most Selling Products</h1>
          <Link className="whitespace-nowrap" to="/most-selling-product">
            View All
          </Link>
        </div>
      )}

      {mostSellingData.length > 0 &&
      mostSellingData.length <= 5 &&
      !isMobile ? (
        <div className="flex flex-row mt-4">

          {mostSellingData?.map((item, index) => (
            <div
                key={index}
                onClick={() => {
                  handleProduct(item.product_info);
                }}
                className="cursor-pointer flex flex-col  justify-center items-center"
              >
                <div
                  className={`border-2 bg-white hover:scale-[1.15] hover:sticky hover:z-10  transform duration-300 ${item.product_info.current_stock === 0
                              ? "border-[#ca1830]"
                              : item.product_info.current_stock <= 5
                              ? "border-[#FBBC05]"
                              : "border-[#18CA29]"} mr-[25px] h-64 w-52 rounded-[20px] p-[12px]`}
                  // style={{
                  //   "box-shadow": "0px 2px 10px rgba(0, 0, 0, 0.10)",
                  // }}
                >
                  <div className="w-full md:h-[135px] rounded-2xl text-center flex justify-center border border-[1px_solid_#a3a3a3] overflow-hidden lg:h-[140px]">
                    <img
                      src={item.product_info.image_path ? item.product_info.image_path : shop_logo}
                      alt="categoryDymmyImaage"
                      className=" object-contain "
                    />
                  </div>
                  <div className="mt-[12px]">
                    <div className="flex flex-row justify-between">
                      <div className="text-[#000232] text-[12px] font-nunito font-bold">
                        {item.product_info.name}
                      </div>
                      <div className="text-[#000232] text-[12px] font-nunito font-bold">
                        {item.product_info?.brands?.name ? item.product_info.brands.name : null}
                      </div>
                    </div>
                    <div className="mt-[4px] text-[#A0AEC0] text-[12px] font-nunito font-semibold">
                      #{item.product_info?.barcode}
                    </div>
                    <div className="mt-[6px] text-[#000232] text-[14px] font-nunito font-bold">
                      <p>
                        {item.product_info.retails_price}{" "}
                        {invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info?.code
                          : "BDT"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
          ))}
        </div>
      ) : isMobile ? (
        <div className="sellingcard-container1">
          <Slider {...settingsMobile}>
            {filteredProducts?.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  handleProduct(item);
                }}
                className="cursor-pointer flex flex-col justify-center items-center"
              >
                <div
                  className={`bg-white border-2 ${item?.current_stock === 0
                              ? "border-[#ca1830]"
                              : item?.current_stock <= 5
                              ? "border-[#FBBC05]"
                              : "border-[#18CA29]"} mr-[25px] h-64 w-40 sm:w-44 md:w-48 rounded-[20px] p-[12px]`}
                  style={{
                    "box-shadow": "0px 6px 13px 0px rgba(0, 0, 0, 0.10)",
                  }}
                >
                  <div className=" h-[140px] flex justify-center border border-[1px_solid_#a3a3a3] overflow-hidden rounded-2xl">
                    <img
                      src={item.image_path ? item.image_path : shop_logo}
                      alt="categoryDymmyImaage"
                      className="h-[100%] object-contain"
                    />
                  </div>
                  <div className="mt-[12px]">
                    <div className="flex flex-row justify-between">
                      <div className="text-[#000232] text-[12px] font-nunito font-bold">
                        {item.name}
                      </div>
                      <div className="text-[#000232] text-[12px] font-nunito font-bold">
                        {item?.brands?.name ? item.brands.name : null}
                      </div>
                    </div>
                    <div className="mt-[4px] text-[#A0AEC0] text-[12px] font-nunito font-semibold">
                      #{item?.barcode}
                    </div>
                    <div className="mt-[6px] text-[#000232] text-[14px] font-nunito font-bold">
                      <p>
                        {item.retails_price}{" "}
                        {invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info?.code
                          : "BDT"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) : filteredProducts.length > 3 && !isMobile ? (
        <div className="sellingcard-container1">
          <Slider {...settings}>
            {filteredProducts?.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  handleProduct(item);
                }}
                className="cursor-pointer flex flex-col  justify-center items-center"
              >
                <div
                  className={`border-2 bg-white hover:scale-[1.15] hover:sticky hover:z-10  transform duration-300 ${item.current_stock === 0
                              ? "border-[#ca1830]"
                              : item.current_stock <= 5
                              ? "border-[#FBBC05]"
                              : "border-[#18CA29]"} mr-[25px] h-64 w-52 rounded-[20px] p-[12px]`}
                  // style={{
                  //   "box-shadow": "0px 2px 10px rgba(0, 0, 0, 0.10)",
                  // }}
                >
                  <div className="w-full md:h-[135px] rounded-2xl text-center flex justify-center border border-[1px_solid_#a3a3a3] overflow-hidden lg:h-[140px]">
                    <img
                      src={item.image_path ? item.image_path : shop_logo}
                      alt="categoryDymmyImaage"
                      className=" object-contain "
                    />
                  </div>
                  <div className="mt-[12px]">
                    <div className="flex flex-row justify-between">
                      <div className="text-[#000232] text-[12px] font-nunito font-bold">
                        {item.name}
                      </div>
                      <div className="text-[#000232] text-[12px] font-nunito font-bold">
                        {item?.brands?.name ? item.brands.name : null}
                      </div>
                    </div>
                    <div className="mt-[4px] text-[#A0AEC0] text-[12px] font-nunito font-semibold">
                      #{item?.barcode}
                    </div>
                    <div className="mt-[6px] text-[#000232] text-[14px] font-nunito font-bold">
                      <p>
                        {item.retails_price}{" "}
                        {invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info?.code
                          : "BDT"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) : null}
    </div>
  );
};

export default SellingCard;
