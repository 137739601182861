import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";

const AddTxnTypeForm = (props) => {
  const {
    loadingCallback,
    navigate,
    setShowModal,
    formData,
    setFormData,
    isOpen,
    title,
    refetch,
    setWarningMessage,
    type,
    selectType,
  } = props;
  const token = localStorage.getItem("token");
  const handleSubmit = () => {
    loadingCallback(true);
    if (selectType === "partyNameList") {
      const newFormData = {
        name: formData.head_name,
        description: formData.head_description,
      };
      if (newFormData?.name) {

        axios
          .post(
            `${process.env.REACT_APP_API_URL}save-party-name`,
            newFormData,
            {
              headers: {
                authorization: `Bearer ${token}`,
                "content-type": "application/json",
              },
            }
          )
          .then((res) => {
            loadingCallback(false);
            if (res.data.success) {
              setFormData({
                head_description: "",
                head_name: "",
              });
              refetch();
            }
          })
          .catch((err) => {
            loadingCallback(false);
            console.log(err);
          });
        setShowModal((val) => !val);
        return;
      }
      setShowModal((val) => !val);
      loadingCallback(false);
      return;
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}save-transaction-type`, formData, {
        headers: {
          authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        loadingCallback(false);
        if (res.data.success) {
          refetch();
        }
      })
      .catch((err) => {
        loadingCallback(false);
        console.log(err);
      });
    setShowModal((val) => !val);
  };

  return (
    <div className="duration-500">
      <div className="flex items-center mt-2 justify-center py-2 gap-3">
        <div className="flex-1 relative">
          <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
            Name
          </label>
          <input
            type="text"
            name="head_name"
            placeholder="Name"
            onChange={(e) =>
              setFormData({
                ...formData,
                head_name: e.target.value,
              })
            }
            className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
          />
        </div>
      </div>
      <div className="flex items-center justify-center py-2 gap-3">
        <div className="w-full relative">
          <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
            Description
          </label>
          <textarea
            type="text"
            name="head_description"
            placeholder="Description"
            onChange={(e) =>
              setFormData({
                ...formData,
                head_description: e.target.value,
              })
            }
            className="input input-bordered border-[#C1CFEF] border-[1px] focus:outline-none  w-full mb-[10px] h-16 pt-2 rounded-xl bg-white resize-none"
          />
        </div>
      </div>

      <div>
        <button
          onClick={handleSubmit}
          className="cursor-pointer w-full mt-4 bg-[#3559b3] hover:bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default AddTxnTypeForm;
