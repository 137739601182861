import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const CustomNavLink  = ({ to, children, activeColor,className,menuName,showNavigation }) => {
    const location = useLocation();
  const isActive = location.pathname === to;
  const activeStyle = {
    backgroundColor: isActive ? `${activeColor}` : 'transparent'
  };
  const activeHidden = {
    display: isActive ? "none" : 'block'
  };

  const isSettingsActive = (location.pathname === '/settings/invoice-settings') || (location.pathname === '/settings/shop-settings');

  const activeSettingsStyle = {
    backgroundColor: isSettingsActive ? `${activeColor}` : 'transparent'
  };
  const activeSettingsHidden = {
    display: isSettingsActive ? "none" : 'block'
  }; 

    return (
        <>
          {showNavigation ? (
            <>
              <a href="javascript:void(0);" onClick={showNavigation} style={activeSettingsStyle} className={className}>
              {children}
              </a>
              <h4 style={activeSettingsHidden}  className="pt-[5px] font-lato font-semibold uppercase text-[9px]">
                {menuName}
              </h4>
            </>

          ) : (
            <>
              <NavLink to={to} style={activeStyle} className={className}>
              {children}
              </NavLink>
              <h4 style={activeHidden}  className="pt-[5px] font-lato font-semibold uppercase text-[9px]">
                {menuName}
              </h4>
            </>
          )}
        </>
    );
};

export default CustomNavLink ;