import React from 'react'
import ContentLayout from '../../layout/ContentLayout'
import "./index.css"
import Footer from '../../components/footer/Index'
import AddProducts from '../../components/products/productsadd/Index'
const AddProductsPage= () => {
    return (
    <>
        <ContentLayout>
            <div className="main-content">
                {/* <ProductsList /> */}
                <AddProducts />
                <Footer />
            </div>
        </ContentLayout>
    </>
    );
};
export default AddProductsPage