import React from 'react';
import { formatDate, formatPrice, timeDiff } from '../../utils/Common';

const CategoryHistoryItems = (props) => {

  const { categoryMostDataHistory ,tabActive} = props;
  const createdDate = new Date(categoryMostDataHistory.date);
  return (
    <>
    {tabActive &&
    <div className="border-b-[1px] text-sm border-solid border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-4 items-start">
    {/* Most Selling Category*/}
    <div className="text-[#000]  text-left font-[500]">
      {categoryMostDataHistory?.name}
    </div>
    {/* Total sell unit */}
    <div className=" text-[#000] font-[500] text-center">{categoryMostDataHistory?.total_products_sold}</div>
    {/* due */}
    <div className=" text-[#000] font-[500] text-center">{formatPrice(categoryMostDataHistory?.total_due_amount)}</div>
    {/* Total Sale Amount*/}
    <div className=" text-[#000] font-[500] text-right">{formatPrice(categoryMostDataHistory?.amount)}</div>

  </div>
    }
    </>

  );
};
CategoryHistoryItems.defaultProps = { className: null };
export default CategoryHistoryItems;