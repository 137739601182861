import axios from "axios";
import React, { useContext, useState, useRef } from "react";
//import Select2 from "react-chosen";
import Resizer from "react-image-file-resizer";
import { useNavigate } from 'react-router-dom';
import { uploadFile,validateData } from '../utils/Common';
import { AppContext } from '../context/context';
import ImageUploader2 from './ImageUploader2';

const CategoryForm = props => {
  const {
    loadingCallback,
    setBrand,
    setWarningMessage,
    state,
    setShowModal,
    formId,
    setCategoryData
  } = props;
    // Gneral Focus Hook
    const useFocus = () => {
        const htmlElRef = useRef(null)
        const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

        return [ htmlElRef, setFocus ]
    }
    const {
        isLoading,
        updateCategoryList,
        updateSingleCategoryList,
        updateSubCategoryList,
        updateBrandList,
        updateUnitList,
        updateInvoiceSetting
    } = useContext(AppContext);
  const [isValid, setIsValid] = useState(true);
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const [inputErors, setInputErrors] = useState({
      name: ''
  });
  const validateForm = (inputName = "Brand") => {
    let valid = true;
    const newInputErrors = { ...inputErors };
    if (validateData(name, inputName)) {
      newInputErrors.name = validateData(name, inputName);
      valid = false;
    }
    setInputErrors(newInputErrors);
    return valid;
  };




  const [inputRef, setInputFocus] = useFocus();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const getCategory = () => {
    loadingCallback(true);
    get("get-all-category", token)
      .then((response) => {
        loadingCallback(false);
        if (response.success) {
          updateCategoryList(response.data);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        console.log(error);
      });
  };


    function handleSubmitCategory(e) {
        e.preventDefault();
        if (validateForm("Category")) {
          loadingCallback(true);
          axios
              .post(
                  `${process.env.REACT_APP_API_URL}save-category`,
                  { name: name, description: description },
                  {
                      headers: {
                          authorization: `Bearer ${token}`,
                          'Content-Type': 'application/json',
                      },
                  }
              )
              .then((response) => {
                  loadingCallback(false);
                  //console.log(response.data.success);
                  if (response.data.success) {
                      setName('');
                      setDescription('');
                      //navigate('/settings/category');
                      setShowModal(false);
                      // window.location.reload();
                      //getCategory();
                      setCategoryData('ok');
                  } else {
                      setName('');
                      setDescription('');
                  }
              })
              .catch((error) => {
                  console.log(error);
                  loadingCallback(false);
                  if (error?.response?.status === 401) {
                      navigate('/login');
                      localStorage.setItem('token', '');
                      localStorage.setItem('token', '');
                      // Clear session and local storage
                      localStorage.setItem('isPinVerify', false);

                      navigate('/login');
                  }
              });
        } else {
          console.log('Form validation failed.');
        }
    }

    return (
        <form
            method="get"
            className="col-sm-12"
            data-autosubmit="false"
            autoComplete="off"
            onSubmit={handleSubmitCategory}
        >
            <input
                type="text"
                name="name"
                value={name}
                onChange={(e) => {
                  const val = e.target.value;
                  setName(val);
                  setInputErrors({ ...inputErors, name: null });
                }}
                ref={inputRef}
                placeholder="Category Name"
                className="input input-bordered w-full mb-[15px] rounded-2xl bg-white"
            />
            {inputErors.name
            ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
            : null}
            <textarea
                name="description"
                value={description}
                onChange={(e) => {
                    const val = e.target.value;
                    setDescription(val);
                }}
                className="textarea textarea-bordered w-full mb-[15px] resize-none rounded-2xl bg-white"
                placeholder="Category Description..."
            ></textarea>
            <div className="button-disabled">
                <button
                    htmlFor="my_modal_2"
                    type="submit"
                    className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                >
                    {''}
                    Save{''}
                </button>
            </div>
        </form>
    );
};

export default CategoryForm;
