import classNames from "classnames";
import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import { useFlashMessage } from "../context/FlashMessageContext";
//import Select2 from "react-chosen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { post } from "../services/apiService";
import { useNavigate } from "react-router-dom";
import FlagIcon from "./FlagIcon";
import DialCodes from "../data/dialCodes.json";

import Input from "./Input";

const ExporterAddForm = (props) => {
  const {
    loadingCallback,
    setExporter,
    setWarningMessage,
    state,
    setShowModal,
    setExporterData,

  } = props;
  // Gneral Focus Hook
  const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };

  const [inputRef, setInputFocus] = useFocus();

  const { countryList } = state;
  const [country, setCountry] = useState("");
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState("");
  const [cityList, setCityList] = useState([]);
  const {
    getFlashMessageStyle,
    setSuccessFlashMessage,
    flashMessage,
    setErrorFlashMessage,
  } = useFlashMessage(); // Retrieve the dispatch function from your state management library
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedExporter, setSelectedCustomer] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [showNewVendorForm, setShowNewVendorForm] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isValid, setIsValid] = useState(true);
  const navigate = useNavigate();
  const page = 1;
  const perPage = 20;
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (selectedExporter && selectedExporter.country) {
      setCountry(selectedExporter.country);
      getDistrictList(selectedExporter.country);
    } else {
      getDistrictList(country);
    }
  }, [country, selectedExporter]);

  const getDistrictList = (country) => {
    post("get-district-by-country", { country_id: country }, token)
      .then((response) => {
        if (response.success) {
          setDistrictList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCityList = (district) => {
    setDistrict(district);
    post("get-cities-by-district", { district_id: district }, token)
      .then((response) => {
        if (response.success) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDialCodeByCountryCode = (code) => {
    const country = DialCodes.find((item) => item.code === code);
    return country ? country.dial_code : "";
  };

  const handleValidation = (formData) => {
    const errors = {};
    let validate = true;
    // if (formData.proprietor_email === '') {
    //   errors.proprietor_email = 'Email is required.';
    //   setIsValid(false);
    //   validate = false;
    // }
    if (formData.email && formData.email !== "") {
      if (formData.email.indexOf("@") === -1) {
        errors.email = "Email is not valid.";
        setIsValid(false);
        validate = false;
      } else {
        //Email address cannot have only numbers before @
        const emailParts = formData.email.split("@");
        if (emailParts[0].match(/^[0-9]+$/) !== null) {
          errors.email = "Email is not valid.";
          setIsValid(false);
          validate = false;
        }
      }
    }
    if (formData.name === "") {
      errors.name = "Vendor name is required.";
      setIsValid(false);
      validate = false;
    }
    //proprietor_name
    if (formData.name === "") {
      errors.name = "Proprietor name is required.";
      setIsValid(false);
      validate = false;
    }

    // if (formData.vendor_phone === "") {
    //   errors.vendor_phone = "Phone is required.";
    //   setIsValid(false);
    //   validate = false;
    // }
    setFormErrors(errors);
    if (!validate) {
      setErrorFlashMessage("Please fill all the required fields.");
    }

    return validate;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const formObject = {};

    formData.forEach((value, key) => {
      formObject[key] = value;
    });
    //if (!selectedExporter) {
      //formObject.vendor_id = 0;
    //}
    if (handleValidation(formObject)) {
      // console.log("============");
      // console.log(isValid);
      // console.log(formObject);
      // console.log(formErrors);
      const vendorData = {
        name: formObject.name ? formObject.name : null,
        email: formObject.email ? formObject.email : null,
        phone_no: formObject.phone_number ? formObject.phone_number : null,
        additional_phone_no: formObject.additional_phone_number ? formObject.additional_phone_number : null,
        org_name: formObject.organization_name ? formObject.organization_name : null,
        org_email: formObject.organization_email ? formObject.organization_email : null,
        address: formObject.address ? formObject.address : null,
      };
      // setExporter(vendorData);
      // setExporterData(vendorData);

      createExporter(vendorData);
    } else {
      console.error("Form validation failed.");
    }
  };

  const createExporter = (formData) => {
    loadingCallback(true);
    // setVendorData(formData);
    // setVendor(formData);
    post("save-exporter", formData, token)
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          setExporter(response.data);
          setExporterData(response.data);
          setSuccessFlashMessage("Exporter saved successfully");
          //setShowNewVendorForm(false);
        } else {
          console.log("Exporter insertion failed");

          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.setItem("token", "");
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
        console.log(error);
      });
  };

  const handleErrorInputChange = (fieldName, value) => {
    if (value !== "") {
      //formErrors[fieldName] = null;
      // unset formErrors by fieldName
      const updatedFormErrors = { ...formErrors };
      delete updatedFormErrors[fieldName];
      setFormErrors(updatedFormErrors);
    }
  };

  const hideNestedModal = () => {
    setShowModal(false);
  };

  return (
    <form
      method="get"
      className="col-sm-12 min-h-[10vh]"
      data-autosubmit="false"
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col gap-3 mt-3">
        <div className="mb-3 relative">
          <input
            type="text"
            id="name"
            name="name"
            className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
            placeholder="Name"
            onChange={(e) => {
              handleErrorInputChange("name", e.target.value);
            }}
          />
          {formErrors && formErrors.name ? (
            <p className="error-message text-red-500">{formErrors.name}</p>
          ) : null}
          <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
            Name
          </label>
        </div>
        <div className="mb-3 relative">
          <input
            type="text"
            id="email"
            name="email"
            className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
            placeholder="Email"
            onChange={(e) => {
              handleErrorInputChange("email", e.target.value);
            }}
          />
          {formErrors && formErrors.email ? (
            <p className="error-message text-red-500">{formErrors.email}</p>
          ) : null}
          <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
            Email
          </label>
        </div>
        <div className="mb-3 relative">
          <input
            type="text"
            id="phone"
            name="phone_number"
            className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
            placeholder="Phone Number"
            onChange={(e) => {
              handleErrorInputChange("phone_number", e.target.value);
            }}
          />
          {formErrors && formErrors.phone_nmber ? (
            <p className="error-message text-red-500">
              {formErrors.phone_nmber}
            </p>
          ) : null}
          <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
            Phone Number
          </label>
        </div>
        <div className="mb-3 relative">
          <input
            type="text"
            id="additional_phone_number"
            name="additional_phone_number"
            className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
            placeholder="Additional Phone Number"
          />
          <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
            Additional Phone Number
          </label>
        </div>
        <div className="mb-3 relative">
          <input
            type="text"
            id="address"
            name="address"
            className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
            placeholder="Address"
          />
          <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
            Address
          </label>
        </div>
        <div className="mb-3 relative">
          <input
            type="text"
            id="organization_name"
            name="organization_name"
            className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
            placeholder="Organization Name"
          />
          <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
            Organization Name
          </label>
        </div>
        <div className="mb-3 relative">
          <input
            type="text"
            id="organization_email"
            name="organization_email"
            className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
            placeholder="Organization Email"
          />
          <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
            Organization Email
          </label>
        </div>
      </div>

      <div className="flex flex-row gap-3 mt-3">
        <input
          onClick={hideNestedModal}
          type="button"
          value="Back"
          className="btn w-[48%] text-[#034AFF] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none"
        />
        <input
          type="submit"
          value="Next"
          className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF]"
        />
      </div>
    </form>
  );
};

export default ExporterAddForm;
