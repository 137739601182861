import classNames from 'classnames';
import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import { useFlashMessage } from "../context/FlashMessageContext";
//import Select2 from "react-chosen";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { post } from "../services/apiService";
import { useNavigate } from 'react-router-dom';
import FlagIcon from "./FlagIcon";
import DialCodes from '../data/dialCodes.json';
import SelectBox from './Select';
import { FlashMessage } from '../utils/FlashMessage';

import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

const InvoiceCustomerForm = props => {
  const {
    inputRef,
    loadingCallback,
    filteredCustomers,
    setFilteredCustomers,
    setCustomer,
    setWarningMessage,
    state,
    setShowModal,
    setShowNestedModal,
    notify,
    setNestedModalData
  } = props;
  const { countryList } = state;
  const [country, setCountry] = useState('');
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState('');
  const [cityList, setCityList] = useState([]);
  const { getFlashMessageStyle, setSuccessFlashMessage, flashMessage, setErrorFlashMessage } = useFlashMessage(); // Retrieve the dispatch function from your state management library
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [showNewCustomerForm, setShowNewCustomerForm] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isValid, setIsValid] = useState(true);
  const navigate = useNavigate();
  const page = 1;
  const perPage = 20;
  const token = localStorage.getItem("token");

  // const notify = (type, msg) =>{
  //   if(type=='success'){
  //     toast.success(msg, {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //       // transition: Bounce,
  //     });
  //   }
  //   if(type=='error'){
  //     toast.error(msg, {
  //       position: "top-right",
  //       autoClose: 1500,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //       // transition: Bounce,
  //     });
  //   }
  // }

  const getCustomerListData = async (searchKeyword='') => {
    loadingCallback(true);
    const page1 = 1;
    if (filteredCustomers?.length === 0 && searchKeyword === "") {
      const options = [];
      axios
      .get(
        `${process.env.REACT_APP_API_URL}customer`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          const customers = response?.data?.data?.data ? response.data.data.data : response.data.data;
          // Append other options if there are customers
          if (customers.length > 0) {
            const customerOptions = customers.map((customer) => ({
              id: customer.id,
              name: customer.name,
              email: customer.email,
              country_code: customer.country_code,
              mobile_number: customer.mobile_number,
              address: customer.address,
              country: customer.country,
              district: customer.district,
              city: customer.city,
              zipcode: customer.zipcode,
              nid: customer.nid
            }));
            options.push(...customerOptions);
          }
        } else {
          console.log("Data Not Found!");
        }
        setFilteredCustomers(options);
      })
      .catch((error) => {
        loadingCallback(false);
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
      });
    } else {
      setFilteredCustomers([]);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}search-customer?page=${page1}&limit=${perPage}`,
          { keyword: searchKeyword },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          loadingCallback(false);
          const options = [];
          if (response.status === 200) {
            //setFilteredCustomers(response.data.data.data);
            const customers = response?.data?.data?.data ? response.data.data.data : response.data.data;

            // Append other options if there are customers
            if (customers.length > 0) {
              const customerOptions = customers.map((customer) => ({
                id: customer.id,
                name: customer.name,
                email: customer.email,
                country_code: customer.country_code,
                mobile_number: customer.mobile_number,
                address: customer.address,
                country: customer.country,
                district: customer.district,
                city: customer.city,
                zipcode: customer.zipcode,
                nid: customer.nid
              }));
              options.push(...customerOptions);
            }

          } else {
            console.log("Data Not Found!");
            const customerOptions = [];
            options.push(customerOptions);
          }
          setFilteredCustomers(options);
        })
        .catch((error) => {
          loadingCallback(false);
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem('token', false);
            // Clear session and local storage
            localStorage.setItem('isPinVerify', false);

            navigate('/login');
          }
        });
    }
  };

  useEffect(() => {
    getCustomerListData(searchKeyword);
  }, [searchKeyword]);
  useEffect(() => {
    if (selectedCustomer && selectedCustomer.country) {
      setCountry(selectedCustomer.country);
      getDistrictList(selectedCustomer.country);
      if (selectedCustomer && selectedCustomer.district) {
        setDistrict(selectedCustomer.district);
        getCityList(selectedCustomer.district);
      }
    } else {
      getDistrictList(country);
    }

  }, [country,selectedCustomer]);

  const getDistrictList = (country) => {
    post("get-district-by-country", { country_id:country }, token)
      .then((response) => {
        if (response.success) {
          setDistrictList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCityList = (district) => {
    setDistrict(district);
    post("get-cities-by-district", { district_id:district }, token)
      .then((response) => {
        if (response.success) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDialCodeByCountryCode = (code) => {
    const country = DialCodes.find((item) => item.code === code);
    return country ? country.dial_code : '';
  };







  const handleValidation = (formData) => {
    const errors = {};
    let validate = true
    // if (formData.customer_email==='') {
    //   errors.customer_email = 'Email is required.';
    //   setIsValid(false);
    //   validate=false;
    // }
    if (formData.customer_email && formData.customer_email !== '') {
      if (formData.customer_email.indexOf('@') === -1) {
        errors.customer_email = 'Email is not valid.';
        setIsValid(false);
        validate = false;
      }  else {
        //Email address cannot have only numbers before @
        const emailParts = formData.customer_email.split('@');
        if (emailParts[0].match(/^[0-9]+$/) !== null) {
          errors.customer_email = 'Email is not valid.';
          setIsValid(false);
          validate = false;
        }
      }
    }
    if (formData.customer_name==='') {
      errors.customer_name = 'Customer name is required.';
      setIsValid(false);
      validate=false;

    }

    if (formData.customer_phone==='') {
      errors.customer_phone = 'Phone Number is required.';
      setIsValid(false);
      validate=false;
    }
    setFormErrors(errors);
    if (!validate) {
      // setErrorFlashMessage('Please fill all the required fields.');
      FlashMessage('Please fill all the required fields.','error');
    }

    return validate;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const formObject = {};

    formData.forEach((value, key) => {
      formObject[key] = value;
    });
    if (!selectedCustomer) {
      formObject.cutomer_id = 0;
    }
    if (handleValidation(formObject)) {
      // console.log("============");
      // console.log(isValid);
      // console.log(formObject);
      // console.log(formErrors);
      //if (isValid) {
        const customerData = {
          name: formObject.customer_name ? formObject.customer_name : null,
          email: formObject.customer_email ? formObject.customer_email : null,
          country_code: formObject.country_code ? formObject.country_code : null,
          mobile_number: formObject.customer_phone ? formObject.customer_phone : null,
          address: formObject.customer_address ? formObject.customer_address : null,
          country: formObject.country_id ? Number(formObject.country_id) : null,
          district: formObject.district_id ? Number(formObject.district_id) : null,
          city: formObject.city_id ? Number(formObject.city_id) : null,
          zipcode: formObject.customer_zip ? Number(formObject.customer_zip) : null,
          nid: formObject.nid ? Number(formObject.nid) : null,
        };
        // console.log("============");
        // console.log(customerData);

        if (selectedCustomer) {
          customerData.id = Number(formObject?.customer_id);
          updateCustomer(customerData);
        }
        else {

          //createCustomer(customerData);
          //setCustomer(response.data);
          setShowModal(false);
          //setSuccessFlashMessage('customer saved successfully');
          setShowNewCustomerForm(false);
        }
      // } else {
      //   console.error('Form validation failed.');
      // }

    } else {
      console.error('Form validation failed.');
    }

  };

  const createCustomer = (formData) => {
      loadingCallback(true);
      // setFlashMessage('Product saved successfully');
      // navigate('/products');
      // Use your API service functions to make API requests here
      //if(isValid){
        post("save-customer", formData, token)
          .then((response) => {
            loadingCallback(false);
            if (response.status===200) {
              setCustomer(response.data);
              setShowModal(false);
              // setSuccessFlashMessage('customer saved successfully');
              FlashMessage('customer saved successfully','success');
              setShowNewCustomerForm(false);
            } else {
              console.log("customer insertion failed");

                setWarningMessage(true);
                const timer = setTimeout(() => {
                  setWarningMessage(false);
                }, 3000);
                return () => clearTimeout(timer);
            }
          })
          .catch((error) => {
            loadingCallback(false);
            if (error?.response?.status === 401) {
              localStorage.clear();
              localStorage.setItem('token', false);
              // Clear session and local storage
              localStorage.setItem('isPinVerify', false);

              navigate('/login');
            }
            console.log(error);
          });
      //}
  }
  const updateCustomer = (formData) => {
    loadingCallback(true);
    // Use your API service functions to make API requests here
    //if(isValid){
      post("save-customer", formData, token)
        .then((response) => {
          loadingCallback(false);
          if (response.status === 200) {
            setCustomer(response.data);
            notify('success','customer added successfully');
            setShowModal(false);
            // setSuccessFlashMessage('customer added successfully');

          } else {
            console.log("customer update failed");
            // setErrorFlashMessage('customer update failed');
            notify('error','customer update failed');

              setWarningMessage(true);
              const timer = setTimeout(() => {
                setWarningMessage(false);
              }, 3000);
              return () => clearTimeout(timer);
          }
        })
        .catch((error) => {
          loadingCallback(false);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem('token', false);
            // Clear session and local storage
            localStorage.setItem('isPinVerify', false);

            navigate('/login');
          }
          console.log(error);
        });
    //}
  }

  const handleNewCustomerFormClick = () => {
    // setShowNewCustomerForm(true);
    // setSelectedCustomer(false);
    setNestedModalData({
      title: 'Add New Customer',
      type: 'new-customer',
      setData:setSelectedCustomer

    });
    setShowNestedModal(true);
  };
  const handleErrorInputChange = (fieldName, value) => {
    if (value!=='') {
      //formErrors[fieldName] = null;
      // unset formErrors by fieldName
      const updatedFormErrors = { ...formErrors };
      delete updatedFormErrors[fieldName];
      setFormErrors(updatedFormErrors);
    }

  }
  const handleallInputChange = (fieldName, value) => {

    if (fieldName === 'country') {
      // Update the country state
      setCountry(value);
      setCityList([]);

      // Nullify the city and district values
      setSelectedCustomer((prevCustomer) => ({
        ...prevCustomer,
        country: value,
        city: null,
        district: null,
      }));
    }
    else if (fieldName === 'district') {
      // Update the district state
      getCityList(value);

      // Update the selectedCustomer state with the modified field
      setSelectedCustomer((prevCustomer) => ({
        ...prevCustomer,
        city: null,
        [fieldName]: value,
      }));
    } else {
      // Update the selectedCustomer state with the modified field
      setSelectedCustomer((prevCustomer) => ({
        ...prevCustomer,
        [fieldName]: value,
      }));
    }
  };
  const handleInputChange = (inputValue) => {
    //if (action && action === 'input-change') {
      // Perform an API call using the inputValue to fetch the filtered options
      // Update the options state with the fetched data
      setSearchKeyword(inputValue);
    //}
  };

  const handleSelectChange = (name, option) => {

    const { value, label } = option;
    if (value === "") {
      setSelectedCustomer(null);
      return;
    }
    // console.log("============");
    // console.log(value);
    // setSearchKeyword(value);
    const customerId = parseInt(value);
    const selectedCustomer = filteredCustomers.find((customer) => customer.id === customerId);
    setSelectedCustomer(selectedCustomer);
    setShowNewCustomerForm(false);
    // Update the value of the input field
    if (selectedCustomer.district) {
      getCityList(selectedCustomer.district);
    } else{
      getCityList([]);
    }
  };

  return (
    <>
      <form
          method="get"
          className="col-sm-12 min-h-[35vh]"
          data-autosubmit="false"
          autoComplete="off"
          onSubmit={handleSubmit}
      >
        <ToastContainer />
        <div className="mb-3">
          <label htmlFor="customerSelect" className="form-label">
            Select Customer
          </label>

          <div className="d-flex align-items-center">
            <SelectBox
              name="customer_id"
              options={filteredCustomers}
              value={selectedCustomer ? selectedCustomer.id : ""}
              //onChange={handleCustomerSelect}
              transparentBorder={false}
              onInputChangeData={handleInputChange}
              onChange={handleSelectChange.bind(this, 'customer_id')}
              placeholder="Select Customer"
              className={classNames(
                'w-full',
                'rounded-[15px]',
                'bg-white'
              )}
            />
            {/* <select
              id="customer_id"
              name="customer_id"
              className={classNames(
                'w-full',
                'rounded-[15px]',
                'p-3',
                'bg-white'
              )}
              style={{  border: "1px solid #ccc" }}
              onChange={handleCustomerSelect}
              value={selectedCustomer ? selectedCustomer.id : ""}
            >
              {filteredCustomers && filteredCustomers.map((customer) => (
                <option key={customer.id} value={customer.id}>
                  {customer.name}
                </option>
              ))}
            </select> */}
            {/* Add nested modal trigger */}
            <label
                  htmlFor="nested_modal"
                  onClick={handleNewCustomerFormClick}
                  className={classNames(
                    " w-[70px]",
                    "h-[56px]",
                    "rounded-[10px]",
                    "bg-[#99A3B7]",
                    "text-center",
                    "ml-2",
                    "cursor-pointer"
                  )}
                >
                <FontAwesomeIcon className='text-center text-white  mt-[18px] ' icon={faPlus} />
            </label>

          </div>
        </div>

        {selectedCustomer && (
          <>
            <div className="mb-3">
              <input
                type="text"
                id="customer_name"
                name="customer_name"
                className={classNames(
                  'form-control',
                  'rounded-[15px]',
                  'p-3',
                  'bg-white'

                )}
                placeholder="Customer Name"
                value={selectedCustomer.name ? selectedCustomer.name : ''}
                onChange={(e) => {
                  handleallInputChange("name", e.target.value)
                  handleErrorInputChange('customer_name', e.target.value)
                }}
              />
              {formErrors && formErrors.customer_name ? <p className="error-message text-red-500">{formErrors.customer_name}</p> : null}
            </div>
            <div className="mb-3">
              <input
                type="text"
                id="customer_email"
                name="customer_email"
                className={classNames(
                  'form-control',
                  'rounded-[15px]',
                  'p-3',
                  'bg-white'

                )}
                placeholder="Customer Email"
                value={selectedCustomer.email ? selectedCustomer.email : ''}
                onChange={(e) => {
                  handleallInputChange("email", e.target.value)
                  handleErrorInputChange('customer_email', e.target.value)
                }}
              />
              {formErrors && formErrors.customer_email ? <p className="error-message text-red-500">{formErrors.customer_email}</p> : null}
            </div>
            <div className="mb-3">
              <div className="flex">
                <select
                  id="country_code"
                  name="country_code"
                  className={classNames(
                    'w-2/4',
                    'rounded-[15px]',
                    'p-3',
                    'bg-white',
                    'mr-2'
                  )}
                  style={{  border: "1px solid #ccc" }}
                  value={selectedCustomer.country_code ? selectedCustomer.country_code : ''}
                  onChange={(e) => handleallInputChange("country_code", e.target.value)}
                >

                  <option value="">Select</option>
                  {countryList.map((country) => (
                    <option key={country.id} className="text-[14px]" value={getDialCodeByCountryCode(country.code)}>
                      <FlagIcon countryCode={country.code} />{getDialCodeByCountryCode(country.code)}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  id="customer_phone"
                  name="customer_phone"
                  className={classNames(
                    'form-control',
                    'rounded-[15px]',
                    'p-3',
                    'bg-white',
                    'w-3/4'

                  )}
                  placeholder="Customer Phone No."
                  value={selectedCustomer.mobile_number ? selectedCustomer.mobile_number : ''}
                  onChange={(e) => {
                    handleallInputChange("mobile_number", e.target.value)
                    handleErrorInputChange('customer_phone', e.target.value)
                  }}
                />
              </div>
              {formErrors && formErrors.customer_phone ? <p className="error-message text-red-500">{formErrors.customer_phone}</p> : null}
            </div>
            <div className="mb-3">
              <input
                type="text"
                id="customer_address"
                name="customer_address"
                className={classNames(
                  'form-control',
                  'rounded-[15px]',
                  'p-3',
                  'bg-white'

                )}
                placeholder="Customer Address"
                value={selectedCustomer.address ? selectedCustomer.address : ''}
                onChange={(e) => handleallInputChange("address", e.target.value)}
              />
            </div>
            {/* {country select box} */}
            <div className="mb-3">
              <select
                id="country_id"
                name="country_id"
                className={classNames(
                  'w-full',
                  'rounded-[15px]',
                  'p-3',
                  'bg-white'
                )}
                style={{  border: "1px solid #ccc" }}
                value={selectedCustomer.country ? selectedCustomer.country : ''}
                onChange={(e) => handleallInputChange("country", e.target.value)}
              >
                <option value="">Select Country</option>
                {countryList.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <select
                id="district_id"
                name="district_id"
                className={classNames(
                  'w-full',
                  'rounded-[15px]',
                  'p-3',
                  'bg-white'
                )}
                style={{  border: "1px solid #ccc" }}
                value={selectedCustomer.district ? selectedCustomer.district : ''}
                onChange={(e) => handleallInputChange("district", e.target.value)}
              >
                <option value="">Select District</option>
                {districtList.length === 0 && (
                  <option value={selectedCustomer.district}>{selectedCustomer.district}</option>
                )}
                {districtList.map((district) => (
                  <option key={district.id} value={district.id}>
                    {district.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">

              <select
                id="city_id"
                name="city_id"
                className={classNames(
                  'w-full',
                  'rounded-[15px]',
                  'p-3',
                  'bg-white'
                )}
                style={{  border: "1px solid #ccc" }}
                value={selectedCustomer.city ? selectedCustomer.city : ''}
                onChange={(e) => handleallInputChange("city", e.target.value)}
              >
                <option value="">Select City</option>
                {cityList.length === 0 && (
                  <option value={selectedCustomer.city}>{selectedCustomer.city}</option>
                )}
                {cityList.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </select>
              <input
                type="text"
                id="customer_zip"
                name="customer_zip"
                className={classNames(
                  'form-control',
                  'rounded-[15px]',
                  'p-3',
                  'bg-white',
                  'mt-2'

                )}
                placeholder="Zip Code"
                value={selectedCustomer.zipcode ? selectedCustomer.zipcode : ''}
                onChange={(e) => handleallInputChange("zipcode", e.target.value)}
              />
            </div>


            <div className="button-disabled">
              <button
                htmlFor='my_modal_2'
                type="submit"
                className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
              > Add Customer
              </button>
            </div>
          </>
        )}

      </form>
    </>
  );
};

export default InvoiceCustomerForm;
