import React from 'react';
import DashedLine from '../../../components/DashedLine/DashedLine';
import DashedLine2 from '../../../components/DashedLine/DashedLine2';
import { formatPhoneNumber, numberToWords, formatPrice, invoiceCalculate } from '../../../utils/Common';
import Watermark from '../Watermark/Watermark';
import './PdfHtmlFor2mm.css';

import QRCode from "react-qr-code";

const PdfHtmlFor2mm = (props) => {
  const { invoice, invoiceSetting, id, parentClassName } = props;
  const BASE_URL = process.env.REACT_APP_ROOT_URL;
  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, "0");
  const day = String(invoiceDate.getDate()).padStart(2, "0");
  const hours = String(invoiceDate.getHours() % 12).padStart(2, "0");
  const minutes = String(invoiceDate.getMinutes()).padStart(2, "0");
  const ampm = invoiceDate.getHours() >= 12 ? "PM" : "AM";
  const shop_logo = invoiceSetting?.shop_logo
    ? invoiceSetting?.shop_logo
    : `${BASE_URL}/layoutlogo.svg`;

  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
  let totalQty = 0;
  let totalItems = 0;
  let totalPrice = 0;
  const fetchImage = async () => {
    try {
      const imageResponse = await fetch(
        "https://api-outlet-expense.squadinnovators.com/uploads/61-test/1700124528.jpg"
      );

      if (!imageResponse.ok) {
        throw new Error(`Failed to fetch image: ${imageResponse.status}`);
      }

      const imageBlob = await imageResponse.blob();
      const reader = new FileReader();
      reader.onload = () => {
        const base64DataURL = `data:image/jpeg;base64,${reader.result}`;
        const imageElement = document.createElement("img");
        //imageElement.crossOrigin = 'anonymous';
        imageElement.className = "h-[100px]";
        imageElement.src = base64DataURL;
        imageElement.alt = "Invoice Logo";

        const invoiceLogoElement = document.querySelector(".invoice-logo-pdf");
        invoiceLogoElement.appendChild(imageElement);
      };
      reader.onerror = (error) =>
        console.error("Failed to read image data:", error);
      reader.readAsDataURL(imageBlob);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  //fetchImage();

  return (
    <div id="pdfConentAreas2" className="pdfConentAreas2 p-0 !text-[12px]">
      {/* // <div id={id} className={parentClassName+ ' '+ id}> */}
      <div id="invoice-detail-page" className="invoice-detail-page">
        <div className="invoice-header">
          <div className="invoice-logo invoice-logo-pdf flex justify-center">
            {/* <img className="h-[100px]" src={`https://api-outlet-expense.squadinnovators.com/uploads/61-test/1700124528.jpg`} alt={invoice.invoice_id} /> */}
            {/* {!id &&
              <img src={shop_logo} alt={invoice.invoice_id} className={parentClassName + ' h-[60px]'} />
            } */}
          </div>
          <DashedLine2 />
          <div className="flex justify-between items-center">
            <div>
              {invoice?.user_info?.outlet_name && (
                <h1 className=" text-black    uppercase ">
                  {invoice?.user_info?.outlet_name}
                </h1>
              )}
              {invoice?.user_info?.address && (
                <p className="mt-1 mb-1    text-black relative ">
                  {invoice?.user_info?.address}
                </p>
              )}
                {invoiceSetting?.mobile_number &&
                <p>{formatPhoneNumber(invoiceSetting?.mobile_number, invoice?.user_info?.country_code)}</p>
              }
               <div className='flex flex-col '>
           <span>{invoiceSetting?.web_address ?
              invoiceSetting?.web_address
            : (
              ""
            )}</span>
            <span>
            {invoiceSetting?.social_link ?
              invoiceSetting?.social_link
            : (
              ""
            )}
            </span>

            </div>
            </div>
            <div className="w-[50px]">
              <QRCode
                className="w-full object-contain"
                value={BASE_URL + "/invoice/" + invoice.invoice_id}
                size={45}
              />
            </div>
          </div>
        </div>
        <DashedLine />

        <div className="invoice-customer flex justify-between">
          <div>
            <p className="   text-black ">Date: {formattedDateTime}</p>
            <p className="   text-black ">
              Wholesaler Id: #{invoice?.wholeseller?.id}
            </p>
          </div>
          <div>
            <p className="   text-black "> Name: {invoice?.wholeseller?.name} </p>
            <p className="   text-black ">
              {" "}
              Phone: {invoice?.wholeseller?.country_code}
              {invoice?.wholeseller?.mobile_number}
            </p>
          </div>
        </div>
        <div style={{ textAlign: "center", margin: "3px auto" }}>
          <p className="  text-black  text-[12px] p-2  uppercase ">
            Invoice No: {invoice.invoice_id}
          </p>
        </div>

        <div className="invoice-items">
          <table className="table">
            <thead>
              <tr className="   text-black  relative ">
                <th className="   text-black   ">Name</th>
                <th className="   text-black   ">Qty</th>
                <th className="   text-black   ">Price</th>
                <th className="   text-black  text-right ">Total</th>
              </tr>
            </thead>

            <tbody>
              <Watermark
                watermark_text={invoiceSetting?.watermark_text}
                watermark_type={invoiceSetting?.watermark_type}
              ></Watermark>
              {invoice.sales_details.map((item) => {
                totalQty += Number(item.qty);
                totalItems += 1;
                const subtotalPrice = formatPrice(item.qty * item.price);
                totalPrice += Number(subtotalPrice);
                return (
                  <tr key={item.id} className="   text-black  relative ">
                    <td>
                      {item?.product_info?.name}
                      <br />
                      <span className="text-[12px]">
                        {item?.product_info?.serial}
                      </span>
                    </td>
                    <td>{item.qty}</td>
                    <td>{item.price}</td>
                    <td className="   text-black  text-right  uppercase ">
                      {subtotalPrice}
                      {invoice?.user_info?.invoiceSetting?.currency_info?.code
                        ? invoice?.user_info?.invoiceSetting?.currency_info
                            ?.code
                        : " BDT"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <DashedLine2 />

        <div className="invoice-summary">
          <p className="flex   justify-between    text-black  relative ">
            <span className=" text-[12px]  text-black  relative ">
              TOTAL ITEMS
            </span>
            <span className=" text-[12px]  text-black  relative ">
              {totalItems}
            </span>
          </p>

          <p className="flex   justify-between   text-black  relative  ">
            <span className=" text-[12px]  text-black  relative ">
              SUB TOTAL
            </span>
            <span className=" text-[12px]  text-black  relative ">
              {formatPrice(totalPrice)}
              {invoice?.user_info?.invoiceSetting?.currency_info?.code
                ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                : " BDT"}
            </span>
          </p>

          <p className="flex   justify-between    text-black  relative ">
            <span className=" text-[12px]  text-black  relative ">DISCOUNT</span>
            <span className=" text-[12px]  text-black  relative ">
              {formatPrice(invoice.discount ? invoice.discount : 0)}
              {invoice?.user_info?.invoiceSetting?.currency_info?.code
                ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                : " BDT"}
            </span>
          </p>
          <p className="flex   justify-between    text-black  relative  ">
            <span className=" text-[12px]  text-black  relative ">Vat {""}</span>
            <span className=" text-[12px]  text-black  relative ">
              {invoice.vat ? formatPrice(invoice.vat) : 0.0}
              {invoice?.user_info?.invoiceSetting?.currency_info?.code
                ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                : " BDT"}
            </span>
          </p>
          <p className="flex   justify-between    text-black  relative  ">
            <span className=" text-[12px]  text-black  relative ">
              Service Charge {""}
            </span>
            <span className=" text-[12px]  text-black  relative ">
              {invoice.tax ? formatPrice(invoice.tax) : 0.0}
              {invoice?.user_info?.invoiceSetting?.currency_info?.code
                ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                : " BDT"}
            </span>
          </p>
          <DashedLine2 />
          <p className="flex   justify-between    text-black  relative  ">
            <span className=" text-[12px]  text-black  relative ">
              Grand Totals
            </span>
            <span className=" text-[12px]  text-black  relative ">
              {formatPrice(invoiceCalculate(invoice).grandTotal)}
              {invoice?.user_info?.invoiceSetting?.currency_info?.code
                ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                : " BDT"}
            </span>
          </p>
        </div>
        <p className="flex   justify-between    text-black  relative  ">
          <span className=" text-[12px]  text-black  relative ">
            Paid Amount
          </span>
          <span className=" text-[12px]  text-black  relative ">
            {invoice.paid_amount && invoice.paid_amount != ""
              ? formatPrice(invoice.paid_amount)
              : formatPrice(0)}
            {invoice?.user_info?.invoiceSetting?.currency_info?.code
              ? invoice?.user_info?.invoiceSetting?.currency_info?.code
              : " BDT"}
          </span>
        </p>
        <p className="flex   justify-between    text-black  relative  ">
          <span className=" text-[12px]  text-black  relative ">
            Due Amount:
          </span>
          {/* <span className=' text-[12px]  text-black  relative '>{formatPrice(invoice.sub_total - invoice.paid_amount)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span> */}
          <span className=" text-[12px]  text-black  relative ">
            {formatPrice(invoiceCalculate(invoice).dueAmount)}
            {invoice?.user_info?.invoiceSetting?.currency_info?.code
              ? invoice?.user_info?.invoiceSetting?.currency_info?.code
              : " BDT"}
          </span>
        </p>
        <hr></hr>
        <p className="flex flex-col      text-black  relative  ">
          <p className="  text-[12px]  uppercase  text-black font-nunito">
            In word:{" "}
            {numberToWords(formatPrice(invoiceCalculate(invoice).grandTotal))}{" "}
            taka only
          </p>
        </p>
        <div
          style={{
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <p className="  text-black  text-[12px] p-3  uppercase ">
            You saved {formatPrice(invoice.discount ? invoice.discount : 0)}
            {invoice?.user_info?.invoiceSetting?.currency_info?.code
              ? invoice?.user_info?.invoiceSetting?.currency_info?.code
              : " BDT"}{" "}
            in this purchase{" "}
          </p>
          <p className="  text-black  text-[12px] pl-2 pr-2  uppercase ">
            Thanks for purchase
          </p>
          <p className="  text-black   pl-2 pr-2  uppercase ">
            A Product of SQUAD INNOVATORS{" "}
          </p>
        </div>

        <div className="text-center font-nunito "></div>
      </div>
      <p className="invisible">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quasi aliquam
        temporibus suscipit itaque cupiditate, error voluptatem velit? Tempore,
        placeat doloremque eveniet rem a possimus ex modi quia ea atque laborum
        minus saepe officia numquam facere deleniti dolores sapiente aspernatur
        veritatis labore natus. Repudiandae hic labore repellendus voluptas
        Repudiandae hic labore repellendus voluptas Repudiandae hic labore
      </p>
      <p className="mt-1">.</p>
    </div>
  );
};

export default PdfHtmlFor2mm;
