import axios from "axios";
import { useState } from "react";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { formatDate } from "../utils/Common";

import { IoPrint } from "react-icons/io5";
import Swal from "sweetalert2";

const ViewExpenseCategoryForm = (props) => {
  const {
    loadingCallback,
    navigate,
    setShowModal,
    formData,
    setFormData,
    isOpen,
    title,
    setWarningMessage,
    type,
    selectType,
  } = props;

  const token = localStorage.getItem("token");
  console.log(formData);

  return (
    <div className="duration-500 px-[15px] font-nunito">
      <div className="flex items-center mt-2  text-base font-semibold justify-between py-2 gap-3">
        <h2> Description</h2>{" "}
        <p>
          {formatDate(formData?.created_at)} - {formatDate(formData?.updated_at)}
        </p>
      </div>
      <div>
        <div className="table-conatiner text-[12px]">

            <div className="grid grid-cols-2 gap-2 items-center font-nunito font-[700] text-[#000232]">
            <div className="py-[8px]">Expense Name</div>
            <div className="py-[8px] text-right">Amount of Transaction</div>
          </div>
          <div className="grid grid-cols-2 gap-2 items-center font-nunito font-[700] text-[#000232]">
            <div className="py-[8px]">{formData?.expense_name}</div>
            <div className="py-[8px] text-right">{formData?.cost_amount || 0}</div>
          </div>

          <div className="hrtable"></div>
        </div>
      </div>
      <div>
        <div className="flex justify-between gap-3 items-center">
          <button
            onClick={() => Swal.fire("Download option coming soon.")}
            className="cursor-pointer flex justify-center items-center gap-2 w-full mt-4 bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-md"
          >
            <FaCloudDownloadAlt size={22} /> Download
          </button>
          <button
            onClick={() => Swal.fire("Printing option coming soon.")}
            className="cursor-pointer flex justify-center items-center gap-2 w-full mt-4 bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-md"
          >
            <IoPrint size={22} /> Print
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewExpenseCategoryForm;
