import React, { useEffect, useState } from 'react';
import { formatDate, formatPrice, timeDiff } from '../../utils/Common';

const CategoryDateWiseItem = (props) => {
  const { categoryData,
    tabActive,
    shouldRenderPagination,
    currentPage,
    onPageChange,
    loading,
    selectAllChecked,
    onSelect,
  } = props;

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(selectAllChecked);
  }, [selectAllChecked]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onSelect(categoryData.id);
  };
  const createdDate = new Date(categoryData.created_at);

  let dueAmount;
  if (categoryData?.due == null) {
    dueAmount = 0;
  }
  else {
    dueAmount = categoryData?.due;
  }

  return (
    <>
    {tabActive &&
      <div id={`categoryDateWiseItem-${categoryData.id}`} className=" border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-7 items-start">
        <div className=''>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
        </div>
        {/* date/time */}


        <div className="">
          <h1 className=" text-[#000] font-[600]">{formatDate(createdDate)}</h1>
          <p className=" text-[#87889E] font-[500] text-xs">{timeDiff(createdDate)}</p>
        </div>
        {/* category name */}
        <div className="text-[#000] font-[500] text-center">
          {categoryData?.category_name}
        </div>

        {/* product name */}
        <div className="text-[#000] font-[500] text-center">
          {categoryData?.product_name}
        </div>

        {/* customer */}
        <div className="text-[#000] font-[500] text-center">
          <p>{categoryData.invoice}</p>
          <p className="text-[#87889E] font-[500] text-xs">{categoryData?.customer}</p>
        </div>
        {/* Due */}
        <div className=" text-[#000] font-[500] text-center ">{formatPrice(dueAmount)}</div>
        {/* Total */}
        <div className=" text-[#000] font-[500] text-right">{formatPrice(categoryData?.total_sale_amount)}</div>
      </div>}
  </>
  );
};
CategoryDateWiseItem.defaultProps = { className: null };
export default CategoryDateWiseItem;