import React, { useEffect, useState } from "react";

import PaymentInfo from "../../components/VendorProfile/PaymentInfo/Index";
import VendorHistory from "../../components/VendorProfile/VendorHistory/Index";
import InvoiceHistory from "../../components/VendorProfile/InvoiceHistory/Index";
import MostPurchaseProducts from "../../components/VendorProfile/MosPurchaseProducts/Index.jsx";

const VendorProfile = (props) => {
  const {
    hideModal,
    dueCollectionData,
    activeTab,
    loadingCallback,
    fetchedData,
    invoiceData,
    vendorId,
    onSubmitPayment,
    handleSubmitAddDue,
    handleSubmitAddPiad,
    handlePrintPdf,
    getDateWiseDueReport
  } = props;
  return (
    <>
      <div className="flex flex-col xl:flex-row gap-[3%]">
        <div className="w-[100%] xl:w-[300px] mb-[25px] xl:mb-0 bg-[#F8F9FA] rounded-[16px] p-3">
          <PaymentInfo
            hideModal={hideModal}
            activeTab={activeTab}
            loadingCallback={loadingCallback}
            fetchedData={fetchedData}
            invoiceData={invoiceData}
            onSubmitPayment={onSubmitPayment}
            dueCollectionData={dueCollectionData}
            handleSubmitAddDue={handleSubmitAddDue}
            handleSubmitAddPiad={handleSubmitAddPiad}
            setSuccessFlashMessage={props.setSuccessFlashMessage}
            setErrorFlashMessage={props.setErrorFlashMessage}
            setWarningMessage={props.setWarningMessage}
            handlePrintPdf={props.handlePrintPdf}
            getDateWiseDueReport={props.getDateWiseDueReport}
            setReportBtn={props.setReportBtn}
            reportBtn={props.reportBtn}
            setFromDate={props.setFromDate}
            setToDate={props.setToDate}
            fromDate={props.fromDate}
            toDate={props.toDate}
            paymentList={props.paymentList}
            paymentAccountList={props.paymentAccountList}
            type="vendor"
          />
        </div>
        <div className="flex-1">
          <VendorHistory
            activeTab={activeTab}
            loadingCallback={loadingCallback}
            fetchedData={fetchedData}
            type="vendor"
          />

          <MostPurchaseProducts
            activeTab={activeTab}
            loadingCallback={loadingCallback}
            fetchedData={fetchedData}
            vendorId={vendorId}
            type="vendor"
          />

          <InvoiceHistory
            activeTab={activeTab}
            loadingCallback={loadingCallback}
            invoiceData={invoiceData}
            vendorId={vendorId}
            type="vendor"
          />
        </div>
      </div>
    </>
  );
};

export default VendorProfile;
