import React from 'react';
import DashedLine from '../../../components/DashedLine/DashedLine';
import DashedLine2 from '../../../components/DashedLine/DashedLine2';
import { formatPhoneNumber, formatPrice, numberToWords,invoiceCalculate } from '../../../utils/Common';
import Watermark from '../Watermark/Watermark';
import './../PdfHtmlContentFor3mm/PdfHtmlContentFor3mm.css';



import QRCode from 'react-qr-code';

const DeliveryInvoice3mm = props => {
  const { invoice, invoiceSetting, shop_logo } = props;
  const userInfo = invoice.user_info;
  // // uncomment
    const BASE_URL = process.env.REACT_APP_ROOT_URL;
    const invoiceDate = new Date(invoice.created_at);
    const year = invoiceDate.getFullYear();
    const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
    const day = String(invoiceDate.getDate()).padStart(2, '0');
    const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
    const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
    const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';
    //const shop_logo = `${BASE_URL}/layoutlogo.svg`;
    const formattedDate = `${year}-${month}-${day}`;

  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
  let totalQty = 0;
  let totalItems = 0;
  let totalPrice = 0;
  return (
    <div id="pdfConentAreas3" className="pdfConentAreas3">
    {/* <div id={id} className={parentClassName+ ' '+ id}> */}
      <div id="invoice-detail-page" className="invoice-detail-page font-bold">
        {/* <div className="invoice-info">
          <h1>Invoice</h1>
          <p>{invoice?.invoice_id}</p>
          <p>{formattedDate}</p>
        </div> */}
        <div className="invoice-header">
          <div className="invoice-logo invoice-logo-pdf flex justify-center font-bold">
            {/* <img className="h-[100px]" src={`https://api-outlet-expense.squadinnovators.com/uploads/61-test/1700124528.jpg`} alt={invoice?.invoice_id} /> */}

              <img src={shop_logo} alt={invoice.invoice_id} className={' h-[80px]'} />

          </div>
          <DashedLine2 />
          <div className='flex justify-between font-bold mb-2'>
            <div>
              {invoice?.user_info?.outlet_name &&
                <h1 className='text-[12px] '>{invoice?.user_info?.outlet_name}</h1>
              }
              {invoice?.user_info?.address &&
                <p className="mt-1 mb-1  text-[11px]  ">{invoice?.user_info?.address}</p>
              }
              {invoice?.user_info?.phone &&
                <p className=' text-[11px]  '>{formatPhoneNumber(invoice?.user_info?.phone, invoice?.user_info?.country_code)}</p>
              }
            </div>
            <div className="w-[70px]">
            <QRCode className="w-full object-contain" value={BASE_URL + '/invoice/' + invoice.invoice_id} size={50} />
            </div>
          </div>
        </div>
        <DashedLine />

        <div className="invoice-customer font-bold flex justify-between">
          <div className='text-[11px]'>
            <p>Date: {formattedDateTime}</p>
            <p>Customer Id:  #{invoice?.customer_id}</p>
            <p> <span className="font-semibold">Customer Name</span> :{" "}
              {invoice.customer_name}</p>
              <span className="">Customer Phone</span> :{" "}
              {invoice.country_code}
              {invoice.customer_phone}
          </div>
          <div className='text-[11px]'>
              <p>
                <span>Delivery Method</span> :{" "}
                {invoice.delivery_method?.type_name}{" "}
              </p>
              <p>
                <span>Delivery Company</span> :{" "}
                <span>{invoice.delivery_method?.delivery_infos[0]?.company_name}{" "}</span>
              </p>
              <p>
                <span>
                  Receiver Name
                </span>
                :{" "}
                <span>
                  {invoice.delivery_customer_name}
                </span>
              </p>
              <p>
                <span>
                  Receiver Phone{" "}
                </span>{" "}
                : <span className="">{invoice.delivery_customer_phone}</span>
              </p>
              <p>
                <span>
                  Receiver Address
                </span>{" "}
                : <span className="">{invoice.delivery_customer_address} </span>
              </p>
            </div>
        </div>
        <div style={{ textAlign: 'center', margin: '0 auto' }}>
          <p className="   text-[11px] p-2 font-bold  uppercase ">Invoice No: {invoice?.invoice_id}</p>
        </div>

        <div className="invoice-items font-bold">
          <table className="table">
            <thead>
              <tr className="text-[12px]">
                <th className='!pl-0 pr-[2px]'>Name</th>
                <th className='px-[2px]'>Wty</th>
                <th className='px-[2px]'>Qty</th>
                <th className='!pr-0 pl-[2px]'>Total</th>
              </tr>
            </thead>

            <tbody style={{ fontSize: '11px' }}>
              {/* <Watermark watermark_text={invoiceSetting?.watermark_text} watermark_type={invoiceSetting?.watermark_type}></Watermark> */}
              {invoice?.sales_details.map((item) => {
                totalQty += Number(item.qty);
                totalItems += 1;
                const subtotalPrice = formatPrice(item.qty * item.price);
                totalPrice += Number(subtotalPrice);
                return (
                  <tr  key={item.id}>
                  <td className='!pl-0 !pt-0 pr-[2px]' style={{ fontWeight: '800', }} >{item?.product_info?.name}<br/><span className='text-[13px]'>{item?.product_info?.serial}</span></td>
                  <td className='px-[2px] !pt-0' style={{ fontWeight: '800' }}> {
                        invoice.defaultwarranties &&
                        invoice.defaultwarranties.map((warrantyItem) => (
                          warrantyItem.invoice_id === invoice.invoice_id && warrantyItem.product_id===item.product_id  &&
                          (
                            warrantyItem.warranty  ? (
                              <p>{warrantyItem?.warranty?.warranties_count} {" "}{warrantyItem?.warranty?.name}</p>
                            ) : (
                              <p>{warrantyItem?.default_warranties_count} {" "}
                              {/* {item?.product_info?.warrenty} */}
                              </p>
                            )
                          )
                        ))
                      }</td>
                  <td className='px-[2px] !pt-0 ' style={{ fontWeight: '800', }}>{item.qty}</td>
                  <td className='!pr-0 !pt-0  pl-[2px]' style={{ fontWeight: '800', }}>{subtotalPrice}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</td>
                </tr>
                );
              }
              )}
            </tbody>
          </table>
        </div>
        <DashedLine2 />
        <div className="invoice-summary">
          <p className='flex   justify-between  font-bold  text-[10px]'>
            <span className=''>TOTAL ITEMS</span>
            <span className=''>{totalItems}</span>
          </p>

          <p className='flex   justify-between  font-bold text-[10px] '>
            <span className=''>SUB TOTAL</span>
            <span className=''>{formatPrice(totalPrice)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>

          <p className='flex   justify-between  font-bold  text-[10px]'>
            <span className=''>DISCOUNT</span>
            <span className=''>{formatPrice(invoice?.discount ? invoice?.discount : 0)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>
          <p className='flex   justify-between  font-bold   text-[10px]'>
            <span className=''>Vat {''}</span>
            <span className=''>{invoice?.vat ? formatPrice(invoice?.vat) : 0.00}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>
          <p className='flex font-bold  justify-between text-[10px]'>
            <span className=''>Service Charge {''}</span>
            <span className=''>{invoice?.tax ? formatPrice(invoice?.tax) : 0.00}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>
          <p className="flex justify-between mb-2 font-bold text-[10px]">
                <span>Delivery fees {""}</span>
                <span>
                  {invoice.delivery_fee ? formatPrice(invoice.delivery_fee) : 0.0}
                  {/* {invoice.delivery_fee} */}
                  {invoiceSetting?.currency_info?.code
                    ? invoiceSetting?.currency_info?.code
                    : " BDT"}
                </span>
              </p>
          <DashedLine2 />
          <p className='flex   justify-between font-bold text-[10px]'>
            <span className=''>Grand Totals</span>
            <span className=''>             {formatPrice(invoiceCalculate(invoice).grandTotal)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>
        </div>
        <p className='flex   justify-between  text-[10px]  font-bold '>
          <span className=''>Paid Amount</span>
          <span className=''> {formatPrice(invoice.paid_amount)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
        </p>
        <p className='flex   justify-between  text-[10px]  font-bold '>
          <span className=''>Due Amount</span>
          <span className='mb-2'> {formatPrice(invoiceCalculate(invoice).dueAmount)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
        </p>
        <hr></hr>
        <p className='flex flex-col  font-bold'>
          <p className='text-[10px] font-bold '>In word: {numberToWords(formatPrice(invoiceCalculate(invoice).grandTotal))} taka only</p>
        </p>
        <div className='font-bold' style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
          <p className="text-[9px] p-2 my-3 uppercase ">You saved {formatPrice(invoice.discount ? invoice.discount : 0)} {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'} in this purchase </p>
          <p className='text-[9px] pl-2 pr-2  uppercase '>Thanks for purchase</p>
          <p className='text-[8px] font-medium pl-2 pr-2 pt-1 uppercase '>A Product of SQUAD INNOVATORS </p>
        </div>

        <div className='text-center font-nunito '>
          <p className='invisible'>.</p>

        </div>

      </div>
    </div>
  );
};

export default DeliveryInvoice3mm;