import React, { useContext, useEffect, useState, useRef } from "react";

import { formatPrice, formatDate } from "../../../utils/Common";
import { Modal } from "../../../utils/Modal";

const VendorHistory = (props) => {
  const { type, activeTab, loadingCallback, fetchedData } = props;
  const [showPayentDetailModal, setShowPayentDetailModal] = useState(false);
  const handleShowPaymentDetails = () => setShowPayentDetailModal(!showPayentDetailModal);
  const handleTabClick = (index) => {
    handleShowPaymentDetails();
  };
  return (
    <>
      <div className="bg-[#F8F9FA] rounded-[16px] px-[2%] py-[2%] mb-[25px]">
        <h2 className="font-nunito font-[600] text-[22px] mb-[20px] text-[#000232]">
          {type=='vendor' ? 'Vendor History' : type === 'wholesaler' ? 'Wholesaler History' : type === "customer"? 'Customer History':type ==="exporter" ? 'Exporter History':type === "carrier"? "Carried History":""}
        </h2>
        {type == "carrier" ? (
          <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className="bg-white border-1 border-solid border-[#29B170] rounded-[16px] p-[5%]">
              <div className="font-nunito font-bold text-[12px] text-[#29B170]">
                Total Carried Product
              </div>
              <div className="font-nunito font-[500] text-[24px] text-[#29B170]">
              {fetchedData?.carrier_product_count ? fetchedData.carrier_product_count : 0}
              </div>
            </div>

            <div onClick={() => handleTabClick("pendingProduct")} htmlFor="carrier_pending_products_modal" className="bg-white border-1 border-solid border-[#347AE2] rounded-[16px] p-[5%]">
              <div className="font-nunito font-bold text-[12px] text-[#347AE2]">
                Pending Product
              </div>
              <div className="font-nunito font-[500] text-[24px] text-[#347AE2]">
              {fetchedData?.pending_product_count ? fetchedData.pending_product_count : 0}
              </div>
            </div>
            <div className="bg-white border-1 border-solid border-[#FF7A00] rounded-[16px] p-[5%]">
              <div className="font-nunito font-bold text-[12px] text-[#FF7A00]">
                Total Due
              </div>
              <div className="font-nunito font-[500] text-[24px] text-[#FF7A00]">
                {fetchedData?.total_due ? fetchedData.total_due.toFixed(2) : 0}
              </div>
            </div>
            <div className="bg-white border-1 border-solid border-[#FF4E4E] rounded-[16px] p-[5%]">
              <div className="font-nunito font-bold text-[12px] text-[#FF4E4E]">
                Number of Exporter
              </div>
              <div className="font-nunito font-[500] text-[24px] text-[#FF4E4E]">
              {fetchedData?.exporter_count ? fetchedData.exporter_count : 0}
              </div>
            </div>
          </div>
        ) : type == "exporter" ? (
          <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className="bg-white border-1 border-solid border-[#29B170] rounded-[16px] p-[5%]">
              <div className="font-nunito font-bold text-[12px] text-[#29B170]">
                Total Purchase Amount
              </div>
              <div className="font-nunito font-[500] text-[24px] text-[#29B170]">
              {fetchedData?.total_purchase_amount ? fetchedData.total_purchase_amount.toFixed(2) : ''}
              </div>
            </div>

            <div className="bg-white border-1 border-solid border-[#347AE2] rounded-[16px] p-[5%]">
              <div className="font-nunito font-bold text-[12px] text-[#347AE2]">
                Total Due
              </div>
              <div className="font-nunito font-[500] text-[24px] text-[#347AE2]">
              {fetchedData?.total_due ? fetchedData.total_due.toFixed(2) : 0}
              </div>
            </div>

            <div className="bg-white border-1 border-solid border-[#FF7A00] rounded-[16px] p-[5%]">
              <div className="font-nunito font-bold text-[12px] text-[#FF7A00]">
                Last Purchase Date
              </div>
              <div className="font-nunito font-[500] text-[24px] text-[#FF7A00]">
              {fetchedData?.last_purchase_date ? formatDate(fetchedData.last_purchase_date.created_at) : ''}
              </div>
            </div>

            <div className="bg-white border-1 border-solid border-[#9B54E1] rounded-[16px] p-[5%]">
              <div className="font-nunito font-bold text-[12px] text-[#9B54E1]">
                Total Ordered Product
              </div>
              <div className="font-nunito font-[500] text-[24px] text-[#9B54E1]">
              {fetchedData?.total_order_products ? fetchedData.total_order_products : 0}
              </div>
            </div>

            <div className="bg-white border-1 border-solid border-[#FF4E4E] rounded-[16px] p-[5%]">
              <div className="font-nunito font-bold text-[12px] text-[#FF4E4E]">
                Number of Received Product
              </div>
              <div className="font-nunito font-[500] text-[24px] text-[#FF4E4E]">
              {fetchedData?.total_received_products ? fetchedData.total_received_products : 0}
              </div>
            </div>

            <div className="bg-white border-1 border-solid border-[#249FE4] rounded-[16px] p-[5%]">
              <div className="font-nunito font-bold text-[12px] text-[#249FE4]">
                Total Pending Products
              </div>
              <div className="font-nunito font-[500] text-[24px] text-[#249FE4]">
              {fetchedData?.total_pending_products ? fetchedData.total_pending_products : 0}
              </div>
            </div>
          </div>
        ) : (
          <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className="bg-white border-1 border-solid border-[#29B170] rounded-[16px] p-[5%]">
              <div className="font-nunito font-bold text-[12px] text-[#29B170]">
                Total Purchase Amount
              </div>
              <div className="font-nunito font-[500] text-[24px] text-[#29B170]">
              {fetchedData?.invoice_list_sum_sub_total ? fetchedData.invoice_list_sum_sub_total.toFixed(2) : ''}
              </div>
            </div>

            <div className="bg-white border-1 border-solid border-[#347AE2] rounded-[16px] p-[5%]">
              <div className="font-nunito font-bold text-[12px] text-[#347AE2]">
                Total Due
              </div>
              <div className="font-nunito font-[500] text-[24px] text-[#347AE2]">
              {fetchedData?.due ? fetchedData.due.toFixed(2) : 0}
              </div>
            </div>

            <div className="bg-white border-1 border-solid border-[#FF7A00] rounded-[16px] p-[5%]">
              <div className="font-nunito font-bold text-[12px] text-[#FF7A00]">
                Last Purchase Date
              </div>
              <div className="font-nunito font-[500] text-[24px] text-[#FF7A00]">
              {fetchedData?.last_invoice_list ? formatDate(fetchedData.last_invoice_list.created_at) : ''}
              </div>
            </div>

            <div className="bg-white border-1 border-solid border-[#9B54E1] rounded-[16px] p-[5%]">
              <div className="font-nunito font-bold text-[12px] text-[#9B54E1]">
                Total Purchase Product
              </div>
              <div className="font-nunito font-[500] text-[24px] text-[#9B54E1]">
              {fetchedData?.vendor_product ? fetchedData.vendor_product : 0}
              </div>
            </div>

            <div className="bg-white border-1 border-solid border-[#FF4E4E] rounded-[16px] p-[5%]">
              <div className="font-nunito font-bold text-[12px] text-[#FF4E4E]">
                Number of Return Product
              </div>
              <div className="font-nunito font-[500] text-[24px] text-[#FF4E4E]">
              {fetchedData?.return_product ? fetchedData.return_product : 0}
              </div>
            </div>

            <div className="bg-white border-1 border-solid border-[#249FE4] rounded-[16px] p-[5%]">
              <div className="font-nunito font-bold text-[12px] text-[#249FE4]">
                Total Invoice
              </div>
              <div className="font-nunito font-[500] text-[24px] text-[#249FE4]">
              {fetchedData?.invoice_list_count ? fetchedData.invoice_list_count : 0}
              </div>
            </div>
          </div>
        )}
      </div>

      {showPayentDetailModal ? (
        <Modal
        isOpen={showPayentDetailModal}
        setOpen={setShowPayentDetailModal}
        title="Pending Products"
        formId="carrier_pending_products_modal"
        loadingCallback={loadingCallback}
        type="carrier_pending_products"
        productData={fetchedData?.pending_products}
        total_due={fetchedData?.total_due ? fetchedData.total_due : 0}
        />
      ) : null}
    </>
  );
};

export default VendorHistory;
