import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";

import { formatPrice, formatDate, timeDiff } from "../../../utils/Common";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Pagination from "../../../utils/pagination";

const InvoiceHistory = (props) => {
  const { type, activeTab, loadingCallback, id } = props;

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [invoices, setInvoices] = useState([]);
  const [invoicesData, setInvoicesData] = useState([]);

  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const limit = 10;
  const [prevSearchKeyword, setPrevSearchKeyword] = useState("");

  useEffect(() => {
    // Check if the search keyword has changed
    const isSearchKeywordChanged = searchKeyword !== prevSearchKeyword;

    // Update the previous search keyword
    setPrevSearchKeyword(searchKeyword);

    // If it's a new search keyword, set the page to 1
    if (isSearchKeywordChanged) {
      setCurrentPage(1);
    } else {
      getInvoiceslist(currentPage, searchKeyword);
    }
  }, [currentPage, searchKeyword, prevSearchKeyword, activeTab]);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  //const { id } = useParams();

  const getFilteredInvoiceslist = (page, searchKeyword) => {
    const url = type=='vendor' ? `vendor-wise-invoice` : type === 'exporter' ? `exporter-history` : type === 'carrier' ? `carrier-history` : type === 'wholesaler' ? `whole-sellers-wise-invoice` : `customer-wise-invoice`;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}${url}/${id}?interval=${activeTab}&invoice_id=${searchKeyword}&page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          setInvoices(response?.data?.data ? response?.data?.data : response?.data);
          setInvoicesData(response?.data?.data?.data ? response?.data?.data?.data : response?.data?.data);
        } else {
          console.log("Data Not Found!");
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };

  const getInvoiceslist = (page, searchKeyword = null) => {
    setLoader(true);
    if (searchKeyword) {
      getFilteredInvoiceslist(page, searchKeyword);
    } else {
      const url = type=='vendor' ? `vendor-wise-invoice` : type === 'exporter' ? `exporter-history` : type === 'carrier' ? `carrier-history` : type === 'wholesaler' ? `whole-sellers-wise-invoice` : `customer-wise-invoice`;
      axios
        .get(
          `${process.env.REACT_APP_API_URL}${url}/${id}?interval=${activeTab}&page=${page}&limit=${limit}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            setLoader(false);
            setInvoices(response?.data?.data ? response?.data?.data : response?.data);
            setInvoicesData(response?.data?.data?.data ? response?.data?.data?.data : response?.data?.data);
          }
        })
        .catch((error) => {
          console.log(error);
          loadingCallback(false);
          if (error?.response?.status === 401) {
            navigate("/login");
            localStorage.setItem("token", "");
            localStorage.setItem("token", "");
            // Clear session and local storage
            localStorage.setItem("isPinVerify", false);

            navigate("/login");
          }
        });
    }
  };


  const shouldRenderPagination = invoices.total > limit;

  return (
    <>
      <div className="bg-white rounded-[16px] px-4 py-4">
        <div className="flex flex-col md:flex-row justify-between mb-4">
          <div>
            <h1 className="text-[#000232] text-[22px] font-[600] font-lato mb-2">
              {type==="exporter"? "Order" : type ==='carrier' ? "Carried" : "Invoice"} History
            </h1>
          </div>
          {type !=='carrier' || type !=='exporter'  ? (
            <div className="flex gap-2 justify-center">
              <div className="flex relative w-full">
                <input
                  autoFocus
                  type="text"
                  className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[28px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                  placeholder="Search Invoice"
                  onChange={(e) => setSearchKeyword(e.target.value)}
                />

                <div className="absolute top-[37%] left-[10px]">
                  <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                </div>

                <div className="absolute top-[36%] right-[10px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M0.83268 12.6665C0.83268 12.3932 1.05935 12.1665 1.33268 12.1665L8.66602 12.1665C8.93935 12.1665 9.16602 12.3932 9.16602 12.6665C9.16602 12.9398 8.93935 13.1665 8.66602 13.1665L1.33268 13.1665C1.05935 13.1665 0.83268 12.9398 0.83268 12.6665Z"
                      fill="#102048"
                    />
                    <path
                      d="M10.8327 12.6665C10.8327 12.3932 11.0594 12.1665 11.3327 12.1665L14.666 12.1665C14.9393 12.1665 15.166 12.3932 15.166 12.6665C15.166 12.9398 14.9393 13.1665 14.666 13.1665L11.3327 13.1665C11.0594 13.1665 10.8327 12.9398 10.8327 12.6665Z"
                      fill="#102048"
                    />
                    <path
                      d="M0.832684 8C0.832684 7.72667 1.05935 7.5 1.33268 7.5L4.66602 7.5C4.93935 7.5 5.16602 7.72667 5.16602 8C5.16602 8.27333 4.93935 8.5 4.66602 8.5L1.33268 8.5C1.05935 8.5 0.832684 8.27333 0.832684 8Z"
                      fill="#102048"
                    />
                    <path
                      d="M6.83268 8C6.83268 7.72667 7.05935 7.5 7.33268 7.5L14.666 7.5C14.9393 7.5 15.166 7.72667 15.166 8C15.166 8.27333 14.9393 8.5 14.666 8.5L7.33268 8.5C7.05935 8.5 6.83268 8.27333 6.83268 8Z"
                      fill="#102048"
                    />
                    <path
                      d="M0.83268 3.3335C0.83268 3.06016 1.05935 2.8335 1.33268 2.8335L8.66602 2.8335C8.93935 2.8335 9.16602 3.06016 9.16602 3.3335C9.16602 3.60683 8.93935 3.8335 8.66602 3.8335L1.33268 3.8335C1.05935 3.8335 0.83268 3.60683 0.83268 3.3335Z"
                      fill="#102048"
                    />
                    <path
                      d="M10.8327 3.3335C10.8327 3.06016 11.0594 2.8335 11.3327 2.8335L14.666 2.8335C14.9393 2.8335 15.166 3.06016 15.166 3.3335C15.166 3.60683 14.9393 3.8335 14.666 3.8335L11.3327 3.8335C11.0594 3.8335 10.8327 3.60683 10.8327 3.3335Z"
                      fill="#102048"
                    />
                    <path
                      d="M8.16602 4.66667L8.16602 2C8.16602 1.72667 8.39268 1.5 8.66602 1.5C8.93935 1.5 9.16602 1.72667 9.16602 2L9.16602 4.66667C9.16602 4.94 8.93935 5.16667 8.66602 5.16667C8.39268 5.16667 8.16602 4.94 8.16602 4.66667Z"
                      fill="#102048"
                    />
                    <path
                      d="M8.16602 14.0002L8.16602 11.3335C8.16602 11.0602 8.39268 10.8335 8.66602 10.8335C8.93935 10.8335 9.16602 11.0602 9.16602 11.3335L9.16602 14.0002C9.16602 14.2735 8.93935 14.5002 8.66602 14.5002C8.39268 14.5002 8.16602 14.2735 8.16602 14.0002Z"
                      fill="#102048"
                    />
                    <path
                      d="M6.83398 9.33317L6.83398 6.6665C6.83398 6.39317 7.06065 6.1665 7.33398 6.1665C7.60732 6.1665 7.83398 6.39317 7.83398 6.6665L7.83398 9.33317C7.83398 9.6065 7.60732 9.83317 7.33398 9.83317C7.06065 9.83317 6.83398 9.6065 6.83398 9.33317Z"
                      fill="#102048"
                    />
                  </svg>
                </div>
              </div>
            </div>
          ) : ""}
        </div>

        {shouldRenderPagination && (
          <Pagination
            currentPage={currentPage}
            lastPage={invoices.last_page}
            onPageChange={onPageChange}
          />
        )}

        <div class="grid grid-cols-4 sm:grid-cols-6 gap-1 font-nunito font-[700] text-[14px] text-[#000232]">
          <div class=" py-[8px]">Invoice ID</div>
          <div class="py-[8px] text-center hidden sm:block">Date/Time</div>
          <div class="py-[8px] text-center">{type=="carrier" ? 'Product Name' :'Amount'}</div>
          <div class="py-[8px] text-center">{type=="carrier" ? 'Received qty' :'Due'}</div>
          {type != "carrier" ? (
            <div class="py-[8px] text-center">Status</div>
          ) : ""}
          <div class="py-[8px] text-right hidden sm:block">Action</div>
        </div>

        {invoicesData && invoicesData.length > 0 &&
          invoicesData.map((item, index) => {
            return (
              <div
                key={index}
                class="border-t-[1px] border-solid border-[#E3EDF6] py-[8px] font-nunito font-[500] text-[#000232] grid grid-cols-4 sm:grid-cols-6  items-center"
              >
                <div class="text-[#000232] text-[14px]">#{item.invoice_id}</div>
                <div class="text-center hidden sm:block">
                  <div className="text-[14px] text-[#000000]">
                    {formatDate(item.created_at)}
                  </div>
                  <div className="font-[600] text-[14px] text-[#87889E]">
                  {type=='carrier' || type=='exporter' ? formatDate(item?.date_time) : item?.updated_at ? timeDiff(new Date(item.updated_at)) : ''}
                  </div>
                </div>
                <div class="text-[16px] text-[#000000] text-center">
                 {type=='carrier' ? item?.product_name : type=='exporter' ? item?.purchase_amount : item?.sub_total}
                 {/* {formatPrice(invoiceCalculate(item,'purchase').grandTotal)} */}
                </div>
                <div class="text-[16px] text-[#000000] text-center">
                  {type=='carrier' ? item?.receive_qty_count : type=='exporter' ? item?.due_amount : item?.paid_amount ? (item.sub_total - item.paid_amount) : ''}
                  {/* {item?.paid_amount ? formatPrice(invoiceCalculate(item,'purchase').dueAmount) : ''} */}
                </div>
                {type == "carrier" ? ""
                : type == "exporter" ? (
                  <div className={` ${type=='exporter' ? "text-green-600" :"text-red-600"} font-[500] text-center`}>
                  {type=='exporter' ? 'Completed' : "Hold"}
                  </div>
                ):
                (
                  <div className={` ${type=='carrier' ? "text-green-600" : item.status && item.status === 1 ? "text-green-600" :"text-red-600"} font-[500] text-center`}>
                  {type=='carrier' ? 'Completed' : item?.status === 1 ?  "Completed" : "Hold"}
                  </div>
                )
                }
                <div class="text-[12px] font-nunito font-[700] flex flex-col lg:flex-row gap-2 justify-end items-center">
                  {type === "customer" ? (
                    <a
                      class="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
                      href={`/invoice/${item.invoice_id}`}
                    >
                      VIEW
                    </a>
                  ) : type === "wholesaler" ? (
                    <a
                      class="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
                      href={`/wholesale-invoice/${item.invoice_id}`}
                    >
                      VIEW
                    </a>
                  ) : type === "carrier" ? (
                    <a
                      class="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
                      href={`/carrier-invoice/${item.id}`}
                    >
                      VIEW
                    </a>
                  ) : type === "exporter" ? (
                    <a
                      class="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
                      href={`/exporter-invoice/${item.id}`}
                    >
                      VIEW
                    </a>
                  ) : (
                    <a
                      class="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
                      href={`/purchase-invoice/${item.invoice_id}`}
                    >
                      VIEW
                    </a>
                  )}
                </div>
              </div>
            );
          })
        }

        {/* <div className="w-full relative px-[27px] overflow-x-auto sm:rounded-lg">
          {loader && <div className="custom-loader"></div>}
        </div> */}
      </div>
    </>
  );
};

export default InvoiceHistory;
