import React from 'react';
import { formatPhoneNumber, numberToWords, formatPrice,invoiceCalculate } from '../../../utils/Common';
import Watermark from '../Watermark/Watermark';
import './PdfHtmlContent.css';



import QRCode from 'react-qr-code';
import ImageUploader3 from '../../ImageUploader3';
import classNames from 'classnames';

const PdfHtmlContent = props => {
  const { invoice, invoiceSetting, id, parentClassName, invoiceImeis } = props;
  const userInfo = invoice.user_info;
  const BASE_URL = process.env.REACT_APP_ROOT_URL;
  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  const day = String(invoiceDate.getDate()).padStart(2, '0');
  const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';
  const shop_logo = invoiceSetting?.shop_logo ? invoiceSetting?.shop_logo : `${BASE_URL}/layoutlogo.svg`;
  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
  const processedInvoiceIds = new Set();
  const processedProductIds = new Set(); // To track processed product_ids
  const imeisByProduct = [];

// Aggregate unique IMEIs with all details
invoiceImeis.forEach((imei) => {
  const imeiValue = imei.imei.toString().trim(); // Normalize the IMEI

  // Check if the IMEI already exists in imeisByProduct
  const imeiExists = imeisByProduct.some(imeiDetail => imeiDetail.imei === imeiValue);

  if (!imeiExists) {
    // If the IMEI doesn't exist, add it to imeisByProduct
    imeisByProduct.push({
      product_id:imei.product_id,
      product_name: imei.product_name,
      imei: imei.imei,
      sale_price: imei.sale_price,
      purchase_price: imei.purchase_price,
      base_currency_code: imei.base_currency_code,
      currency_rate: imei.currency_rate,
      purchase_currency_code: imei.purchase_currency_code,
    });
  }
});


  let totalQty = 0;
  let totalItems = 0;
  let totalPrice = 0;
  let grandTotal = totalPrice + 0;

  let totalQtyPrint = 0;
  let totalItemsPrint = 0;
  let totalPricePrint = 0;

  let grandTotalValue = invoice?.sales_details?.product_info?.reduce( (sum, product )=>{
    const imeisArray = invoiceImeis?.filter( (imei)=>imei.product_id === product.product_id);
    if(imeisArray?.length){
      const qty = imeisArray.length;
      sum + ( qty * imeisArray[0].sale_price )
    }
    else {
      sum + ( product.qty * product.price);
    }
    return sum;
  }, 0);


// console.log("sale ");

  return (
  // new invoice
  <>
  <div className="pdfConentAreas">
      <div id="invoice-detail-page" className="invoice-detail-page font-nunito">
       {/* invoice header */}
       <div className="text-center text-xs md:text-sm lg:text-base">
          <div>

            <img src={shop_logo} alt={invoice.invoice_id} className={parentClassName + ' mx-auto w-1/2 md-[5px] max-w-[220px]'} />

            <div className=' leading-4 sm:leading-5 md:leading-6 mt-[5px] font-normal'>
              {userInfo?.outlet_name &&
                <p className='capitalize text-[22px] font-semibold'>{userInfo?.outlet_name} </p>
              }
              {userInfo?.address &&
                <p>{userInfo?.address}</p>
              }
              {invoiceSetting?.mobile_number &&
                <p>{formatPhoneNumber(invoiceSetting?.mobile_number, invoice?.user_info?.country_code)}</p>
              }
            <div className='flex flex-col '>
           <span>{invoiceSetting?.web_address ?
              invoiceSetting?.web_address
            : (
              ""
            )}</span>
            <span>
            {invoiceSetting?.social_link ?
              invoiceSetting?.social_link
            : (
              ""
            )}
            </span>

            </div>

            </div>
          </div>
        </div>
        {/* customer info and QR */}
        <div className="flex items-center mt-[10px] justify-between">
          <div className="text-black space-y-[2px] md:space-y-[6px] font-nunito text-xs  lg:text-base">
            <p> <span className='font-semibold'>Customer Name</span>  :  {invoice.customer_name} </p>
            <p><span className='font-semibold'>Customer Phone</span> :  {invoice.country_code}{invoice.customer_phone}</p>
            <p><span className='font-semibold'> Customer ID </span> :#{invoice.customer_id}</p>
            <p><span className='font-semibold'> Invoice Date </span>: {formattedDateTime} </p>
          </div>
          <div className="w-[45px] sm:w-[65px] md:w-[100px]">
            <QRCode className="w-full object-contain" value={BASE_URL + '/invoice/' + invoice.invoice_id} size={100} />
          </div>
        </div>
        <div className='text-xs  lg:text-base' style={{ width: '100%', backgroundColor: '#3056FA', padding: '10px', textAlign: 'center', margin: '15px auto' }}>
          <p className="text-white font-nunito py-1 text-14 font-bold uppercase">Invoice No: {invoice.invoice_id}</p>
        </div>
       {/* table */}
       <div className=' mx-auto md:w-full md:max-w-full overflow-x-auto'>
       <table className='table'>
            <thead id='pdfContentTHead'>
              <tr className="leading-normal text-sm  lg:text-base font-nunito tracking-wide">
                <th className='font-nunito'>Item Name</th>
                <th className='font-nunito text-center '>Warranty</th>
                <th className='font-nunito text-center'>Quantity</th>
                <th className='font-nunito text-center'>Price</th>
                <th className='font-nunito text-right'>Total</th>
              </tr>
            </thead>
            <tbody>
              {/* <Watermark watermark_text={invoiceSetting?.watermark_text} watermark_type={invoiceSetting?.watermark_type}></Watermark> */}
              {invoice.sales_details.map((item) => {
                  const productId = item.product_id;

                  // Skip if the product_id has already been processed
                  if (processedProductIds.has(productId)) {
                    return;
                  }

                  // Retrieve unique IMEIs and their details for the current product_id
                  const imeisDetails = imeisByProduct.filter((imeiDetail) => imeiDetail.product_id === productId);
                  let subtotalPrice = 0;
                  console.log(imeisByProduct);
                  console.log(imeisDetails);
                  if (imeisDetails.length > 0) {
                    const qty = imeisDetails.length; // Number of unique IMEIs found for the product
                    totalItems += qty;

                    // Calculate the total sale price for all unique IMEIs
                    const sumPrice = imeisDetails.reduce((acc, imei) => acc + imei.sale_price, 0);
                    subtotalPrice = sumPrice;
                    totalPrice += subtotalPrice;

                    // Optionally, log the details for debugging
                    console.log(`Product: ${imeisDetails[0].product_name}, IMEIs: ${qty}, Subtotal: ${subtotalPrice}`);
                    console.log(imeisDetails);

                    // Mark this product_id as processed to avoid duplication
                    processedProductIds.add(productId);
                  } else {
                      subtotalPrice = formatPrice(item.qty * item.price); // Calculate subtotal price for the product
                      totalQty += Number(item.qty); // Add the quantity of the product
                      totalItems += item.qty; // Count this product as a single item
                      totalPrice += Number(subtotalPrice); // Add to the total price

                      // Optionally, log the details for debugging
                      console.log(`Non-IMEI Product: ${item.product_id}, Quantity: ${item.qty}, Subtotal: ${subtotalPrice}`);

                  }

                return (
                  imeisDetails?.length ?
                  (
                    imeisDetails?.map((imei)=>(
                      <tr key={imei.id} className=" font-nunito border-[#E7EBF4] font-normal text-sm  lg:text-base relative">
                      <td className=''>{imei?.product_name} {imei?.optional_name}<br /><span className='text-[13px]'>{ imei?.imei }</span></td>
                      {/* <td className='text-center'>{item?.product_info?.warrenty ? item?.product_info?.warrenty : '        '}</td> */}
                      <td className='text-center'>
                        {/* {
                          invoice.defaultwarranties &&
                          invoice.defaultwarranties.map((warrantyItem) => (
                            warrantyItem.invoice_id === invoice.invoice_id && warrantyItem.product_id===item.product_id &&
                            (
                              warrantyItem.warranty ? (
                                // <h1>{""}</h1>
                                <h1 className='text-sm'>{warrantyItem?.warranty?.warranties_count==null?"":warrantyItem?.warranty?.warranties_count}
                                {" "}{warrantyItem?.warranty?.name==null?"":warrantyItem?.warranty?.name}</h1>
                              ) : (
                                // <h1>{""}</h1>
                                <h1 className='text-sm'>{warrantyItem?.default_warranties_count==null?"":warrantyItem?.default_warranties_count} {" "}
                                {item?.product_info?.warrenty==null?"":item?.product_info?.warrenty}
                                </h1>
                              )
                            )
                          ))
                        } */}
                        {
                          invoice.defaultwarranties &&
                          invoice.defaultwarranties.map((warrantyItem) => (
                            warrantyItem.invoice_id === invoice.invoice_id && warrantyItem.product_id===item.product_id &&
                            (
                              warrantyItem.warranty_id==null ? (
                                // <h1>{""}</h1>
                                <h1 className='text-sm'>{warrantyItem?.default_warranties_count==null?"":warrantyItem?.default_warranties_count} {" "}
                                {item?.product_info?.warrenty==null?"":item?.product_info?.warrenty}
                                </h1>
                              ) : (
                                // <h1>{""}</h1>
                                <h1 className='text-sm'>{warrantyItem?.warranty?.warranties_count==null?"":warrantyItem?.warranty?.warranties_count}
                                {" "}{warrantyItem?.warranty?.name==null?"":warrantyItem?.warranty?.name}</h1>
                              )
                            )
                          ))
                        }
                        {/* {
                          invoice.defaultwarranties &&
                          invoice.defaultwarranties.map((warrantyItem) => (
                            warrantyItem.invoice_id === invoice.invoice_id && warrantyItem.product_id===item.product_id  &&
                            (
                              warrantyItem.warranty==="null"  ? (
                                <h1>{""}</h1>
                                // <h1 className='text-sm'>{warrantyItem?.warranty?.warranties_count=="null"?"":warrantyItem?.warranty?.warranties_count}
                                // {" "}{warrantyItem?.warranty?.name=="null"?"":warrantyItem?.warranty?.name}</h1>
                              ) : (
                                <h1 className='text-sm'>{warrantyItem?.default_warranties_count=="null"?"":warrantyItem?.default_warranties_count} {" "}
                                {item?.product_info?.warrenty=="null"?"":item?.product_info?.warrenty}
                                </h1>
                              )
                            )
                          ))
                        } */}
                      </td>
                      <td className='text-center'>{"1"}</td>
                      <td className='text-center'>{imei.sale_price}</td>
                      <td className=' text-right font-semibold md:font-semibold'>{imei.sale_price}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</td>
                    </tr>
                    ))
                  )
                  :
                 ( <tr key={item.id} className=" font-nunito border-[#E7EBF4] font-normal text-sm  lg:text-base relative">
                    <td className=''>{item?.product_info?.name} <br /><span className='text-[13px]'>{   item?.product_info?.serial}</span></td>
                    {/* <td className='text-center'>{item?.product_info?.warrenty ? item?.product_info?.warrenty : '        '}</td> */}
                    <td className='text-center'>
                      {/* {
                        invoice.defaultwarranties &&
                        invoice.defaultwarranties.map((warrantyItem) => (
                          warrantyItem.invoice_id === invoice.invoice_id && warrantyItem.product_id===item.product_id &&
                          (
                            warrantyItem.warranty ? (
                              // <h1>{""}</h1>
                              <h1 className='text-sm'>{warrantyItem?.warranty?.warranties_count==null?"":warrantyItem?.warranty?.warranties_count}
                              {" "}{warrantyItem?.warranty?.name==null?"":warrantyItem?.warranty?.name}</h1>
                            ) : (
                              // <h1>{""}</h1>
                              <h1 className='text-sm'>{warrantyItem?.default_warranties_count==null?"":warrantyItem?.default_warranties_count} {" "}
                              {item?.product_info?.warrenty==null?"":item?.product_info?.warrenty}
                              </h1>
                            )
                          )
                        ))
                      } */}
                      {
                        invoice.defaultwarranties &&
                        invoice.defaultwarranties.map((warrantyItem) => (
                          warrantyItem.invoice_id === invoice.invoice_id && warrantyItem.product_id===item.product_id &&
                          (
                            warrantyItem.warranty_id==null ? (
                              // <h1>{""}</h1>
                              <h1 className='text-sm'>{warrantyItem?.default_warranties_count==null?"":warrantyItem?.default_warranties_count} {" "}
                              {item?.product_info?.warrenty==null?"":item?.product_info?.warrenty}
                              </h1>
                            ) : (
                              // <h1>{""}</h1>
                              <h1 className='text-sm'>{warrantyItem?.warranty?.warranties_count==null?"":warrantyItem?.warranty?.warranties_count}
                              {" "}{warrantyItem?.warranty?.name==null?"":warrantyItem?.warranty?.name}</h1>
                            )
                          )
                        ))
                      }
                      {/* {
                        invoice.defaultwarranties &&
                        invoice.defaultwarranties.map((warrantyItem) => (
                          warrantyItem.invoice_id === invoice.invoice_id && warrantyItem.product_id===item.product_id  &&
                          (
                            warrantyItem.warranty==="null"  ? (
                              <h1>{""}</h1>
                              // <h1 className='text-sm'>{warrantyItem?.warranty?.warranties_count=="null"?"":warrantyItem?.warranty?.warranties_count}
                              // {" "}{warrantyItem?.warranty?.name=="null"?"":warrantyItem?.warranty?.name}</h1>
                            ) : (
                              <h1 className='text-sm'>{warrantyItem?.default_warranties_count=="null"?"":warrantyItem?.default_warranties_count} {" "}
                              {item?.product_info?.warrenty=="null"?"":item?.product_info?.warrenty}
                              </h1>
                            )
                          )
                        ))
                      } */}
                    </td>
                    <td className='text-center'>{item.qty}</td>
                    <td className='text-center'>{item.price}</td>
                    <td className=' text-right font-semibold md:font-semibold'>{subtotalPrice}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</td>
                  </tr>)
                );
              }
              )}
            </tbody>
          </table>
       </div>

        {/* <DashedLine2 /> */}
        <br />
        {/* disclaimer & summary */}
        <div className='flex justify-between'>
        {/* disclaimer */}
          <div className="w-1/2 text-xs  pr-1 md:pr-0">
            {invoiceSetting?.sale_condition ?
              invoiceSetting?.sale_condition
            : (
              ""
              // <p>Damage caused by misuse, abuse, or neglect.<br/>
              //   Unauthorized modifications or repairs.<br/>
              //   Acts of nature, such as floods, earthquakes, or other disasters.<br/>
              //   This may include proper use.</p>
            )}
          </div>
          {/* invoice sumary */}
          <div className="w-1/2 text-xs md:text-sm lg:text-base font-semibold pl-2  md:pl-8">
            <div className='space-y-[2px] mb-2'>
            <p className='flex justify-between'>
              <span>Total Items</span>
              <span>{totalItems}</span>
            </p>
            <p className='flex justify-between'>
              <span>Sub Total</span>
              <span>{formatPrice(totalPrice)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <p className='flex justify-between'>
              <span>Discount</span>
              <span>{formatPrice(invoice.discount ?invoice.discount : 0)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <p className='flex justify-between' >
              <span>Vat {''}</span>
              <span>{invoice.vat ? formatPrice(invoice.vat) : 0.00}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <p className='flex justify-between'>
              <span>Service Charge {''}</span>
              <span>{invoice.tax ? formatPrice(invoice.tax) : 0.00}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            </div>
            <hr></hr>
            <div className='my-2'>
            <p className='flex justify-between'>
              <span>Grand Total</span>
              {/* <span>{formatPrice(invoiceCalculate(invoice).grandTotal) } */}
              <span>{Number.parseFloat(Number(totalPrice) + Number(invoice?.vat) + Number(invoice?.tax) + Number(invoice?.delivery_fee)) - Number(invoice?.discount).toFixed(2)}
              {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <p className='flex justify-between'>
              <span>Paid Amount</span>
              {/* <span>{invoice.paid_amount && invoice.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(totalPrice)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span> */}
              <span>{invoice.paid_amount && invoice.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(0)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            </div>
            <hr></hr>
            <p className='flex justify-between mt-2'>
              <span>Due Amount</span>
              {/* <span>{formatPrice(invoice.sub_total - invoice.paid_amount)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span> */}
              {/* <span>{formatPrice(invoiceCalculate(invoice).dueAmount) } */}
              <span>{ Number.parseFloat( ((Number(totalPrice) + Number(invoice?.vat) + Number(invoice?.tax) + Number(invoice?.delivery_fee)) - Number(invoice?.discount))-(Number(invoice.paid_amount && invoice.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(0)))).toFixed(2)}
              {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
          </div>
        </div>
        {/* amount in words */}
        <p className='text-gray-900 mt-4 font-nunito text-sm  lg:text-base font-semibold leading-normal text-center'><span className='text-[#7282A0]'>In Word:</span> {numberToWords(formatPrice(invoiceCalculate(invoice).grandTotal))} taka only</p>
        <div style={{ width: '100%', backgroundColor: '#034AFF', padding: '10px', textAlign: 'center', margin: '25px auto' }}>
          <p className="text-white font-nunito text-14 font-bold">You saved {formatPrice(invoice.discount ?invoice.discount : 0)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'} in this purchase </p>
        </div>
        {/* signature */}
          <div className='text-xs md:text-sm lg:text-base'>
            <div className='flex justify-between'>
              <p className="invisible">.</p>
              {!id && invoiceSetting?.sign_authority ? <div className='w-[120px] h-[120px] rounded-lg'>
                <img src={invoiceSetting.sign_authority} className='object-cover excludeFromPdf' />
              </div> : null}
            </div>
            <div className="flex justify-between m-2">
              <p><hr></hr> Customer Signature</p>
              <p><hr></hr> Seller Signature </p>
            </div>
          </div>
        {/* footer */}
        <div className='text-center font-nunito mt-4 text-xs md:text-sm lg:text-base'>
          <h2 className='text-orange-500  text-xl font-semibold '>Thanks for purchase</h2>
          <p className='text-black mt-3 text-xs mb-2'>A Product of SQUAD INNOVATORS </p>
        </div>
      </div>
    </div>

  {/* this is the pownload file */}
  <div className='hidden'>
  <div id="pdfConentAreas" className="pdfConentAreas">
      <div id="invoice-detail-page" className="invoice-detail-page font-nunito">
        {/* invoice header */}
        <div className="text-center text-xs md:text-sm lg:text-base">
           {/* outlet info */}

            <img
              src={shop_logo}
              alt={invoice.invoice_id}
              className="mx-auto w-1/2 md-[0px] max-w-[220px]"
            />
            {/* <div className=" leading-4 sm:leading-5 md:leading-6 mt-[16px] font-normal"> */}
            <div className=" leading-4 sm:leading-5 md:leading-6 mt-[5px] font-semibold space-y-1">
              {userInfo?.outlet_name ? (
                <p className='text-[22px] font-semibold'>{userInfo?.outlet_name} </p>
              ) : (
                ""
                // <p>Outlet Name</p>
              )}
              {userInfo?.address ? (
                <p>{userInfo?.address}</p>
              ) : (
                ""
                // <p>Outlet Address</p>
              )}
              {invoice?.user_info?.phone ? (
                <p>{formatPhoneNumber(invoice?.user_info?.phone, invoice?.user_info?.country_code)}</p>
              ) : (
                ""
                // <p>Outlet phone </p>
              )}
              <div className='flex flex-col '>
                <span>{invoiceSetting?.web_address ?
                    invoiceSetting?.web_address
                  : (
                    ""
                  )}</span>
                  <span>
                  {invoiceSetting?.social_link ?
                    invoiceSetting?.social_link
                  : (
                    ""
                  )}
                </span>

              </div>
            </div>


        </div>
        {/* customer info and  + QR*/}
             <div className="flex items-center mt-[7px] justify-between">
          <div className="text-black space-y-[2px] md:space-y-[4px] font-nunito text-xs  lg:text-base">
            <p> <span className='font-semibold'>Customer Name</span>  :  {invoice.customer_name} </p>
            <p><span className='font-semibold'>Customer Phone</span> :  {invoice.country_code}{invoice.customer_phone}</p>
            <p><span className='font-semibold'> Customer ID </span> :#{invoice.customer_id}</p>
            <p><span className='font-semibold'> Invoice Date </span>: {formattedDateTime} </p>
          </div>
          <div className="w-[45px] sm:w-[65px] md:w-[100px]">
            <QRCode className="w-full object-contain" value={BASE_URL + '/invoice/' + invoice.invoice_id} size={100} />
          </div>
        </div>
        {/* invoice serial */}
        <div
          className="text-xs  lg:text-base"
          style={{
            width: "100%",
            backgroundColor: "#3056FA",
            padding: "0 10px 10px 0px",
            textAlign: "center",
            margin: "5px auto",
          }}
        >
          <p className="text-white font-nunito py-1 text-14 font-bold uppercase">
            Invoice No: {invoice.invoice_id}
          </p>
        </div>
        {/* table */}
        {/* <div className="max-w-[calc(100vw-28vw)] w-[calc(100vw-28vw)] mx-auto md:w-full md:max-w-full overflow-x-auto"> */}
        <div className="overflow-x-auto">
          <table className="table">
            <thead id="pdfContentTHead">
              <tr className="leading-normal text-sm  lg:text-base font-nunito tracking-wide">
                <th className="pb-3 font-nunito">Item Name</th>
                <th className="pb-3 font-nunito text-center ">Warranty</th>
                <th className="pb-3 font-nunito text-center">Quantity</th>
                <th className="pb-3 font-nunito text-center">Price</th>
                <th className="pb-3 font-nunito text-right">Total</th>
              </tr>
            </thead>
            <tbody textAlign="start">
              {/* <Watermark watermark_text={invoiceSetting?.watermark_text} watermark_type={invoiceSetting?.watermark_type}></Watermark> */}
              {invoice?.sales_details?.map((item) => {
                // totalQtyPrint += Number(item.qty);
                // totalItemsPrint += 1;
                // const subtotalPrice = item.qty * item.price;
                // totalPricePrint += Number(subtotalPrice);
                const findImeis = invoiceImeis.filter( (imei) => imei?.product_id === item?.product_id )
                let subtotalPrice = 0;
                if(findImeis?.length){
                  const qty = Number.parseInt(findImeis?.length)
                  totalItemsPrint += qty;
                  // subtotalPrice = Number.parseFloat( findImeis[0].sale_price * qty)
                  const sumPrice = findImeis?.reduce( (acc, prod)=>{
                    return acc + prod.sale_price
                  },0)
                  // subtotalPrice = Number.parseFloat( findImeis[0].sale_price * qty)
                  subtotalPrice = sumPrice;
                  totalPricePrint += Number(subtotalPrice);
                }
                else{
                  totalQty += Number(item.qty);
                  totalItemsPrint += 1;
                  subtotalPrice = formatPrice(item.qty * item.price);
                  totalPricePrint += Number(subtotalPrice);
                }
                return (
                  findImeis?.length ?
                  (
                    findImeis?.map((imei)=>(
                      <tr
                      key={item.id}
                      className=" font-nunito border-[#E7EBF4] font-normal text-sm  lg:text-base relative"
                    >
                      <td className="pt-0">{imei?.product_name} {imei?.optional_name}<br /><span className='text-[13px]'>{imei?.imei}</span></td>
                      <td className="pt-0 text-center">
                        {/*
                          {
                          invoice.defaultwarranties &&
                          invoice.defaultwarranties.map((warrantyItem) => (
                            warrantyItem.invoice_id === invoice.invoice_id && warrantyItem.product_id===item.product_id  &&
                            (
                              warrantyItem.warranty  ? warrantyItem?.warranty?.warranties_count+ " "+warrantyItem?.warranty?.name
                              :
                                warrantyItem?.default_warranties_count+" "
                            )
                          ))
                        } */}
                        {
                          invoice.defaultwarranties &&
                          invoice.defaultwarranties.map((warrantyItem) => (
                            warrantyItem.invoice_id === invoice.invoice_id && warrantyItem.product_id===item.product_id &&
                            (
                              warrantyItem.warranty_id==null ? (
                                <h1 className='text-sm'>{warrantyItem?.default_warranties_count==null?"":warrantyItem?.default_warranties_count} {" "}
                                {item?.product_info?.warrenty==null?"":item?.product_info?.warrenty}
                                </h1>
                              ) : (
                                <h1 className='text-sm'>{warrantyItem?.warranty?.warranties_count==null?"":warrantyItem?.warranty?.warranties_count}
                                {" "}{warrantyItem?.warranty?.name==null?"":warrantyItem?.warranty?.name}</h1>
                              )
                            )
                          ))
                        }
                      </td>
                      <td className="pt-0 text-center">{"1"}</td>
                      <td className="pt-0 text-center">{imei?.sale_price}</td>
                      <td className="pt-0 text-right font-semibold md:font-semibold">
                        {imei?.sale_price}
                        {invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info
                              ?.code
                          : " BDT"}
                      </td>
                    </tr>) ))
                    :
                    (<tr
                      key={item.id}
                      className=" font-nunito border-[#E7EBF4] font-normal text-sm  lg:text-base relative"
                    >
                      <td className="pt-0">{item?.product_info?.name}<br /><span className='text-[13px]'>{item?.product_info?.serial}</span></td>
                      <td className="pt-0 text-center">
                        {/*
                          {
                          invoice.defaultwarranties &&
                          invoice.defaultwarranties.map((warrantyItem) => (
                            warrantyItem.invoice_id === invoice.invoice_id && warrantyItem.product_id===item.product_id  &&
                            (
                              warrantyItem.warranty  ? warrantyItem?.warranty?.warranties_count+ " "+warrantyItem?.warranty?.name
                              :
                                warrantyItem?.default_warranties_count+" "
                            )
                          ))
                        } */}
                        {
                          invoice.defaultwarranties &&
                          invoice.defaultwarranties.map((warrantyItem) => (
                            warrantyItem.invoice_id === invoice.invoice_id && warrantyItem.product_id===item.product_id &&
                            (
                              warrantyItem.warranty_id==null ? (
                                <h1 className='text-sm'>{warrantyItem?.default_warranties_count==null?"":warrantyItem?.default_warranties_count} {" "}
                                {item?.product_info?.warrenty==null?"":item?.product_info?.warrenty}
                                </h1>
                              ) : (
                                <h1 className='text-sm'>{warrantyItem?.warranty?.warranties_count==null?"":warrantyItem?.warranty?.warranties_count}
                                {" "}{warrantyItem?.warranty?.name==null?"":warrantyItem?.warranty?.name}</h1>
                              )
                            )
                          ))
                        }
                      </td>
                      <td className="pt-0 text-center">{item.qty}</td>
                      <td className="pt-0 text-center">{item.price}</td>
                      <td className="pt-0 text-right font-semibold md:font-semibold">
                        {subtotalPrice}
                        {invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info
                              ?.code
                          : " BDT"}
                      </td>
                    </tr>)
                );
              })}
            </tbody>
          </table>
        </div>
        {/* disclaimer & summary */}
        <div className="flex justify-between">
          {/* disclaimer */}
          <div className="w-1/2 text-xs  pr-1 md:pr-0">
            {invoiceSetting?.condition ? (
            invoiceSetting?.condition
          ) : (
            ""
          )}
          </div>
          {/* invoice sumary */}
          <div className="w-1/2 text-xs md:text-sm lg:text-base font-semibold pl-2  md:pl-8">
            <div className="space-y-[2px] mb-2">
              <p className="flex justify-between">
                <span>Total Items</span>
                <span>{totalItemsPrint}</span>
              </p>
              <p className="flex justify-between">
                <span>Sub Total</span>
                <span>
                  {formatPrice(totalPricePrint)}
                  {/* {totalPrice} */}
                  {invoiceSetting?.currency_info?.code
                    ? invoiceSetting?.currency_info?.code
                    : " BDT"}
                </span>
              </p>
              <p className="flex justify-between">
                <span>Discount</span>
                <span>
                  {formatPrice(invoice.discount ? invoice.discount : 0)}
                  {/* {invoice.discount ? invoice.discount : 0} */}
                  {invoiceSetting?.currency_info?.code
                    ? invoiceSetting?.currency_info?.code
                    : " BDT"}
                </span>
              </p>
              <p className="flex justify-between">
                <span>Vat</span>
                <span>
                  {invoice.vat ? formatPrice(invoice.vat): 0.0000}
                  {/* {invoice.vat} */}
                  {invoiceSetting?.currency_info?.code
                    ? invoiceSetting?.currency_info?.code
                    : " BDT"}
                </span>
              </p>
              <p className="flex justify-between">
                <span>Service Charge </span>
                <span>
                  {invoice.tax ? formatPrice(invoice.tax) : 0.0}
                  {/* {invoice.tax} */}
                  {invoiceSetting?.currency_info?.code
                    ? invoiceSetting?.currency_info?.code
                    : " BDT"}
                </span>
              </p>
            </div>
            <hr></hr>
            <div className="my-2">
              <p className="flex justify-between">
                <span>Grand Total</span>
                <span>
                  {/* {formatPrice(invoiceCalculate(invoice).grandTotal)} */}
                  {Number.parseFloat((Number(totalPricePrint) + Number(invoice?.vat) + Number(invoice?.tax) + Number(invoice?.delivery_fee)) - Number(invoice?.discount)).toFixed(2)}
                  {/* {invoice.sub_total} */}
                  {invoiceSetting?.currency_info?.code
                    ? invoiceSetting?.currency_info?.code
                    : " BDT"}
                </span>
              </p>
              <p className="flex justify-between">
                <span>Paid Amount</span>
                <span>
                  {/* {formatPrice(invoice.paid_amount)} */}
                  {formatPrice(invoice.paid_amount)}
                  {invoiceSetting?.currency_info?.code
                    ? invoiceSetting?.currency_info?.code
                    : " BDT"}
                </span>
              </p>
            </div>
            <hr></hr>
            <p className="flex justify-between mt-2">
              <span>Due Amount</span>
              <span>
                {/* {Number.parseFloat(invoiceCalculate(invoice).dueAmount).toFixed(2)} */}
                { Number.parseFloat( ((Number(totalPricePrint) + Number(invoice?.vat) + Number(invoice?.tax) + Number(invoice?.delivery_fee)) - Number(invoice?.discount))-(Number(invoice.paid_amount && invoice.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(0)))).toFixed(2)}
                {/* {invoice.sub_total - invoice.paid_amount} */}
                {invoiceSetting?.currency_info?.code
                  ? invoiceSetting?.currency_info?.code
                  : " BDT"}
              </span>
            </p>
          </div>
        </div>
        {/* amount in words */}
        <p className="text-gray-900 mt-4 font-nunito text-sm  lg:text-base font-semibold leading-normal text-center">
          <span className="text-[#7282A0]">In Word:</span>{" "}
          {numberToWords(formatPrice(invoiceCalculate(invoice).grandTotal))} taka only
          {/* numberToWords(invoice.sub_total) taka only */}
        </p>
        <div
          style={{
            width: "100%",
            backgroundColor: "#034AFF",
            padding: "0 10px 10px 10px",
            textAlign: "center",
            margin: "25px auto",
          }}
        >
          <p className="text-white font-nunito text-14 font-bold">
            You saved {formatPrice(invoice.discount ? invoice.discount : 0)}
            {/* You saved {invoice.discount ? invoice.discount : 0} */}
            {invoiceSetting?.currency_info?.code
              ? invoiceSetting?.currency_info?.code
              : " BDT"}{" "}
            in this purchase{" "}
          </p>
        </div>
        {/* signature */}
        <div className="text-xs md:text-sm lg:text-base">
          <div className="flex justify-between">
            {/* <p className="invisible">
              I dont know what or where this section is :(
            </p> */}
            {invoiceSetting?.sign_authority && (
            <div className="w-[100px] h-[30px] rounded-lg">
              {/* <img
                src={invoiceSetting.sign_authority}
                className="object-cover"
              /> */}
            </div>
          )}

            <div className="w-[100px] h-[30px] rounded-lg">
              {/* <img
                className="object-cover"
                alt="invoice-setting-sign-authority"
              /> */}
            </div>
          </div>
          <div className="flex justify-between m-2">
            <p>
              <hr></hr> Customer Signature
            </p>
            <p>
              <hr></hr> Seller Signature{" "}
            </p>
          </div>
        </div>
        {/* footer */}
        <div className="text-center font-nunito mt-4 text-xs md:text-sm lg:text-base">
          <h2 className="text-orange-500  text-xl font-semibold ">
            Thanks for purchase
          </h2>
          <p className="text-black mt-2 text-xs">
            A Product of SQUAD INNOVATORS{" "}
          </p>
        </div>
      </div>
    </div>
  </div>
  </>
  );
};

export default PdfHtmlContent;
