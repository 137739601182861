import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "./setNewPassword.css";
import { Button, Form, Input } from "antd";
import axios from "axios";
import { BeatLoader } from 'react-spinners';
const BASE_URL = process.env.REACT_APP_ROOT_URL;
function SetNewPassword() {
  const navigate = useNavigate();
  const [passwordMismatchError, setPasswordMismatchError] = useState(false);
  const [warningMessage, setWarningMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };
  const onFinish = (values) => {
    console.log("Success:", values);
    const resetEmailAddress = localStorage.getItem("resetEmailAddress");

    const credentials = {
      email: resetEmailAddress,
      password: values.password,
    };

    if (values.password !== values.confirmpassword) {
      setPasswordMismatchError(true);
      const timer = setTimeout(() => {
        setPasswordMismatchError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }

    if (!resetEmailAddress) {
      setWarningMessage(true);
      const timer = setTimeout(() => {
        setWarningMessage(false);
        navigate("/login");
      }, 3000);
      return () => clearTimeout(timer);
    }
    loadingCallback(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}reset-password`, credentials)
      .then((response) => {
        console.log(response);
        loadingCallback(false);
        if (response.data.status === 200) {
          localStorage.removeItem("isResetPassword");
          localStorage.removeItem("resetEmailAddress");
          navigate("/login");
        } else if (response.data.status === 404) {
          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 404) {
          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      });
  };

  return (
    <section style={{ background: "#000" }}>
      <div className="SetPasswordForget-container">
        <div className="SetPasswordForget-left">
          <img
            src={`${BASE_URL}/assets/registration/svg/forgetpassveripasss.svg`}
            alt="loginimage"
          />
        </div>
        <div className="SetPasswordForget-right">
          <div className="SetPasswordForget-right-card">
            <div className="SetPasswordForget-log-img">
              <img src={`${BASE_URL}/assets/registration/webp/logo1.webp`} alt="logo" />
              {/* <h1>Log In</h1> */}
              <p>Set New Your Password</p>

              {/* <p>your expense for you.</p> */}
            </div>

            {passwordMismatchError && (
              <div className="warning-message">Password mismatch!</div>
            )}

            {warningMessage && (
              <div className="warning-message">
                Something went wrong! Please try again
              </div>
            )}
            {loading && (

              <div className="custom-loader-logo">
                {/* <BeatLoader color={"#123abc"} size={20} /> */}
                <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
              </div>
            )}
            <div className="SetPasswordForget-form-main">
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #F1F1FA",
                      borderRadius: "16px",
                      fontSize: "16px",
                      paddingTop: "17px",
                      paddingBottom: "17px",
                    }}
                    name="password"
                    type="password"
                    placeholder="New Password"
                  />
                </Form.Item>
                <Form.Item
                  name="confirmpassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #F1F1FA",
                      borderRadius: "16px",
                      fontSize: "16px",
                      paddingTop: "17px",
                      paddingBottom: "17px",
                    }}
                    name="confirmpassword"
                    type="password"
                    placeholder="Confirm New password"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="SetPasswordForget-form-button"
                  >
                    Continue
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SetNewPassword;
