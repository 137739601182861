import React, { useState, useEffect } from "react";
import { arrayOf, bool, object, func, shape, string } from 'prop-types';
import { BrowserRouter, Routes, Route, Navigate,Redirect } from "react-router-dom";
import SignUp from "./components/registration/signup/Index";
import { propTypes } from './utils/types';
const RouteComponent = (props, context) => {

  const { routes } = props;

  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const isPinVerify = localStorage.getItem("isPinVerify");

    if (token && isPinVerify) {
      setAuthenticated(true);
    }
  }, []);
  // const handleLogin = () => {
  //   // Perform login logic here
  //   // After successful login, set authenticated to true and store the value in localStorage
  //   setAuthenticated(true);
  //   localStorage.setItem("credentials", "true");
  // };
  
   const toRouteComponent = route => {  
      if (route.childGroup) {
        return (
          <Route
            key={route.name}
            path={route.path}
            element={route.element}
          >
            {route.childGroup.map(toRouteComponent)}
          </Route>
        );
      } else {
        return (
          <Route
            key={route.name}
            path={route.path}
            element={route.element}
          />
        );
      }
      
  };

  return (
    <BrowserRouter>
      <Routes>
        {routes.map(toRouteComponent)}
        {authenticated ? (
             <Route path="*" element={<Navigate to="/verifypin" replace />} />
           ) : (
             <Route path="*" element={<SignUp />} />
        )}
      </Routes>
    </BrowserRouter>
  );
}
RouteComponent.propTypes = {
  routes: arrayOf(propTypes.route).isRequired,
};

export default RouteComponent;

