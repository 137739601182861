
import React, { useEffect, useState } from 'react';
import { formatDate, formatPrice, timeDiff } from '../../utils/Common';

const BalanceHistoryItem = (props) => {
  const {
    balanceData,
    paymentList,
    paymentAccountList,
    activeTab,
    selectAllChecked,
    setAllFetchedData,
    allFetchedData,
    onSelect,
    index
  } = props;

  const [isChecked, setIsChecked] = useState(false);

  // useEffect(() => {
  //   setIsChecked(selectAllChecked);
  // }, [selectAllChecked]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onSelect(index, balanceData);
  };

  const paymentData = paymentList.data ? paymentList.data : paymentList;
  const paymentAccountData = paymentAccountList.data ? paymentAccountList.data : paymentAccountList;

  const createdDate = new Date(balanceData?.created_at);

  return (
    <>
      {activeTab &&
        <div className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-7 items-start">

          <div className='text-left'>
            <input
              type="checkbox"
              checked={balanceData.completed}
              onChange={handleCheckboxChange}
            />
          </div>

          <div className="text-left">
            <h1 className=" text-[#000] font-[600]">{formatDate(balanceData?.created_at)}</h1>
            <p className=" text-[#87889E] font-[500] text-sm">{timeDiff(createdDate)}</p>
          </div>

          <div className="text-[#000] font-[500] text-center">
            <p className={`text-blue-500 text-[17px]`}>{balanceData?.payment_type?.type_name}</p>
            <p className={`text-green-500`}>{balanceData?.payment_type_category?.payment_category_name}</p>
            <p className={`text-red-500`}>{balanceData?.payment_type_category?.account_number}</p>

          </div>


          <div className="text-[#000] font-semibold text-center">
            <p className="text-[#000] font-[500] text-md">{balanceData?.status}</p>
          </div>


          <div className="text-[#000] font-[500] text-center">
            <p className="text-[#000] font-[500] text-md">{balanceData?.type}</p>
          </div>

          <div className=" text-[#000] font-[500] text-center ">{balanceData?.invoice_id}</div>

          <div className=" text-[#000] font-[500] text-right">{Number.parseFloat(balanceData?.payment_amount).toFixed(2)}</div>
        </div>}
    </>
  );
};
BalanceHistoryItem.defaultProps = { className: null };
export default BalanceHistoryItem;