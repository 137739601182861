import Item from 'antd/es/list/Item';
import React, { useEffect, useMemo, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ItemSelectBox from './ItemSelectBox';
import Swal from 'sweetalert2';

const PaymentInfoForm = (props) => {
  const {
    dueAmount,
    loadingCallback,
    setSeller,
    setWarningMessage,
    state,
    setShowModal,
    isOpen,
    formId,
    paymentList,
    paymentAccountList,
    filteredAccountList,
    setFilteredAccountList,
    setSelectedAccountList,
    setPaidAmount,
    paymentData,
    formData,
    setFormData,
    selectType,
    setData,
    filteredSeller,
    setFilteredSeller,
    purchaseAmount
  } = props;

  const [methodIndex, setMethodIndex] = useState(null)
  const [filteredAccountDetails, setFilteredAccountDetails] = useState({})
  const [paymentAccountAmount, setPaymentAccountAmount] = useState([{
    methodName: '',
    AccountName: "",
    account_number: "",
    payment_type_id: '',
    payment_type_category_id: "",
    Amount: '',
  }]);
  const amountData = {
    methodName: "cash",
    AccountName: "",
    account_number: "",
    payment_type_id: "",
    payment_type_category_id: "",
    Amount: '',
  }
  const handleChangeSelect = (name, option) => {
    const { value, label } = option;
      // setFormData({
      //   ...formData,
      //   [name]: value,
      // });
      console.log("Option", name, option);
      console.log("PaymentList", paymentAccountList, paymentList);
      if (name === 'payment_id') {
        if (label != 'Select Payment' && value) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            method_id: '',
            account_number: "",
          }));
          const selectedMethodObject = paymentList.find(
            (method) => method.id === value
          );
          if (selectedMethodObject) {
            setFilteredAccountList(selectedMethodObject.payment_type_category || []);
          } else {
            setFilteredAccountList([]);
          }
          const newArray = [...paymentData];
          newArray[methodIndex] = selectedMethodObject;
          setData(newArray);

          const newArray2 = [...paymentAccountAmount];
          newArray2[methodIndex] = {...amountData,
            payment_type_id: value,
            methodName: label,

          }
          setPaymentAccountAmount(newArray2);
        }
        if (label == 'Select Payment') {
          setFormData((prevFormData) => ({
            ...prevFormData,
            account_id: '',
            account_number: "",
          }));
        }
      }
      else if(name=='account_id'){
        if (label == 'Select Account') {
          setFormData((prevFormData) => ({
            ...prevFormData,
            account_id: '',
            account_number: "",
          }));
          const newArray = [...paymentAccountAmount];
          newArray[methodIndex] = {...amountData,
            account_number: "",
            AccountName: "",
            payment_type_category_id: ''
          }
          setPaymentAccountAmount(newArray);
          setFilteredAccountDetails({})
        }
        else{
          const selectedAccount = paymentAccountList.find(
            (method) => method.id === value
          );

          console.log("account",selectedAccount, methodIndex);
          if(selectedAccount){
            const newArray = [...paymentAccountAmount];
            newArray[methodIndex] = {...newArray[methodIndex],
              AccountName: label,
              account_number: selectedAccount?.account_number,
              payment_type_category_id: value
            }
            setPaymentAccountAmount(newArray);
            setFilteredAccountDetails(selectedAccount)
          }
        }
      }
   }

   const handleAddMethod = async () =>{
    if(paymentData.length<paymentList.length){
      const selectedAccount = paymentList.find(
        (method) => method.type_name === "cash"
      );
      setData([...paymentData, selectedAccount])
      const newArray = [...paymentAccountAmount];
      let data = {...amountData,
        methodName: selectedAccount.type_name,
        payment_type_id: selectedAccount.id
      }
      setPaymentAccountAmount([...paymentAccountAmount, data])
    }
  }

  const handleCancelMethod = (id, par) => {
    if(paymentData.length>1){
      const indexToRemove = paymentData.findIndex((item, index) => index === par);
      if (indexToRemove !== -1) {
        const newData = [...paymentData];
        newData.splice(indexToRemove, 1);
        setData(newData);
      }
      const indexToRemove2 = paymentAccountAmount.findIndex((item, index) => index === par);
      if (indexToRemove2 !== -1) {
        const newData2 = [...paymentAccountAmount];
        newData2.splice(indexToRemove2, 1);
        setPaymentAccountAmount(newData2);
      }
    }
  };

  const handleSubmit = (e) =>{
    e.preventDefault();

    // if(selectType === "exporter"){
    //   Swal.fire("pay due option coming soon!")
    //   return;
    // }
    const uniqueNamesMap = new Set();

    const uniqueMap = paymentAccountAmount.reduce((acc, t) => {
      const key = `${t.payment_type_category_id}_${t.payment_type_id}`;

      if (!uniqueNamesMap.has(key) && Number(t.Amount)>=0 ) {
        uniqueNamesMap.add(key);

        const total = paymentAccountAmount.reduce((innerAcc, item) => {
          if (
            t.payment_type_category_id === item.payment_type_category_id &&
            t.payment_type_id === item.payment_type_id && Number(item.Amount)>0
          ) {
            return innerAcc + (Number(item.Amount));
          } else {
            return innerAcc;
          }
        }, 0);

        console.log("total Amount:", total);
        acc.push({ ...t, Amount: total });
      }

      return acc;
    }, []);

    console.log("patmentList", uniqueMap);

    const totalAmount = uniqueMap.reduce((acc, item) => {
      return acc + Number(item.Amount);
    }, 0);
    console.log(totalAmount);
    setPaidAmount(totalAmount);

    setSelectedAccountList(uniqueMap)
    //setShowModal(!isOpen)
  }

  return(
    <div className="duration-500">
      <div class="flex flex-row justify-between items-center py-3 px-1">
        <h1 class="text-md font-semibold font-lato pb-1 px-1">Purchase Amount</h1>
        <div class="">
          <p class="text-sm px-[4px] py-[2px]">{purchaseAmount}</p>
        </div>
      </div>
      {paymentData.map((item, index) => (
        <div key={index} className="">
          <div className="flex flex-row justify-between items-center py-3 px-1">
            <h1 className="text-md font-semibold font-lato pb-1 px-1">
              {item.type_name}
            </h1>
            {index != 0 && (
              <div
                className="bg-red-600 text-white rounded-md cursor-pointer"
                onClick={() => handleCancelMethod(item.id, index)}
              >
                <p className="text-sm px-[4px] py-[2px]">Cancel</p>
              </div>
            )}
          </div>
          <div
            className="flex items-center justify-center py-3 gap-3"
            onClick={() => setMethodIndex(index)}
          >
            <ItemSelectBox
              name="payment_id"
              options={paymentList}
              value={formData.payment_id}
              onChange={handleChangeSelect.bind(this, "payment_id")}
              label={"Method Name"}
              placeholder={item.type_name}
              style={{ maxHeight: "300px", overflowY: "scroll" }}
            />
          </div>
          {item.type_name != "cash" && (
            <>
              <div
                className="flex items-center justify-center py-3 gap-3"
                onClick={() => setMethodIndex(index)}
              >
                <ItemSelectBox
                  name="account_id"
                  options={filteredAccountList}
                  value={formData.account_id}
                  label={"Account Name"}
                  onChange={handleChangeSelect.bind(this, "account_id")}
                  placeholder="Select Account"
                />
              </div>

              <div className="flex items-center justify-center py-3 gap-3">
                <div className="w-full relative">
                  <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                    Account Number
                  </label>
                  <input
                    type="text"
                    name="name"
                    disabled
                    value={paymentAccountAmount[index].account_number}
                    className="input input-bordered border-2 border-gray-500 w-full mb-[10px] rounded-xl bg-white"
                  />
                </div>
              </div>
            </>
          )}
          <div className="flex items-center justify-center py-2 gap-3">
            <div className="w-full relative">
              <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[14px] left-[12px] bg-white px-1 font-semibold">
                Amount
              </label>
              <input
                type="text"
                name="name"
                placeholder="000.0"
                disabled={
                  item.type_name == "cash"
                    ? false
                    : paymentAccountAmount[index].account_number
                    ? false
                    : true
                }
                //value={paymentAccountAmount[index].Amount}
                onChange={(e) => {
                  setPaymentAccountAmount((prevAmount) => [
                    ...prevAmount.slice(0, index),
                    { ...prevAmount[index], Amount: e.target.value },
                    ...prevAmount.slice(index + 1),
                  ]);
                }}
                className="input input-bordered w-full mb-[10px] rounded-xl bg-white"
              />
            </div>
          </div>
          {paymentData.length > 1 && <hr />}
        </div>
      ))}
      <div className="flex flex-row justify-between items-center py-3 px-1">
        <div>
          <h1 className="text-md font-semibold font-lato">
            Pay with more Method
          </h1>
        </div>
        <div>
          <div
            onClick={handleAddMethod}
            className="flex justify-center items-center border-2 text-[#000] text-[18px] font-nunito font-bold py-[10px] px-[10px] cursor-pointer rounded-lg capitalize"
          >
            <i class="fa-solid fa-plus"></i>
          </div>
        </div>
      </div>
      <div>
        <button
          htmlFor="my_modal_payment_info"
          onClick={handleSubmit}
          className="cursor-pointer w-full bg-[#3559b3] hover:bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
        >
          Save
        </button>
      </div>
    </div>
  );

};
export default PaymentInfoForm;