
import React, { useEffect, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import Resizer from "react-image-file-resizer";
import { validateData, getDialCodeByCountryCode } from '../utils/Common';
import { useFlashMessage } from '../context/FlashMessageContext';
import { useNavigate } from 'react-router-dom';
//import arrayMutators from 'final-form-arrays';
import storeImage from './Store.png'
import { get, post } from "../services/apiService";
import SelectBox from './Select';
import FlagIcon from './FlagIcon';
import classNames from 'classnames';


const ShopSettingForm = props => {
    const {
            //shopCategoryList,
            districtList,
            cityList,
            handleChangeSelect,
            handleChange,
            state,
            setUploadedFiles,
            setUploadedFilesWatermark,
            uploadDetails,
            setUploadDetails,
            initialValues,
            transparentBorder
        } = props;
    const {countryList,shopCategoryList} = state;
    //const [country, setCountry] = useState(null);
    const { setErrorFlashMessage } = useFlashMessage();
    const [isValid, setIsValid] = useState(true);
    const [fileLogo, setFile] = useState(null);
    const [watermarkFile, setWatermarkFile] = useState(initialValues?.watermark_type == 'Logo' ? initialValues.watermark_text : null);
    const navigate = useNavigate();

    const [selectedValue, setSelectedValue] = useState(initialValues ? initialValues.watermark_type : 'text');

    const handleSelectChange = (event) => {
        //event.preventDefault();

        setSelectedValue(event.target.value);
    };
    const handleNavigate = () => {
        navigate('/settings/branch-settings')
    }
    const handleallInputChange = (fieldName, value) => {
      // Update the selectedCustomer state with the modified field
      setSelectedCustomer((prevCustomer) => ({
        ...prevCustomer,
        [fieldName]: value,
      }));
    };

console.log(cityList)
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });



    return (
        <div className="px-3 pb-4">
            <FinalForm
                {...props}
                initialValues={initialValues}
                onSubmit={props.onSubmit}
                validate={(values) => ({
                    outlet_name: validateData(values.outlet_name, 'Shop Name'),
                    // shop_address: validateData(values.shop_address, 'Shop address'),
                    // sign_authority: validateData(values.sign_authority, 'Sign of Authority'),
                    // name_authority: validateData(values.name_authority, 'Name Authority'),
                    // watermark_text: validateData(values.watermark_text, 'Text Watermark'),
                })}
                render={formRenderProps => {
                    const {
                        handleSubmit,
                        file,
                        currency
                    } = formRenderProps;
                    return (
                        <>
                            <form
                                method="post"
                                className="col-sm-12"
                                data-autosubmit="false"
                                autoComplete="off"
                                onSubmit={e => {
                                    e.preventDefault();
                                    handleSubmit(e);
                                }}
                                encType="multipart/form-data"
                            >
                                <div className=" border-t-[1px] border-solid border-[#E7EBF4]">
                                    <div className="flex flex-col sm:flex-row gap-3 mb-3">
                                        <div className=" flex-1">
                                            <Field name="outlet_name">
                                                {({ input, meta }) => (
                                                    <>
                                                        <input
                                                            {...input}
                                                            type="text"
                                                            placeholder="Shop Name"
                                                            onChange={handleChange}
                                                            //value={initialValues?.outlet_name}
                                                            className="bg-[#FFF] text-[#91919F] w-full h-[50px] rounded-[16px] px-3 focus:outline-none"
                                                        />
                                                        {input.value && input.value === '' && (
                                                            <div className="warning-message">
                                                                This field cannot be empty.
                                                            </div>
                                                        )}
                                                        {meta.touched && meta.error && (
                                                            <div className="warning-message">
                                                                {meta.error}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </Field>
                                        </div>

                                        <div className="flex-1">
                                          <SelectBox
                                            name="type"
                                            options={shopCategoryList}
                                            value={Number(initialValues?.type)}
                                            onChange={handleChangeSelect.bind(
                                              this,
                                              "type"
                                            )}
                                            label={false}
                                            placeholder="Shop Type"
                                            className="w-full bg-[#FFF] rounded-[16px] focus:outline-none customSelect py-[1px]"
                                          />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col sm:flex-row gap-3 mb-3'>
                                    <div className='flex-1'>
                                        <Field name="owner_name">
                                            {({ input, meta }) => (
                                                <>
                                                    <input
                                                        {...input}
                                                        type="text"
                                                        placeholder="Owner Name"
                                                        onChange={handleChange}
                                                        //value={initialValues?.owner_name}
                                                        className='w-full h-[50px] rounded-[16px] px-3 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]'
                                                    />
                                                    {meta.touched && meta.error && (
                                                        <div className="warning-message">
                                                            {meta.error}
                                                        </div>
                                                    )}

                                                </>
                                            )}
                                        </Field>
                                    </div>

                                    <div className='flex-1'>
                                        <Field name="address">
                                            {({ input, meta }) => (
                                                <>
                                                    {/* {selectedValue !== 'Logo' && ( */}
                                                    <div id="address">
                                                        <input
                                                            {...input}
                                                            type="text"
                                                            placeholder="Address"
                                                            onChange={handleChange}
                                                            //value={initialValues?.address}
                                                            className="w-full h-[50px] rounded-[16px] px-3 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]"
                                                        />
                                                        {meta.touched && meta.error && (
                                                            <div className="warning-message">
                                                                {meta.error}
                                                            </div>

                                                        )}
                                                    </div>


                                                </>
                                            )}
                                        </Field>
                                    </div>
                                </div>

                                {/* <div className='flex flex-col sm:flex-row gap-3 mb-3'>
                                    <div className='flex-1 pointer-events-none'>
                                        <SelectBox
                                            name="country"
                                            // options={countryList}
                                            options={countryList.filter((country)=> country?.id === Number(initialValues?.country))}
                                            value={Number(initialValues?.country)}
                                            onChange={handleChangeSelect.bind(this, 'country')}
                                            label={false}
                                            // disabled={true}
                                            placeholder="Select country"
                                            className='w-full bg-[#FFF] rounded-[16px] focus:outline-none customSelect py-[1px]'
                                        />

                                    </div>
                                    <div className="flex-1">

                                        <SelectBox
                                            name="district"
                                            options={districtList}
                                            value={Number(initialValues?.district)}
                                            onChange={handleChangeSelect.bind(this, 'district')}
                                            label={false}
                                            placeholder="Select district"
                                            className='w-full bg-[#FFF] rounded-[16px] focus:outline-none customSelect py-[1px]'
                                        />
                                    </div>


                                </div> */}




                                <div className='flex flex-col sm:flex-row gap-3 mb-3'>

                                    {/* <div className='flex-1'>
                                        <div className='flex justify-between gap-3'>
                                            <div className='flex-1'>
                                                <SelectBox
                                                    name="city"
                                                    options={cityList}
                                                    transparentBorder={true}
                                                    value={Number(initialValues?.city)}
                                                    onChange={handleChangeSelect.bind(this, 'city')}
                                                    label={false}
                                                    placeholder="Select City"
                                                    className='w-full bg-[#FFF] rounded-[16px] focus:outline-none customSelect py-[1px]'
                                                />
                                            </div>
                                            <div className='flex-1'>
                                                <Field name="zipcode">
                                                    {({ input, meta }) => (
                                                        <>
                                                            <input
                                                                {...input}
                                                                type="text"
                                                                placeholder="Zip/Postcode"
                                                                onChange={handleChange}
                                                                //value={initialValues?.zipcode}
                                                                className='w-full h-[50px] rounded-[16px] px-3 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]'
                                                            />
                                                        </>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className='flex-1'>
                                        <div className='flex justify-between gap-3'>
                                            {/* <div style={{ flex: 2 }}>
                                              <Field name="country_code" disabled component="select"
                                            //   className="w-full bg-[#FFF] rounded-[16px] pl-[10px] focus:outline-none customSelect h-[50px]">
                                              className="w-40 bg-[#FFF] rounded-[16px] pl-[10px] focus:outline-none customSelect h-[50px]">
                                                  <option value="">Country Code </option>
                                                  {countryList.map((country) => (
                                                      <option key={country.id} className="text-[14px]" value={getDialCodeByCountryCode(country.code)}>
                                                          <FlagIcon countryCode={country.code} />{getDialCodeByCountryCode(country.code)}
                                                      </option>
                                                  ))}
                                              </Field>
                                            </div> */}
                                            <div style={{ flex: 3 }}>
                                                <Field name="phone">
                                                    {({ input, meta }) => (
                                                        <>
                                                            <input
                                                                {...input}
                                                                type="text"
                                                                placeholder="Phone Number"
                                                                onChange={handleChange}
                                                                readOnly
                                                                //value={initialValues?.phone}
                                                                className='w-full h-[50px] rounded-[16px] px-3 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]'
                                                            />

                                                        </>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex flex-col sm:flex-row gap-3 mb-3'>
                                    <div className='flex-1'>
                                        <Field name="trade_license">
                                            {({ input, meta }) => (
                                                <>
                                                    <input
                                                        {...input}
                                                        type="text"
                                                        placeholder="Trade license NO."
                                                        onChange={handleChange}
                                                        //value={initialValues?.trade_license}
                                                        className='w-full h-[50px] rounded-[16px] px-3 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]'
                                                    />

                                                </>
                                            )}
                                        </Field>
                                    </div>
                                    <div className='flex-1'>
                                        <Field name="email">
                                            {({ input, meta }) => (
                                                <>
                                                    <input
                                                        {...input}
                                                        type="text"
                                                        placeholder="Email"
                                                        onChange={handleChange}
                                                        readOnly
                                                        //value={initialValues?.email}
                                                        className='w-full h-[50px] rounded-[16px] px-3 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]'
                                                    />

                                                </>
                                            )}
                                        </Field>
                                    </div>

                                </div>
                                </div>


{/*
                                <div>
                                    <p className='text-[22px] text-[#000232] mt-[30px] mb-[10px] font-[600] font-lato'>Other Branches</p>
                                    <div className='flex justify-between flex-col md:flex-row gap-3 mb-[30px]'>
                                        <div className="flex-1">
                                            <div className='w-full bg-[#fff] flex items-center justify-between rounded-[16px] shadow-sm py-[13px] pl-[16px]'>
                                                <div className='flex gap-3 justify-center items-center'>
                                                    <img src={storeImage} className='w-[34px] h-[34px]' />

                                                    <div className='font-lato font-[400]'>
                                                        <p className='text-[16px] text-[#000]'>Aftabnagar Branch</p>
                                                        <p className='text-[12px] m-0 text-[#7282A0]'>20/22, Block-C, Aftabnagar, Dhaka-1212</p>
                                                        <p className='text-[12px] m-0 text-[#7282A0]'>+8801886610003</p>
                                                    </div>
                                                </div>
                                                <div className="bg-[#E5252A] rounded-tl-[20px] rounded-bl-[20px] w-[39px] h-[30px] flex justify-center items-center">
                                                    <i className="fa-solid fa-trash-can text-white w-[16px] h-[16px]"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex-1">
                                            <div className='w-full bg-[#fff] flex items-center justify-between rounded-[16px] shadow-sm py-[13px] pl-[16px]'>
                                                <div className='flex gap-3 justify-center items-center'>
                                                    <img src={storeImage} className='w-[34px] h-[34px]' />

                                                    <div className='font-lato font-[400]'>
                                                        <p className='text-[16px] text-[#000]'>Aftabnagar Branch</p>
                                                        <p className='text-[12px] m-0 text-[#7282A0]'>20/22, Block-C, Aftabnagar, Dhaka-1212</p>
                                                        <p className='text-[12px] m-0 text-[#7282A0]'>+8801886610003</p>
                                                    </div>
                                                </div>
                                                <div className="bg-[#E5252A] rounded-tl-[20px] rounded-bl-[20px] w-[39px] h-[30px] flex justify-center items-center">
                                                    <i className="fa-solid fa-trash-can text-white w-[16px] h-[16px]"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> */}

                                <div className='mb-3 flex flex-col gap-3'>
                                    {/* <button
                                        type="submit"
                                        className='w-full bg-[#FFF] border border-solid border-1 border-blue-500 text-blue-500 text-[18px] font-lato font-600 py-[10px] rounded-[16px]'
                                        onClick={() => handleNavigate()}
                                    >
                                        {''}
                                        Add Branch{''}
                                    </button> */}
                                    <button
                                        type="submit"
                                        className='w-full bg-[#034AFF] text-[#FFF] text-[18px] font-lato font-600 py-[10px] rounded-[16px]'
                                    >
                                        {''}
                                        Save Shop{''}
                                    </button>
                                </div>
                            </form>
                        </>
                    );
                }}
            />
        </div>)
}



export default ShopSettingForm;