import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "./index.css";
import { Button, Form, Input } from "antd";
import axios from "axios";
import { BeatLoader } from 'react-spinners';
const BASE_URL = process.env.REACT_APP_ROOT_URL;
function ResetPassword(props) {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ email: "" });
  const [warningMessage, setWarningMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    loadingCallback(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}reset-request`, credentials)
      .then((response) => {
        console.log(response);
        loadingCallback(false);
        if (response.data.status === 200) {
          localStorage.setItem("phoneNumber", response.data.data.phone);
          localStorage.setItem("resetEmailAddress", credentials.email);
          sendOtp(response.data.data.phone);
        } else if (response.data.status === 404) {
          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 404) {
          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      });
  };

  const sendOtp = (phoneNumber) => {
    loadingCallback(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}send-otp`,
        { phone: phoneNumber },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        loadingCallback(false);
        if (response.data.status === 200) {
          localStorage.setItem("isResetPassword", true);
          return navigate("/verify-otp");
        } else {
          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 404) {
          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      });
  };

  const onInputChange = (e) => {
    // alert();
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  return (
    <section style={{ background: "#000" }}>
      <div className="emailverifyforget-container">
        <div className="emailverifyforget-left">
          <img
            src={`${BASE_URL}/assets/registration/svg/forgetpassleft.svg`}
            alt="loginimage"
          />
        </div>
        <div className="emailverifyforget-right">
          <div className="emailverifyforget-right-card">
            <div className="emailverifyforget-log-img">
              <img src={`${BASE_URL}/assets/registration/webp/logo1.webp`} alt="logo" />
              {/* <h1>Log In</h1> */}
              <p>Forgot Password?</p>
              <p>Don’t worry.</p>
              <p>Enter your email and we’ll send </p>
              <p>you a link to reset your password.</p>
              {/* <p>your expense for you.</p> */}
            </div>
            {warningMessage && (
              <div className="warning-message">Invalid email address!</div>
            )}
            {loading && (

              <div className="custom-loader-logo">
                {/* <BeatLoader color={"#123abc"} size={20} /> */}
                <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
              </div>
            )}
            <div className="emailverifyforget-form-main">
              <Form
                name="normal_emailverifyforget"
                className="emailverifyforget-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    value={credentials.email}
                    onChange={onInputChange}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="emailverifyforget-form-button"
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
