import React, { useContext, useState } from "react";
import { AppContext } from "../../context/context";
import { formatPrice, formatDate } from "../../utils/Common";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Modal } from "../../utils/Modal";

const ChartOfAccountListItem = ({ txnType, loadingCallback }) => {
  const { config, state, updatePaymentList } = useContext(AppContext);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const url = process.env.REACT_APP_API_URL;

  const [showViewModal, setShowViewModal] = useState(false);

  return (
    <div>
      <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] font-nunito font-[500] text-xs sm:text-sm md:text-[16px] text-[#000232] grid grid-cols-3 items-start">
        <div>
          <div className="flex flex-col sm:flex-row gap-2">
            <div className="font-nunito ">
              <div className="font-[700]  capitalize text-black">
                {txnType?.head_name}
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">{txnType.acc_head_transaction_sum_transaction_amount || 0} BDT</div>
        <div class="flex gap-2  text-xs justify-end">
          <label
            htmlFor="viewChartOfAcc_modal"
            onClick={() => setShowViewModal((val) => !val)}
            className="border-[1px] border-solid bg-[#407BFF] border-[#407BFF] text-white rounded-[5px] px-[7px] py-1"
          >
            View
          </label>
          <label
            htmlFor="deleteChartOfAcc_modal"
            onClick={() => Swal.fire("Delete option coming soon")}
            className="border-[1px] border-solid bg-[#FF0303] border-[#FF0303] text-white rounded-[5px] px-[6px] py-1"
          >
            Delete
          </label>
        </div>
      </div>
      {showViewModal ? (
        <Modal
        formData={txnType}
          title={"Details"}
          formId="viewChartOfAcc_modal"
          isOpen={showViewModal}
          setOpen={setShowViewModal}
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="viewFinance"
          selectType={"chartOfAcc"}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ChartOfAccountListItem;
