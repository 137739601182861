import React from "react";
import ContentLayout from "../../layout/ContentLayout";
import TopHeader from "../../components/topheader/Index";
import ProductsList from "../../components/products/productlist/Index";
import Footer from "../../components/footer/Index";

const ProductsPage = () => {
  return (
    <>
      <ContentLayout>
        <div className="main-content mt-40 md:mt-0">
          <ProductsList />
          <Footer />
        </div>
      </ContentLayout>
    </>
  );
};

export default ProductsPage;
