import React, { useContext } from "react";

import { AppContext } from "../../context/context";
import { formatPrice, formatDate } from '../../utils/Common';
import manIcon from "../../assets/images/man2.png";
import { Link, Navigate, useNavigate } from "react-router-dom";

const ExporterListItem = (props) => {
  const { config, state } = useContext(AppContext);
  const { BASE_URL } = config;

  const {
    exporter
  } = props;
  return (
    <div>
      <div class="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] font-nunito font-[500] text-[#000232] grid grid-cols-4 sm:grid-cols-6 items-center">
        <div>
          <div className="flex flex-col sm:flex-row gap-2">
            <div>
              <img src={exporter?.image ? exporter?.image :manIcon} width={40} className="rounded-[50%]" />{" "}
            </div>
            <div className="font-nunito text-[14px]">
              <div className="font-[500] text-[#000232]">{exporter.id}</div>
              <div className="font-[700] text-[#87889E]">{exporter?.name}</div>
            </div>
          </div>
        </div>
        <div className="text-center">{exporter?.purchase_number}</div>
        <div className="text-center">{exporter?.pending_number}</div>
        <div className="text-center hidden sm:block">{exporter?.purchase_amount}</div>
        <div className="text-center">{exporter?.due}</div>
        <div class="flex justify-end">
          <Link
            class="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
            to={`/exporter-profile/${exporter.id}`}
          >
            VIEW
          </Link>
        </div>
      </div>


    </div>
  );
};

export default ExporterListItem;