import React, { useState, useRef, useEffect } from "react";
import ContentLayout from '../../layout/ContentLayout'
import InvoiceDetail from './InvoiceDetail';
import { useParams, useNavigate } from 'react-router-dom';
import { get, post } from "../../services/apiService";


const InvoiceDetailPagePrint = () => {
  const { id } = useParams(); // Get the id parameter from the URL
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const invoice = {
    logo: "https://example.com/logo.png",
    name: "Acme Corporation",
    number: "INV-12345",
    date: "2023-10-04",
    created_at: "2023-10-04",
    invoice_id: "INV-12345",
    customer_name: "John Doe",
    customer_phone: "123213123",
    sales_details: [
      {
        id: 1,
        name: "Widget A",
        qty: 2,
        price: 10,
        total: 20,
        product_info: {
          name: "Widget A",
          price: 10
        }
      },
      {
        id: 2,
        name: "Widget B",
        qty: 1,
        price: 50,
        total: 50,
        product_info: {
          name: "Widget B",
          price: 50
        }
      },
      {
        id: 3,
        name: "Widget C",
        qty: 1,
        price: 100,
        total: 100,
        product_info: {
          name: "Widget C",
          price: 100
        }
      }
    ],
    sub_total: 170,
    tax: 25.5,
    paid_amount: 195.5,
    vat: 15
  };
  const [invoiceData, setInvoiceData] = useState(invoice);
  const [invoiceConditionData,setInvoiceConditionData] = useState(invoiceCondition)
  console.error('Error fetching image:', invoiceConditionData);
  useEffect(() => {
      // Fetch invoice data based on the ID and populate
      fetchInvoiceData(id);
  }, [id]);
  const fetchInvoiceData = (invoiceId) => {
    // Make an API request to fetch product data by ID
    // Once data is retrieved, update the form fields with the data
    post("invoice-details", { 'invoice_id': invoiceId }, token)
      .then((response) => {
        const invoiceData = response.data; // Replace with your API response data


        setInvoiceData(invoiceData);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
          // Navigate to the '/login' route
        }
      });
      // post("save-invoice-settings", token)
      // .then((response) => {
      //   const saveInvoiceCondition = response.data; // Replace with your API response data
      //   setInvoiceConditionData(saveInvoiceCondition);
      // })
      // .catch((error) => {
      //   console.log(error);
      //   if (error?.response?.status === 401) {
      //     localStorage.clear();
      //     localStorage.setItem('token', false);
      //     // Clear session and local storage
      //     localStorage.setItem('isPinVerify', false);

      //     navigate('/login');
      //     // Navigate to the '/login' route
      //   }
      // });
  };




  return (
    <>
      <ContentLayout>
        <div className="pb-7">
          <div className=" flex flex-col w-full">
            { invoiceData && invoiceData.invoice_id && invoiceConditionData && (
              <InvoiceDetail invoice={invoiceData}  />
            )}
          </div>
        </div>
      </ContentLayout>
    </>
  );
};


export default InvoiceDetailPagePrint