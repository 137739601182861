import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ItemSelectBox from "./ItemSelectBox";
import ItemSelectBox2 from "./ItemSelectBox2";
import ReactDatePicker from "react-datepicker";
import SelectBox from "./Select";
import axios from "axios";
import moment from "moment";
import {Modal} from '../utils/Modal';

const AddExpenseTypeForm = (props) => {
  const [date, setDate] = useState(new Date());
  const {
    loadingCallback,
    navigate,
    // setShowPaymentModal,
    setShowModal,
    isOpen,
    title,
    setWarningMessage,
    type,
    //formData,
    //setFormData,
    refetch,
    hideModal,
  } = props;
  const token = localStorage.getItem("token");
  // const [txnCategoryList, setTxnCategoryList] = useState([])
  const [formData, setFormData] = useState({
    name: "",
    method_id: 0,
    account_id: 0,
    account_number: "",
    methodLabel: "",
    accountLabel: ""
  });
  const handleSubmit = (data) => {
    loadingCallback(true);
    console.log("fData",formData)
    console.log("sData",data)
    const payload = { ...formData, ...data };
    axios.post(`${process.env.REACT_APP_API_URL}save-expense`, payload, {
      headers:{
        authorization: `Bearer ${token}`,
        "content-type":"application/json",
      }
    }).then((res) => {
        loadingCallback(false);
        if (res.data.success) {
          console.log(res)
        refetch();
        }
      }).catch(err=>{
        loadingCallback(false)
        console.log(err)
      })
    setShowModal(val=>!val)
  };
  // const handleTxnCategoryTypeChange = (e) => {
  //   console.log("Selected Option:", e);
  //   setFormData({
  //     ...formData,
  //     transaction_category: e.label,
  //   })
  // }
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  useEffect(() => {
    setShowPaymentModal(false);
  }, [hideModal]);
  const handleClicks = (data) => {
    setShowPaymentModal(true);
  };
  const [paymentData, setPaymentData] = useState([]);
  const getPayment = () => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}payment-type-list`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response.data.success){
        loadingCallback(false)
        setPaymentData([...paymentData, response?.data?.data?.data[0]])
        //updatePaymentList(response?.data?.data);
        setPaymentList(response?.data?.data?.data);
        console.log("payment data: ", response?.data?.data?.data[0])
        console.log("payment list: ", response?.data?.data?.data)
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };
  useEffect(()=>{
    getPayment();
    getPaymentAccountList()
  },[])
  const [paymentList, setPaymentList] = useState([]);
  const [filteredAccountList, setFilteredAccountList] = useState([]);
  const [paymentAccountList, setPaymentAccountList] = useState([]);
  const [paidAmount, setPaidAmount] = useState(0.00);
  const [selectedPaymentAccountList, setSelectedPaymentAccountList] = useState([]);
  const getPaymentAccountList = () => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}payment-type-category-list`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {

      if(response?.data.success){
        loadingCallback(false)
        setPaymentAccountList(response?.data?.data?.data);
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };
  useEffect(() => {
    submitPaymentAmount(selectedPaymentAccountList);
  }, [selectedPaymentAccountList]);
  const submitPaymentAmount = async(selectedPaymentAccountList) => {
    if (selectedPaymentAccountList.length > 0) {
      setShowPaymentModal(!showPaymentModal);
      const paymentMap = selectedPaymentAccountList.map(item => ({
        payment_type_id: item?.payment_type_id,
        payment_type_category_id: item?.payment_type_category_id,
        payment_amount: item?.Amount,
      }));
      // ---------------------------
      const data = {
        //previous_due: type !='carrier' ? fetchedData?.invoice_list_sum_sub_total - fetchedData?.invoice_list_sum_paid_amount : 0,
        paid_amount: Number(paidAmount),
        payment_method: paymentMap
      }
      // if (type === "vendor") {
      //   data.vendor_id = fetchedData?.id;
      // }
      // if (type === "wholesaler") {
      //   data.wholesaler_id = fetchedData?.id;
      // }
      // if (type === "customer") {
      //   data.customer_id = fetchedData?.id;
      // }
      // if (type === "exporter") {
      //   data.exporter_id = fetchedData?.id;
      // }
      // if (type === "carrier") {
      //   data.carrier_id = fetchedData?.id;
      // }
      // console.log("============");
      console.log("payment data", data);

      // onSubmitPayment(data);
      handleSubmit(data);
    }
  };
  // const [formData, setFormData] = useState({});
  return (
    <div className="duration-500">

      <div className="w-full relative">
        <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
          Name
        </label>
        <input
          type="text"
          name="party_name"
          placeholder="Name"
          onChange={(e) => setFormData({
            ...formData,
            catogory_name: e.target.value
          })}
          className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
        />
      </div>
      <div className="w-full relative">
        <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
          Date
        </label>
        <input
          type="date"
          name="party_name"
          placeholder="Date"
          onChange={(e) => {
            const selectedDate = e.target.value; // The value from date picker in YYYY-MM-DD format
            setFormData({
              ...formData,
              transaction_date: selectedDate
            });
          }}
          className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
        />
      </div>
      <div className="w-full relative">
        <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
          Amount
        </label>
        <input
          type="text"
          name="party_name"
          placeholder="0.00"
          onChange={(e) => setFormData({
            ...formData,
            amount: e.target.value
          })}
          className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
        />
      </div>
      <div className="w-full relative mt-2">
        <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
          Description
        </label>
        <textarea
          type="text"
          name="description"
          onChange={(e) => setFormData({
            ...formData,
            description: e.target.value
          })}
          placeholder="Text"
          className="input input-bordered border-[#C1CFEF] border-[1px] focus:outline-none  w-full  h-20 pt-2 rounded-xl bg-white resize-none"
        />
      </div>

      <div>
        <button
          htmlFor="my_modal_payment_info"
          // onClick={()=>handleSubmit()}
          onClick={ () => handleClicks()}
          className="cursor-pointer w-full mt-4 bg-[#3559b3] hover:bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
        >
          Next
        </button>
      </div>
      {showPaymentModal ? (
            <Modal
              isOpen={showPaymentModal}
              setOpen={setShowPaymentModal}
              // dueAmount={type === "carrier" || type === "exporter" && fetchedData?.total_due ? fetchedData.total_due.toFixed(2) : fetchedData?.due ? fetchedData.due.toFixed(2) : 0}
              purchaseAmount={formData.amount}
              getData={paymentData}
              listData={paymentList}
              formData={formData}
              setFormData={setFormData}
              paymentAccountList={paymentAccountList}
              setSelectedAccountList={setSelectedPaymentAccountList}
              setData={setPaymentData}
              setPaidAmount={setPaidAmount}
              // setWarningMessage={setWarningMessage}
              title="Payment Info"
              formId="my_modal_payment_info"
              loadingCallback={loadingCallback}
              navigate={navigate}
              type="payment-info"
              selectType={type}
              setFilteredAccountList={setFilteredAccountList}
              filteredAccountList={filteredAccountList}
            />
          ) : null}
    </div>
  );
};

export default AddExpenseTypeForm;
