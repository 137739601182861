import React, { useContext,useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/context";
import Swal from "sweetalert2";
import { formatPrice } from "../../utils/Common";
import axios from "axios";

const SalesReturnItem = (props) => {
  const { showModalBeforeChange,invoice, className, showModalBeforeDelete,index,type, loadingCallback } = props;
  const { config, state } = useContext(AppContext);
  const { BASE_URL } = config;
  const navigate = useNavigate();
  const test = false;

  const token = localStorage.getItem("token");

  const handleClick = (e) => {
    navigate(type == 'salesReturnList' ?`/sales-return/${invoice.return_id}` : `/purchase-return/${invoice.return_id}`);
  };



  const handleCheckboxChange = (item,checkboxId,index) => {
    if (item.status === '1') {
      showModalBeforeChange(item,checkboxId,index);
    } else {
      item.status='1'
    }
  };

  return (

      <div className="border-t-[1px] text-sm border-solid border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-5 sm:grid-cols-6 items-start">
      <div className="">
        <h1 className="text-[#000232] font-[500]">{invoice.return_id}</h1>
        <p className="text-[#87889E] text-xs font-[700]">{invoice.sname}</p>
      </div>

      <div className=" text-[#000] font-[500] text-center">{invoice.purchased_date}</div>
      <div className=" text-[#000] font-[500] text-center">{invoice.time}</div>
      {/* <div className=" text-[#000] font-[500] text-center hidden sm:block">{invoice.return_amount}</div> */}
      <div className=" text-[#000] font-[500] text-center hidden sm:block">{invoice.return_amount}</div>

      <div className="flex flex-row justify-center">
        <label class="relative inline-flex items-center cursor-pointer">
        <input
            type="checkbox"
            value="1"
            checked={invoice?.status === '1' || invoice?.checked ? true : false}
            className="sr-only peer"
            id={`product_${invoice.return_id}`}
            defaultChecked={invoice?.status === '1' ? true : false}
            disabled={invoice?.status !== '1'}
            //checked={invoice?.status === '1' ? true : false}
            onChange={() => handleCheckboxChange(invoice, `product_${invoice.return_id}`,index)}
          />
          <div class={`w-11 h-6
           bg-white
           border-1
           border-solid
           rounded-full  peer
           dark:bg-gray-700
           peer-checked:after:translate-x-full
           rtl:peer-checked:after:-translate-x-full
          peer-checked:after:border-white
          after:content-['']
          after:absolute after:top-[2px]
          after:start-[2px]
          after:rounded-full
          after:h-5 after:w-5 after:transition-all
          dark:border-gray-600
          ${invoice?.status==='1'
          ? 'border-[#FF0303] after:bg-[#FF0303] after:border-[#FF0303] peer-checked:bg-[#407BFF] peer-checked:border-[#407BFF] peer-checked:after:bg-[#407BFF]'
          :' border-[#FF0303] after:bg-[#FF0303] after:border-[#FF0303] peer-checked:bg-[#407BFF] peer-checked:border-[#407BFF] peer-checked:after:bg-[#407BFF]'}

          peer:after:bg-[#FF0303]`}
             ></div>
        </label>
      </div>

      <div className="text-[12px] font-nunito font-[700] flex flex-col lg:flex-row gap-2 justify-center items-center">
        <a className="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] cursor-pointer hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]" onClick={() => handleClick()}>VIEW</a>
        <a onClick={() => showModalBeforeDelete(invoice?.id, index)} className="cursor-pointer block rounded-[5px] text-[#FFF] hover:text-[#FFF] bg-[#FF0303] px-[5px] pt-[2px] pb-[2px]">DELETE</a>
      </div>
    </div>

  );
};

SalesReturnItem.defaultProps = { className: null };

export default SalesReturnItem;
