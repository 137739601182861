import { faL } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import React, { useState } from 'react';

const SaveHistory = ({ setShowModal, total,setHistory, history,setExpression,setPrevExpression }) => {
  const [error, setError] = useState(false);
  const [calName, setCalName] = useState('');
  const token = localStorage.getItem("token");

  const handleSumbmit = (e) => {
  
    if(calName.length){
      setShowModal(false)
      const data = {
        name: calName, 
        total: Number(total)
      }
      axios.post(
        `${process.env.REACT_APP_API_URL}save-calculator`, data,{
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if(response.data.success){
          setCalName('')
          setError(false)
          setHistory([...history, data])
          setExpression("0")
          setPrevExpression('')
          alert('Added successfull!');
          e.target.reset();
        }
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          localStorage.setItem("isPinVerify", false);
        }
      });
    }
    else {
      setError(true)
      e.preventDefault();
    }
  }

  return (
    <div className='shadow-2xl me-2'>
      <dialog id="my_modal_3" className="modal shadow-2xl">
        <div className={` bg-white rounded-2xl `}>
          <form className='flex justify-between items-center border-b-[2px]' method='dialog'>
            <h2 className='text-xl font-semibold p-3'>Calculator</h2>
            <button onClick={() => {setShowModal(false), setError(false)} } className="outline-none  text-white bg-[#FF0303] btn-circle btn-xs me-3  hover:bg-[#FF0303]">✕</button>
          </form>
          <form className='flex flex-col p-4 ' onSubmit={handleSumbmit} method='dialog' >
            <div className='flex flex-col justify-between items-start pb-4 '>
              <div className='flex justify-between items-center'>
                <input  onChange={(e) =>{ setCalName(e.target.value), setError(false)}}  placeholder='Name' className={`${(error && calName.length==0) ? "input-bordered border-red-500" : "input-bordered" } input  min-w-72 max-w-xs text-lg font-normal focus:outline-none`}></input>
                <div className='flex justify-center items-center mx-3'>
                  <h1 className='font-semibold text-xl w-full text-right'>{total}</h1>
                  <h1 className='font-semibold text-xl w-full text-right ps-1'>BDT</h1>
                </div>
              </div>
              {(error && calName.length==0) && <p className='text-red-500 text-md ps-[4px] duration-300'>Input name is required.</p> }
            </div>
            <button className='outline-none p-3  w-full bg-[#034AFF] hover:bg-[#034AFF] rounded-xl text-white'>Done</button>
          </form>
        </div>
      </dialog>
    </div>
  );
};

export default SaveHistory;