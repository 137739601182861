import React, { useContext, useState } from "react";

import ImageUploader from "./ImageUploader";
import CommonUploader from "./CommonUploader";
import { ToastContainer, toast } from 'react-toastify';

import Input from "./Input";
import SelectBox from "./Select";

import uploadImg from "../assets/images/upload.png";
import Swal from "sweetalert2";
import { FlashMessage } from "../utils/FlashMessage";

const VendorCustomerEditForm = props => {
  const {
    handleSubmitForm,
    countryList,
    formData,
    setErrorFlashMessage,
    handleChange,
    districtList,
    cityList,
    handleChangeSelect,
    actionType,
    type,
    bloodGroupList,
    handleImageChange
  } = props;

  const [formErrors, setFormErrors] = useState({});
  const [isValid, setIsValid] = useState(true);

  const handleValidation = (formData) => {
    const errors = {};
    let validate = true
    // if (formData.customer_email==='') {
    //   errors.customer_email = 'Email is required.';
    //   setIsValid(false);
    //   validate=false;
    // }
    if (formData.customer_email && formData.customer_email !== '') {
      if (formData.customer_email.indexOf('@') === -1) {
        errors.customer_email = 'Email is not valid.';
        setIsValid(false);
        validate = false;
      }  else {
        //Email address cannot have only numbers before @
        const emailParts = formData.customer_email.split('@');
        if (emailParts[0].match(/^[0-9]+$/) !== null) {
          errors.customer_email = 'Email is not valid.';
          setIsValid(false);
          validate = false;
        }
      }
    }
    if (formData.customer_name==='') {
      errors.customer_name = 'name is required.';
      setIsValid(false);
      validate=false;

    }

    if (formData.customer_phone==='') {
      errors.customer_phone = 'Phone Number is required.';
      setIsValid(false);
      validate=false;
    }
    setFormErrors(errors);
    if (!validate) {
      // setErrorFlashMessage('Please fill all the required fields.');
      FlashMessage('Please fill all the required fields.','error');
    }

    return validate;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // if(type === "exporter"){
    //   event.target.reset();
    //   Swal.fire(`${actionType==="add"? "Adding":"Editing"} option coming soon`)
    //   return;
    // }


    if (handleValidation(formData)) {
      //setIsValid(true);
      handleSubmitForm();
    } else {
      console.error('Form validation failed.');
    }
  };
  return (
    <div className="">
      <ToastContainer />
      <form
      method="post"
      data-autosubmit="false"
      autoComplete="off"
      onSubmit={handleSubmit}
      >
        <div className="text-center flex items-center justify-center py-4">
          {/* <img src={uploadImg} className="w-[140px]" /> */}
          <CommonUploader
            name={"image"}
            isBig={true}
            onChange={handleImageChange}
            image={formData.image ? formData.image : null}
            className="w-[140px]"
          ></CommonUploader>
        </div>

        <div className="w-full pt-5">
          <div className="flex flex-col sm:flex-row gap-3 mb-4">
            <div className="flex-1">
              <div className="relative">
                <Input
                  label="Name"
                  id="customer_name"
                  name="customer_name"
                  placeholder="Name"
                  value={formData.customer_name}
                  onChange={handleChange}
                  error={formErrors.customer_name}
                  setFormErrors={setFormErrors}
                />
              </div>
            </div>

            {/* <div className="flex-1 relative">
              <select
                name="cus_type"
                className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                style={{ maxHeight: "300px", overflowY: "scroll" }}
              >
                <option disabled=""> Select Customer Type</option>
              </select>
              <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                Customer Type
              </label>
            </div> */}
          </div>

          <div className="flex flex-col sm:flex-row gap-3 mb-4">
            <div className="flex-1">
              <div className="relative">
                <Input
                  label="Phone"
                  id="customer_phone"
                  name="customer_phone"
                  placeholder="Phone"
                  value={formData.customer_phone}
                  onChange={handleChange}
                  error={formErrors.customer_phone}
                  setFormErrors={setFormErrors}
                />
              </div>
            </div>
            <div className="flex-1">
              <Input
                label="Email"
                id="customer_email"
                name="customer_email"
                placeholder="Email"
                value={formData.customer_email}
                onChange={handleChange}
                error={formErrors.customer_email}
                setFormErrors={setFormErrors}
              />
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-3 mb-4">
            <div className="flex-1">
              <div className="relative">
                <Input
                  label="NID"
                  id="nid"
                  name="nid"
                  placeholder="NID"
                  value={formData.nid}
                  onChange={handleChange}
                  //error={formErrors.customer_email}
                  //setFormErrors={setFormErrors}
                />
              </div>
            </div>
            {/* {type === "customer" && (
            <div className="flex-1">
              <Input
                label="Customer ID"
                id="customer_id"
                name="customer_id"
                placeholder="Customer ID"
                value={formData.customer_id}
                onChange={handleChange}
                //error={formErrors.customer_email}
                //setFormErrors={setFormErrors}
              />
            </div>
            )} */}
          </div>

          <div className="flex flex-col sm:flex-row gap-3 mb-4">
            <div className="w-[100%] sm:w-[30%] relative">
              <select
                name="blood_group"
                onChange={handleChange}
                value={formData.blood_group}
                className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                style={{ maxHeight: "300px", overflowY: "scroll" }}
              >
                <option disabled=""> Select Blood Group</option>
                {bloodGroupList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                Blood Group
              </label>
            </div>
            <div className="w-[100%] sm:w-[40%]">
              <Input
                label="Address"
                id="address"
                name="address"
                placeholder="Address"
                value={formData.address}
                onChange={handleChange}
                //error={formErrors.customer_email}
                //setFormErrors={setFormErrors}
              />
            </div>
            <div className="w-[100%] sm:w-[30%] relative">
              <SelectBox
                name="country"
                options={countryList}
                value={formData.country}
                onChange={handleChangeSelect.bind(this, 'country')}
                label={false}
                placeholder="Select country"
                className="w-full bg-[#FFF] rounded-[16px] focus:outline-none customSelect py-[1px]"
              />
              <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                Country
              </label>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-3 mb-5">
            <div className="w-[100%] sm:w-[33%] relative">
              <SelectBox
                name="district"
                options={districtList}
                value={formData.district}
                onChange={handleChangeSelect.bind(this, 'district')}
                label={false}
                placeholder="Select District"
                className="w-full bg-[#FFF] rounded-[16px] focus:outline-none customSelect py-[1px]"
              />
              <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                District
              </label>
            </div>

            <div className="w-[100%] sm:w-[33%] relative">
              <SelectBox
                name="city"
                options={cityList}
                value={formData.city}
                onChange={handleChangeSelect.bind(this, 'city')}
                label={false}
                placeholder="Select city"
                className="w-full bg-[#FFF] rounded-[16px] focus:outline-none customSelect py-[1px]"
              />
              <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                City
              </label>
            </div>

            <div className="w-[100%] sm:w-[33%]">
              <Input
                label="Zip/Postcode"
                placeholder={"Zip/Postcode"}
                id="zipcode"
                name="zipcode"
                value={formData.zipcode}
                onChange={handleChange}
                //error={formErrors.customer_email}
                //setFormErrors={setFormErrors}
              />
            </div>
          </div>

          <div className="flex items-center justify-center mt-3">
            <button
              type="submit"
              className="bg-[#407BFF] rounded-[16px] text-[#FFF] text-[18px] font-nunito font-[600] py-3 px-[167px] flex items-center"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VendorCustomerEditForm;
