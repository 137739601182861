import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { formatPrice, formatDate, timeDiff, invoiceCalculate } from "../../../utils/Common";
import Swal from "sweetalert2";

const InvoiceHistory = (props) => {
  const { type, activeTab, loadingCallback, invoiceData, vendorId } = props;
  const showPopup = ()=>{
    Swal.fire("List coming soon!")
  }
  //console.log(invoiceData);
  return (
    <>
      <div className="bg-white rounded-[16px] px-4 py-4">
        <div className="flex justify-between mb-3">
          <h2 className="font-nunito font-[600] text-[22px] text-[#000232]">
            {type==="exporter"? "Order" : type ==='carrier' ? "Carried" : "Invoice"} History
          </h2>
          {type === "customer" ? (
            <Link
              to={`/customer-invoice-history/${vendorId}`}
              className="font-lato font-[600] text-[12px] underline text-[#034AFF]"
            >
              View All
            </Link>
          ) :
          type === "wholesaler" ? (
            <Link
              to={`/wholesaler-invoice-history/${vendorId}`}
              className="font-lato font-[600] text-[12px] underline text-[#034AFF]"
            >
              View All
            </Link>
          ) :
            type === "exporter" ? (
              <Link
              to={`/exporter-invoice-history/${vendorId}`}
              //onClick={showPopup}
              className="font-lato font-[600] text-[12px] underline text-[#034AFF]"
            >
              View All
            </Link>
            ) :
            type === "carrier" ? (
              <Link
              to={`/carrier-invoice-history/${vendorId}`}
              //onClick={showPopup}
              className="font-lato font-[600] text-[12px] underline text-[#034AFF]"
            >
              View All
            </Link>
            ) : ""
          }

        </div>

        <div class="grid grid-cols-4 sm:grid-cols-6 gap-1 font-nunito font-[700] text-[14px] text-[#000232]">
          <div class=" py-[8px]">Invoice ID</div>
          <div class="py-[8px] text-center hidden sm:block">Date/Time</div>
          <div class="py-[8px] text-center">{type=="carrier" ? 'Product Name' :'Amount'}</div>
          <div class="py-[8px] text-center">{type=="carrier" ? 'Received qty' :'Due'}</div>
          {type != "carrier" ? (
            <div class="py-[8px] text-center">Status</div>
          ) : ""}
          <div class="py-[8px] text-right hidden sm:block">Action</div>
        </div>

        {invoiceData.length > 0 && invoiceData.map((item, index) => {
          if (index < 10) {
            return (
              <div
                key={index}
                class="border-t-[1px] border-solid border-[#E3EDF6] py-[8px] font-nunito font-[500] text-[#000232] grid grid-cols-4 sm:grid-cols-6  items-center"
              >
                <div class="text-[#000232] text-[14px]">#{item.invoice_id}</div>
                <div class="text-center hidden sm:block">
                  <div className="text-[14px] text-[#000000]">
                    {formatDate(item.created_at)}
                  </div>
                  <div className="font-[600] text-[14px] text-[#87889E]">
                  {type=='carrier' || type=='exporter' ? formatDate(item?.date_time) : item?.updated_at ? timeDiff(new Date(item.updated_at)) : ''}
                  </div>
                </div>
                <div class="text-[16px] text-[#000000] text-center">
                 {type=='carrier' ? item?.product_name : type=='exporter' ? item?.purchase_amount : item?.sub_total}
                 {/* {formatPrice(invoiceCalculate(item,'purchase').grandTotal)} */}
                </div>
                <div class="text-[16px] text-[#000000] text-center">
                  {type=='carrier' ? item?.receive_qty_count : type=='exporter' ? item?.due_amount : item?.paid_amount ? (item.sub_total - item.paid_amount) : ''}
                  {/* {item?.paid_amount ? formatPrice(invoiceCalculate(item,'purchase').dueAmount) : ''} */}
                </div>
                {type == "carrier" ? ""
                : type == "exporter" ? (
                  <div className={` ${type=='exporter' ? "text-green-600" :"text-red-600"} font-[500] text-center`}>
                  {type=='exporter' ? 'Completed' : "Hold"}
                  </div>
                ):
                (
                  <div className={` ${type=='carrier' ? "text-green-600" : item.status && item.status === 1 ? "text-green-600" :"text-red-600"} font-[500] text-center`}>
                  {type=='carrier' ? 'Completed' : item?.status === 1 ?  "Completed" : "Hold"}
                  </div>
                )
                }

                <div class="text-[12px] font-nunito font-[700] flex flex-col lg:flex-row gap-2 justify-end items-center">
                  {type === "customer" ? (
                    <a
                      class="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
                      href={`/invoice/${item.invoice_id}`}
                    >
                      VIEW
                    </a>
                  ) : type === "wholesaler" ? (
                    <a
                      class="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
                      href={`/wholesale-invoice/${item.invoice_id}`}
                    >
                      VIEW
                    </a>
                  ): type === "carrier" ? (
                    <a
                      class="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
                      href={`/carrier-invoice/${item.id}`}
                    >
                      VIEW
                    </a>
                  ) : type === "exporter" ? (
                    <a
                      class="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
                      href={`/exporter-invoice/${item.id}`}
                    >
                      VIEW
                    </a>
                  ) : (
                    <a
                      class="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
                      href={`/purchase-invoice/${item.invoice_id}`}
                    >
                      VIEW
                    </a>
                  )}

                  {/* <a
                    class="block rounded-[5px] text-[#FFF] hover:text-[#FFF] bg-[#407BFF] px-[12px] pt-[2px] pb-[2px]"
                    href={`/invoice/edit/${item.invoice_id}`}
                  >
                    EDIT
                  </a> */}
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    </>
  );
};

export default InvoiceHistory;
