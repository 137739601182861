import React from 'react';
import { formatPhoneNumber, numberToWords,formatPrice,invoiceCalculate } from '../../../utils/Common';
import Watermark from '../Watermark/Watermark';
import './PdfHtmlContent.css';



import QRCode from 'react-qr-code';

const PdfHtmlContent = props => {
  const {invoice, invoiceSetting, id} = props;
  const userInfo = invoice.user_info;
  const BASE_URL = process.env.REACT_APP_ROOT_URL;
  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  const day = String(invoiceDate.getDate()).padStart(2, '0');
  const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';
  const shop_logo = invoiceSetting?.shop_logo ? invoiceSetting?.shop_logo : `${BASE_URL}/layoutlogo.svg`;
  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
  let totalQty = 0;
  let totalItems = 0;
  let totalPrice = 0;



  return (
    <div id="pdfConentAreas" className="pdfConentAreas">
      <div id="invoice-detail-page" className="invoice-detail-page font-nunito">
       {/* invoice header */}
       <div className="text-center text-xs md:text-sm lg:text-base">
          <div>
            <img
              src={shop_logo}
              alt={invoice.invoice_id}
              className="mx-auto w-1/2 md:w-2/3 max-w-[220px]"
            />
            <div className=' leading-4 sm:leading-5 md:leading-6 mt-[16px] font-normal'>
              {userInfo?.outlet_name &&
                <p className='capitalize text-[22px] font-semibold'>{userInfo?.outlet_name} </p>
              }
              {userInfo?.address &&
                <p>{userInfo?.address}</p>
              }
              {invoice?.user_info?.phone &&
                <p>{formatPhoneNumber(invoice?.user_info?.phone, invoice?.user_info?.country_code)}</p>
              }
            </div>
          </div>
        </div>
        {/* customer info and QR */}
        <div className="flex items-center mt-[20px] justify-between">
          <div className="text-black space-y-[2px] md:space-y-[6px] font-nunito text-xs md:text-base lg:text-lg">
            <p> <span className='font-semibold'>Customer Name</span>  :  {invoice.customer_name} </p>
            <p><span className='font-semibold'>Customer Phone</span> :  {invoice.country_code}{invoice.customer_phone}</p>
            <p><span className='font-semibold'> Customer ID </span> :#{invoice.customer_id}</p>
            <p><span className='font-semibold'> Invoice Date </span>: {formattedDateTime} </p>
          </div>
          <div className="w-[45px] sm:w-[65px] md:w-[100px]">
            <QRCode className="w-full object-contain" value={BASE_URL + '/invoice/' + invoice.invoice_id} size={100} />
          </div>
        </div>
        <div className='text-xs md:text-base lg:text-lg' style={{ width: '100%', backgroundColor: '#3056FA', padding: '10px', textAlign: 'center', margin: '15px auto' }}>
          <p className="text-white font-nunito py-1 text-14 font-bold uppercase">Invoice No: {invoice.invoice_id}</p>
        </div>
       {/* table */}
       <div className='max-w-[calc(100vw-28vw)] w-[calc(100vw-28vw)] mx-auto md:w-full md:max-w-full overflow-x-auto'>
       <table className='table'>
            <thead id='pdfContentTHead'>
              <tr className="leading-normal text-sm md:text-base lg:text-lg font-nunito tracking-wide">
                <th className='font-nunito'>Item Name</th>
                <th className='font-nunito text-center '>Warranty</th>
                <th className='font-nunito text-center'>Quantity</th>
                <th className='font-nunito text-center'>Price</th>
                <th className='font-nunito text-right'>Total</th>
              </tr>
            </thead>
            <tbody>
              {/* <Watermark watermark_text={invoiceSetting?.watermark_text} watermark_type={invoiceSetting?.watermark_type}></Watermark> */}
              {invoice.sales_details.map((item) => {
                totalQty += Number(item.qty);
                totalItems += 1;
                const subtotalPrice = formatPrice(item.qty * item.price);
                totalPrice += Number(subtotalPrice);
                return (
                  <tr key={item.id} className=" font-nunito border-[#E7EBF4] font-normal text-sm md:text-base lg:text-lg relative">
                    <td className=''>{item?.product_info?.name} <br /><span className='text-[13px]'>{item?.product_info?.serial}</span></td>
                    {/* <td className='text-center'>{item?.product_info?.warrenty ? item?.product_info?.warrenty : '        '}</td> */}
                    <td className='text-center'>
                      {/* {
                        item.defaultwarranties ?
                        (
                          item.defaultwarranties.map((warrantyItem) => (
                            warrantyItem.invoice_id === item.invoice_id && warrantyItem.product_id===item.product_id  &&
                            (

                              warrantyItem.warranty  ? (
                                <h1 className='text-sm my-1'>{warrantyItem?.warranty?.warranties_count} {" "}{warrantyItem?.warranty?.name}</h1>
                              ) : (
                                <h1 className='text-sm my-1'>{warrantyItem?.default_warranties_count} {" "}
                                {item?.product_info?.warrenty}
                                </h1>
                              )
                            )
                          ))
                        )
                        : (
                          <h1 className='text-sm my-1'>{item?.product_info?.warrenty}</h1>
                        )
                      } */}
                        {
                          invoice.defaultwarranties &&
                          invoice.defaultwarranties.map((warrantyItem) =>{
                            return (
                            warrantyItem.product_id===item.product_id &&
                            (
                              warrantyItem.warranty_id==null ? (
                                <h1 className='text-sm'>{warrantyItem?.default_warranties_count==null?"":warrantyItem?.default_warranties_count} {" "}
                                {item?.product_info?.warrenty==null?"":item?.product_info?.warrenty}
                                </h1>
                              ) : (
                                <h1 className='text-sm'>{warrantyItem?.warranty?.warranties_count==null?"":warrantyItem?.warranty?.warranties_count}
                                {" "}{warrantyItem?.warranty?.name==null?"":warrantyItem?.warranty?.name}</h1>
                              )
                            )
                          )}
                          )
                        }
                    </td>
                    <td className='text-center'>{item.qty}</td>
                    <td className='text-center'>{item.price}</td>
                    <td className=' text-right font-semibold md:font-semibold'>{subtotalPrice}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</td>
                  </tr>
                );
              }
              )}
            </tbody>
            <Watermark text={invoice?.user_info?.invoiceSetting?.watermark_text}></Watermark>
          </table>
       </div>

        {/* <DashedLine2 /> */}
        <br />
        {/* disclaimer & summary */}
        <div className='flex justify-between'>
        {/* disclaimer */}
          <div className="w-1/2 text-xs md:text-base pr-1 md:pr-0">
            {invoiceSetting?.condition ?
              invoiceSetting?.condition
            : (
              ""
              // <p>Damage caused by misuse, abuse, or neglect.<br/>
              //   Unauthorized modifications or repairs.<br/>
              //   Acts of nature, such as floods, earthquakes, or other disasters.<br/>
              //   This may include proper use.</p>
            )}
          </div>
          {/* invoice sumary */}
          <div className="w-1/2 text-xs md:text-sm lg:text-base font-semibold pl-2  md:pl-8">
            <div className='space-y-[2px] mb-2'>
            <p className='flex justify-between'>
              <span>TOTAL ITEMS</span>
              <span>{totalItems}</span>
            </p>
            <p className='flex justify-between'>
              <span>SUB TOTAL</span>
              <span>{formatPrice(totalPrice)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <p className='flex justify-between'>
              <span>DISCOUNT</span>
              <span>{formatPrice(invoice.discount ?invoice.discount : 0)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <p className='flex justify-between' >
              <span>Vat {''}</span>
              <span>{invoice.vat ? formatPrice(invoice.vat) : 0.00}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <p className='flex justify-between'>
              <span>Service Charge {''}</span>
              <span>{invoice.tax ? formatPrice(invoice.tax) : 0.00}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            </div>
            <hr></hr>
            <div className='my-2'>
            <p className='flex justify-between'>
              <span>Grand Total</span>
              <span>{formatPrice(invoiceCalculate(invoice).grandTotal)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <p className='flex justify-between'>
              <span>Paid Amount</span>
              {/* <span>{invoice.paid_amount && invoice.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(totalPrice)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span> */}
              <span>{invoice.paid_amount && invoice.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(0)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            </div>
            <hr></hr>
            <p className='flex justify-between mt-2'>
              <span>Due Amount</span>
              <span>{formatPrice(invoiceCalculate(invoice).dueAmount)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
          </div>
        </div>
        <p className='text-gray-900 mt-3 font-nunito text-[18px] font-semibold leading-normal text-center'>In Word: {numberToWords(formatPrice(invoiceCalculate(invoice).grandTotal))} taka only</p>
        <div style={{ width: '100%', backgroundColor: '#034AFF', padding: '10px', textAlign: 'center', margin: '25px auto' }}>
          <p className="text-white font-nunito text-14 font-bold">You saved {formatPrice(invoice.discount ?invoice.discount : 0)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'} in this purchase </p>
        </div>
        {/* signature */}
          <div className='text-xs md:text-sm lg:text-base'>
            <div className='flex justify-between'>
              <p className="invisible">.</p>

            </div>
            <div className="flex justify-between m-2">
              <p><hr></hr> Customer Signature</p>
              <p><hr></hr> Seller Signature </p>
            </div>
          </div>
        {/* footer */}
        <div className='text-center font-nunito mt-8 text-xs md:text-sm lg:text-base'>
          <h2 className='text-orange-500  text-xl font-semibold '>Thanks for purchase</h2>
          <p className='text-black mt-3 text-xs mb-5'>A Product of SQUAD INNOVATORS </p>
        </div>
      </div>
    </div>
  );
};

export default PdfHtmlContent;
