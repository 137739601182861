import React, { useContext, useEffect, useState } from 'react';
import ContentLayout from '../../layout/ContentLayout/index.jsx';
import { AppContext } from '../../context/context.js';
import { get } from '../../services/apiService.js';
import {RiMoneyDollarCircleLine} from "react-icons/ri";
import { timeDiff,formatPrice } from '../../utils/Common.js';
import { AiOutlineSearch } from 'react-icons/ai';
import { post } from '../../services/apiService.js';
import { Link } from 'react-router-dom';
import { FaEye } from "react-icons/fa";
import InvoiceItem from '../../components/Invoice/InvoiceItem.js';
import { BeatLoader } from 'react-spinners'
import MostSellingProducts from '../../components/MostSellingProducts/MostSellingProducts'
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_ROOT_URL;
const MostSellingProductPage = () => {
    const [invoiceData, setInvoiceData] = useState([]);

    useEffect(() => {
        fetch('invoiceData.json')
            .then((res) => res.json())
            .then((data) => setInvoiceData(data));
    }, []);
    const { state, isLoading, updateInvoiceList } = useContext(AppContext);
    const { invoiceList, invoiceSetting } = state;
    const token = localStorage.getItem('token');
    const [searchKeyword, setSearchKeyword] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const perPage = 20;
    useEffect(() => {
        getInvoicelist(currentPage,searchKeyword);
    }, [currentPage,searchKeyword]);
    const getInvoicelist = (page,searchKeyword) => {
        if (searchKeyword !== "") {
            const reqData = { keyword: searchKeyword };
            post(`search-invoice?page=${page}&limit=${perPage}`, reqData, token)
            .then((response) => {

                if(response.status === 200)
                {
                    const listData = response.data.data;


                    const transformedInvoiceArray = listData.map(item => ({
                        id: item.id,
                        icon: <RiMoneyDollarCircleLine/>,
                        name: item.invoice_id,
                        sname: item.customer_name,
                        product: item.sales_details
                        ? item.sales_details
                            .filter(sale => sale.product_info)
                            .map(sale => sale.product_info.name)
                            .join(', ')
                        : '',
                        price: invoiceSetting?.currency_info?.code ? formatPrice(item.paid_amount)+' '+invoiceSetting.currency_info.code : formatPrice(item.paid_amount)+' BDT',
                        time: timeDiff(new Date(item.updated_at)),
                        colors: "#00A3EE"
                    }));
                    updateInvoiceList(transformedInvoiceArray)
                }
            })
            .catch((error) => {
                console.log(error);
            });
        } else {
            get(`invoice-list?page=${page}&limit=${perPage}`, token)
                .then((response) => {
                    console.log(response.data.data);
                    if (response.data.data) {
                        const listData = response.data.data;


                        const transformedInvoiceArray = listData.map(item => ({
                            id: item.id,
                            icon: <RiMoneyDollarCircleLine/>,
                            name: item.invoice_id,
                            sname: item.customer_name,
                            product: item.sales_details
                            ? item.sales_details
                                .filter(sale => sale.product_info)
                                .map(sale => sale.product_info.name)
                                .join(', ')
                            : '',
                            price: invoiceSetting?.currency_info?.code ? formatPrice(item.paid_amount)+' '+invoiceSetting.currency_info.code : formatPrice(item.paid_amount)+' BDT',
                            time: timeDiff(new Date(item.updated_at)),
                            colors: "#00A3EE"
                        }));
                        updateInvoiceList(transformedInvoiceArray)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    const onPageChange = (page) => {
        setCurrentPage(page);
    };
    const shouldRenderPagination = invoiceList.total > perPage;

    const [loading, setLoading] = useState(false);
    const loadingCallback = loading => {
        setLoading(loading);
    };
    const [activeTab, setActiveTab] = useState('daily');

    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    const [mostSellingData, setMostSellingData] = useState([]);

    const getTopSaleProductlist = (activeTab) => {
        loadingCallback(true);
        axios
        .get(
            `${process.env.REACT_APP_API_URL}web-dashboard?interval=${activeTab}`,
            {
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            }
        )
        .then((response) => {

            if (response.data.success) {
                loadingCallback(false);
                const fetchedData = response.data.data;
                const cardData = {
                sales: {
                    total: fetchedData?.sales,
                    percentage: 0
                },
                expense: {
                    total: fetchedData?.expense,
                    percentage: 0
                },
                orders: {
                    total: fetchedData?.order,
                    percentage: 0
                },
                customers : {
                    total: fetchedData?.new_customer,
                    percentage: 0
                }
                };
                setMostSellingData(fetchedData?.most_selling);
            }

        })
        .catch((error) => {
            console.log(error);
            loadingCallback(false);
            if (error?.response?.status === 401) {
                localStorage.clear();
                localStorage.setItem('token', false);
                // Clear session and local storage
                localStorage.setItem('isPinVerify', false);

                navigate('/login');
                // Navigate to the '/login' route
            }
        });
    };
    useEffect(() => {
        getTopSaleProductlist(activeTab);
    }, [activeTab]);


    return (
        <>
            <ContentLayout>
                <div className="simple-tabs-container">
                    <div className="flex flex-row gap-[1%] justify-center md:justify-start">
                    <div
                        onClick={() => handleTabClick('daily')}
                        className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-nunito ${activeTab === 'daily' ? 'border-[#407BFF] text-[#407BFF]' : 'text-[#1C2F48] border-transparent'}`}
                    >
                        Daily
                    </div>
                    <div
                        onClick={() => handleTabClick('weekly')}
                        className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-nunito ${activeTab === 'weekly' ? 'border-[#407BFF] text-[#407BFF]' : 'text-[#1C2F48] border-transparent'}`}
                    >
                        Weekly
                    </div>
                    <div
                        onClick={() => handleTabClick('monthly')}
                        className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-nunito ${activeTab === 'monthly' ? 'border-[#407BFF] text-[#407BFF]' : 'text-[#1C2F48] border-transparent'}`}
                    >
                        Monthly
                    </div>
                    <div
                        onClick={() => handleTabClick('yearly')}
                        className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-nunito ${activeTab === 'yearly' ? 'border-[#407BFF] text-[#407BFF]' : 'text-[#1C2F48] border-transparent'}`}
                    >
                        Yearly
                    </div>
                    </div>
                    <div className="tab-content">
                    {activeTab &&
                        <>
                            {/* <div className="main-content homePage">
                                <MostSellingProducts type={activeTab} mostSellingData={mostSellingData} loadingCallback={loadingCallback} />
                            </div> */}

                            <div className="main-content">
                                <div className="flex flex-col w-full">
                                    <MostSellingProducts type={activeTab} mostSellingData={mostSellingData} loadingCallback={loadingCallback} />

                                </div>
                            </div>
                        </>
                    }
                    </div>
                </div>
                {loading && (

                  <div className="custom-loader-logo">
                    {/* <BeatLoader color={"#123abc"} size={20} /> */}
                    <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
                  </div>
                )}
            </ContentLayout>
        </>
    );
};

export default MostSellingProductPage;
