import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from './../../utils/Modal';
import Swal from "sweetalert2";

const ExpenseItem = ({expense, loadingCallback}) => {
  return(
    <div>
      <div className="grid grid-cols-5 md:gap-5  gap-2 items-center font-nunito font-[500] text-[14px] text-[#000232] border-t-[1px] border-solid border-[#E7EBF4] py-[8px]">
      {/* <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] font-nunito font-[500] text-[#000232] grid grid-cols-7 items-start"> */}
        <div>
          <div className="">
            {/* <div className="font-nunito text-[14px]"> */}
              <div className="font-[500] text-[#000232]">#{expense.id}</div>
              <div className="font-[700] text-[#87889E]">{expense.catogory_name}</div>
            {/* </div> */}
          </div>
        </div>
        {/* <div className="text-center">{expense.catogory_name}</div> */}
        <div className="text-center">{expense.transaction_date}</div>
        <div className="text-center">{expense.amount}  {" BDT"}</div>
        <div className="text-center">{expense.description}</div>
        <div className="flex gap-2  text-xs justify-end">
          <label
            htmlFor="viewChartOfAcc_modal"
            // onClick={()=>setShowViewModal(val=>!val)}
            onClick={()=> Swal.fire("View option coming soon")}

            className="border-[1px] border-solid border-[#407BFF] text-[#407BFF] rounded-[5px] px-[6px] py-1"
          >
          VIEW
          </label>
          <label
            htmlFor="editAccTxn_modal"
            onClick={()=> Swal.fire("Edit option coming soon")}
            className="border-[1px] border-solid bg-[#407BFF] border-[#407BFF] text-white rounded-[5px] px-[7px] py-1"
          >
          EDIT
          </label>
        </div>
      </div>
    </div>

  );
};

export default ExpenseItem;