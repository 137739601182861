import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { LuSettings } from "react-icons/lu";
import { ToastContainer, toast } from 'react-toastify';
import { BeatLoader } from "react-spinners";
import { uploadFile, uploadFileMultipleInput } from "../../../utils/Common";
import ImageUploader2 from "../../../forms/ImageUploader2";
import { useFlashMessage } from "../../../context/FlashMessageContext";

import { AppContext } from "../../../context/context";
import { get, post } from "../../../services/apiService";

import "./BranchSettings.scss";
import BranchSettingForm from "../../../forms/BranchSettingForm";
import { FlashMessage } from "../../../utils/FlashMessage";
const BASE_URL = process.env.REACT_APP_ROOT_URL;

const BranchSettings = () => {
  const { id } = useParams(); // Get the id parameter from the URL
  const isEditMode = !!id; // Check if in edit mode

  const { state, updateInvoiceSetting } = useContext(AppContext);
  const {
    getFlashMessageStyle,
    setSuccessFlashMessage,
    flashMessage,
    setErrorFlashMessage,
  } = useFlashMessage(); // Retrieve the dispatch function from your state management library

  const [currency, setCurrency] = useState([]);

  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  const invoiceSettingData = state.invoiceSetting;

  const [logoFile, setLogoFile] = useState();
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [uploadedFilesWatermark, setUploadedFilesWatermark] = useState({});
  const [uploadDetails, setUploadDetails] = useState([]);
  const navigate = useNavigate();
  const [country, setCountry] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [branchData, setBranchData] = useState({
          branch_name: "",
          address: "",
          country: "",
          city: "",
          district: "",
          zipcode: "",
          phone: "",
          email: "",
        });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch product data based on the ID and populate the form fields
        if (isEditMode) {
          await fetchBranchData(id);
        }

        // Rest of your code after all data is fetched
      } catch (error) {
        // Handle errors if needed
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [isEditMode, id]);

  const fetchBranchData = (branchId) => {
    // Make an API request to fetch product data by ID
    // Once data is retrieved, update the form fields with the data
    //show - branch;
    post("show-branch", { branch_id: branchId }, token)
      .then((response) => {
        if (response.success) {
          const fetchedBranchData = response.data; // Replace with your API response data
          setBranchData({
            branch_id: branchId,
            branch_name: fetchedBranchData.branch_name,
            address: fetchedBranchData.address,
            country: fetchedBranchData.country,
            city: fetchedBranchData.city,
            district: fetchedBranchData.district,
            zipcode: fetchedBranchData.zipcode,
            phone: fetchedBranchData.phone,
            email: fetchedBranchData.email,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };
  const [countryList, setCountryList] = useState(state?.countryList);
  const [districtList, setDistrictList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const getDistrictList = (country) => {
    post("get-district-by-country", { country_id: country }, token)
      .then((response) => {
        if (response.success) {
          setDistrictList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCityList = (district) => {
    setDistrict(district);
    post("get-cities-by-district", { district_id: district }, token)
      .then((response) => {
        if (response.success) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNavigate = () => {
    navigate("/settings/branch-list");
  };
  const token = localStorage.getItem("token");
  const getCurrency = () => {
    get("currency", token)
      .then((response) => {
        if (response.success) {
          setCurrency(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCurrency();
  }, []);
  const [warningMessage, setWarningMessage] = useState(false);

  const branchDataSave = (values, branchSetting) => {
    const { ...data } = values;
    // console.log("============");
    // console.log(data);
    loadingCallback(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}save-branch`, data, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        loadingCallback(false);
        if (response.data.success) {
          // setSuccessFlashMessage("Saved successfully");
          FlashMessage("Saved successfully",'success');
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        // setErrorFlashMessage("Please fill all the required fields.");
        FlashMessage("Please fill all the required fields.",'error');
        setWarningMessage(true);
        const timer = setTimeout(() => {
          setWarningMessage(false);
        }, 3000);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };

  const branchDataUpdate = (values, branchSetting) => {
    const { ...data } = values;
    // console.log("============");
    // console.log(data);
    loadingCallback(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}update-branch`, data, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        loadingCallback(false);
        if (response.data.success) {
          // setSuccessFlashMessage("Saved successfully");
          FlashMessage("Saved successfully",'success');
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        // setErrorFlashMessage("Please fill all the required fields.");
        FlashMessage("Please fill all the required fields.",'error');
        setWarningMessage(true);
        const timer = setTimeout(() => {
          setWarningMessage(false);
        }, 3000);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };

  function handleSubmitBranch(values, branchData) {
    const updatedFormData = { ...values };

    if (isEditMode) {
      //updatedFormData;
      // Edit mode: Send a PUT request to update the existing product
      //updateProduct(updatedFormData);
      branchDataUpdate(updatedFormData, branchData);
    } else {
      // Add mode: Send a POST request to create a new product
      branchDataSave(updatedFormData, branchData);
    }
  }


  // Function to handle changes in the input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setBranchData({
      ...branchData,
      [name]: value,
    });
  };

  // Function to handle changes in the input fields
  const handleChangeSelect = (name, option) => {
    const { value, label } = option;
    // Use the callback function provided by setState
    setBranchData((prevBranchData) => {
      // Create a new object with the updated value
      const updatedBranchData = {
        ...prevBranchData,
        [name]: value,
      };
      if (name === "country") {
        setCountry(value);
      }
      if (name === "district") {
        setDistrict(value);
      }
      // Return the updated state
      return updatedBranchData;
    });
  };

  useEffect(() => {
    if (branchData && branchData.country) {
      setCountry(branchData.country);
      getDistrictList(branchData.country);
    }
    if (branchData && branchData.district) {
      setDistrict(branchData.district);
      getCityList(branchData.district);
    } else {
      getDistrictList(country);
    }
  }, [country, branchData]);

  return (
    <div
      className="rounded-[27px]"
      style={{ backgroundColor: "rgba(18, 203, 196, 0.15)" }}
    >
      <ToastContainer />
      {/* <Breadcrumb></Breadcrumb> */}
      {/* <Table data={setting} type="setting"></Table> */}

      <div className="flex justify-between items-center px-10 md:px-[27px] pt-[29px] pb-[13px]">
        <h4 className="font-semibold font-lato text-[22px] text-[#000232] capitalize flex items-center gap-2">
          <p>Branch Settings</p>
          <span className="text-[#10204869] text-lg">
            <LuSettings />
          </span>
        </h4>

        <p className="font-semibold font-lato text-xs text-[#034AFF] underline text-right cursor-pointer">
          <a onClick={() => handleNavigate()}>View All</a>
        </p>
      </div>
      {warningMessage && (
        <div className="warning-message">something wrong!</div>
      )}
      {flashMessage && (
        <div
          className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(
            flashMessage.type
          )}`}
        >
          {flashMessage.text}
        </div>
      )}
      {loading && (
        <div className="custom-loader-logo">
          {/* <BeatLoader color={"#123abc"} size={20} /> */}
          <img
            className=""
            src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
            alt="logo"
          />
        </div>
      )}
      <BranchSettingForm
        initialValues={branchData}
        onSubmit={(values) => {
          //const { ...updateValues } = values;
          handleSubmitBranch(values, branchData);
        }}
        state={state}
        countryList={countryList}
        districtList={districtList}
        cityList={cityList}
        handleChange={handleChange}
        handleChangeSelect={handleChangeSelect}
      />
    </div>
  );
};

export default BranchSettings;