import React from 'react';
import './CalculatorPopup.css';
import Calculator from '../Calculator';


const CalculatorPopup = ({ isOpen, isCalculatorOpen, setCalculatorOpen }) => {
    return (
      <div className={`calculator-popup ${isOpen ? "open" : ""}`}>
        <Calculator isOpen={isOpen} isCalculatorOpen={isCalculatorOpen} setCalculatorOpen={setCalculatorOpen}></Calculator>
      </div>
    );
  };
  
  export default CalculatorPopup;
  