import classNames from 'classnames';
import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import { useFlashMessage } from "../context/FlashMessageContext";
//import Select2 from "react-chosen";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { post } from "../services/apiService";
import { useNavigate } from 'react-router-dom';
import FlagIcon from "./FlagIcon";
import DialCodes from '../data/dialCodes.json';
import SelectBox from './Select';
import { FlashMessage } from '../utils/FlashMessage';
import { ToastContainer, toast } from 'react-toastify';


const InvoiceWholeSalerForm = props => {
  const {
    inputRef,
    loadingCallback,
    filteredWholeSaler,
    setFilteredWholeSaler,
    setWholeSaler,
    setWarningMessage,
    state,
    setShowModal,
    setShowNestedModal,
    setNestedModalData
  } = props;
  const { countryList } = state;
  const [country, setCountry] = useState('');
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState('');
  const [cityList, setCityList] = useState([]);
  const { getFlashMessageStyle, setSuccessFlashMessage, flashMessage, setErrorFlashMessage } = useFlashMessage(); // Retrieve the dispatch function from your state management library
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedWholeSaler, setSelectedWholeSaler] = useState(null);
  const [WholeSalerOptions, setWholeSalerOptions] = useState([]);
  const [showNewWholeSalerForm, setShowNewWholeSalerForm] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isValid, setIsValid] = useState(true);
  const navigate = useNavigate();
  const page = 1;
  const perPage = 20;
  const token = localStorage.getItem("token");
  const getWholeSalerListData = async (searchKeyword='') => {
    loadingCallback(true);
    const page1 = 1;
    if (filteredWholeSaler?.length === 0 && searchKeyword === "") {
      const options = [];
      axios
      .get(
        `${process.env.REACT_APP_API_URL}whole-sellers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          const wholeSalers = response.data.data.data;
          // Append other options if there are WholeSalers
          if (wholeSalers.length > 0) {
            const wholeSalerOptions = wholeSalers.map((wholeSaler) => ({
              id: wholeSaler.id,
              wholeseller_id: wholeSaler.id,
              name: wholeSaler.name,
              email: wholeSaler.email,
              country_code: wholeSaler.country_code,
              mobile_number: wholeSaler.mobile_number,
              address: wholeSaler.address,
              country: wholeSaler.country,
              district: wholeSaler.district,
              city: wholeSaler.city,
              zipcode: wholeSaler.zipcode,
              //nid: wholeSaler.nid
            }));
            options.push(...wholeSalerOptions);
          }
        } else {
          console.log("Data Not Found!");
        }
        setFilteredWholeSaler(options);
      })
      .catch((error) => {
        loadingCallback(false);
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
      });
    } else {
      setFilteredWholeSaler([]);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}search-whole-seller?page=${page1}&per_page=${perPage}`,
          { keyword: searchKeyword },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          loadingCallback(false);
          const options = [];
          if (response.status === 200) {
            //setFilteredWholeSalers(response.data.data.data);
            const wholeSalers = response.data.data.data;

            // Append other options if there are WholeSalers
            if (wholeSalers.length > 0) {
              const wholeSalerOptions = wholeSalers.map((wholeSaler) => ({
                id: wholeSaler.id,
                wholeseller_id: wholeSaler.id,
                name: wholeSaler.name,
                email: wholeSaler.email,
                country_code: wholeSaler.country_code,
                mobile_number: wholeSaler.mobile_number,
                address: wholeSaler.address,
                country: wholeSaler.country,
                district: wholeSaler.district,
                city: wholeSaler.city,
                zipcode: wholeSaler.zipcode,
                //nid: wholeSaler.nid
              }));
              options.push(...wholeSalerOptions);
            }

          } else {
            console.log("Data Not Found!");
            const wholeSalerOptions = [];
            options.push(wholeSalerOptions);
          }
          setFilteredWholeSaler(options);
        })
        .catch((error) => {
          loadingCallback(false);
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem('token', false);
            // Clear session and local storage
            localStorage.setItem('isPinVerify', false);

            navigate('/login');
          }
        });
    }
  };

  useEffect(() => {
    getWholeSalerListData(searchKeyword);
  }, [searchKeyword]);
  useEffect(() => {
    if (selectedWholeSaler && selectedWholeSaler.country) {
      setCountry(selectedWholeSaler.country);
      getDistrictList(selectedWholeSaler.country);
      if (selectedWholeSaler && selectedWholeSaler.district) {
        setDistrict(selectedWholeSaler.district);
        getCityList(selectedWholeSaler.district);
      }
    } else {
      getDistrictList(country);
    }

  }, [country,selectedWholeSaler]);

  const getDistrictList = (country) => {
    post("get-district-by-country", { country_id:country }, token)
      .then((response) => {
        if (response.success) {
          setDistrictList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCityList = (district) => {
    setDistrict(district);
    post("get-cities-by-district", { district_id:district }, token)
      .then((response) => {
        if (response.success) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDialCodeByCountryCode = (code) => {
    const country = DialCodes.find((item) => item.code === code);
    return country ? country.dial_code : '';
  };







  const handleValidation = (formData) => {
    const errors = {};
    let validate = true
    // if (formData.wholeseller_email==='') {
    //   errors.wholeseller_email = 'Email is required.';
    //   setIsValid(false);
    //   validate=false;
    // }
    if (formData.wholeseller_email !== '') {
      if (formData.wholeseller_email.indexOf('@') === -1) {
        errors.wholeseller_email = 'Email is not valid.';
        setIsValid(false);
        validate = false;
      }  else {
        //Email address cannot have only numbers before @
        const emailParts = formData.wholeseller_email.split('@');
        if (emailParts[0].match(/^[0-9]+$/) !== null) {
          errors.wholeseller_email = 'Email is not valid.';
          setIsValid(false);
          validate = false;
        }
      }
    }
    if (formData.wholeseller_name==='') {
      errors.wholeseller_name = 'WholeSaler name is required.';
      setIsValid(false);
      validate=false;

    }

    if (formData.wholeseller_phone==='') {
      errors.wholeseller_phone = 'Phone Number is required.';
      setIsValid(false);
      validate=false;
    }
    setFormErrors(errors);
    if (!validate) {
      // setErrorFlashMessage('Please fill all the required fields.');
      FlashMessage('Please fill all the required fields.','error');
    }

    return validate;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const formObject = {};

    formData.forEach((value, key) => {
      formObject[key] = value;
    });
    if (!selectedWholeSaler) {
      formObject.wholeseller_id = 0;
    }

    if (handleValidation(formObject)) {
      // console.log("============");
      // console.log(isValid);
      // console.log(formObject);
      // console.log(formErrors);
      //if (isValid) {
        const WholeSalerData = {
          name: formObject.wholeseller_name ? formObject.wholeseller_name : null,
          email: formObject.wholeseller_email ? formObject.wholeseller_email : null,
          country_code: formObject.country_code ? formObject.country_code : null,
          mobile_number: formObject.wholeseller_phone ? formObject.wholeseller_phone : null,
          address: formObject.wholeseller_address ? formObject.wholeseller_address : null,
          country: formObject.country_id ? Number(formObject.country_id) : null,
          district: formObject.district_id ? Number(formObject.district_id) : null,
          city: formObject.city_id ? Number(formObject.city_id) : null,
          zipcode: formObject.wholeseller_zip ? Number(formObject.wholeseller_zip) : null,
          nid: formObject.nid ? Number(formObject.nid) : null,
        };

        if (selectedWholeSaler) {
          WholeSalerData.id = Number(selectedWholeSaler?.id);
          WholeSalerData.wholeseller_id = Number(selectedWholeSaler?.id);
          updateWholeSaler(WholeSalerData);
        }
        else {

          //createWholeSaler(WholeSalerData);
          //setWholeSaler(response.data);
          setShowModal(false);
          //setSuccessFlashMessage('WholeSaler saved successfully');
          setShowNewWholeSalerForm(false);
        }
      // } else {
      //   console.error('Form validation failed.');
      // }

    } else {
      console.error('Form validation failed.');
    }

  };

  const updateWholeSaler = (formData) => {
    loadingCallback(true);
    // Use your API service functions to make API requests here
    //if(isValid){
      console.log("wholesaler", formData);
      post("edit-whole-seller", formData, token)
        .then((response) => {
          loadingCallback(false);
          if (response.status === 200) {
            setWholeSaler(response.data);
            setShowModal(false);
            // setSuccessFlashMessage('Wholesaler added successfully');
            FlashMessage('Wholesaler added successfully','success');
          } else {
            console.log("WholeSaler update failed");
            // setErrorFlashMessage('WholeSaler update failed');
            FlashMessage('WholeSaler update failed','error');

              setWarningMessage(true);
              const timer = setTimeout(() => {
                setWarningMessage(false);
              }, 3000);
              return () => clearTimeout(timer);
          }
        })
        .catch((error) => {
          loadingCallback(false);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem('token', false);
            // Clear session and local storage
            localStorage.setItem('isPinVerify', false);

            navigate('/login');
          }
          console.log(error);
        });
    //}
  }

  const handleNewWholeSalerFormClick = () => {
    // setShowNewWholeSalerForm(true);
    // setSelectedWholeSaler(false);
    setNestedModalData({
      title: 'Add New Wholesaler',
      type: 'new-wholesaler',
      setData:setSelectedWholeSaler

    });
    setShowNestedModal(true);
  };
  const handleErrorInputChange = (fieldName, value) => {
    if (value!=='') {
      //formErrors[fieldName] = null;
      // unset formErrors by fieldName
      const updatedFormErrors = { ...formErrors };
      delete updatedFormErrors[fieldName];
      setFormErrors(updatedFormErrors);
    }

  }
  const handleallInputChange = (fieldName, value) => {

    if (fieldName === 'country') {
      // Update the country state
      setCountry(value);
      setCityList([]);

      // Nullify the city and district values
      setSelectedWholeSaler((prevWholeSaler) => ({
        ...prevWholeSaler,
        country: value,
        city: null,
        district: null,
      }));
    }
    else if (fieldName === 'district') {
      // Update the district state
      getCityList(value);

      // Update the selectedWholeSaler state with the modified field
      setSelectedWholeSaler((prevWholeSaler) => ({
        ...prevWholeSaler,
        city: null,
        [fieldName]: value,
      }));
    } else {
      // Update the selectedWholeSaler state with the modified field
      setSelectedWholeSaler((prevWholeSaler) => ({
        ...prevWholeSaler,
        [fieldName]: value,
      }));
    }
  };
  const handleInputChange = (inputValue) => {
    //if (action && action === 'input-change') {
      // Perform an API call using the inputValue to fetch the filtered options
      // Update the options state with the fetched data
      setSearchKeyword(inputValue);
    //}
  };

  const handleSelectChange = (name, option) => {
    console.log("value", option);
    const { value, label } = option;
    if (value === "") {
      setSelectedWholeSaler(null);
      return;
    }
    // console.log("============");
    // console.log(value);
    // setSearchKeyword(value);
    const wholeSalerId = parseInt(value);
    const selectedWholeSaler = filteredWholeSaler.find((WholeSaler) => WholeSaler.id === wholeSalerId);
    setSelectedWholeSaler(selectedWholeSaler);
    setShowNewWholeSalerForm(false);
    // Update the value of the input field
    if (selectedWholeSaler.district) {
      getCityList(selectedWholeSaler.district);
    } else{
      getCityList([]);
    }
  };

  return (
    <form
        method="get"
        className="col-sm-12 min-h-[35vh]"
        data-autosubmit="false"
        autoComplete="off"
        onSubmit={handleSubmit}
    >
      <ToastContainer />
      <div className="mb-3">
        <label htmlFor="WholeSalerSelect" className="form-label">
          Select Wholesaler
        </label>

        <div className="d-flex align-items-center">
          <SelectBox
            name="wholeseller_id"
            options={state.wholeSalerList ? state.wholeSalerList : filteredWholeSaler}
            value={selectedWholeSaler ? selectedWholeSaler.id : ""}
            //onChange={handleWholeSalerSelect}
            onInputChangeData={handleInputChange}
            onChange={handleSelectChange.bind(this, 'wholeseller_id')}
            placeholder="Select Wholesaler"
            className={classNames(
              'w-full',
              'rounded-[15px]',
              'bg-white'
            )}
          />
          {/* Add nested modal trigger */}
          <label
                htmlFor="nested_modal"
                onClick={handleNewWholeSalerFormClick}
                className={classNames(
                  " w-[70px]",
                  "h-[56px]",
                  "rounded-[10px]",
                  "bg-[#99A3B7]",
                  "text-center",
                  "ml-2",
                  "cursor-pointer"
                )}
              >
              <FontAwesomeIcon className='text-center text-white  mt-[18px] ' icon={faPlus} />
          </label>

        </div>
      </div>

      {selectedWholeSaler && (
        <>
          <div className="mb-3">
            <input
              type="text"
              id="wholeseller_name"
              name="wholeseller_name"
              className={classNames(
                'form-control',
                'rounded-[15px]',
                'p-3',
                'bg-white'

              )}
              placeholder="Wholesaler Name"
              value={selectedWholeSaler.name ? selectedWholeSaler.name : ''}
              onChange={(e) => {
                handleallInputChange("name", e.target.value)
                handleErrorInputChange('wholeseller_name', e.target.value)
              }}
            />
            {formErrors && formErrors.wholeseller_name ? <p className="error-message text-red-500">{formErrors.wholeseller_name}</p> : null}
          </div>
          <div className="mb-3">
            <input
              type="text"
              id="wholeseller_email"
              name="wholeseller_email"
              className={classNames(
                'form-control',
                'rounded-[15px]',
                'p-3',
                'bg-white'

              )}
              placeholder="Wholesaler Email"
              value={selectedWholeSaler.email ? selectedWholeSaler.email : ''}
              onChange={(e) => {
                handleallInputChange("email", e.target.value)
                handleErrorInputChange('wholeseller_email', e.target.value)
              }}
            />
            {formErrors && formErrors.wholeseller_email ? <p className="error-message text-red-500">{formErrors.wholeseller_email}</p> : null}
          </div>
          <div className="mb-3">
            <div className="flex">
              <select
                id="country_code"
                name="country_code"
                className={classNames(
                  'w-2/4',
                  'rounded-[15px]',
                  'p-3',
                  'bg-white',
                  'mr-2'
                )}
                style={{  border: "1px solid #ccc" }}
                value={selectedWholeSaler.country_code ? selectedWholeSaler.country_code : ''}
                onChange={(e) => handleallInputChange("country_code", e.target.value)}
              >

                <option value="">Select</option>
                {countryList.map((country) => (
                  <option key={country.id} className="text-[14px]" value={getDialCodeByCountryCode(country.code)}>
                    <FlagIcon countryCode={country.code} />{getDialCodeByCountryCode(country.code)}
                  </option>
                ))}
              </select>
              <input
                type="text"
                id="wholeseller_phone"
                name="wholeseller_phone"
                className={classNames(
                  'form-control',
                  'rounded-[15px]',
                  'p-3',
                  'bg-white',
                  'w-3/4'

                )}
                placeholder="Wholesaler Phone No."
                value={selectedWholeSaler.mobile_number ? selectedWholeSaler.mobile_number : ''}
                onChange={(e) => {
                  handleallInputChange("mobile_number", e.target.value)
                  handleErrorInputChange('wholeseller_phone', e.target.value)
                }}
              />
            </div>
            {formErrors && formErrors.wholeseller_phone ? <p className="error-message text-red-500">{formErrors.wholeseller_phone}</p> : null}
          </div>
          <div className="mb-3">
            <input
              type="text"
              id="wholeseller_address"
              name="wholeseller_address"
              className={classNames(
                'form-control',
                'rounded-[15px]',
                'p-3',
                'bg-white'

              )}
              placeholder="Wholesaler Address"
              value={selectedWholeSaler.address ? selectedWholeSaler.address : ''}
              onChange={(e) => handleallInputChange("address", e.target.value)}
            />
          </div>
          {/* {country select box} */}
          <div className="mb-3">
            <select
              id="country_id"
              name="country_id"
              className={classNames(
                'w-full',
                'rounded-[15px]',
                'p-3',
                'bg-white'
              )}
              style={{  border: "1px solid #ccc" }}
              value={selectedWholeSaler.country ? selectedWholeSaler.country : ''}
              onChange={(e) => handleallInputChange("country", e.target.value)}
            >
              <option value="">Select Country</option>
              {countryList.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <select
              id="district_id"
              name="district_id"
              className={classNames(
                'w-full',
                'rounded-[15px]',
                'p-3',
                'bg-white'
              )}
              style={{  border: "1px solid #ccc" }}
              value={selectedWholeSaler.district ? selectedWholeSaler.district : ''}
              onChange={(e) => handleallInputChange("district", e.target.value)}
            >
              <option value="">Select District</option>
              {districtList.length === 0 && (
                <option value={selectedWholeSaler.district}>{selectedWholeSaler.district}</option>
              )}
              {districtList.map((district) => (
                <option key={district.id} value={district.id}>
                  {district.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">

            <select
              id="city_id"
              name="city_id"
              className={classNames(
                'w-full',
                'rounded-[15px]',
                'p-3',
                'bg-white'
              )}
              style={{  border: "1px solid #ccc" }}
              value={selectedWholeSaler.city ? selectedWholeSaler.city : ''}
              onChange={(e) => handleallInputChange("city", e.target.value)}
            >
              <option value="">Select City</option>
              {cityList.length === 0 && (
                <option value={selectedWholeSaler.city}>{selectedWholeSaler.city}</option>
              )}
              {cityList.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
            <input
              type="text"
              id="wholeseller_zip"
              name="wholeseller_zip"
              className={classNames(
                'form-control',
                'rounded-[15px]',
                'p-3',
                'bg-white',
                'mt-2'

              )}
              placeholder="Zip Code"
              value={selectedWholeSaler.zipcode ? selectedWholeSaler.zipcode : ''}
              onChange={(e) => handleallInputChange("zipcode", e.target.value)}
            />
          </div>


          <div className="button-disabled">
            <button
              htmlFor='my_modal_wholesaler'
              type="submit"
              className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
            > Add Wholesaler
            </button>
          </div>
        </>
      )}

    </form>
  );
};

export default InvoiceWholeSalerForm;
