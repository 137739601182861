import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import axios from "axios";
import { BeatLoader } from 'react-spinners';
// import otpbanner from "../../../../assets/registration/otpbanner.svg"
const BASE_URL = process.env.REACT_APP_ROOT_URL;
export const useMountEffect = (fun) => useEffect(fun, []);

// import { Button, Form, Input } from 'antd';

function VerifyOtp(props) {
  const navigate = useNavigate();
  const [warningMessage, setWarningMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [areAllInputsNumeric, setAreAllInputsNumeric] = useState(false);
  const [minutes, setMinutes] = useState(4);
  const [seconds, setSeconds] = useState(59);
  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };

  const phoneNumber = localStorage.getItem("phoneNumber");
  const phoneNumberWithoutPrefix = phoneNumber.replace(/^(\+88)/, '');
  // Gneral Focus Hook

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const UseFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("This will run after 1 second!");
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  // const isBoolean = param => typeof param === 'boolean';

  const [input1Val, setInput1Val] = useState("");
  const [input1Ref, setInput1Focus] = UseFocus();

  const [input2Val, setInput2Val] = useState("");
  const [input2Ref, setInput2Focus] = UseFocus();

  const [input3Val, setInput3Val] = useState("");
  const [input3Ref, setInput3Focus] = UseFocus();

  const [input4Val, setInput4Val] = useState("");
  const [input4Ref, setInput4Focus] = UseFocus();

  const [input5Val, setInput5Val] = useState("");
  const [input5Ref, setInput5Focus] = UseFocus();

  // const [input6Val, setInput6Val] = useState("");
  // const [input6Ref, setInput6Focus] = UseFocus();

  // const [completeBtnRef, setCompleteFocus] = UseFocus();
  // const [disabel, setDisable] = useState(false);


  const checkIfAllNumeric = () => {
    const inputValues = [
      input1Val,
      input2Val,
      input3Val,
      input4Val,
      input5Val,
    ];
    const areAllNumeric = inputValues.every((value) =>
      /^[0-9]*$/.test(value)
    );
    const areNotEmpty = inputValues.every((value) => value !== '');
    const isFormValid = areAllNumeric && areNotEmpty;
    setAreAllInputsNumeric(isFormValid);
  };
  useEffect(() => {
    checkIfAllNumeric();
  }, [input1Val, input2Val, input3Val, input4Val, input5Val]);

  useMountEffect(setInput1Focus, setInput2Focus);
  function handleSubmit(e) {
    e.preventDefault();
    setInput1Val("");
    setInput2Val("");
    setInput3Val("");
    setInput4Val("");
    setInput5Val("");
    // setInput6Val("");
    // navigate('/');

    const otpCode = input1Val + input2Val + input3Val + input4Val + input5Val;
    loadingCallback(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}verify-otp`,
        { phone: phoneNumberWithoutPrefix, otp_code: otpCode },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        loadingCallback(false);
        if (response.data.status === 400) {
          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        } else if (response.data.status === 200) {
          console.log(
            "isResetPassword:" + localStorage.getItem("isResetPassword")
          );
          if (localStorage.getItem("isResetPassword") == "true") {
            navigate("/set-new-password");
          } else {
            props.continueSubmit();
          }
        } else {
          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
      });
  }

  const resendOtp = () => {
    loadingCallback(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}send-otp`,
        { phone: phoneNumberWithoutPrefix },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        loadingCallback(false);
        if (response.data.status === 200) {
          setSuccessMessage(true);
          setMinutes(4);
          setSeconds(59);
          const timer = setTimeout(() => {
            setSuccessMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
      });
  };

  return (
    <section style={{ background: "#000" }}>
      <div className="otp-container">
        <div className="otp-left">
          <img src={`${BASE_URL}/assets/registration/webp/otpbanner.webp`} alt="otpimage" />
        </div>
        <div className="otp-right">
          <div className="otp-right-card">
            <div className="otp-desctitle">
              <p>
                Enter your <br></br> Verification Code
              </p>
            </div>

            {warningMessage && (
              <div className="warning-message">
                Invalid OTP! Please provide correct OTP.
              </div>
            )}
            {successMessage && (
              <div className="success-message">
                We have sent you a new OTP. Please check you inbox.
              </div>
            )}
            {loading && (

              <div className="custom-loader-logo">
                {/* <BeatLoader color={"#123abc"} size={20} /> */}
                <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
              </div>
            )}

            <div className="otp-form-main">
              <form
                method="get"
                className="digit-group input-sm col-sm-12"
                data-group-name="digits"
                data-autosubmit="false"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <div className="otp-box">
                  <input
                    className="m-2 text-center form-control otp-rounded"
                    inputMode="numeric"
                    type="text"
                    id="first"
                    name="digit1"
                    maxLength="1"
                    onChange={(e) => {
                      const val = e.target.value;
                      setInput1Val(val);

                      if (val.length === 1) setInput2Focus();
                    }}
                    ref={input1Ref}
                    value={input1Val}
                  />
                  <input
                    className="m-2 text-center form-control otp-rounded"
                    inputMode="numeric"
                    type="text"
                    id="second"
                    name="digit2"
                    maxLength="1"
                    onChange={(e) => {
                      const val = e.target.value;
                      setInput2Val(val);

                      if (val.length === 1) setInput3Focus();
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 8 || e.key === "Backspace") {
                        setInput2Val("");
                        setInput1Focus();
                      }
                    }}
                    ref={input2Ref}
                    value={input2Val}
                  />
                  <input
                    className="m-2 text-center form-control otp-rounded"
                    inputMode="numeric"
                    type="text"
                    id="third"
                    name="digit3"
                    maxLength="1"
                    onChange={(e) => {
                      const val = e.target.value;
                      setInput3Val(val);

                      if (val.length === 1) setInput4Focus();
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 8 || e.key === "Backspace") {
                        setInput3Val("");
                        setInput2Focus();
                      }
                    }}
                    ref={input3Ref}
                    value={input3Val}
                  />
                  <input
                    className="m-2 text-center form-control otp-rounded"
                    inputMode="numeric"
                    type="text"
                    id="fourth"
                    name="digit4"
                    maxLength="1"
                    onChange={(e) => {
                      const val = e.target.value;
                      setInput4Val(val);

                      if (val.length === 1) setInput5Focus();
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 8 || e.key === "Backspace") {
                        setInput4Val("");
                        setInput3Focus();
                      }
                    }}
                    ref={input4Ref}
                    value={input4Val}
                  />
                  <input
                    className="m-2 text-center form-control otp-rounded"
                    inputMode="numeric"
                    type="text"
                    id="fifth"
                    name="digit5"
                    maxLength="1"
                    onChange={(e) => {
                      const val = e.target.value;
                      setInput5Val(val);
                      // setDisable(false);
                      // if (val.length === 1) setInput6Focus();
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 8 || e.key === "Backspace") {
                        setInput5Val("");
                        setInput4Focus();
                        //  setDisable(false);
                      }
                    }}
                    ref={input5Ref}
                    value={input5Val}
                  />
                  {/* <input
                    className="m-2 text-center form-control otp-rounded"
                    inputMode="numeric"
                    type="text"
                    id="sixth"
                    name="digit6"
                    maxLength="1"
                    value={input6Val}
                    onChange={(e) => {
                      const val = e.target.value;
                      setInput6Val(val);

                      if (val.length === 1) setCompleteFocus();
                      setDisable(true);
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 8 || e.key === "Backspace") {
                        setInput6Val("");
                        setInput5Focus();
                        setDisable(false);
                      }
                    }}
                    ref={input6Ref}
                  /> */}
                </div>

                <div className="otp-timer">
                  {seconds > 0 || minutes > 0 ? (
                    <p>
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  ) : (
                    ""
                  )}

                  {/* <p>04:59</p> */}
                </div>
                <div className="otp-info">
                  <p>
                    We have sent verification code to your<br></br>
                    phone{" "}
                    <span style={{ color: "#034AFF" }}>
                      {" "}
                      {"+88" + phoneNumberWithoutPrefix}
                    </span>
                    . You can<br></br>
                    check your inbox.
                  </p>
                </div>
                <div className="resend-otp">
                  {seconds <= 0 && minutes <= 0 ? (
                    <p>
                      I didn’t received the code?{" "}
                      <a href="#" onClick={resendOtp}>
                        Send again
                      </a>{" "}
                    </p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="button-disabled">
                  <button
                    type="submit"
                    disabled={!areAllInputsNumeric}
                    className="otp-form-button"
                  >
                    {" "}
                    Verify{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default VerifyOtp;
