import React, { useState, useRef, useEffect, useContext } from "react";
import ContentLayout from '../../layout/ContentLayout'
import AdvanceBookingDetail from './AdvanceBookingDetail';
import { useParams, useNavigate } from 'react-router-dom';
import { get, post } from "../../services/apiService";
import { AppContext } from "../../context/context";
import { scrollUp } from "../../utils/Common";
import axios from "axios";


const AdvanceBookingDetailPage = () => {
  const { id } = useParams(); // Get the id parameter from the URL
  const {
      state,
  } = useContext(AppContext);

  const { invoiceSetting, paperSize } = state;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const invoice = {
    logo: "https://example.com/logo.png",
    name: "Acme Corporation",
    number: "INV-12345",
    date: "2023-10-04",
    created_at: "2023-10-04",
    invoice_id: "INV-12345",
    customer_name: "John Doe",
    customer_phone: "123213123",
    sales_details: [
      {
        id: 1,
        name: "Widget A",
        qty: 2,
        price: 10,
        total: 20,
        product_info: {
          name: "Widget A",
          price: 10
        }
      },
      {
        id: 2,
        name: "Widget B",
        qty: 1,
        price: 50,
        total: 50,
        product_info: {
          name: "Widget B",
          price: 50
        }
      },
      {
        id: 3,
        name: "Widget C",
        qty: 1,
        price: 100,
        total: 100,
        product_info: {
          name: "Widget C",
          price: 100
        }
      }
    ],
    sub_total: 170,
    tax: 25.5,
    paid_amount: 195.5,
    vat: 15
  };
  const [invoiceData, setInvoiceData] = useState(invoice);


  useEffect(() => {
      // Fetch invoice data based on the ID and populate
      // fetchInvoiceData(id, () =>{
      //   getDelivery()
      // });
      fetchInvoiceData(id)

      // getDeliveryAccountList()
  }, [id]);



  // const getDeliveryAccountList = () => {
  //   loadingCallback(true)
  //   axios.get(
  //     `${process.env.REACT_APP_API_URL}delivery-info-list`,{
  //       headers: {
  //         authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   )
  //   .then((response) => {
  //     if(response.data.success){
  //       loadingCallback(false)
  //     }
  //   })
  //   .catch((error) => {
  //     navigate('/login');
  //     console.log(error);
  //   });
  // };

  const fetchInvoiceData = (invoiceId) => {
    // Make an API request to fetch product data by ID
    // Once data is retrieved, update the form fields with the data
    post("advance-invoice-details", { 'invoice_id': invoiceId }, token)
      .then((response) => {
        const invoiceData = response.data;
        setInvoiceData(invoiceData);
        console.log("call advance", );
      })
      .catch((error) => {
        console.log(error);
      });
  };

// scroll up on render
  useEffect(()=>{
    scrollUp()
  },[])

  // console.log("delivery", deliveryData);


  return (
    <>
      <ContentLayout>
        <div className="main-content">
          <div className="RecentOrdersTable-container">
            { invoiceData && invoiceData.invoice_id && (
              <AdvanceBookingDetail paperSize={paperSize} invoice={invoiceData} invoiceSetting={invoiceSetting ? invoiceSetting : null} />
            )}

          </div>
        </div>
      </ContentLayout>
    </>
  );
};


export default AdvanceBookingDetailPage