import React from "react";
import LogoAlignLeft from './bigLogo.png';
import { formatDate, formatPrice } from "../../utils/Common";
import ProductStockReportItem from "./ProductStockReportItem";
const ProductStockDetails = (props) => {

  const {activeTab,detailsData,fullApiData} = props;
  let createdDate,updatedDate;
  detailsData.map(el =>{
    createdDate = el.created_at;
    updatedDate = el.updated_at;
   } )

  return (
    <div
      id="productStockHistory"
      className="bg-white rounded-3xl font-nunito p-[26px] mb-20"
    >
      <div className="flex justify-between items-center">
        {/* outlet logo */}
        <div  className="w-20 cursor-pointer -pb-[4px]">
          <img src={LogoAlignLeft} className="w-full h-full object-contain" />
        </div>
        {/* title and date */}
        <div className="text-[#000232] text-right">
          <h2 className="text-sm font-semibold">Product Stock Report</h2>
          <p className="text-xs">{formatDate(createdDate)} - {formatDate(updatedDate)}</p>
        </div>
      </div>
      {/* table head */}
      <div className="grid text-center mt-4 pt-[4px] pb-[18px] items-center text-xs font-bold bg-[#000232] text-white px-3 grid-cols-7 border-b-[1px] border-[#E7EBF4]">
        <div className="text-left col-span-1">No.</div>
        <div className="col-span-2">Product Name</div>
        <div className="col-span-1">Sale Unit</div>
        <div className="col-span-1">Purchase Unit</div>
        <div className="col-span-2 text-right ">Total Sale Unit</div>
      </div>
      {/* table body component */}
      {detailsData.map((cData, index) =>
         (cData?.completed &&
          (
          <ProductStockReportItem
          categoryData={cData}
            tabActive={activeTab}
            key={index}
            index={index}
          />  )
      )
      )}

      <hr className="border-[#000232] border-[1px]" />
      <div className="w-[38%] ml-auto text-xs font-[500]">
{/*         <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Total Product</p>
          <p>{fullApiData?.total_product}</p>
        </div> */}
        <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Sold Product Unit</p>
          <p>{fullApiData?.total_sales_qty}</p>
        </div>
        <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Purchase Product Unit</p>
          <p>{fullApiData?.total_purchase_qty}</p>
        </div>
{/*         <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Total Customer</p>
          <p>{fullApiData?.total_customer}</p>
        </div> */}
        <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Total Sold Amount </p>
          <p>{formatPrice(fullApiData?.total_sales_amount)}</p>
        </div>
        <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Total Purchase Amount </p>
          <p>{formatPrice(fullApiData?.total_purchase_amount)}</p>
        </div>
      </div>
    </div>
  );
};


export default ProductStockDetails;