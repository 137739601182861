import React from "react";

const TextArea = ({ className, name, placeholder, label, onChange,value='' }) => {
  return (
    <div className="w-full relative">
      <textarea
        rows="4"
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        className={`w-full bg-[#FFF] font-normal placeholder:text-[#91919F] outline-none pt-4 pl-[15px] pb-2.5 text-sm rounded-[10px] resize-none border  border-[#C1CFEF] ${className}`}
      ></textarea>
      <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
        {label}
      </label>
    </div>
  );
};

export default TextArea;
