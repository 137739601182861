import axios from "axios";
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from "react";
import "./index.css";
// import phoneinfobannner from "../../../../assets/registration/phoneinfobanner.svg"
// import flag from "../../../../assets/registration/bdflag.svg"
// import outletlogo from "../../../../assets/registration/outletlogo.svg"
// import bdflag from "../../../../assets/registration/bdf-number.svg"
// import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from "antd";
import { AppContext } from "../../../../context/context";
import DialCodes from '../../../../data/dialCodes.json';
import FlagIcon from "../../../../forms/FlagIcon";
import { get, post } from "../../../../services/apiService";

const BASE_URL = process.env.REACT_APP_ROOT_URL;

function PhoneInfo(props) {
  const {
    state,
    updateCountryList
  } = useContext(AppContext);
  const [warningMessage, setWarningMessage] = useState(false);

  const validatePhoneNumber = (number, countryCode) => {
    // Strip out non-numeric characters
    const cleanedNumber = number.replace(/\D/g, '');

    if (countryCode === '+880') {
      const bdPattern = /^(1[234567890]|8[0-9])(\d{8})$/;
      return bdPattern.test(cleanedNumber);
    } else if (countryCode === '+1') {
      const usPattern = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
      return usPattern.test(cleanedNumber);
    }
    return false;
  };



  // const createUser = (formData) => {
  //   post("save-product", formData, token)
  //     .then((response) => {
  //       loadingCallback(false);
  //       if (response.success) {
  //         setSuccessFlashMessage('Product saved successfully');
  //         navigate('/products');
  //       } else {
  //         console.log("Product insertion failed");

  //           setWarningMessage(true);
  //           const timer = setTimeout(() => {
  //             setWarningMessage(false);
  //           }, 3000);
  //           return () => clearTimeout(timer);
  //       }
  //     })
  //     .catch((error) => {
  //       loadingCallback(false);
  //       if (error?.response?.status === 401) {
  //         localStorage.clear();
  //         localStorage.setItem('token', false);
  //         // Clear session and local storage
  //         localStorage.setItem('isPinVerify', false);

  //         navigate('/login');
  //       }
  //       console.log(error);
  //     });
  // }

  const onFinish = (values) => {
    console.log("Success:", values);

    if (validatePhoneNumber(values.phoneNumber, selectedCountryCode)) {
      localStorage.setItem("email", values.email);
      localStorage.setItem("phoneNumber", selectedCountryCode + values.phoneNumber);
      props.continueSubmit();
    } else {
      setWarningMessage(true);
      const timer = setTimeout(() => {
        setWarningMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  };


  // const onFinishFailed = (errorInfo) => {
  //     console.log('Failed:', errorInfo);
  // };

  useEffect(() => {
    if (!state.countryList.length) {
      getCountryList();
    }
  },[]);

  const page = 1;
  const perPage = 100;

  const getCountryList = () => {
    axios
    .get(
        `${process.env.REACT_APP_API_URL}get-countries?page=${page}&limit=${perPage}`,
        {
            headers: {
                authorization: `Bearer `,
                "Content-Type": "application/json",
            },
        }
    )
    .then((response) => {
        if(response.data.success)
        {
          updateCountryList(response.data.data)
        }
    })
    .catch((error) => {
        console.log(error);
    });
  };
  const getDialCodeByCountryCode = (code) => {
    const country = DialCodes.find((item) => item.code === code);
    return country ? country.dial_code : '';
  };
  const [selectedCountryCode, setSelectedCountryCode] = useState("+880");
  const handleChange = (name, value) => {
    if (name === "country_code") {
      setSelectedCountryCode(value);
    }
  };

  return (
    <section style={{ background: "#000" }}>
      <div className="phone-container">
        <div className="phone-left">
          <img
            src={`${BASE_URL}/assets/registration/webp/phoneinfobanner.webp`}
            alt="phoneimage"
          />
        </div>
        <div className="phone-right">
          <div className="phone-right-card">
            <div className="phone-log-img">
              <img
                src={`${BASE_URL}/assets/registration/webp/outletlogo.webp`}
                alt="logo"
              />
              <h1>Create Account</h1>
              {/* <p>your expense for you.</p> */}
            </div>

            <div className="hr"></div>
            <div className="phone-desctitle">
              <p>
                Provide us your  <br></br>  Contact Information
              </p>
            </div>
            <div className="phone-description ">
              <p className="m-3">
              To create a store for you, and keep<br></br>  track your expense for you.
              </p>
            </div>
            {warningMessage && (
              <div className="warning-message">Invalid phone number!</div>
            )}
            <div className="phone-form-main">
              <Form
                name="normal_phone"
                className="phone-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email!",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email address.",
                    },
                    ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!/^\d+@/.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Email address cannot have only numbers before @');
                    },
                  }),
                  ]}
                >
                  <Input placeholder="Email" type="email" />
                </Form.Item>
                <div className="bd-number mb-2">

                    {/* <img src={bdflag} /> */}
                    {/* <p> <img src={flag} alt='bdflag' /><span >+880</span></p> */}
                    {/* <img
                      src={`${BASE_URL}/assets/registration/webp/bdflag.webp`}
                      alt="bdflag"
                    />
                    <span>+880</span> */}
                    <select
                      id="country_code"
                      name="country_code"
                      className={classNames(
                        'rounded-[15px]',
                        'p-3',
                        'bg-white',
                        'h-[50px]',
                      )}
                      style={{  border: "1px solid #ccc" }}
                      // value={selectedCountryCode}
                      onChange={(e) => handleChange("country_code", e.target.value)}
                    >

                      <option value="">Select</option>
                      {state.countryList.map((country) => (
                        <option key={country.id} className="text-[14px]" value={getDialCodeByCountryCode(country.code)}>
                          <FlagIcon countryCode={country.code} />{getDialCodeByCountryCode(country.code)}
                        </option>
                      ))}
                    </select>
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Phone Number!",
                      },
                    ]}
                  >
                    <Input placeholder="Phone Number" type="tel" />
                  </Form.Item>
                </div>
                <Form.Item>
                  <Button htmlType="submit" className="phone-form-button">
                    Next
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PhoneInfo;
