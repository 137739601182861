import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { AppContext } from "../../../context/context.js";

import "./index.css";
const BASE_URL = process.env.REACT_APP_ROOT_URL;
const shop_logo = `${BASE_URL}/layoutlogo.svg`;
const MAX_MOBILE_SCREEN_WIDTH = 767;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="p-[6px] rounded-full absolute top-[46%] -right-[25px]"
      style={{ ...style, display: "inline-block", background: "#7F8098" }}
      onClick={onClick}
    >
      <AiOutlineArrowRight className="text-[#FFF] text-base font-bold"></AiOutlineArrowRight>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="p-[6px] rounded-full absolute top-[46%] -left-[25px] z-50"
      style={{ ...style, display: "inline-block", background: "#7F8098" }}
      onClick={onClick}
    >
      <AiOutlineArrowLeft className="text-[#FFF] text-base font-bold"></AiOutlineArrowLeft>
    </div>
  );
}

const MostPurchaseProducts = (props) => {
  const { type, activeTab, loadingCallback, fetchedData, vendorId } = props;
  const { state } = useContext(AppContext);
  const { invoiceSetting } = state;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      document.documentElement.clientWidth <= MAX_MOBILE_SCREEN_WIDTH
    ) {
      setIsMobile(true);
    }

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const handleResize = () => {
    if (
      typeof window !== "undefined" &&
      document.documentElement.clientWidth <= MAX_MOBILE_SCREEN_WIDTH
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const settings = {
    className: "sliderAreaHome md:min-w-[500px] lg:min-w-[650px] w-[100%]",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Adjust slidesToShow based on the number of products
    slidesToScroll: 2, // Adjust slidesToScroll as well
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const settingsMobile = {
    className: "sliderAreaHome md:min-w-[300px] w-[250px] sm-[300p]",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Adjust slidesToShow based on the number of products
    slidesToScroll: 1, // Adjust slidesToScroll as well
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const handleProduct = (item) => {
    if (type === "vendor") {
      navigate(`/recently-added/${item.product_id}`);
    } else {
      navigate(`/products/${item.product_id}`);
    }
  };

  const [productData, setProductData] = useState({});
  const [productDataCarrier, setProductDataCarrier] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 1000;

  useEffect(() => {
    getProduct(activeTab, currentPage);
  }, [activeTab, currentPage]);

  const getProduct = (activeTab, page) => {
    loadingCallback(true);
    const url =
      type == "vendor"
        ? `vendor-wise-product`
        : type === "wholesaler"
        ? `whole-sellers-wise-product`
        : type === "carrier"
        ? `carrier-recent-product`
        : type === "exporter"
        ? `exporter-recent-product`
        : `customer-wise-product`;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}${url}/${vendorId}?interval=${activeTab}&page=${page}&limit=${perPage}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          let responseData = response?.data?.data?.data ? response?.data?.data?.data : response?.data?.data;

          setProductData(responseData);


          // console.log("==========");
          // console.log(responseData);
          // console.log("==========");
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };


  return (
    <div className="RecentSalesTable  bg-[#F8F9FA] rounded-[25px] px-[30px] pt-[28px] pb-[30px] mt-[30px] mb-[30px]">
      {productData.length > 0 && (
        <div className="title-link">
          {type == "carrier" ? (
            <h1>Recent Carried Products</h1>
          ) : <h1>Most Purchase Products</h1>
          }

          {type == "vendor" ? (
            <Link
              className="whitespace-nowrap"
              to={`/vendor-most-purchase-products/${vendorId}`}
            >
              View All
            </Link>
          ) : type == "wholesaler" ? (
            <Link
              className="whitespace-nowrap"
              to={`/wholesaler-most-purchase-products/${vendorId}`}
            >
              View All
            </Link>
          ) : type == "carrier" ? (
            <Link
              className="whitespace-nowrap"
              to={`/carrier-profile/${vendorId}`}
            >
              View All
            </Link>
          ) : (
            <Link
              className="whitespace-nowrap"
              to={`/customer-most-purchase-products/${vendorId}`}
            >
              View All
            </Link>
          )}
        </div>
      )}

      {productData.length > 0 && productData.length < 5 && !isMobile ? (
        <div className="flex flex-row gap-3">
          {type == "carrier"
            ? productData?.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    handleProduct(item);
                  }}
                  className="cursor-pointer flex flex-1 max-w-[218px] flex-col justify-center p-[12px] mt-[30px] mb-[30px] bg-white rounded-[20px]"
                  style={{
                    "box-shadow": "0px 6px 13px 0px rgba(0, 0, 0, 0.10)",
                  }}
                >
                  <div className="mt-4 pt-4">
                    <div className="h-[140px]">
                      <img
                        src={item?.image_path ? item?.image_path : shop_logo}
                        alt="categoryDymmyImaage"
                        className="w-[100%] h-[100%] object-contain rounded-[16px]"
                      />
                    </div>
                    <div className="mt-[12px]">
                      <div className="flex flex-row justify-between">
                        <div className="text-[#000232] text-[12px] font-nunito font-bold">
                          {item?.product_name}
                        </div>
                        <div className="text-[#000232] text-[12px] font-nunito font-bold">
                          {item?.brand_name ? item.brand_name : null}
                        </div>
                      </div>
                      <div className="mt-[4px] text-[#A0AEC0] text-[12px] font-nunito font-semibold">
                        #{item?.product_id}
                      </div>
                      <div className="mt-[6px] text-[#000232] text-[16px] font-nunito font-bold">
                        <p>
                          {type == "carrier"
                            ? item?.purchase_price
                            : item?.purchase_price}{" "}
                          {item?.base_currency ? item?.base_currency : "BDT"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : type == "exporter"
            ? productData?.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    handleProduct(item);
                  }}
                  className="cursor-pointer flex flex-1 max-w-[218px] flex-col justify-center p-[12px] mt-[30px] mb-[30px] bg-white rounded-[20px]"
                  style={{
                    "box-shadow": "0px 6px 13px 0px rgba(0, 0, 0, 0.10)",
                  }}
                >
                  <div className="mt-4 pt-4">
                    <div className="h-[140px]">
                      <img
                        src={item?.image_path ? item?.image_path : shop_logo}
                        alt="categoryDymmyImaage"
                        className="w-[100%] h-[100%] object-contain rounded-[16px]"
                      />
                    </div>
                    <div className="mt-[12px]">
                      <div className="flex flex-row justify-between">
                        <div className="text-[#000232] text-[12px] font-nunito font-bold">
                          {item?.product_name}
                        </div>
                        <div className="text-[#000232] text-[12px] font-nunito font-bold">
                          {item?.brand_name ? item.brand_name : null}
                        </div>
                      </div>
                      <div className="mt-[4px] text-[#A0AEC0] text-[12px] font-nunito font-semibold">
                        #{item?.product_id}
                      </div>
                      <div className="mt-[6px] text-[#000232] text-[16px] font-nunito font-bold">
                        <p>
                          {type == "carrier"
                            ? item?.purchase_price
                            : item?.purchase_price}{" "}
                          {item?.base_currency ? item?.base_currency : "BDT"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : productData?.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    handleProduct(item);
                  }}
                  className="cursor-pointer flex flex-1 max-w-[218px] flex-col justify-center p-[12px] mt-[30px] mb-[30px] bg-white rounded-[20px]"
                  style={{
                    "box-shadow": "0px 6px 13px 0px rgba(0, 0, 0, 0.10)",
                  }}
                >
                  <div className="mt-4 pt-4">
                    <div className="h-[140px]">
                      <img
                        src={
                          item?.product_info?.image_path
                            ? item?.product_info?.image_path
                            : shop_logo
                        }
                        alt="categoryDymmyImaage"
                        className="w-[100%] h-[100%] object-contain rounded-[16px]"
                      />
                    </div>
                    <div className="mt-[12px]">
                      <div className="flex flex-row justify-between">
                        <div className="text-[#000232] text-[12px] font-nunito font-bold">
                          {item?.product_info?.name}
                        </div>
                        <div className="text-[#000232] text-[12px] font-nunito font-bold">
                          {item?.product_info?.brands?.name
                            ? item.product_info?.brands.name
                            : null}
                        </div>
                      </div>
                      <div className="mt-[4px] text-[#A0AEC0] text-[12px] font-nunito font-semibold">
                        #{item?.product_info?.barcode}
                      </div>
                      <div className="mt-[6px] text-[#000232] text-[16px] font-nunito font-bold">
                        <p>
                          {type == "vendor"
                            ? item?.product_info?.purchase_price
                            : item?.product_info?.retails_price}{" "}
                          {invoiceSetting?.currency_info?.code
                            ? invoiceSetting?.currency_info?.code
                            : "BDT"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      ) : isMobile ? (
        <div className="sellingcard-container1">
          <Slider {...settingsMobile}>
            {productData?.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  handleProduct(item);
                }}
                className="cursor-pointer flex flex-col justify-center items-center"
              >
                <div
                  className="bg-white mr-[25px] h-64 w-40 sm:w-44 md:w-48 rounded-[20px] p-[12px]"
                  style={{
                    "box-shadow": "0px 6px 13px 0px rgba(0, 0, 0, 0.10)",
                  }}
                >
                  <div className=" h-[140px] flex justify-center border border-[1px_solid_#a3a3a3] overflow-hidden rounded-2xl">
                    <img
                      src={
                        item?.image_path
                          ? item?.image_path
                          : item?.product_info?.image_path
                          ? item?.product_info?.image_path
                          : shop_logo
                      }
                      alt="categoryDymmyImaage"
                      className="h-[100%] object-contain"
                    />
                  </div>
                  <div className="mt-[12px]">
                    <div className="flex flex-row justify-between">
                      <div className="text-[#000232] text-[12px] font-nunito font-bold">
                        {item?.product_name
                          ? item?.product_name
                          : item?.product_info?.name}
                      </div>
                      <div className="text-[#000232] text-[12px] font-nunito font-bold">
                        {item?.brand_name
                          ? item?.brand_name
                          : item?.product_info.brands?.name
                          ? item.product_info.brands.name
                          : null}
                      </div>
                    </div>
                    <div className="mt-[4px] text-[#A0AEC0] text-[12px] font-nunito font-semibold">
                      #
                      {item?.product_info.barcode
                        ? item?.product_info.barcode
                        : item?.product_id}
                    </div>
                    <div className="mt-[6px] text-[#000232] text-[16px] font-nunito font-bold">
                      <p>
                        {type == "carrier"
                          ? item?.purchase_price
                          : type == "vendor"
                          ? item?.product_info?.purchase_price
                          : item?.product_info?.retails_price}{" "}
                        {type == "carrier"
                          ? item?.base_currency
                          : invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info?.code
                          : "BDT"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) : productData.length > 4 && !isMobile ? (
        <div className="sellingcard-container1">
          <Slider {...settings}>
            {productData?.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  handleProduct(item);
                }}
                className="cursor-pointer flex flex-col  justify-center items-center"
              >
                <div
                  className="bg-white mr-[25px] h-64 w-52 rounded-[20px] p-[12px]"
                  style={{
                    "box-shadow": "0px 2px 10px rgba(0, 0, 0, 0.10)",
                  }}
                >
                  <div className="w-full md:h-[135px] rounded-2xl text-center flex justify-center border border-[1px_solid_#a3a3a3] overflow-hidden lg:h-[140px]">
                    <img
                      src={
                        item?.image_path
                          ? item?.image_path
                          : item?.product_info?.image_path
                          ? item?.product_info?.image_path
                          : shop_logo
                      }
                      alt="categoryDymmyImaage"
                      className=" object-contain "
                    />
                  </div>
                  <div className="mt-[12px]">
                    <div className="flex flex-row justify-between">
                      <div className="text-[#000232] text-[12px] font-nunito font-bold">
                        {item?.product_name ? item?.product_name : item?.product_info?.name}
                      </div>
                      <div className="text-[#000232] text-[12px] font-nunito font-bold">
                        {item?.brand_name ? item?.brand_name
                          : item?.product_info?.brands?.name
                          ? item.product_info?.brands.name
                          : null}
                      </div>
                    </div>
                    <div className="mt-[4px] text-[#A0AEC0] text-[12px] font-nunito font-semibold">
                      #{type == "carrier" || type == "exporter"  ? item?.product_id : item?.product_info?.barcode}
                    </div>
                    <div className="mt-[6px] text-[#000232] text-[16px] font-nunito font-bold">
                      <p>
                        {type == "carrier" || type == "exporter" ? item?.purchase_price
                          : type == "vendor"
                          ? item?.product_info?.purchase_price
                          : item?.product_info?.retails_price}{" "}
                        {invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info?.code
                          : "BDT"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) : null}
    </div>
  );
};

export default MostPurchaseProducts;
