import React from 'react';
import { formatDate, formatPrice, timeDiff } from '../../utils/Common';

const PaymentTypeItem = (props) => {
  const {
    ptData
  } = props;


  return (
    <>

      <div className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-3 items-start">
        <div className='text-left  text-[#000] font-[600]'>
          {ptData?.payment_type_category_id}
        </div>
        <div className='text-center  text-[#000] font-[600]'>
          {ptData?.account_number}

        </div>
        <div className='text-right  text-[#000] font-[600]'>
          {ptData?.payment_amount}
        </div>
      </div>

    </>
  );
};
PaymentTypeItem.defaultProps = { className: null };
export default PaymentTypeItem;
