
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/context';
import { formatPrice, formatDate } from '../../utils/Common';



const InvoiceItem = props => {
  const { invoice, className } = props;
  const { config, state } = useContext(AppContext);
  const { BASE_URL } = config;
  const navigate = useNavigate();

  const handleClick = e => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/invoice/${invoice.name}`);
  };
  return (
    <div className="cursor-pointer" onClick={handleClick}>
      <div className="hrtable"></div>
      <div className="table-data flex  invoiceItemTable">
          <div className="first">
              {/* <div className="images">
                  <img src={`${BASE_URL}/dollaricon.svg`} alt="dollar" />
              </div> */}
              <div className="firstdata w-full ">
                  <h1>{invoice.name}</h1>
                  <p>{invoice.sname}</p>
              </div>
          </div>
          <div className="second">
              <p>{invoice.price}</p>
          </div>
          <div className='due text-center flex items-center'>
            <p className='w-full'>{invoice.due}</p>
          </div>
          <div className="fourth">
              <h1>{formatDate(invoice.date)}</h1>
              <p>{invoice.time}</p>
          </div>
      </div>
    </div>
  );
};

InvoiceItem.defaultProps = { className: null };

export default InvoiceItem;