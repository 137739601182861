import React from 'react';
import ContentLayout from '../../layout/ContentLayout';
import ProductsList from '../../components/products/productlist/Index';
import Footer from '../../components/footer/Index';
import ExporterProductList from '../../components/ExporterProduct/ExporterProductList/ExporterProductList';

const ExporterProductListPage = () => {
  return (
    <div>
       <ContentLayout>
        <div className="main-content mt-40 md:mt-0">
          <ExporterProductList />
          <Footer />
        </div>
      </ContentLayout>
    </div>
  );
};

export default ExporterProductListPage;