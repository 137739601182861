import React from 'react';
import  { formatDate, timeDiff } from './../../utils/Common'
const CategoryHistoryReportItems = (props) => {
  const { categoryData,
    actTab,
    shouldRenderPagination,
    currentPage,
    onPageChange,
    loading,
    index
} = props;

  const createdDate = new Date(categoryData?.created_at);
  let dueAmount;
  if (categoryData?.due == null) {
    dueAmount = 0;
  }
  else {
    dueAmount = categoryData?.due;
  }
const indexNumber = index+1;
  return (
    <>

     <div className="border-b-[1px] text-xs border-solid last:border-none border-[#E7EBF4] -pt-[4px] pb-[13px] font-nunito grid grid-cols-10 items-center">
     {/* date/time */}
      <div className="text-[#000] font-[500] text-center col-span-1">
       {indexNumber}
     </div>
     <div className="  col-span-2 text-center">
       <h1 className=" text-[#000] font-[600]">{formatDate(categoryData?.created_at)}</h1>
       <p className=" text-[#87889E] font-[500] text-xs">{timeDiff(createdDate)}</p>
     </div>
     {/* category name */}
     <div className="text-[#000] font-[500] text-center col-span-1">
       {categoryData?.category_name}
     </div>
     {/* product name */}
     <div className="text-[#000] font-[500] text-center col-span-2">
       {categoryData?.product_name}
     </div>
     {/* customer */}
     <div className="text-[#000] font-[500] text-center col-span-2">
       <p>{categoryData?.invoice}</p>
       <p className="text-[#87889E] font-[500] text-xs">{categoryData?.customer}</p>
     </div>
     {/* Due */}
     <div className=" text-[#000] font-[500] text-center col-span-1">{dueAmount}</div>
     {/* Total */}
     <div className="pr-3 text-[#000] font-[500] text-right col-span-1">{categoryData?.total_sale_amount}</div>
   </div>
    </>
  );
};
CategoryHistoryReportItems.defaultProps = { className: null };
export default CategoryHistoryReportItems;