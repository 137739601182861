import React from 'react';
import Breadcrumb from '../Settings_components/Breadcrumb';
import Table from '../Settings_components/Table';
import { send_feedback } from '../../../data/data';

const Send_feedback = () => {
    return (
        <div className='rounded-[27px]' style={{backgroundColor: 'rgba(163, 203, 56, 0.15)' }}>
       <Breadcrumb></Breadcrumb>
      <Table data={send_feedback} type="send_feedback" ></Table>
       </div> 
     
    );
};

export default Send_feedback;