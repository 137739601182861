
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/context";
import { formatPrice, formatDate } from '../../utils/Common';


const DefectItem = (props) => {
  const { invoice,showModalBeforeDelete,index,handleShowModal } = props;
  const { config, state } = useContext(AppContext);
  const { BASE_URL } = config;
  const navigate = useNavigate();

  const defectList = [
    {
      productName: "Paratiout", 
      brandName: "Marico",
      vendorName: "Foysal",
      purchaseDate: "28 Dec 2023",
      purchaseInvoiceId: "#354356",
      description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                    Inventore atque dolorem, quam minima nemo quas laboriosam 
                    similique debitis deserunt incidunt.`,
      defectAmount: "120 BDT", 
    }
  ]

  return (
    <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-4 sm:grid-cols-5 items-center">
      <div className="text-[14px]">
        <h1 className="text-[#000232] font-[500]">{invoice.name}</h1>
        <p className="text-[#87889E] font-[700]">{invoice.sname}</p>
      </div>

      <div className="text-[16px] text-[#000] font-[500] text-center">{invoice.brandName}</div>
      <div className="text-[16px] text-[#000] font-[500] text-center">{invoice.vendorName}</div>
      <div className="hidden sm:block">
        <h1 className="text-[14px] text-[#000] font-[500] text-center">{formatDate(invoice.purchaseDate)}</h1>
        <p className="text-[14px] text-[#87889E] font-[600] text-center">{invoice.time}</p>
      </div>
      <div className="text-[12px] font-nunito font-[7000] flex flex-col lg:flex-row gap-2 justify-center items-center">
        <a className="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"  >Back to Vendor</a>
        <a className="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]" 
          // href={`/advance-bookings/edit/${invoice.name}`}
          htmlFor='defect_view'
          onClick={handleShowModal}
        >View</a>
        {/* <a onClick={() => showModalBeforeDelete(invoice?.name, index)} className="cursor-pointer block rounded-[5px] text-[#FFF] hover:text-[#FFF] bg-[#FF0303] px-[5px] pt-[2px] pb-[2px]">DELETE</a> */}
      </div>
    </div>
  );
};

DefectItem.defaultProps = { className: null };

export default DefectItem;
