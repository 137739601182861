import React, { useState, useRef, useEffect, useContext } from "react";
import ContentLayout from '../../layout/ContentLayout'
import Footer from "../../components/footer/Index.jsx";
import EditOrderProduct from "../../components/EditOrderProduct/EditOrderProduct.js";
import { AppContext } from '../../context/context.js';
import { useParams, useNavigate } from 'react-router-dom';
import { get, post } from "../../services/apiService";
import { BeatLoader } from 'react-spinners';

const AdvanceBookingEditPage = () => {
  const { id } = useParams(); // Get the id parameter from the URL
  const {
      state,
  } = useContext(AppContext);
  const [discount, setDiscount] = useState(0)
  const [paidAmount, setPaidAmount] = useState(0);

  const { invoiceSetting,currentUser } = state;
  const token = localStorage.getItem("token");
  const invoice = {
    logo: "https://example.com/logo.png",
    name: "Acme Corporation",
    number: "INV-12345",
    date: "2023-10-04",
    created_at: "2023-10-04",
    invoice_id: "INV-12345",
    customer_name: "John Doe",
    customer_phone: "123213123",
    sales_details: [
      {
        id: 1,
        name: "Widget A",
        qty: 2,
        price: 10,
        total: 20,
        product_info: {
          name: "Widget A",
          price: 10
        }
      },
      {
        id: 2,
        name: "Widget B",
        qty: 1,
        price: 50,
        total: 50,
        product_info: {
          name: "Widget B",
          price: 50
        }
      },
      {
        id: 3,
        name: "Widget C",
        qty: 1,
        price: 100,
        total: 100,
        product_info: {
          name: "Widget C",
          price: 100
        }
      }
    ],
    sub_total: 170,
    tax: 25.5,
    paid_amount: 195.5,
    vat: 15
  };
  const [invoiceData, setInvoiceData] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };
  useEffect(() => {
      // Fetch invoice data based on the ID and populate
      fetchInvoiceData(id);
  }, [id]);

  const fetchInvoiceData = (invoiceId) => {
    loadingCallback(true);
    // Make an API request to fetch product data by ID
    // Once data is retrieved, update the form fields with the data
    post("advance-invoice-details", { 'invoice_id': invoiceId }, token)
      .then((response) => {
        loadingCallback(false);
        const invoiceData = response.data; // Replace with your API response data
        console.log("edit page data", invoiceData);
        setDiscount(invoiceData.discount);
        setPaidAmount(invoiceData.paid_amount)
        setInvoiceData(invoiceData);
      })
      .catch((error) => {
        loadingCallback(false);
        console.log(error);
        // if (error?.response?.status === 401) {
        //   localStorage.setItem('token', '');
        //   localStorage.setItem('token', '');
        //   // Clear session and local storage
        //   localStorage.setItem('isPinVerify', false);
        //   navigate('/login');
        // }
      });
  };

  return (
    <>
      <ContentLayout>
        {invoiceData && (
          <div className="">
            <EditOrderProduct
              type="booking"
              initialDiscount={discount}
              setInitialDiscount={setDiscount}
              paidAmount={paidAmount}
              setPaidAmount={setPaidAmount}
              invoiceData={invoiceData ? invoiceData : null}
              currentUser={currentUser ? currentUser : null}
            ></EditOrderProduct>
            <Footer />
          </div>
        )}
        {loading && (

          <div className="custom-loader-logo">
            {/* <BeatLoader color={"#123abc"} size={20} /> */}
            <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
          </div>
        )}
      </ContentLayout>
    </>
  );
};


export default AdvanceBookingEditPage

// {
//   "id": 17,
//   "sales_id": "1535",
//   "invoice_id": "INV-2024-03-1535",
//   "product_id": 2005,
//   "qty": 2,
//   "price": "590",
//   "mode": "1",
//   "size": "1",
//   "created_at": "2024-03-05T07:34:26.000000Z",
//   "updated_at": "2024-03-05T07:34:26.000000Z",
//   "product_info": {
//       "id": 17,
//       "name": "SAVLON 112 ML",
//       "category_id": 199,
//       "others": null,
//       "barcode": null,
//       "regular_price": null,
//       "sub_category_id": 114,
//       "brand_id": 89,
//       "unit_id": 9,
//       "currency_id": 27,
//       "image_path": "https://api-outlet-expense.squadinnovators.com/uploads/21-Evan/1702663336.jpg",
//       "image_path1": "https://api-outlet-expense.squadinnovators.com/uploads/21-Evan/1702663339.jpg",
//       "image_path2": "https://api-outlet-expense.squadinnovators.com/uploads/21-hesham/1703613548.jpg",
//       "purchase_price": 500,
//       "wholesale_price": 660,
//       "retails_price": 700,
//       "discount_type": null,
//       "discount": 110,
//       "description": "hwllo and the other relevant",
//       "warrenty": "returns warranty",
//       "user_id": 21,
//       "quantity": 0,
//       "created_at": "2023-08-12T17:47:11.000000Z",
//       "updated_at": "2024-02-28T14:49:15.000000Z",
//       "product_id": null,
//       "have_variant": null,
//       "serial": null,
//       "color": null,
//       "warranties_count": 20
//   }
// }

// {
//   "id": 100,
//   "name": "Super Mom Wipes",
//   "category_id": 199,
//   "others": null,
//   "barcode": null,
//   "regular_price": null,
//   "sub_category_id": 115,
//   "brand_id": 91,
//   "unit_id": 9,
//   "currency_id": 27,
//   "image_path": "https://api-outlet-expense.squadinnovators.com/uploads/21-Evan/1702665384.jpg",
//   "image_path1": "https://api-outlet-expense.squadinnovators.com/uploads/21-Evan/1702665385.jpg",
//   "image_path2": "https://api-outlet-expense.squadinnovators.com/uploads/21-Evan/1702665386.jpg",
//   "purchase_price": 1500,
//   "wholesale_price": 1300,
//   "retails_price": 12000,
//   "discount_type": "1",
//   "discount": 1,
//   "description": "1 years",
//   "warrenty": null,
//   "user_id": 21,
//   "quantity": 12,
//   "created_at": "2023-11-18T07:08:10.000000Z",
//   "updated_at": "2023-12-15T18:36:26.000000Z",
//   "product_id": null,
//   "have_variant": null,
//   "serial": null,
//   "color": null,
//   "warranties_count": 0,
//   "sales_sum_qty": 43,
//   "purchase_sum_qty": 2004,
//   "current_stock": 1959,
//   "unit": {
//       "id": 9,
//       "name": "Pc",
//       "description": "Pcs",
//       "user_id": 21,
//       "created_at": "2023-08-19T13:09:00.000000Z",
//       "updated_at": "2023-12-15T17:50:51.000000Z"
//   },
//   "category": {
//       "id": 199,
//       "name": "Medicine",
//       "description": "All Medicine List",
//       "user_id": 21,
//       "created_at": "2023-12-15T17:48:19.000000Z",
//       "updated_at": "2023-12-15T17:48:19.000000Z"
//   },
//   "sub_category": {
//       "id": 115,
//       "category_id": 199,
//       "name": "Baby's Wipes",
//       "description": "Baby's Wipes",
//       "user_id": 21,
//       "created_at": "2023-12-15T18:15:37.000000Z",
//       "updated_at": "2023-12-15T18:15:37.000000Z"
//   },
//   "brands": {
//       "id": 91,
//       "name": "Square",
//       "description": "Square",
//       "image_path": null,
//       "user_id": 21,
//       "created_at": "2023-12-15T18:34:50.000000Z",
//       "updated_at": "2023-12-15T18:34:50.000000Z"
//   },
//   "currency": {
//       "id": 27,
//       "name": "Taka",
//       "symbol": "৳",
//       "exchange_rate": 84,
//       "status": 1,
//       "code": "BDT",
//       "created_at": "2018-10-09T12:35:08.000000Z",
//       "updated_at": "2018-12-02T05:16:13.000000Z"
//   },
//   "variants": [
//       {
//           "id": 1948,
//           "name": "Super Mom Wipes d",
//           "category_id": 199,
//           "others": null,
//           "barcode": 1709101350,
//           "regular_price": null,
//           "sub_category_id": 115,
//           "brand_id": 91,
//           "unit_id": 9,
//           "currency_id": null,
//           "image_path": null,
//           "image_path1": null,
//           "image_path2": null,
//           "purchase_price": 400,
//           "wholesale_price": 500,
//           "retails_price": 600,
//           "discount_type": null,
//           "discount": null,
//           "description": null,
//           "warrenty": null,
//           "user_id": 21,
//           "quantity": 1,
//           "created_at": "2024-02-28T06:22:30.000000Z",
//           "updated_at": "2024-02-28T06:22:30.000000Z",
//           "product_id": 100,
//           "have_variant": null,
//           "serial": null,
//           "color": null,
//           "warranties_count": 0
//       },
//       {
//           "id": 1949,
//           "name": "Super Mom Wipes d",
//           "category_id": 199,
//           "others": null,
//           "barcode": 1709101350,
//           "regular_price": null,
//           "sub_category_id": 115,
//           "brand_id": 91,
//           "unit_id": 9,
//           "currency_id": null,
//           "image_path": null,
//           "image_path1": null,
//           "image_path2": null,
//           "purchase_price": 500,
//           "wholesale_price": 600,
//           "retails_price": 700,
//           "discount_type": null,
//           "discount": null,
//           "description": null,
//           "warrenty": null,
//           "user_id": 21,
//           "quantity": 1,
//           "created_at": "2024-02-28T06:22:30.000000Z",
//           "updated_at": "2024-02-28T06:22:30.000000Z",
//           "product_id": 100,
//           "have_variant": null,
//           "serial": null,
//           "color": null,
//           "warranties_count": 0
//       },
//       {
//           "id": 1950,
//           "name": "Super Mom Wipes h",
//           "category_id": 199,
//           "others": null,
//           "barcode": 1709101350,
//           "regular_price": null,
//           "sub_category_id": 115,
//           "brand_id": 91,
//           "unit_id": 9,
//           "currency_id": null,
//           "image_path": null,
//           "image_path1": null,
//           "image_path2": null,
//           "purchase_price": 900,
//           "wholesale_price": 1000,
//           "retails_price": 1100,
//           "discount_type": null,
//           "discount": null,
//           "description": null,
//           "warrenty": null,
//           "user_id": 21,
//           "quantity": 1,
//           "created_at": "2024-02-28T06:22:30.000000Z",
//           "updated_at": "2024-02-28T06:22:30.000000Z",
//           "product_id": 100,
//           "have_variant": null,
//           "serial": null,
//           "color": null,
//           "warranties_count": 0
//       },
//       {
//           "id": 1953,
//           "name": "Super Mom Wipes ",
//           "category_id": 199,
//           "others": null,
//           "barcode": 1709101646,
//           "regular_price": null,
//           "sub_category_id": 115,
//           "brand_id": 91,
//           "unit_id": 9,
//           "currency_id": null,
//           "image_path": null,
//           "image_path1": null,
//           "image_path2": null,
//           "purchase_price": null,
//           "wholesale_price": null,
//           "retails_price": null,
//           "discount_type": null,
//           "discount": null,
//           "description": null,
//           "warrenty": null,
//           "user_id": 21,
//           "quantity": 1,
//           "created_at": "2024-02-28T06:27:26.000000Z",
//           "updated_at": "2024-02-28T06:27:26.000000Z",
//           "product_id": 100,
//           "have_variant": null,
//           "serial": null,
//           "color": null,
//           "warranties_count": 0
//       },
//       {
//           "id": 1954,
//           "name": "Super Mom Wipes ",
//           "category_id": 199,
//           "others": null,
//           "barcode": 1709101646,
//           "regular_price": null,
//           "sub_category_id": 115,
//           "brand_id": 91,
//           "unit_id": 9,
//           "currency_id": null,
//           "image_path": null,
//           "image_path1": null,
//           "image_path2": null,
//           "purchase_price": null,
//           "wholesale_price": null,
//           "retails_price": null,
//           "discount_type": null,
//           "discount": null,
//           "description": null,
//           "warrenty": null,
//           "user_id": 21,
//           "quantity": 1,
//           "created_at": "2024-02-28T06:27:26.000000Z",
//           "updated_at": "2024-02-28T06:27:26.000000Z",
//           "product_id": 100,
//           "have_variant": null,
//           "serial": null,
//           "color": null,
//           "warranties_count": 0
//       },
//       {
//           "id": 1955,
//           "name": "Super Mom Wipes ",
//           "category_id": 199,
//           "others": null,
//           "barcode": 1709101646,
//           "regular_price": null,
//           "sub_category_id": 115,
//           "brand_id": 91,
//           "unit_id": 9,
//           "currency_id": null,
//           "image_path": null,
//           "image_path1": null,
//           "image_path2": null,
//           "purchase_price": null,
//           "wholesale_price": null,
//           "retails_price": null,
//           "discount_type": null,
//           "discount": null,
//           "description": null,
//           "warrenty": null,
//           "user_id": 21,
//           "quantity": 1,
//           "created_at": "2024-02-28T06:27:26.000000Z",
//           "updated_at": "2024-02-28T06:27:26.000000Z",
//           "product_id": 100,
//           "have_variant": null,
//           "serial": null,
//           "color": null,
//           "warranties_count": 0
//       },
//       {
//           "id": 1956,
//           "name": "Super Mom Wipes ",
//           "category_id": 199,
//           "others": null,
//           "barcode": 1709101646,
//           "regular_price": null,
//           "sub_category_id": 115,
//           "brand_id": 91,
//           "unit_id": 9,
//           "currency_id": null,
//           "image_path": null,
//           "image_path1": null,
//           "image_path2": null,
//           "purchase_price": null,
//           "wholesale_price": null,
//           "retails_price": null,
//           "discount_type": null,
//           "discount": null,
//           "description": null,
//           "warrenty": null,
//           "user_id": 21,
//           "quantity": 1,
//           "created_at": "2024-02-28T06:27:26.000000Z",
//           "updated_at": "2024-02-28T06:27:26.000000Z",
//           "product_id": 100,
//           "have_variant": null,
//           "serial": null,
//           "color": null,
//           "warranties_count": 0
//       },
//       {
//           "id": 1992,
//           "name": "Super Mom Wipes prod123",
//           "category_id": 199,
//           "others": null,
//           "barcode": 1709446521,
//           "regular_price": null,
//           "sub_category_id": 115,
//           "brand_id": 91,
//           "unit_id": 9,
//           "currency_id": null,
//           "image_path": null,
//           "image_path1": null,
//           "image_path2": null,
//           "purchase_price": 300,
//           "wholesale_price": 350,
//           "retails_price": 400,
//           "discount_type": null,
//           "discount": null,
//           "description": null,
//           "warrenty": null,
//           "user_id": 21,
//           "quantity": 1,
//           "created_at": "2024-03-03T06:15:21.000000Z",
//           "updated_at": "2024-03-03T06:15:21.000000Z",
//           "product_id": 100,
//           "have_variant": null,
//           "serial": "97867543",
//           "color": null,
//           "warranties_count": 0
//       },
//       {
//           "id": 1993,
//           "name": "Super Mom Wipes black",
//           "category_id": 199,
//           "others": null,
//           "barcode": 1709446853,
//           "regular_price": null,
//           "sub_category_id": 115,
//           "brand_id": 91,
//           "unit_id": 9,
//           "currency_id": null,
//           "image_path": null,
//           "image_path1": null,
//           "image_path2": null,
//           "purchase_price": 190,
//           "wholesale_price": 0,
//           "retails_price": 0,
//           "discount_type": null,
//           "discount": null,
//           "description": null,
//           "warrenty": null,
//           "user_id": 21,
//           "quantity": 1,
//           "created_at": "2024-03-03T06:20:53.000000Z",
//           "updated_at": "2024-03-03T06:20:53.000000Z",
//           "product_id": 100,
//           "have_variant": null,
//           "serial": "1234",
//           "color": null,
//           "warranties_count": 0
//       },
//       {
//           "id": 1994,
//           "name": "Super Mom Wipes gold",
//           "category_id": 199,
//           "others": null,
//           "barcode": 1709446853,
//           "regular_price": null,
//           "sub_category_id": 115,
//           "brand_id": 91,
//           "unit_id": 9,
//           "currency_id": null,
//           "image_path": null,
//           "image_path1": null,
//           "image_path2": null,
//           "purchase_price": 200,
//           "wholesale_price": 0,
//           "retails_price": 0,
//           "discount_type": null,
//           "discount": null,
//           "description": null,
//           "warrenty": null,
//           "user_id": 21,
//           "quantity": 1,
//           "created_at": "2024-03-03T06:20:53.000000Z",
//           "updated_at": "2024-03-03T06:20:53.000000Z",
//           "product_id": 100,
//           "have_variant": null,
//           "serial": "3455",
//           "color": null,
//           "warranties_count": 0
//       },
//       {
//           "id": 1995,
//           "name": "Super Mom Wipes royal blue",
//           "category_id": 199,
//           "others": null,
//           "barcode": 1709447756,
//           "regular_price": null,
//           "sub_category_id": 115,
//           "brand_id": 91,
//           "unit_id": 9,
//           "currency_id": null,
//           "image_path": null,
//           "image_path1": null,
//           "image_path2": null,
//           "purchase_price": 190,
//           "wholesale_price": 301,
//           "retails_price": 310,
//           "discount_type": null,
//           "discount": null,
//           "description": null,
//           "warrenty": null,
//           "user_id": 21,
//           "quantity": 1,
//           "created_at": "2024-03-03T06:35:56.000000Z",
//           "updated_at": "2024-03-03T06:35:56.000000Z",
//           "product_id": 100,
//           "have_variant": null,
//           "serial": "9876543",
//           "color": null,
//           "warranties_count": 0
//       },
//       {
//           "id": 2007,
//           "name": "Super Mom Wipes Wipes 23",
//           "category_id": 199,
//           "others": null,
//           "barcode": 1709458965,
//           "regular_price": null,
//           "sub_category_id": 115,
//           "brand_id": 91,
//           "unit_id": 9,
//           "currency_id": null,
//           "image_path": null,
//           "image_path1": null,
//           "image_path2": null,
//           "purchase_price": 1100,
//           "wholesale_price": 1200,
//           "retails_price": 1500,
//           "discount_type": null,
//           "discount": null,
//           "description": null,
//           "warrenty": null,
//           "user_id": 21,
//           "quantity": 1,
//           "created_at": "2024-03-03T09:42:45.000000Z",
//           "updated_at": "2024-03-03T09:42:45.000000Z",
//           "product_id": 100,
//           "have_variant": null,
//           "serial": "12345678",
//           "color": null,
//           "warranties_count": 0
//       }
//   ],
//   "qty": 2
// }