import React from 'react';
import DashedLine from '../../../components/DashedLine/DashedLine';
import DashedLine2 from '../../../components/DashedLine/DashedLine2';
import { formatPhoneNumber, numberToWords, formatPrice, invoiceCalculate } from '../../../utils/Common';
import Watermark from '../Watermark/Watermark';
import './PdfHtmlFor2mm.css';



import QRCode from 'react-qr-code';

const PdfHtmlFor2mm = props => {
  const {invoice, invoiceSetting, id, shop_logo} = props;
  const BASE_URL = process.env.REACT_APP_ROOT_URL;
  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  const day = String(invoiceDate.getDate()).padStart(2, '0');
  const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';


  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
  let totalQty = 0;
  let totalItems = 0;
  let totalPrice = 0;




  return (
    <div id="pdfConentAreas2" className="pdfConentAreas2">
    {/* // <div id={id} className={parentClassName+ ' '+ id}> */}
        <div id="invoice-detail-page"className="invoice-detail-page">
        <div className="invoice-header">
          <div className="invoice-logo invoice-logo-pdf flex justify-center">
            <img className="h-[100px]" src={shop_logo} alt={invoice.invoice_id} />

          </div>
          <DashedLine2 />
          <div className='flex justify-between '>
            <div>
              {invoice?.user_info?.outlet_name &&
                <h1 className='text-nunito  text-black  text-[7px] font-extrabold uppercase '>{invoice?.user_info?.outlet_name}</h1>
              }
              {invoice?.user_info?.address &&
                <p className="mt-1 mb-1 text-nunito text-[7px]  text-black  relative font-extrabold">{invoice?.user_info?.address}</p>
              }
              {invoice?.user_info?.phone &&
                <p className='text-nunito text-[7px] font-extrabold text-black '>Mobile: {formatPhoneNumber(invoice?.user_info?.phone, invoice?.user_info?.country_code)}</p>
              }
            </div>
            <div className="w-[50px]">
              <QRCode className="w-full object-contain" value={BASE_URL + '/invoice/' + invoice.invoice_id} size={30} />
            </div>
          </div>
        </div>
        <DashedLine />

        <div className="invoice-customer flex justify-between">
          <div>
            <p className='text-nunito text-[7px] font-extrabold text-black '>Date: {formattedDateTime}</p>
            <p className='text-nunito text-[7px] font-extrabold text-black '>Customer Id:  #{invoice.customer_id}</p>
          </div>
          <div>
            <p className='text-nunito text-[7px] font-extrabold text-black ' > Name: {invoice.customer_name} </p>
            <p className='text-nunito text-[7px] font-extrabold text-black '> Phone: {invoice.country_code}{invoice.customer_phone}</p>
          </div>
        </div>
        <div style={{ textAlign: 'center', margin: '3px auto'}}>
          <p className="text-nunito  text-black  text-[8px] p-2 font-extrabold uppercase ">Invoice No: {invoice.invoice_id}</p>
        </div>

        <div className="invoice-items">
          <table className="table">
            <thead>
              <tr className="text-nunito text-[7px]  text-black  relative font-extrabold">
                <th className='text-nunito text-[7px]  text-black   font-extrabold'>Name</th>
                <th className='text-nunito text-[7px]  text-black   font-extrabold'>Qty</th>
                <th className='text-nunito text-[7px]  text-black   font-extrabold'>Price</th>
                <th className='text-nunito text-[7px]  text-black  right-0 font-extrabold'>Total</th>
              </tr>
            </thead>

            <tbody>
              <Watermark watermark_text={invoiceSetting?.watermark_text} watermark_type={invoiceSetting?.watermark_type}></Watermark>
              {invoice.sales_details.map((item) => {
                totalQty += Number(item.qty);
                totalItems += 1;
                const subtotalPrice = formatPrice(item.qty * item.price);
                totalPrice += Number(subtotalPrice);
                return (
                  <tr key={item.id} className="text-nunito text-[7px]  text-black  relative font-extrabold">
                    <td>{item?.product_info?.name}<br/><span className='text-[13px]'>{item?.product_info?.serial}</span></td>
                    <td>{item.qty}</td>
                    <td>{item.price}</td>
                    <td className='text-nunito text-[7px]  text-black  right-0 font-extrabold uppercase '>{subtotalPrice}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</td>
                  </tr>
                );
              }
              )}
            </tbody>
          </table>
        </div>
        <DashedLine2 />

        <div className="invoice-summary">
          <p className='flex   justify-between text-nunito   text-black  relative font-extrabold'>
            <span className='text-nunito text-[8px]  text-black  relative font-extrabold'>TOTAL ITEMS</span>
            <span className='text-nunito text-[8px]  text-black  relative font-extrabold'>{totalItems}</span>
          </p>

          <p className='flex   justify-between text-nunito  text-black  relative font-extrabold '>
            <span className='text-nunito text-[8px]  text-black  relative font-extrabold'>SUB TOTAL</span>
            <span className='text-nunito text-[8px]  text-black  relative font-extrabold'>{formatPrice(totalPrice)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>

          <p className='flex   justify-between text-nunito   text-black  relative font-extrabold'>
            <span className='text-nunito text-[8px]  text-black  relative font-extrabold'>DISCOUNT</span>
            <span className='text-nunito text-[8px]  text-black  relative font-extrabold'>{formatPrice(invoice.discount ?invoice.discount : 0)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>
          <p className='flex   justify-between text-nunito   text-black  relative font-extrabold '>
            <span className='text-nunito text-[8px]  text-black  relative font-extrabold'>Vat {''}</span>
            <span className='text-nunito text-[8px]  text-black  relative font-extrabold'>{invoice.vat ? formatPrice(invoice.vat): 0.00}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>
          <p className='flex   justify-between text-nunito text-[7px]  text-black  relative font-extrabold '>
            <span className='text-nunito text-[8px]  text-black  relative font-extrabold'>Service Charge {''}</span>
            <span className='text-nunito text-[8px]  text-black  relative font-extrabold'>{invoice.tax ? formatPrice(invoice.tax): 0.00}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>
          <DashedLine2 />
          <p className='flex   justify-between text-nunito text-[7px]  text-black  relative font-extrabold '>
            <span className='text-nunito text-[8px]  text-black  relative font-extrabold'>Grand Totals</span>
            <span className='text-nunito text-[8px]  text-black  relative font-extrabold'>{formatPrice(invoiceCalculate(invoice).grandTotal)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>
        </div>
        <p className='flex   justify-between text-nunito text-[7px]  text-black  relative font-extrabold '>
          <span className='text-nunito text-[8px]  text-black  relative font-extrabold'>Paid Amount</span>
          <span className='text-nunito text-[8px]  text-black  relative font-extrabold'>{invoice.paid_amount && invoice.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(0)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
        </p>
        <p className='flex   justify-between text-nunito text-[7px]  text-black  relative font-extrabold '>
          <span className='text-nunito text-[8px]  text-black  relative font-extrabold'>Due Amount:</span>
          {/* <span className='text-nunito text-[8px]  text-black  relative font-extrabold'>{formatPrice(invoice.sub_total - invoice.paid_amount)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span> */}
          <span className='text-nunito text-[8px]  text-black  relative font-extrabold'>{formatPrice(invoiceCalculate(invoice).dueAmount) }{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
        </p>
        <hr></hr>
        <p className='flex flex-col   text-nunito text-[7px]  text-black  relative font-extrabold '>
          <p className='  text-[8px] font-extrabold uppercase  text-black font-nunito'>In word: {numberToWords(formatPrice(invoiceCalculate(invoice).grandTotal))} taka only</p>
        </p>
        <div style={{ textAlign: 'center', marginLeft:'auto',marginRight:'auto'}}>
          <p className="text-nunito  text-black  text-[8px] p-3 font-extrabold uppercase ">You saved {formatPrice(invoice.discount ?invoice.discount : 0)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'} in this purchase </p>
          <p className='text-nunito  text-black  text-[8px] pl-2 pr-2 font-extrabold uppercase '>Thanks for purchase</p>
          <p className='text-nunito  text-black  text-[7px] pl-2 pr-2 font-extrabold uppercase '>A Product of SQUAD INNOVATORS </p>
        </div>

      </div>

    </div>
  );
};

export default PdfHtmlFor2mm;