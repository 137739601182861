import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../context/context.js";
import "../products/productlist/index.scss";
import { formatNumber } from '../../utils/Common.js';
import { Link } from 'react-router-dom';
import { MdModeEdit } from 'react-icons/md';

const BASE_URL = process.env.REACT_APP_ROOT_URL;
const shop_logo = `${BASE_URL}/layoutlogo.svg`;
const MAX_MOBILE_SCREEN_WIDTH = 767;



const RecentlyAddedProductsList = (props) => {
    const { type, productsData, loadingCallback } = props;
    const {
        state
    } = useContext(AppContext);
    const { invoiceSetting } = state;
    const token = localStorage.getItem("token");
    const [filterItem, setFilterItem] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    const [isMobile, setIsMobile] = useState(false);

    const navigate = useNavigate();


    const getTopSaleProductlist = () => {
        loadingCallback(true);
        axios
        .get(`${process.env.REACT_APP_API_URL}top-sales`, {
            headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            if (response.data.success) {
                loadingCallback(false);
                setFilterItem(response.data.data.data);
                setFilteredProducts(response.data.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
            loadingCallback(false);
            if (error?.response?.status === 401) {
              localStorage.clear();
              localStorage.setItem('token', false);
              // Clear session and local storage
              localStorage.setItem('isPinVerify', false);

              navigate('/login');
            }
        });
    };

    useEffect(() => {
        getTopSaleProductlist();
        if(typeof window !== 'undefined' && document.documentElement.clientWidth <= MAX_MOBILE_SCREEN_WIDTH){
            setIsMobile(true);
        }


        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [isMobile]);

    const handleResize = () => {
        if (typeof window !== 'undefined' && document.documentElement.clientWidth <= MAX_MOBILE_SCREEN_WIDTH) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    // Calculate the number of products to show in the carousel

    const eclipse = (str, length) => {
        return str && str.length > 0 &&  str.length > length ? str.substring(0, length) + "..." : str;
    }
    const perPage = 10;
    const shouldRenderPagination = productsData?.total > perPage;


    return (
    <>


        {/* Pagination component */}
        {/* {shouldRenderPagination && (
          <Pagination
            currentPage={currentPage}
            lastPage={productsData?.last_page}
            onPageChange={onPageChange}
          />
        )} */}

        {productsData.map((item, index) => {
          return (
            <div
              className={`${
                item?.current_stock === 0
                  ? 'border-[#ca1830]'
                  : item.current_stock < 5 ? "border-[#FBBC05]":'border-[#18CA29]'
              } border-1 border-solid productlist-table`}
              key={item.id}
            >
              <div className="table-content flex-1">
                <div className="table-content-image w-full md:w-[160px] h-auto md:h-[160px]">
                  <img
                    className="w-[160px] h-[160px] md:w-[100%] md:max-h-[160px] md:min-h-[160px] m-auto md:m-0"
                    src={
                      item?.image_path
                        ? item.image_path
                        : shop_logo
                    }
                    alt="img"
                  />
                </div>
                <div className="table-content-data flex-1">
                  <div className="flex flex-row gap-[20px] mb-[12px]">
                    <div className="font-nunito">
                      <div className="text-[#2D3748] text-[16px] font-bold">
                        {item.name}
                      </div>
                      <div className="text-[#718096] text-[14px] font-extrabold">
                        #{item.barcode}
                      </div>
                    </div>
                    {/* <div className="border-l-[1px] border-solid border-#718096 p-l-[16px] flex items-center">
                      <span className="bg-[#FF6B02] rounded-[5px] text-[#FFF] text-[12px] font-extrabold px-2 pt-[2px] pb-[2px] ml-[20px]">
                        {item.discount +
                          ((item.discount_type ===
                          'Percentage' || item.discount_type ===
                          '2')
                            ? '%'
                            : invoiceSetting
                                ?.currency_info
                                ?.code || 'BDT')}
                      </span>
                    </div> */}
                  </div>
                  {/* old code */}
                  {/* <div className="table-content-data-details">
                    <div className="left table-content-data-left grid grid-cols-[100px,1fr] gap-x-[5px] gap-y-[2px]">
                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                        Category
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        :{' '}
                        {item.category
                          ? item.category.name
                          : null}
                      </div>

                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                        Sub Category
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        :{' '}
                        {item.sub_category
                          ? item.sub_category.name
                          : null}
                      </div>

                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                        Brand
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        :{' '}
                        {item.brands
                          ? item.brands.name
                          : null}
                      </div>

                      <div
                        className={`${
                          item?.current_stock === 0
                            ? 'text-[#F00000]'
                            : 'text-[#718096]'
                        }  text-[14px] font-medium font-nunito`}
                      >
                        {item?.current_stock === 0
                          ? 'Stock Out'
                          : 'Stock In'}
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        : {formatNumber(item.current_stock)}{' '}
                        {item.unit_id
                          ? item?.unit?.name
                          : null}
                      </div>
                    </div>
                    <div className="right table-content-data-left grid grid-cols-[100px,1fr] gap-x-[5px] gap-y-[2px]">
                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                        Unit
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        :{' '}
                        {item.unit_id
                          ? item?.unit?.name
                          : null}
                      </div>

                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                        Purchase price
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        :{' '}
                        {formatNumber(item.purchase_price)}{' '}
                        {invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info
                              ?.code
                          : 'BDT'}
                      </div>

                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                        Retail price
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        : {formatNumber(item.retails_price)}{' '}
                        {invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info
                              ?.code
                          : 'BDT'}
                      </div>

                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                      Vendor Name
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        :
                      </div>
                    </div>
                  </div> */}
                  {/* new code */}
                  <div className="flex flex-col lg:flex-row gap-3 w-full sm:gap-4 md:gap-5 lg:gap-6">
                            {/* first info */}
                            <div className="text-sm font-nunito flex-1 space-y-1">
                            {/* category */}
                            <div className="grid grid-cols-5">
                                  <div className="text-[#718096] font-medium col-span-2">Category</div>
                                  <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                                   <span>:</span>
                                   <span style={{wordBreak:"break-word"}}>{item.category ? item.category.name : null}</span>
                                   </div>
                                </div>
                            {/* sub category */}
                            <div className="grid grid-cols-5">
                                  <div className="text-[#718096] whitespace-nowrap font-medium col-span-2">Sub Category</div>
                                  <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                                   <span>:</span>
                                   <span style={{wordBreak:"break-word"}}>{item.sub_category ? item.sub_category.name : null}</span>
                                   </div>
                                </div>
                            {/* brand */}
                            <div className="grid grid-cols-5">
                                  <div className="text-[#718096] font-medium col-span-2">Brand</div>
                                  <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                                   <span>:</span>
                                   <span style={{wordBreak:"break-word"}}>{item.brands ? item.brands.name : null}</span>
                                   </div>
                                </div>
                            {/* stock */}
                            <div className="grid grid-cols-5">
                            <div className={`font-medium col-span-2 ${item?.current_stock === 0
                                                ? 'text-[#F00000]'
                                                : 'text-[#718096]'
                                                }`}
                                        >
                                            {item?.current_stock === 0
                                                ? 'Stock Out'
                                                : 'Stock In'}
                                </div>
                                <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                                   <span>:</span>
                                   <span style={{wordBreak:"break-word"}}>{(item.current_stock != "") ? formatNumber(item.current_stock) : '0'} {item.unit_id ? item?.unit?.name : null}</span>
                                   </div>
                            </div>
                            {/* serial */}
                            <div className="grid grid-cols-5">
                                  <div className="text-[#718096] font-medium col-span-2">Serial/IMEI</div>
                                  <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                                   <span>:</span>
                                   <span style={{wordBreak:"break-word"}}>{item?.serial ? item?.serial  : null}</span>
                                   </div>
                                </div>
                            </div>
                            {/* second info */}
                            <div className="text-sm font-nunito flex-1 space-y-1">
                            {/* unit */}
                            <div className="grid grid-cols-5">
                                  <div className="text-[#718096] font-medium col-span-2">Unit</div>
                                  <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                                   <span>:</span>
                                   <span style={{wordBreak:"break-word"}}>{item.unit_id ? item?.unit?.name : null}</span>
                                   </div>
                                </div>
                            {/* Purchase */}
                            <div className="grid grid-cols-5">
                                  <div className="text-[#718096] font-medium col-span-2">Purchase price</div>
                                  <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                                   <span>:</span>
                                   <span style={{wordBreak:"break-word"}}>{formatNumber(item.purchase_price)} {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : 'BDT'}</span>
                                   </div>
                                </div>
                            {/* Retail price */}
                            <div className="grid grid-cols-5">
                                  <div className="text-[#718096] font-medium col-span-2">Retail price</div>
                                  <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                                   <span>:</span>
                                   <span style={{wordBreak:"break-word"}}>{formatNumber(item.retails_price)} {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : 'BDT'}</span>
                                   </div>
                                </div>
                            {/* Wholesale price */}
                            <div className="grid grid-cols-5">
                                  <div className="text-[#718096] font-medium col-span-2">Wholesale price</div>
                                  <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                                   <span>:</span>
                                   <span style={{wordBreak:"break-word"}}>{formatNumber(item.wholesale_price)} {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : 'BDT'}</span>
                                   </div>
                                </div>
                             </div>
                         </div>
                </div>
              </div>
              <div className="flex flex-col mt-2 md:mt-0">
                <div className="flex flex-row gap-2">
                  {/* <button className='flex justify-center items-center bg-[#E6E6E6] text-gray-700 text-[12px] font-nunito rounded-[5px] px-3 pt-[3px] pb-[3px]' >
                      <MdModeEdit /> <Link className="hover:text-gray-700" to={`/recently-editproduct/${item.id}`}><span>Edit</span></Link>
                  </button> */}

                  <button className='flex justify-center items-center bg-[#407BFF] text-[#FFF] text-[12px] font-nunito rounded-[5px] px-3 pt-[6px] pb-[4px]' >
                      <Link className="whitespace-nowrap" to={`/recently-added/${item.id}`}><span>View Product</span></Link>
                  </button>
                </div>

                {/* <Link className="hover:text-gray-700" to={`/recently-editproduct/${item.id}`}><span>Edit</span></Link>
                <Link className="bg-[#407BFF] text-[12px] text-[#FFF] font-nunito font-bold rounded-[5px] px-[8px] py-[4px]" to={`/recently-added/${item.id}`}><span>View Product</span></Link> */}
              </div>
            </div>
          );
        })}
    </>
  );
}

export default RecentlyAddedProductsList