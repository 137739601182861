import React from "react";
import LogoAlignLeft from './bigLogo.png';
import { formatDate, formatPrice } from "../../utils/Common";
import CategoryStockHistoryItem from "../../components/Invoice/CategoryStockHistoryItem";
import CategoryStockItemForPdf from "../../components/Invoice/CategoryStockItemForPdf";
import { bool } from "prop-types";

const CategoryStockDetails = (props) => {
  const { activeTab, CStockData, fullApiData } = props;
  let createdDate, updatedDate;

  CStockData.map(el => {
    createdDate = el.created_at;
    updatedDate = el.updated_at;

  })



  return (
    <div
      id="catehorystockHistory"
      className="bg-white rounded-3xl font-nunito p-[26px] mb-20"

    >
      <div className="flex justify-between items-center">
        {/* outlet logo */}
        <div className="w-20 cursor-pointer -pb-[4px]">
          <img src={LogoAlignLeft} className="w-full h-full object-contain" />
        </div>
        {/* title and date */}
        <div className="text-[#000232] text-right">
          <h2 className="text-sm font-semibold">Category Stock Report</h2>
          <p className="text-xs">{formatDate(createdDate)} - {formatDate(updatedDate)}</p>
        </div>
      </div>
      {/* table head */}
      <div className="grid text-center mt-4 pt-[4px] pb-[18px] items-center text-xs font-bold bg-[#000232] text-white px-3 grid-cols-7 border-b-[1px] border-[#E7EBF4]">
      <div className="text-left">Category Name</div>
        <div className="text-center">Category Name</div>
        <div className="text-center">Product Stock Unit</div>
        <div className="text-center">Sale Unit</div>
        <div className="text-center">Purchase Unit</div>
        <div className="text-center">Due</div>
        <div className="text-right">Total Sale Amount</div>
      </div>
      {/* table body component */}
      {CStockData.map((cData, index) =>
        (cData?.completed &&
          (
          <CategoryStockItemForPdf
            CStockData={cData}
            tabActive={activeTab}
            key={index}
            index={index}
          />)
        )

      )}
      <hr className="border-[#000232] border-[1px]" />
      <div className="w-[38%] ml-auto text-xs font-[500]">
        <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Total Product</p>
          <p>{fullApiData?.total_product}</p>
        </div>
        <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Sold Category Unit</p>
          <p>{fullApiData?.sold_category_unit}</p>
        </div>
        <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Purchase Category Unit</p>
          <p>{formatPrice(fullApiData?.purchase_category_unit)}</p>
        </div>
        <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Total Customer</p>
          <p>{formatPrice(fullApiData?.total_customer)}</p>
        </div>
        <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Total Sold Amount</p>
          <p>{formatPrice(fullApiData?.total_sold_amount)}</p>
        </div>
        <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Total Due Amount</p>
          <p>{formatPrice(fullApiData?.total_due_amount)}</p>
        </div>
      </div>
    </div>
  );
};

export default CategoryStockDetails;