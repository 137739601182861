import React, { useContext, useState, useRef, useEffect } from "react";
import classNames from "classnames";
import axios from "axios";
import Input from "./Input";
import SelectBox from "./Select";

const ExporterSearchCarrier = (props) => {
  const {
    formId,
    loadingCallback,
    navigate,
    setOpen,
    setWarningMessage,
    setShowNestedModal,
    setNestedModalData,
    state,
    setShowModal,
    setExporterCarrier,
    updateFilteredCarrier,
    filteredCarrier,
    exporterId,
    exporterOrder,
    setAddedCarrier,
    addedCarrier,
    baseCurrency,
    productQty,
    receivedQty
  } = props;
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedExporterCarrier, setSelectedExporterCarrier] = useState(null);
  const [showNewCustomerForm, setShowNewCustomerForm] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: '',
    phone_no: 0,
    brand_id: 0,
    flight_date: '',
    received_qty: 0,
  });
  const perPage = 100;
  const token = localStorage.getItem("token");
  const getCarrierListData = async (searchKeyword = "") => {
    loadingCallback(true);
    const page1 = 1;
    if (searchKeyword === "") {
      const options = [];
      axios
        .get(`${process.env.REACT_APP_API_URL}carrier-list`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          loadingCallback(false);
          console.log(response);
          if (response.status === 200) {
            const customers = response?.data?.data?.data
              ? response.data.data.data
              : response.data.data;
            // Append other options if there are customers
            if (customers.length > 0) {
              const customerOptions = customers.map((item) => ({
                id: item.id,
                name: item.name,
                email: item?.email,
                phone_no: item?.phone_no,
                flight_date: item?.flight_date,
                received_qty: item.received_qty,
                nid_passport_no: item.nid_passport_no,
                address: item.address
              }));
              options.push(...customerOptions);
            }
          } else {
            console.log("Data Not Found!");
          }
          updateFilteredCarrier(options);
        })
        .catch((error) => {
          loadingCallback(false);
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem("token", false);
            // Clear session and local storage
            localStorage.setItem("isPinVerify", false);

            navigate("/login");
          }
        });
    } else {
      updateFilteredCarrier([]);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}search-carrier`,
          { keyword: searchKeyword },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          loadingCallback(false);
          const options = [];
          if (response.status === 200) {
            //setfilteredExporter(response.data.data.data);
            const customers = response?.data?.data?.data
              ? response.data.data.data
              : response.data.data;

            // Append other options if there are customers
            if (customers.length > 0) {
              const customerOptions = customers.map((item) => ({
                id: item.id,
                name: item.name,
                email: item?.email,
                phone_no: item?.phone_no,
                flight_date: item?.flight_date,
                received_qty: item.received_qty,
                nid_passport_no: item.nid_passport_no,
                address: item.address
              }));
              options.push(...customerOptions);
            }
          } else {
            console.log("Data Not Found!");
            const customerOptions = [];
            options.push(customerOptions);
          }
          updateFilteredCarrier(options);
        })
        .catch((error) => {
          loadingCallback(false);
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem("token", false);
            // Clear session and local storage
            localStorage.setItem("isPinVerify", false);

            navigate("/login");
          }
        });
    }
  };
  useEffect(() => {
    getCarrierListData(searchKeyword);
  }, [searchKeyword]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData1 = new FormData(event.target);
    const formObject = {};
    formData1.forEach((value, key) => {
      formObject[key] = value;
    });
    if (formObject.carrier_id) {
      const carrierId = parseInt(formData.carrier_id);
      const selectedCarrier = filteredCarrier.find(
        (customer) => customer.id === carrierId
      );
      setSelectedExporterCarrier(selectedCarrier);
      if (selectedCarrier) {
        setNestedModalData({
          title: "Add Carrier Info",
          type: "exporter_add_carrier_info",
          setData: setSelectedExporterCarrier,
          setAddedCarrier: setAddedCarrier,
          exporterCarrier: selectedCarrier,
          carrierId: carrierId,
          exporterOrder: exporterOrder,
          productQty: productQty,
          receivedQty: receivedQty,
        });
        setShowNestedModal(true);
      }
      setShowNewCustomerForm(false);
    } else {
      console.error("Form validation failed.");
    }
  };
  const handleInputChange = (inputValue) => {
    if (inputValue !== "") {
      setSearchKeyword(inputValue);
    }
  };
  const handleSelectChange = (name, option) => {
    const { value, label } = option;
    if (value === "") {
      setSelectedExporterCarrier(null);
      return;
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    if (selectedExporterCarrier) {
      setNestedModalData({
        title: "Add Carrier Info",
        type: "exporter_add_carrier_info",
        setData: setSelectedExporterCarrier,
        setAddedCarrier: setAddedCarrier,
        exporterCarrier: selectedExporterCarrier,
        exporterId: exporterId,
        exporterOrder: exporterOrder,
        productQty: productQty,
        receivedQty: receivedQty,
      });
      setShowNestedModal(true);
    }
  }, [selectedExporterCarrier]);
  useEffect(() => {
    if (addedCarrier) {
      setShowModal(false);
    }
  }, [addedCarrier]);
  const handleShowAddCarrierExporterModal = () => {
    setNestedModalData({
      title: "Add Carrier Info",
      type: "exporter_add_carrier_info",
      setData: setSelectedExporterCarrier,
      setAddedCarrier: setAddedCarrier,
      exporterId: exporterId,
      exporterOrder: exporterOrder,
      productQty: productQty,
      receivedQty: receivedQty,
    });
    setShowNestedModal(true);
  };

  return (
    <>
      <form
        method="get"
        className="col-sm-12 min-h-[30vh]"
        data-autosubmit="false"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-row gap-3 mt-3 mb-6">
          <div className="w-[90%]">
            <SelectBox
              name="carrier_id"
              options={filteredCarrier}
              value={formData?.carrier_id ? formData?.carrier_id : ""}
              //onChange={handleCustomerSelect}
              transparentBorder={false}
              onInputChangeData={handleInputChange}
              onChange={handleSelectChange.bind(this, "carrier_id")}
              placeholder={"Search Carrier"}
              className={classNames("w-full", "rounded-[15px]", "bg-white")}
            />
          </div>
          <label htmlFor="nested_modal" onClick={handleShowAddCarrierExporterModal}>
            <i className="fa-solid fa-plus font-semibold border border-red-50 text-2xl px-3 py-2 rounded-[10px] cursor-pointer"></i>
          </label>
        </div>

        <div className="pt-6">
          <input
            type="submit"
            value="Next"
            className="btn btn-primary w-full text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF]"
          />
        </div>
      </form>
    </>
  );
};

export default ExporterSearchCarrier;
