import { useEffect, useState } from "react";
import axios from "axios";
import { get, post } from "../services/apiService";
import SelectBox from "./Select";
import {
  uploadFile,
  uploadFileMultipleInput,
  uploadFileMultipleInputVariants,
  validateData
} from '../utils/Common';

const SubCategoryForm = (props) => {

  const {
    formId,
    navigate,
    loadingCallback,
    setWarningMessage,
    setShowModal,
    categoryId

  } = props;
  const [category, setCategory] = useState([]);
  const [scategoryId, setCategoryId] = useState(categoryId);
  const [inputErors, setInputErrors] = useState({});
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const token = localStorage.getItem("token");

  const getCategory = () => {
    loadingCallback(true)
    //get("category?page=1&limit=10", token)
    get("get-all-category", token)
      .then((response) => {
        loadingCallback(false)
        if (response.success) {
          setCategory(response.data);
        }
      })
      .catch((error) => {
        loadingCallback(false)
        console.log(error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {


        // Fetch other data (category, subcategory, brand, currency, units)
        const categoryPromise = getCategory();

        // Wait for all promises to resolve
        await Promise.all([
          categoryPromise
        ]);

        // Rest of your code after all data is fetched
      } catch (error) {
        // Handle errors if needed
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const validateForm = (inputName = "Brand") => {
    let valid = true;
    const newInputErrors = { ...inputErors };
    if (validateData(name, inputName)) {
      newInputErrors.name = validateData(name, inputName);
      valid = false;
    }
    if (inputName === "Sub Category") {
      if (categoryId === "" || categoryId === 0) {
        newInputErrors.categoryId = `Category is required`;
        valid = false;
      }
    }

    setInputErrors(newInputErrors);
    return valid;
  };

  function handleSubmitSubcategory(e) {
        e.preventDefault();
        // Validate the category selection
        // if (categoryId === '') {
        //     setErrorFlashMessage('Please select a category.');
        //     //setError('Product update failed');
        //     return; // Do not proceed with the form submission if there's an error
        // }
        if (validateForm("Sub Category")) {
          loadingCallback(true);
          axios
          .post(
              `${process.env.REACT_APP_API_URL}save-sub-category`,
              { category_id: categoryId, name: name, description: description },
              {
                  headers: {
                      authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                  },
              }
          )
          .then((response) => {
              //console.log(response.data.success);
              loadingCallback(false);
              if(response.data.success)
              {
                  setName("");
                  setDescription("");
                  getCategory();
                  setShowModal(false);
              }
              else
              {
                  setName("");
                  setDescription("");
              }
          })
          .catch((error) => {
              console.log(error);
              loadingCallback(false);
              if (error?.response?.status === 401) {
                localStorage.clear();
                localStorage.setItem('token', false);
                // Clear session and local storage
                localStorage.setItem('isPinVerify', false);

                navigate('/login');
              }
          });
        }

    }

  const hideNestedModal = () => {
    setShowModal(false);
  };

  const handleSelectChange = (name, option) => {

    const { value, label } = option;
    const selectedValue = value;

    if (name === 'category_id') {
        setCategoryId(selectedValue);
        setInputErrors({ ...inputErors, categoryId: '' });
    } else {
        setCategoryId('');
        setInputErrors({ ...inputErors, categoryId: '' });
    }
    // Check if the selected value is the default (disabled) option
    if (selectedValue === '') {
        //setError('Please select a '+name+'.');
        setCategoryId('');
    } else {
        //setError('');
    }
  };

  return (
    <form
      method="get"
      className="col-sm-12 min-h-[10vh]"
      data-autosubmit="false"
      autoComplete="off"
      onSubmit={handleSubmitSubcategory}
    >
      <div className="mb-3 relative">
        <SelectBox
          name="category_id"
          options={category}
          value={scategoryId}
          //onChange={(e) => handleSelectChange('category', 'Category', e)}
          onChange={handleSelectChange.bind(this, "category_id")}
          //style={{ maxHeight: '300px', overflowY: 'scroll' }}
          // error={formErrors.category_id}
          // setFormErrors={setFormErrors}
          placeholder="Select Category"
          className="px-[0] select w-full rounded-2xl mb-[15px] bg-white"
        />
        <label className="absolute font-lato text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
          Category
        </label>
      </div>
      {inputErors.categoryId
        ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.categoryId}</p>
        : null}

      <div className="mb-3 relative">
        <input
          type="text"
          id="name"
          name="name"
          onChange={(e) => {
              const val = e.target.value;
              setName(val);
              setInputErrors({ ...inputErors, name: null });
          }}
          className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
          placeholder="Name"
        />
        <label className="absolute font-lato text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
          Subcategory
        </label>
      </div>
      {inputErors.name
        ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
        : null}

      <div className="mb-3 relative">
        <input
          type="text"
          id="description"
          name="description"
          value={description}
          onChange={(e) => {
              const val = e.target.value;
              setDescription(val);
          }}
          className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
          placeholder="Description"
        />
        <label className="absolute font-lato text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
          Subcategory Description
        </label>
      </div>

      <div className="flex flex-row gap-3">
        <input
          onClick={hideNestedModal}
          type="button"
          value="Back"
          className="btn w-[48%] text-[#034AFF] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none"
        />
        <input
          type="submit"
          value="Complete"
          className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF]"
        />
      </div>
    </form>
  );
};

export default SubCategoryForm;
