import html2pdf from 'html2pdf.js';
import React, { useState, useRef, useEffect, useContext } from "react";
import SalesReturnDetail from '../../components/ReturnDetail/SalesReturnDetail';

import ContentLayout from '../../layout/ContentLayout'

import { useParams, useNavigate } from 'react-router-dom';
import { get, post } from "../../services/apiService";
import { AppContext } from "../../context/context";


const BASE_URL = process.env.REACT_APP_ROOT_URL;

const SalesReturnDetailPage = props => {

  const { id } = useParams(); // Get the id parameter from the URL

  const {
      state,
  } = useContext(AppContext);
  const { invoiceSetting, paperSize } = state;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const invoice = {
    logo: "https://example.com/logo.png",
    name: "Acme Corporation",
    number: "INV-12345",
    date: "2023-10-04",
    created_at: "2023-10-04",
    invoice_id: "INV-12345",
    customer_name: "John Doe",
    customer_phone: "123213123",
    sales_details: [
      {
        id: 1,
        name: "Widget A",
        qty: 2,
        price: 10,
        total: 20,
        product_info: {
          name: "Widget A",
          price: 10
        }
      },
      {
        id: 2,
        name: "Widget B",
        qty: 1,
        price: 50,
        total: 50,
        product_info: {
          name: "Widget B",
          price: 50
        }
      },
      {
        id: 3,
        name: "Widget C",
        qty: 1,
        price: 100,
        total: 100,
        product_info: {
          name: "Widget C",
          price: 100
        }
      }
    ],
    sub_total: 170,
    tax: 25.5,
    paid_amount: 195.5,
    vat: 15
  };
  const [invoiceData, setInvoiceData] = useState(null);

  useEffect(() => {
      // Fetch invoice data based on the ID and populate
      fetchInvoiceData(id);
  }, [id]);

  const fetchInvoiceData = (invoiceId) => {
    // Make an API request to fetch product data by ID
    // Once data is retrieved, update the form fields with the data
    get(`sale_return-invoice-details/${id}`, token)
      .then((response) => {
        const invoiceD = response.data; // Replace with your API response data

        setInvoiceData(invoiceD);
      })
      .catch((error) => {
        console.log(error);
        // if (error?.response?.status === 401) {
        //   localStorage.setItem('token', '');
        //   localStorage.setItem('token', '');
        //   // Clear session and local storage
        //   localStorage.setItem('isPinVerify', false);
        //   navigate('/login');
        // }
      });
  };

  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  const monthName = invoiceDate.toLocaleString("en-US", { month: "long" });
  const day = String(invoiceDate.getDate()).padStart(2, '0');
  const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';


  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day} ${monthName} ${year} ( ${hours}:${minutes}${ampm} )`;

  const shop_logo = invoiceSetting?.shop_logo ? invoiceSetting?.shop_logo : `${BASE_URL}/layoutlogo.svg`;
  //const shop_logo = `${BASE_URL}/layoutlogo.svg`;
  let totalQty = 0;
  let totalDiscount = 0;


  console.log("============");
  console.log(invoiceData);
  return (
  <>
    <ContentLayout>
      { invoiceData && invoiceData?.invoice_id && (
        <SalesReturnDetail type='salesReturn' paperSize={paperSize} invoice={invoiceData} invoiceSetting={invoiceSetting ? invoiceSetting : null} />
      )}

    </ContentLayout>
    </>
  );
};



export default SalesReturnDetailPage;
