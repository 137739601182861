import React, { useState } from 'react';
import "./ProductSaleGraph.css";

import ReactApexChart from 'react-apexcharts';

const ProductSaleGraph = ({ revenueProductData, allFetchedData}) => {


  return (
    <>
      <div className="chart-info w-full">
        <div className="chart-info-title">
          <p className='text-[24px]'>Product Sale Chart</p>
        </div>
      </div>
      <div id="category-chart">
        <ReactApexChart options={revenueProductData.options} series={revenueProductData.series} type="area" height={300} />
      </div>
    </>
  );
};

export default ProductSaleGraph;
