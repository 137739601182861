import React from 'react';
import LogoAlignLeft from './bigLogo.png';
import { formatDate, formatPrice } from "../../utils/Common";
import MethodCreditReportItem from './MethodCreditReportItem';
import MethodDebitReportItem from './MethodDebitReportItem';
const MethodReport = (props) => {
  const { activeTab, balanceData, fullApiData, paymentList, paymentAccountList } = props;
  let createdDate, updatedDate;
  balanceData.map(bd => {
    createdDate = bd.created_at;
    updatedDate = bd.updated_at;
  })
  let totalCredit = 0, totalDebit = 0;
  fullApiData?.credit?.map(cr => {
    totalCredit += cr?.payment_amount;
  })
  fullApiData?.debit?.map(cr => {
    totalDebit += cr?.payment_amount;
  })
  return (
    <>
      <div
        className="bg-white rounded-3xl font-nunito p-[26px] mb-20"
      >
        <div className="flex justify-between items-center">
          {/* outlet logo */}
          <div className="w-20 cursor-pointer -pb-[4px]">
            <img src={LogoAlignLeft} className="w-full h-full object-contain" />
          </div>
          {/* title and date */}
          <div className="text-[#000232] text-right">
            <h2 className="font-semibold">Method Report</h2>
            <p className="text-xs">{formatDate(createdDate)} - {formatDate(updatedDate)}</p>
          </div>
        </div>
        {/* credit report */}
        <div className='mt-3'>
          <p className='text-md'>Credit</p>
          {/* table head */}
          <div className="border-b-[1px] text-xs px-2 border-solid last:border-none border-[#E7EBF4] -pt-[4px] pb-[13px] font-nunito grid grid-cols-3 items-center">
            <div className="text-left text-[14px] col-span-1">Account Name</div>
            <div className="text-center text-[14px]  col-span-1">Account Number</div>
            <div className="text-right text-[14px]  col-span-1">Amount</div>
          </div>
          {/* table body component */}
          {fullApiData?.credit?.map((cr, index) => {
            return (<>
              <MethodCreditReportItem creditData={cr} key={index} />
            </>)

          })}

          <div className='flex justify-end px-2 gap-20'><p className=''>Total Amount</p><span className='text-center'>{formatPrice(totalCredit)}</span></div>
        </div>
        {/* Debit report */}
        <div>
          <p className='text-md'>Debit</p>
          <div className="border-b-[1px] text-xs px-2 border-solid last:border-none border-[#E7EBF4] -pt-[4px] pb-[13px] font-nunito grid grid-cols-3 items-center">
            <div className="text-left text-[14px] col-span-1">Account Name</div>
            <div className="text-center text-[14px]  col-span-1">Account Number</div>
            <div className="text-right text-[14px]  col-span-1">Amount</div>
          </div>
          {/* table body component */}
          {fullApiData?.debit?.map((cr, index) => {
            return (<>
              <MethodDebitReportItem creditData={cr} key={index} />
            </>)

          })}

          <div className='flex justify-end px-2 gap-20'><p className=''>Total Amount</p><span className='text-center'>{formatPrice(totalCredit)}</span></div>
        </div>

        {/* Mobile Bank report */}
        <div>
          <p className='text-md'>Mobile Banking History</p>
          {/* table head */}
          <div className="border-b-[1px] text-xs px-2 border-solid last:border-none border-[#E7EBF4] -pt-[4px] pb-[13px] font-nunito grid grid-cols-3 items-center">
            <div className="text-left text-[14px] col-span-1">Account Name</div>
            <div className="text-center text-[14px]  col-span-1">Account Number</div>
            <div className="text-right text-[14px]  col-span-1">Amount</div>
          </div>
          {/* table body component */}
          {fullApiData?.balances?.map((cr, index) => {
            return (<>
              <MethodDebitReportItem creditData={cr} key={index} />
            </>)

          })}

          <div className='flex justify-end px-2 gap-20'><p className=''>Total Amount</p><span className='text-center'>{formatPrice(totalCredit)}</span></div>
        </div>
      </div>
    </>
  );
};

export default MethodReport;