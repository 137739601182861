import classNames from 'classnames';
import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import { useFlashMessage } from "../context/FlashMessageContext";
//import Select2 from "react-chosen";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { post } from "../services/apiService";
import { useNavigate } from 'react-router-dom';
import FlagIcon from "./FlagIcon";
import { faToggleOn } from '@fortawesome/free-solid-svg-icons';
import MemberShipLogo from './Group 7109.png';
import { getDialCodeByCountryCode } from '../utils/Common';
import { FlashMessage } from '../utils/FlashMessage';
import { ToastContainer, toast } from 'react-toastify';

const NewWholeSalerForm = props => {
  const {
    loadingCallback,
    setWholeSaler,
    setWarningMessage,
    state,
    setShowModal,
    setWholeSalerData
  } = props;
  // Gneral Focus Hook
  const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

    return [htmlElRef, setFocus]
  }
  const [inputRef, setInputFocus] = useFocus();

  const { countryList } = state;
  const [country, setCountry] = useState('');
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState('');
  const [cityList, setCityList] = useState([]);
  const { getFlashMessageStyle, setSuccessFlashMessage, flashMessage, setErrorFlashMessage } = useFlashMessage(); // Retrieve the dispatch function from your state management library
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedWholeSaler, setSelectedWholeSaler] = useState(null);
  const [wholeSalerOptions, setWholeSalerOptions] = useState([]);
  const [showNewWholeSalerForm, setShowNewWholeSalerForm] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isValid, setIsValid] = useState(true);
  const navigate = useNavigate();
  const page = 1;
  const perPage = 20;
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (selectedWholeSaler && selectedWholeSaler.country) {
      setCountry(selectedWholeSaler.country);
      getDistrictList(selectedWholeSaler.country);
    } else {
      getDistrictList(country);
    }

  }, [country, selectedWholeSaler]);

  const getDistrictList = (country) => {
    post("get-district-by-country", { country_id: country }, token)
      .then((response) => {
        if (response.success) {
          setDistrictList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCityList = (district) => {
    setDistrict(district);
    post("get-cities-by-district", { district_id: district }, token)
      .then((response) => {
        if (response.success) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };











  const handleValidation = (formData) => {
    const errors = {};
    let validate = true
    // if (formData.wholesaler_email === '') {
    //   errors.wholesaler_email = 'Email is required.';
    //   setIsValid(false);
    //   validate = false;
    // }
    if (formData.wholesaler_email !== '') {
      if (formData.wholesaler_email.indexOf('@') === -1) {
        errors.wholesaler_email = 'Email is not valid.';
        setIsValid(false);
        validate = false;
      }  else {
        //Email address cannot have only numbers before @
        const emailParts = formData.wholesaler_email.split('@');
        if (emailParts[0].match(/^[0-9]+$/) !== null) {
          errors.wholesaler_email = 'Email is not valid.';
          setIsValid(false);
          validate = false;
        }
      }
    }
    if (formData.wholesaler_name === '') {
      errors.wholesaler_name = 'wholeSaler name is required.';
      setIsValid(false);
      validate = false;

    }

    if (formData.wholesaler_phone === '') {
      errors.wholesaler_phone = 'Phone Number is required.';
      setIsValid(false);
      validate = false;
    }
    setFormErrors(errors);
    if (!validate) {
      setErrorFlashMessage('Please fill all the required fields.');
    }

    return validate;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // setwholeSalerData('ok');
    // return;
    const formData = new FormData(event.target);
    const formObject = {};

    formData.forEach((value, key) => {
      formObject[key] = value;
    });
    if (!selectedWholeSaler) {
      formObject.wholeseller_id = 0;
    }


    if (handleValidation(formObject)) {
      const WholeSalerData = {
        name: formObject.wholesaler_name ? formObject.wholesaler_name : null,
        email: formObject.wholesaler_email ? formObject.wholesaler_email : null,
        country_code: formObject.country_code ? formObject.country_code : null,
        mobile_number: formObject.wholesaler_phone ? formObject.wholesaler_phone : null,
        address: formObject.wholesaler_address ? formObject.wholesaler_address : null,
        country: formObject.country_id ? Number(formObject.country_id) : null,
        district: formObject.district_id ? Number(formObject.district_id) : null,
        city: formObject.city_id ? Number(formObject.city_id) : null,
        zipcode: formObject.wholesaler_zip ? Number(formObject.wholesaler_zip) : null,
        nid: formObject.nid ? Number(formObject.nid) : null,
      };
      createWholeSaler(WholeSalerData);

    } else {
      console.error('Form validation failed.');
    }

  };

  const createWholeSaler = (formData) => {
    loadingCallback(true);
    // setWholeSalerData(formData);
    // setWholeSaler(formData);
    post("save-whole-seller", formData, token)
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          console.log("wholesaler data", response.data);
          setWholeSalerData(response.data);
          setWholeSaler(response.data);
          //setShowModal(false);
          // setSuccessFlashMessage('WholeSaler saved successfully');
          FlashMessage('WholeSaler saved successfully','success');
          //setShowNewWholeSalerForm(false);
        } else {
          console.log("WholeSaler insertion failed");

          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.setItem('token', '');
          localStorage.setItem('token', '');
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
        console.log(error);
      });
  }


  const handleErrorInputChange = (fieldName, value) => {
    if (value !== '') {
      //formErrors[fieldName] = null;
      // unset formErrors by fieldName
      const updatedFormErrors = { ...formErrors };
      delete updatedFormErrors[fieldName];
      setFormErrors(updatedFormErrors);
    }

  }


  return (
    <form
      method="get"
      className="col-sm-12 text-[#91919F]"
      data-autosubmit="false"
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <ToastContainer />
      <div className='flex gap-3'>
        <div className="mb-3">
          <input
            type="text"
            id="wholesaler_name"
            name="wholesaler_name"
            className={classNames(
              'form-control',
              'rounded-[15px]',
              'p-3',
              'bg-white'

            )}
            placeholder="Wholesaler Name"
            ref={inputRef}
            onChange={(e) => {
              handleErrorInputChange('wholesaler_name', e.target.value)
            }}
          />
          {formErrors && formErrors.wholesaler_name ? <p className="error-message text-red-500">{formErrors.wholesaler_name}</p> : null}
        </div>
        <div className="mb-3">
          <input
            type="text"
            id="wholesaler_email"
            name="wholesaler_email"
            className={classNames(
              'form-control',
              'rounded-[15px]',
              'p-3',
              'bg-white'

            )}
            placeholder="Wholesaler Email"
            //ref={inputRef}
            onChange={(e) => {
              //delete formErrors.wholesaler_email;
              handleErrorInputChange('wholesaler_email', e.target.value)
            }}
          />
          {formErrors && formErrors.wholesaler_email ? <p className="error-message text-red-500">{formErrors.wholesaler_email}</p> : null}
        </div>
      </div>
      <div className="mb-3">
        <div className="flex ">
          <select
            id="country_code"
            name="country_code"
            className={classNames(
              'w-2/4',
              'rounded-[15px]',
              'p-3',
              'bg-white',
              'mr-2',

            )}
            placeholder='Select'
            style={{ border: "1px solid #ccc" }}
          >

            <option value="">Select</option>
            {countryList.map((country) => (
              <option key={country.id} className="text-[14px]" value={getDialCodeByCountryCode(country.code)}>
                <FlagIcon countryCode={country.code} />{getDialCodeByCountryCode(country.code)}
              </option>
            ))}
          </select>
          <input
            type="text"
            id="wholesaler_phone"
            name="wholesaler_phone"
            className={classNames(
              'form-control',
              'rounded-[15px]',
              'p-3',
              'bg-white',
              'w-4/5'

            )}
            placeholder=" Phone Number"
            ref={inputRef}
            onChange={(e) => {
              handleErrorInputChange('wholesaler_phone', e.target.value)
            }}
          />
        </div>
        {formErrors && formErrors.wholesaler_phone ? <p className="error-message text-red-500">{formErrors.wholesaler_phone}</p> : null}
      </div>

      {/* {country select box} */}
      <div className="mb-3">
        <select
          id="country_id"
          name="country_id"
          className={classNames(
            'w-full',
            'rounded-[15px]',
            'p-3',
            'bg-white'
          )}
          style={{ border: "1px solid #ccc" }}
          onChange={e => setCountry(e.target.value)}
        >
          <option value="">Select Country</option>
          {countryList.map((country) => (
            <option key={country.id} value={country.id}>
              {country.name}
            </option>
          ))}
        </select>
      </div>
      <div className='flex gap-3'>
        <div className="mb-3 flex-1">
          <input
            type="text"
            id="wholesaler_address"
            name="wholesaler_address"
            className={classNames(
              'form-control',
              'rounded-[15px]',
              'pt-3',
              'pb-3',
              'bg-white'
            )}
            placeholder="Wholesaler Address"
            ref={inputRef}
          />
        </div>
        <div className="mb-3 flex-1">
          <select
            id="district_id"
            name="district_id"
            className={classNames(
              'rounded-[15px]',
              'p-3',
              'bg-white',
              'mr-2'
            )}
            style={{ border: "1px solid #ccc" }}
            onChange={e => getCityList(e.target.value)}
          >
            <option value="">Branch District</option>
            {districtList.map((district) => (
              <option key={district.id} value={district.id}>
                {district.name}
              </option>
            ))}
          </select>
        </div>
      </div>




      <div className='flex gap-3'>
        <div className="mb-3 flex-1">
          <select
          id="city_id"
          name="city_id"
          className={classNames(
            'w-full',
            'rounded-[15px]',
            'p-3',
            'bg-white'
          )}
          style={{ border: "1px solid #ccc" }}
        >
          <option value="">Select City</option>
          {cityList.map((city) => (
            <option key={city.id} value={city.id}>
              {city.name}
            </option>
          ))}
        </select></div>
        <div className='mb-3 flex-1'>
          <input
            type="text"
            id="wholesaler_zip"
            name="wholesaler_zip"
            className={classNames(
              'form-control',
              'rounded-[15px]',
              'p-3',
              'bg-white',

            )}
            placeholder="Zip Code"
            ref={inputRef}
          />
        </div>
      </div>


      {/* <div className='flex justify-between items-center'>
        <div className='flex gap-1 mb-3'>
        <div className='w-[45px] h-[45px]'><img src={MemberShipLogo} alt="MemberShipLogo" />
        </div>
        <div className='font-nunito text-[#102048]'><h1 className='font-semibold text-[18px]'>Membership</h1> <p className='text-[12px]'>Members Can addittional discount and ...</p></div>
        </div>
        <div  className="w-[40px] h-[25px] text-[#BF9950]  "><FontAwesomeIcon icon={faToggleOn} className="text-3xl"/></div>
      </div> */}

      <div className="button-disabled">
        <button
          htmlFor='my_modal_wholesaler'
          type="submit"
          className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
        > Save
        </button>
      </div>

    </form>
  );
};

export default NewWholeSalerForm;
