import { useNavigate } from "react-router-dom";

import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import axios from "axios";
import { BeatLoader } from 'react-spinners';

export const useMountEffect = (fun) => useEffect(fun, []);
const BASE_URL = process.env.REACT_APP_ROOT_URL;
function ConfirmPin() {
  const navigate = useNavigate();
  const [warningMessage, setWarningMessage] = useState(false);

  const token = localStorage.getItem("token");
  const newPin = localStorage.getItem("newPin");

  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
    if (!newPin) {
      navigate("/set-pin");
    }
  }, []);

  // Gneral Focus Hook
  const UseFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };

  // const isBoolean = param => typeof param === 'boolean';

  const [input1Val, setInput1Val] = useState("");
  const [input1Ref, setInput1Focus] = UseFocus();

  const [input2Val, setInput2Val] = useState("");
  const [input2Ref, setInput2Focus] = UseFocus();

  const [input3Val, setInput3Val] = useState("");
  const [input3Ref, setInput3Focus] = UseFocus();

  const [input4Val, setInput4Val] = useState("");
  const [input4Ref, setInput4Focus] = UseFocus();

  const [input5Val, setInput5Val] = useState("");
  const [input5Ref, setInput5Focus] = UseFocus();

  const [input6Val, setInput6Val] = useState("");
  const [input6Ref, setInput6Focus] = UseFocus();

  const [completeBtnRef, setCompleteFocus] = UseFocus();
  const [disabel, setDisable] = useState(false);
  const [areAllInputsNumeric, setAreAllInputsNumeric] = useState(false);
  const checkIfAllNumeric = () => {
    const inputValues = [
      input1Val,
      input2Val,
      input3Val,
      input4Val,
      input5Val,
      input6Val,
    ];
    const areAllNumeric = inputValues.every((value) =>
      /^[0-9]*$/.test(value)
    );
    const areNotEmpty = inputValues.every((value) => value !== '');
    const isFormValid = areAllNumeric && areNotEmpty;
    setAreAllInputsNumeric(isFormValid);
  };
  useEffect(() => {
    checkIfAllNumeric();
  }, [input1Val, input2Val, input3Val, input4Val, input5Val, input6Val]);

  useMountEffect(setInput1Focus, setInput2Focus);
  function handleSubmit(e) {
    e.preventDefault();
    setInput1Val("");
    setInput2Val("");
    setInput3Val("");
    setInput4Val("");
    setInput5Val("");
    setInput6Val("");

    const confirmPin =
      input1Val + input2Val + input3Val + input4Val + input5Val + input6Val;
    if (newPin !== confirmPin) {
      //Your confirm pin not match with you pin
      setWarningMessage(true);
      const timer = setTimeout(() => {
        setWarningMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    } else {
      console.log(token);
      loadingCallback(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}set-pin`,
          { pin: confirmPin },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          loadingCallback(false);
          localStorage.setItem("isPinVerify", true);
          localStorage.removeItem("newPin");
          navigate("/dashboard");
        })
        .catch((error) => {
          console.log(error);
          loadingCallback(false);
          localStorage.setItem("set-pin", false);
          // localStorage.removeItem("newPin");
          navigate("/login");

          // if (error?.response?.status === 401) {
          //   setWarningMessage(true);
          //   const timer = setTimeout(() => {
          //     setWarningMessage(false);
          //   }, 3000);
          //   return () => clearTimeout(timer);
          // }
        });
    }
    // navigate("/dashboard");
  }
  return (
    <section style={{ background: "#000" }}>
      <div className="securitypin-container">
        <div className="securitypin-left">
          <img
            src={`${BASE_URL}/assets/registration/webp/securitypinleft.webp`}
            alt="securitypinimage"
            className="img-fluid"
          />
        </div>
        <div className="securitypin-right ">
          <div className="securitypin-right-card securitypincard">
            <div className="securitypin-log-img">
              <img
                src={`${BASE_URL}/assets/registration/webp/seclogo.webp`}
                alt="logo"
                className="img-fluid"
              />
            </div>
            <div className="securitypin-form-main">
              <p>
                Re-type your PIN For <br></br> Confirm
              </p>
            </div>
            <div className="securitypinform-console">
              <p>
                Confirm pin for any kind of unnecessary
                <br></br>activity from other people{" "}
              </p>
            </div>
            {warningMessage && (
              <div className="warning-message">
                Your confirm pin not match with you pin!
              </div>
            )}
            {loading && (

              <div className="custom-loader-logo">
                {/* <BeatLoader color={"#123abc"} size={20} /> */}
                <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
              </div>
            )}

            <div className="otp-form-main">
              <form
                method="get"
                className="digit-group input-sm col-sm-12"
                data-group-name="digits"
                data-autosubmit="false"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <div className="securitypin-box">
                  <input
                    className="m-2 text-center form-control otp-rounded"
                    inputMode="numeric"
                    type="text"
                    id="first"
                    name="digit1"
                    maxLength="1"
                    onChange={(e) => {
                      const val = e.target.value;
                      setInput1Val(val);

                      if (val.length === 1) setInput2Focus();
                    }}
                    ref={input1Ref}
                    value={input1Val}
                  />
                  <input
                    className="m-2 text-center form-control otp-rounded"
                    inputMode="numeric"
                    type="text"
                    id="second"
                    name="digit2"
                    maxLength="1"
                    onChange={(e) => {
                      const val = e.target.value;
                      setInput2Val(val);

                      if (val.length === 1) setInput3Focus();
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 8 || e.key === "Backspace") {
                        setInput2Val("");
                        setInput1Focus();
                      }
                    }}
                    ref={input2Ref}
                    value={input2Val}
                  />
                  <input
                    className="m-2 text-center form-control otp-rounded"
                    inputMode="numeric"
                    type="text"
                    id="third"
                    name="digit3"
                    maxLength="1"
                    onChange={(e) => {
                      const val = e.target.value;
                      setInput3Val(val);

                      if (val.length === 1) setInput4Focus();
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 8 || e.key === "Backspace") {
                        setInput3Val("");
                        setInput2Focus();
                      }
                    }}
                    ref={input3Ref}
                    value={input3Val}
                  />
                  <input
                    className="m-2 text-center form-control otp-rounded"
                    inputMode="numeric"
                    type="text"
                    id="fourth"
                    name="digit4"
                    maxLength="1"
                    onChange={(e) => {
                      const val = e.target.value;
                      setInput4Val(val);

                      if (val.length === 1) setInput5Focus();
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 8 || e.key === "Backspace") {
                        setInput4Val("");
                        setInput3Focus();
                      }
                    }}
                    ref={input4Ref}
                    value={input4Val}
                  />
                  <input
                    className="m-2 text-center form-control otp-rounded"
                    inputMode="numeric"
                    type="text"
                    id="fifth"
                    name="digit5"
                    maxLength="1"
                    onChange={(e) => {
                      const val = e.target.value;
                      setInput5Val(val);

                      if (val.length === 1) setInput6Focus();
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 8 || e.key === "Backspace") {
                        setInput5Val("");
                        setInput4Focus();
                      }
                    }}
                    ref={input5Ref}
                    value={input5Val}
                  />
                  <input
                    className="m-2 text-center form-control otp-rounded"
                    inputMode="numeric"
                    type="text"
                    id="sixth"
                    name="digit6"
                    maxLength="1"
                    value={input6Val}
                    onChange={(e) => {
                      const val = e.target.value;
                      setInput6Val(val);

                      if (val.length === 1) setCompleteFocus();
                      setDisable(true);
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 8 || e.key === "Backspace") {
                        setInput6Val("");
                        setInput5Focus();
                        setDisable(false);
                      }
                    }}
                    ref={input6Ref}
                  />
                </div>

                <div className="button-disabled">
                  <button
                    type="submit"
                    disabled={!areAllInputsNumeric}
                    className="otp-form-button"
                  >
                    {" "}
                    Next{" "}
                  </button>
                </div>
              </form>
            </div>

            {/* <div className="email-continues-button ">
                            <button className='continue-form-button' onClick={props.continueSubmit}>Continue</button>
                        </div> */}
            {/* <div className="securitypin-progressbar-signup indicator-securitypin">
                            <div className="continues-button ">
                                <button className='continue-form-button' onClick={props.continueSubmit}>Continue</button>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ConfirmPin;
