
import qrCode from "../../assets/images/qr_code.png";
import React, { useState } from 'react';
import QRCode from "react-qr-code";
import { formatPhoneNumber, numberToWords, formatPrice,invoiceCalculate } from '../../utils/Common';
import { useNavigate } from 'react-router-dom';
const BASE_URL = process.env.REACT_APP_ROOT_URL;


const VendorDueInvoice = props => {
  const navigate = useNavigate();
  const {
    invoice,
    invoiceSetting,
    invoiceCondition,
    paperSize,
    deliveryMode,
  } = props;
  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  const monthName = invoiceDate.toLocaleString("en-US", { month: "long" });
  const day = String(invoiceDate.getDate()).padStart(2, '0');
  const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';
  const [id, setId] = useState('')
  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day} ${monthName} ${year} ( ${hours}:${minutes}${ampm} )`;
  const shop_logo = `${BASE_URL}/layoutlogo.svg`;
  let totalQty = 0;
  let totalItems = 0;
  let totalPrice = 0;
  const userInfo = invoice?.user_info;
  const invoiceCreator = invoice?.wholesaller_id ? invoice?.wholeseller : invoice?.vendor_id ? invoice?.vendor : invoice?.customers ? invoice?.customers : invoice.user_info;

  return (
    <div className="rounded-[25px] px-[50px] py-[50px] bg-white mt-[30px]">
      <h2 className="text-center font-nunito font-bold text-[50px] text-[#000] mb-[25px]">
        <img src={shop_logo} alt={invoice.invoice_id} className={' excludeFromPdf mx-auto w-1/2 md:w-2/3 max-w-[320px]'} />
      </h2>
      <p className="text-center font-nunito font-[600] text-[24px] text-[#000] mb-5">
        {userInfo?.outlet_name &&
          <p className='capitalize text-[22px] font-semibold'>{userInfo?.outlet_name} </p>
        }
        {userInfo?.address &&
          <p>{userInfo?.address}</p>
        }
        {invoice?.user_info?.phone &&
          <p>{formatPhoneNumber(invoice?.user_info?.phone, invoice?.user_info?.country_code)}</p>
        }
      </p>

      <div className="flex flex-row justify-between mb-5">
        <div className="w-[65%]">
          <div className="flex flex-row gap-2">
            <div className="w-[50%] font-nunito">
              <h2 className="text-[24px] font-bold mb-2">Customer info</h2>

              <div className="flex flex-row gap-2 text-[24px] mb-2">
                <div className="font-bold">NAME:</div>
                <div className="font-[400]">{invoiceCreator?.name}</div>
              </div>

              <div className="flex flex-row gap-2 text-[24px] mb-2">
                <div className="font-bold">EMAIL:</div>
                <div className="font-[400]">{invoiceCreator?.email}</div>
              </div>

              <div className="flex flex-row gap-2 text-[24px]">
                <div className="font-bold">PHONE:</div>
                <div className="font-[400]">{invoiceCreator?.mobile_number}</div>
              </div>
            </div>
            <div className="w-[50%] font-nunito">
              <h2 className="text-[24px] font-bold mb-2">Receiver Info</h2>

              <div className="flex flex-row gap-2 text-[24px] mb-2">
                <div className="font-bold">NAME:</div>
                <div className="font-[400]">{invoice?.user_info?.user_name}</div>
              </div>

              <div className="flex flex-row gap-2 text-[24px] mb-2">
                <div className="font-bold">EMAIL:</div>
                <div className="font-[400]">{invoice?.user_info?.email}</div>
              </div>

              <div className="flex flex-row gap-2 text-[24px]">
                <div className="font-bold">ID:</div>
                <div className="font-[400]">#{invoice?.user_info?.id}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[25%] flex justify-end">
          <QRCode className="w-full object-contain" value={BASE_URL + '/due-invoice/' + invoice.invoice_id} size={100} />
        </div>
      </div>

      <div className="flex justify-between font-nunito font-bold text-[36px] px-[15px] py-[10px] text-[#FFF] bg-[#3056FA] mb-3">
        <div>Bill INV #{invoice.invoice_id}</div>
        <div>{formattedDateTime}</div>
      </div>

      <div className="w-full mb-[130px]">
        <div className="p-2 font-nunito font-bold text-[24px] text-[#000] flex flex-row justify-between border-b-[1px] border-solid border-[#E7EBF4]">
          <div>Status</div>
          <div>Amount</div>
        </div>

        <div className="p-2 font-nunito font-[400] text-[24px] text-[#000] flex flex-row justify-between border-b-[1px] border-solid border-[#E7EBF4]">
          <div>Previous Due</div>
          <div>{formatPrice(invoice.previous_due)} {invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</div>
        </div>

        <div className="p-2 font-nunito font-[400] text-[24px] text-[#000] flex flex-row justify-between border-b-[1px] border-solid border-[#E7EBF4]">
          <div>Current Pay</div>
          <div>{formatPrice(invoice.paid_amount)} {invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</div>
        </div>

        {/* <div className="p-2 font-nunito font-[400] text-[24px] text-[#000] flex flex-row justify-between border-b-[1px] border-solid border-[#E7EBF4]">
          <div>Current Due</div>
          <div>{invoice.previous_due - invoice.paid_amount}</div>
        </div> */}

        <div className="p-2 font-nunito font-[700] text-[24px] text-[#000] flex flex-row justify-between">
          <div className="w-[70%] text-right">Current Due</div>
          <div className="w-[20%] text-right">{formatPrice(invoice.previous_due - invoice.paid_amount)} {invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</div>
        </div>
      </div>

      <p className="font-nunito font-[500] text-[34px] text-[#102048] mb-5 text-center">
        In word: {numberToWords(formatPrice(invoice.paid_amount))} taka only
      </p>

      <div className="font-nunito font-[400] text-[36px] px-[5px] py-[10px] text-[#FFF] bg-[#034AFF] text-center mb-[130px]">
        You saved 0Tk in this purchase
      </div>

      <div className="p-2 font-nunito font-[600] text-[24px] text-[#000] underline flex flex-row justify-between mb-[90px]">
        <div>Customer Signature</div>
        <div>Seller Signature</div>
      </div>

      <div className="text-center text-[#FF6C02] font-nunito font-bold text-[40px] mb-[0px]">
        Thanks for Purchase
      </div>

      <div className="text-center text-[#000000] font-nunito font-[400] text-[16px] mb-[50px]">
        A Product of SQUAD INNOVATORS
      </div>
    </div>
  );
};

export default VendorDueInvoice;
