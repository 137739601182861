import React, { useEffect, useState,useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useFlashMessage } from '../../context/FlashMessageContext';
import { get, post } from "../../services/apiService";
import Resizer from "react-image-file-resizer";

import ContentLayout from "../../layout/ContentLayout";
import VendorCustomerEditForm from "../../forms/VendorCustomerEditForm";
import { AppContext } from "../../context/context";
import {
  uploadFile,
  uploadFileMultipleInput,
  uploadFileMultipleInputVariants,
  validateData
} from '../../utils/Common';
import arrowLeft from "../../assets/images/arrow-left.png";
import { FaArrowLeftLong } from "react-icons/fa6";

const CarrierEditPage = () => {
  const { state,updateBloodGroups } = useContext(AppContext);
  const { id } = useParams(); // Get the id parameter from the URL
  const isEditMode = !!id; // Check if in edit mode
  const navigate = useNavigate();
  const { getFlashMessageStyle, setSuccessFlashMessage,flashMessage,setErrorFlashMessage } = useFlashMessage(); // Retrieve the dispatch function from your state management library
  const token = localStorage.getItem("token");
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };

  const [countryList, setCountryList] = useState(state?.countryList);
  const [bloodGroupList, setBloodGroupList] = useState(
    state?.bloodGroups?.map((item) => ({ ...item, name: item.blood_group_name }))
  );
  const [districtList, setDistrictList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [country, setCountry] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [userData, setUserData] = useState(null);
  const [warningMessage, setWarningMessage] = useState(false);
  const [formData, setFormData] = useState({
    id: id,
    customer_name: '',
    customer_email: '',
    country_code: '',
    customer_phone: '',
    address: '',
    country: '',
    district: '',
    city: '',
    zipcode: '',
    nid: '',
    blood_group: '',
    //customer_id: '',
    police_station: '',
    image: null,
  });



  const handleSubmit = async (event) => {
    //event.preventDefault();

    //const formData = new FormData(event.target);
    const formObject = formData;
        const customerData = {
          name: formObject.customer_name ? formObject.customer_name : null,
          email: formObject.customer_email ? formObject.customer_email : null,
          //country_code: formObject.country_code ? formObject.country_code : null,
          phone_no: formObject.customer_phone ? formObject.customer_phone : null,
          address: formObject.address ? formObject.address : null,
          country: formObject.country ? Number(formObject.country) : null,
          district: formObject.district ? Number(formObject.district) : null,
          city: formObject.city ? Number(formObject.city) : null,
          zip_postcode: formObject.zipcode ? Number(formObject.zipcode) : null,
          nid_passport_no: formObject.nid ? Number(formObject.nid) : null,
          //customer_id: formObject.customer_id ? Number(formObject.customer_id) : null,
          //blood_group: formObject.blood_group ? formObject.blood_group : null,
          //police_station: formObject.police_station ? formObject.police_station : null,
          image: formObject.image ? formObject.image : null,
        };
        if (selectedVendor) {
          customerData.id = Number(id);
          customerData.carrier_id = Number(id);
          updateVendor(customerData);
        }
        else {

          //createCustomer(customerData);
          //setSuccessFlashMessage('customer saved successfully');
        }

  };

  const updateVendor = (formData) => {
    loadingCallback(true);
    // Use your API service functions to make API requests here
    post("save-carrier", formData, token)
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          setSelectedVendor(response.data);
          setSuccessFlashMessage('Carrier updated successfully');
        } else {
          console.log("Carrier update failed");
          setErrorFlashMessage('Carrier update failed');

          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
        console.log(error);
      });
  }

  useEffect(() => {
    // Fetch product data based on the ID and populate
    fetchUserData(id);
    //if (!state.bloodGroups.length) {
      getBloodGroupList();
    //}
  }, [id]);

  const fetchUserData = (Id) => {
    loadingCallback(true);
    // Make an API request to fetch product data by ID
    // Once data is retrieved, update the form fields with the data
    get("carrier-details/" + Id, token)
      .then((response) => {
        const fetchedData = response.data; // Replace with your API response data

        loadingCallback(false);
        //setUserData(fetchedData);
        setFormData({
          customer_name: fetchedData.name,
          customer_email: fetchedData.email,
          //country_code: fetchedData.country_code,
          customer_phone: fetchedData.phone_no,
          address: fetchedData.address,
          country: fetchedData.country ? Number(fetchedData.country) : '',
          district: fetchedData.district ? Number(fetchedData.district) : '',
          city: fetchedData.city ? Number(fetchedData.city) : '',
          zipcode: fetchedData.zip_postcode,
          nid: fetchedData.nid_passport_no,
          blood_group: fetchedData.blood_group,
          //customer_id: fetchedData.customer_id,
          //police_station: fetchedData.police_station,
          image: fetchedData.image,
        });
        setSelectedVendor(fetchedData);

      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
          // Navigate to the '/login' route
        }
      });
  };
  useEffect(() => {
    if (selectedVendor && selectedVendor.country && typeof selectedVendor.country === 'number') {
      setCountry(selectedVendor.country);
      getDistrictList(selectedVendor.country);
      if (selectedVendor && selectedVendor.district && typeof selectedVendor.district === 'number') {
        setDistrict(selectedVendor.district);
        getCityList(selectedVendor.district);
      }
    } else {
      getDistrictList(country);
    }

  }, [country,selectedVendor]);
  useEffect(() => {
    if (selectedVendor && selectedVendor.district && typeof selectedVendor.district === 'number') {
      setDistrict(selectedVendor.district);
      getCityList(selectedVendor.district);
    } else {
      getCityList(district);
    }
  }, [district]);

  const getDistrictList = (country) => {
    post("get-district-by-country", { country_id:country }, token)
      .then((response) => {
        if (response.success) {
          setDistrictList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCityList = (district) => {
    setDistrict(district);
    post("get-cities-by-district", { district_id:district }, token)
      .then((response) => {
        if (response.success) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBloodGroupList = () => {
    axios
    .get(
        `${process.env.REACT_APP_API_URL}get-blood-group?page=1&limit=100`,
        {
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }
    )
    .then((response) => {
        if(response.data.success)
        {
          updateBloodGroups(response.data.data)
          setBloodGroupList(response.data.data.map((item) => ({ ...item, name: item.blood_group_name })));
        }
    })
    .catch((error) => {
        console.log(error);
    });
  };
  const [uploadDetails, setUploadDetails] = useState([]);
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
  });
  const handleImageChange = async (files, name, id=null) => {
    let tempUploadDetails = uploadDetails;
    const allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    for (let i = 0; i < files.length; i++) {

      // Validate file type
      if (!allowedImageTypes.includes(files[i].type)) {
        setErrorFlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        console.error(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        // Handle invalid file type (display a message, set an error state, etc.)
        //setIsValid(false);
        continue; // Skip the current iteration for invalid file types
      }

      let size = files[i].size / 1024;
      let sizeName = '';

      if (size > 1024) {
        size = (size / 1024).toFixed(2);
        sizeName = size + 'MB';
      } else {
        sizeName = size.toFixed(2) + 'KB';
      }
      const resizedImage = await resizeFile(files[i]);
      // Extract base64 data
      const base64Data = resizedImage.split(',')[1];

      // Convert base64 to Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the MIME type accordingly

      // Create a File from Blob
      const file = new File([blob],files[i].name, { type: "image/png" }); // Adjust the file name and MIME type accordingly

      const existingIndex = tempUploadDetails.findIndex(
          (variant) => variant.input_name === name
        );
        if (existingIndex !== -1) {
          loadingCallback(true);
          // If a variant with the same name exists, update it
          tempUploadDetails[existingIndex] = {
            name: files[i].name,
            size: sizeName,
            percentage: 0,
            file: file,
            input_name: name,
            image_path: URL.createObjectURL(files[i]),
          };
          const brandLogo = await uploadFileMultipleInputVariants(
            tempUploadDetails,
            name
          );
          if (brandLogo) {
            setFormData({
              ...formData,
              [name]: brandLogo.path,
            });
            loadingCallback(false);
          }
        } else {
          loadingCallback(true);
          tempUploadDetails.push({
            name: files[i].name,
            size: sizeName,
            percentage: 0,
            file: file,
            input_name: name,
            image_path: URL.createObjectURL(files[i]),
          });

          const brandLogo = await uploadFileMultipleInputVariants(
            tempUploadDetails,
            name
          );
          // console.log("============");
          // console.log(brandLogo);

          if (brandLogo) {
            loadingCallback(false);
            setFormData({
              ...formData,
              [name]: brandLogo.path,
            });
          }
        }
    }


    setUploadDetails(tempUploadDetails);
  };
  // Function to handle changes in the input fields
  const handleChangeSelect = (name, option) => {

   const { value, label } = option;

    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'country') {
      // If the selected category changes, filter and update the subcategories
      if (value != selectedVendor.country) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          district: '', // Reset subcategory when category changes
          country: value,
        }));
        setCountry(value);
      } else {
        setCountry(value);
        setFormData((prevFormData) => ({
          ...prevFormData,
          country: value,
        }));
      }
    }
    if (name === 'district') {
      // If the selected category changes, filter and update the subcategories
      if (value != selectedVendor.district) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          city: '', // Reset subcategory when category changes,
          district: value,
        }));
        setDistrict(value);
      } else {

        setDistrict(value);
        setFormData((prevFormData) => ({
          ...prevFormData,
          district: value,
        }));
      }
    }
  };
  // Function to handle changes in the input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(formData);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <ContentLayout>
        <div className="rounded-[16px] bg-white px-[35px] py-[30px]">
        <div className="flex flex-row gap-2 items-center">
            <Link className="block w-[24px] transform duration-200" to={`/carrier-profile/${id}`}>
           <FaArrowLeftLong size={24}/>
            </Link>
            <a className="font-nunito cursor-default text-[22px] font-[600] hover:text-black" href="#">
            Edit Carrier
            </a>
            {/* <Link className="font-nunito text-[22px] font-[600]" to={`/exporter-list`}>Add New Exporter</Link> */}
          </div>
          {warningMessage && (
            <div className="warning-message">
              something wrong!
            </div>
          )}
          {flashMessage && (
            <div className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(flashMessage.type)}`}>
              {flashMessage.text}
            </div>
          )}

          {loading && (

            <div className="custom-loader-logo">
              {/* <BeatLoader color={"#123abc"} size={20} /> */}
              <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
            </div>
          )}
          <VendorCustomerEditForm
            formData={formData}
            countryList={state.countryList}
            districtList={districtList}
            cityList={cityList}
            handleChange={handleChange}
            handleChangeSelect={handleChangeSelect}
            type="carrier"
            handleSubmitForm={handleSubmit}
            setErrorFlashMessage={setErrorFlashMessage}
            bloodGroupList={bloodGroupList}
            handleImageChange={handleImageChange}
          />
        </div>
      </ContentLayout>
    </>
  );
};

export default CarrierEditPage;
