import React from 'react';
import PropTypes from 'prop-types';

const Watermark = ({ text }) => {
  const watermarkStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
   
  };

  const textContainerStyle = {
    position: 'absolute',
    top: '50%',
    left: '90%',
    transform: 'translate(-50%, -50%) rotate(-45deg)', // Rotate the text
    opacity: 0.5,
    fontSize: '3em', // Adjusted font size
    fontFamily: 'nunito',
    fontWeight: 'bold',
    color: '#CCCCCC',
    userSelect: 'none',
    whiteSpace: 'nowrap', // Prevent text from wrapping
    fontStyle: 'italic',
  };

  return (
    <div style={watermarkStyle}>
      <div style={textContainerStyle}>{text}</div>
    </div>
  );
};

Watermark.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Watermark;
