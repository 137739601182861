

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import Swal from "sweetalert2";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DeliveryTableItems = ({loader,  data, accountData, getDelivery, getDeliveryAccountList}) => {
  // const deliveryData = data;
  const deliveryData = data.data ? data.data : data;
  const deliverAccountyData = accountData.data ? accountData.data : accountData;

  const token = localStorage.getItem("token");

  const [showModal, setShowModal] = useState(false);
  const [showNestedModal, setShowNestedModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const notify = (msg,type) =>{
    if(type=='success'){
      toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // transition: Bounce,
      });
    }
    if(type=='error'){
      toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // transition: Bounce,
      });
    }
  }

  const [name, setName] = useState("");
  const [methodInfo, setMethodInfo] = useState({})

  const [addAccount, setAddAccount] = useState({
    delivery_method_id: "",
    company_name: "",
    phone_number: "",
  })

  const [viewAccountDetails, setViewAccountDetails] = useState({
    id: "",
    delivery_infos: [],
  })

  const [editAccountDetails, setEditAccountDetails] = useState({})


  const handleEditMethodInfo = (items) =>{
    setMethodInfo(items)
    setEditModal( !editModal);
    setViewModal(false);
    setAddModal(false);
    setShowModal(true);
  }

  const handleOpen = (modal, items) => {
    resetFormError();
    if(modal=="add_modal"){
      setAddModal( !addModal);
      setViewModal(false);
      setEditModal(false);
      setShowModal(true);
    }
    else if(modal=="edit_modal"){
      setEditModal( !editModal);
      setViewModal(false);
      setAddModal(false);
      setShowModal(true);
    }
    else if(modal=="view_modal"){

      setViewModal( !viewModal);
      setAddModal(false);
      setEditModal(false);
      setShowModal(true);
    }
    else if(modal=='editAcount_modal'&&showModal){
      setShowNestedModal(!showNestedModal)
      setAddModal(false);
      setEditModal(false);
    }
    else {
      setViewModal(false);
      setAddModal(false);
      setEditModal(false);
      setShowModal(false);
      setShowNestedModal(false)
    }
  };
  const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
    return [ htmlElRef, setFocus ]
  }
  const [inputRef, setInputFocus] = useFocus();
  const [inputErors, setInputErrors] = useState({
    name: 'This field can not be empty!',
    error1: false,
    error2: false,
    error3: false
  });
  const resetFormError = () => {
    setInputErrors({
        ...inputErors,
        error1: false,
        error2: false,
        error3: false
    });
  };

  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
      setLoading(loading);
  };

  const showModalWarning = (msg, icon='warning', data = null) => {
    Swal.fire({
      title: msg,
      text: "",
      icon: icon,
    });
  };

  const handleEditDelivery = (e) => {
    e.preventDefault();
    setInputErrors({...inputErors, error1: (methodInfo.type_name==''), error2: methodInfo.icon_letter=='' })
    if(methodInfo.type_name&&methodInfo.icon_letter){
      loadingCallback(true)
      axios.post(
        `${process.env.REACT_APP_API_URL}delivery-method-update`,
        { id: methodInfo.id, type_name: methodInfo.type_name, icon_letter: methodInfo.icon_letter},
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false)
        if(response.data.success)
        {
          showModalWarning("Method updated successfully!", 'success')
          setShowModal(false);
          getDelivery();
        }
        else if(response?.data?.errors){
          response.data.errors?.type_name ?
          showModalWarning(response.data.errors?.type_name) :
          showModalWarning(response.data.errors?.icon_letter)
        }
        else {
          showModalWarning("Sometings went wrong!")
        }
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }

  const handleSubmitAccountInfo = (e) =>{
    e.preventDefault();
    setInputErrors({...inputErors, error1: (addAccount.company_name==''), error2: addAccount.phone_number=='' })
    if(addAccount.company_name&&addAccount.phone_number){
      loadingCallback(true)
      axios.post(
        `${process.env.REACT_APP_API_URL}delivery-info-save`,
        { delivery_method_id: addAccount.delivery_method_id, company_name: addAccount.company_name, phone_number: addAccount.phone_number },
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
          console.log(response.data);
          if(response.data.success)
          {
            showModalWarning("Account added successfully!", 'success')
            setShowModal(false);
            getDelivery();
            setAddAccount();
            getDeliveryAccountList();
            setAddAccount({
              delivery_method_id: "",
              company_name: "",
              phone_number: "",
            })
          }
          loadingCallback(false)
      })
      .catch((error) => {
        console.log(error);
      });
      }
  }

  // const handleUpdateAccountDetails = (e) =>{
  //   e.preventDefault();

  //   setInputErrors({...inputErors, error1: (editAccountDetails.company_name==''), error2: editAccountDetails.phone_number=='' })
  //   if(editAccountDetails.company_name&&editAccountDetails.phone_number){
  //     loadingCallback(true)
  //     axios.post(
  //       `${process.env.REACT_APP_API_URL}delivery-info-update`,
  //       {
  //         id: editAccountDetails.id,
  //         delivery_method_id: editAccountDetails.delivery_method_id,
  //         company_name: editAccountDetails.company_name,
  //         phone_number: editAccountDetails.phone_number
  //       },
  //       {
  //         headers: {
  //           authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       if(response.data.success){
  //         setShowNestedModal(false);
  //         setViewAccountDetails(prevState => ({
  //           ...prevState,
  //           company_name: prevState.delivery_infos.map(item =>
  //             item.id === editAccountDetails.id ? { ...item, company_name: editAccountDetails.company_name,
  //               phone_number: editAccountDetails.phone_number  } : item
  //           )
  //         }));
  //         showModalWarning("Account updated successfully!", 'success')
  //         getDelivery();
  //         getDeliveryAccountList();
  //       }
  //       loadingCallback(false)
  //     })
  //     .catch((error) => {
  //       loadingCallback(false)

  //     });
  //   }
  // }

  const [accountViewId, setAccountViewId] = useState(null)
  const [editDeliveryAccount, setEditDeliveryAccount] = useState({})

  const handleUpdateAccountDetails2 = (e) =>{
    e.preventDefault();
    console.log("DeliveryAccount:", deliverAccountyData);
    setInputErrors({...inputErors, error1: (editDeliveryAccount.company_name==''), error2: editDeliveryAccount.phone_number=='' })
    if(editDeliveryAccount.company_name&&editDeliveryAccount.phone_number){
      loadingCallback(true)
      axios.post(
        `${process.env.REACT_APP_API_URL}delivery-info-update`,
        {
          id: editDeliveryAccount.id,
          delivery_method_id: editDeliveryAccount.delivery_method_id,
          company_name: editDeliveryAccount.company_name,
          phone_number: editDeliveryAccount.phone_number
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if(response.data.success){
          setShowNestedModal(false);
          showModalWarning("Account updated successfully!", 'success')
          getDeliveryAccountList();
          getDelivery();

        }
        loadingCallback(false)
      })
      .catch((error) => {
        loadingCallback(false)

      });
    }
  }

  const handleCancelMethod = (modal) =>{
    setAddAccount({
      delivery_method_id: "",
      company_name: "",
      phone_number: "",
    })
    handleOpen()
  }

  const handleDeleteCondition = (id) =>{
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Condition!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {

        loadingCallback(true)
        axios.post(
          `${process.env.REACT_APP_API_URL}delete-delivery-method`,{
            deliverymethodId: id
        },
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
            loadingCallback(false)
            console.log(response.data);
            if(response.data.success)
            {
              notify("Delivery Method deleted successfully!", 'success')
              getPayment();
            }
            else if(!response.data.success) {
              notify(response.data.message, 'error')
            }
            else {
              notify("Something went wrong!", 'error')
            }
        })
        .catch((error) => {
          loadingCallback(false)
          console.log(error);
        });
      }
    });
  }
  const handleDeleteAccount = (id) =>{
    console.log(id);
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Account!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {

        loadingCallback(true)
        axios.post(
          `${process.env.REACT_APP_API_URL}delete-delivery-info`,{
            paymenttypecategoryId: id
        },
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
            loadingCallback(false)
            if(response.data.success)
            {
              notify("Account deleted successfully!", 'success')
              getPayment();
              getPaymentAccountList();
            }
            else if(!response.data.success) {
              notify(response.data.message, 'error')
            }
            else {
              notify("Something went wrong!", 'error')
            }
        })
        .catch((error) => {
          loadingCallback(false)
          console.log(error);
        });
      }
    });
  }

  return (
    <div className="w-full relative px-[27px] overflow-x-auto sm:rounded-lg">
      {loading && (
        <div className="custom-loader-logo">
          <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
        </div>
      )}
      <ToastContainer />
      <table className=" w-full text-sm text-left">
        <tbody>
          {deliveryData.map((items, index) => (
            <>
              <tr key={items.id} className="border-t border-[#005DAE1C] rounded-xl">
                <td className="py-2 w-1/12">
                  <div className={`rounded-full w-[42px] h-[42px] mr-8 bg-white flex justify-center font-oleo items-center text-[#551FFF] `}>
                    {items.icon_letter}
                  </div>
                </td>
                <td className="py-3">
                  <h4 className="font-lato font-semibold text-18px text-[#000232]">{items.type_name} </h4>
                </td>
                { items.type_name=="hand-to-hand" ?
                  <td>
                    <div className="text-[12px] font-nunito font-[7000] flex flex-col lg:flex-row gap-2 justify-center items-center">
                      <h1>By Default</h1>
                    </div>
                  </td> :
                  <td className=" text-right w-1/4">
                    <div className="text-[12px] font-nunito font-[7000] flex flex-col lg:flex-row gap-2 justify-center items-center">
                      <label onClick={()=>{handleOpen('add_modal'), setAddAccount({...addAccount, delivery_method_id: items.id})}} htmlFor='add_modal' className="cursor-pointer block border-[1px] border-solid border-[#407BFF] text-[#fff] hover:text-[#fff] bg-[#407BFF] rounded-[5px] px-[8px] pt-[2px] pb-[0px] my-[2px]"  >Add</label>
                      <label onClick={()=>{handleOpen('view_modal'), setAccountViewId(items.id), setViewAccountDetails({...viewAccountDetails, id: items.id, delivery_infos: items.delivery_infos})}} htmlFor='view_modal' className="cursor-pointer block border-[1px] border-solid border-[#407BFF] text-[#407BFF] rounded-[5px] px-[8px] pt-[2px] pb-[0px] my-[2px]"  >View</label>
                      <label onClick={()=>handleEditMethodInfo(items)} htmlFor='edit_modal' className="cursor-pointer block border-[1px] border-solid border-[#407BFF]  text-[#fff] hover:text-[#fff] bg-[#407BFF] rounded-[5px] px-[8px] pt-[2px] pb-[0px] my-[2px]"  >Edit</label>
                      <label onClick={()=>handleDeleteCondition(items.id)} className=" cursor-pointer block border-[1px] border-solid border-[#f14343] text-[#f14343] rounded-[5px] px-[8px] pt-[2px] pb-[0px] my-[2px]"  >Delete</label>
                    </div>
                  </td>
                }
              </tr>
            </>
          ))}
        </tbody>
      </table>


       {/* Add Account Info */}
       {showModal ? (
        <>
          <div>
            <input type='checkbox' id='add_modal' className='modal-toggle' />
            <div className='modal'>
              <div className='modal-box w-[390px] p-0 bg-white'>
                <div className='flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]'>
                  <h4 className='font-nunito font-semibold text-lg '>Authorize Info</h4>
                  <div className='modal-action'>
                    <label htmlFor='add_modal'  className='cursor-pointer modal-action bg-red-600 text-white rounded-md' onClick={()=>handleCancelMethod()}>
                      <GrFormClose  />
                    </label>
                  </div>
                </div>
                <div className='px-5 pt-5 pb-[22px]'>
                  <form
                    method="get"
                    className="col-sm-12"
                    data-autosubmit="false"
                    autoComplete="off"
                    onSubmit={handleSubmitAccountInfo}
                  >
                    <input
                      type='text'
                      name="name"
                      onChange={(e) => {
                        const val = e.target.value;
                        setAddAccount({...addAccount, company_name: val})
                        setInputErrors({ ...inputErors, error1: false });
                      }}
                      ref={inputRef}
                      placeholder='Company Name'
                      className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                    />
                    {inputErors.error1
                    ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                    : null}
                    <input
                      type='text'
                      name="name"
                      onChange={(e) => {
                        const val = e.target.value;
                        setAddAccount({...addAccount, phone_number: val})
                        setInputErrors({ ...inputErors, error2: false });
                      }}
                      ref={inputRef}
                      placeholder='Phone number'
                      className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                    />
                    {inputErors.error2
                    ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                    : null}
                    <div className="button-disabled">
                      <button
                        htmlFor='add_modal'
                        type="submit"
                        className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                      >
                        {""}
                        Save{""}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

       {/* { edit delivery method form} */}
        {showModal ? (
        <>
          <div>
            <input type='checkbox' id='edit_modal' className='modal-toggle' />
            <div className='modal'>
                <div className='modal-box w-[390px] p-0 bg-white'>
                  <div className='flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]'>
                    <h4 className='font-nunito font-semibold text-lg '>Edit Method Name</h4>
                    <div className='modal-action'>
                      <label htmlFor='edit_modal' className='cursor-pointer modal-action bg-red-600 text-white rounded-md' onClick={()=>{handleOpen('edit_modal'), setMethodInfo({})}}>
                        <GrFormClose />
                      </label>
                    </div>
                  </div>
                    <div className='px-5 pt-5 pb-[22px]'>
                    <form
                      method="get"
                      className="col-sm-12"
                      data-autosubmit="false"
                      autoComplete="off"
                      onSubmit={handleEditDelivery}
                    >
                      <input
                        type='text'
                        name="name"
                        value={methodInfo.type_name}
                        onChange={(e) => {
                          const val = e.target.value;
                          setMethodInfo( {...methodInfo, type_name: val});
                          setInputErrors({ ...inputErors, error1: false });
                        }}
                        ref={inputRef}
                        placeholder='Edit Payment Method'
                        className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                      />
                      {inputErors.error1
                      ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                      : null}
                      <input
                        type='text'
                        name="methodIcon"
                        value={methodInfo.icon_letter}
                        onChange={(e) => {
                          const val = e.target.value;
                          setMethodInfo( {...methodInfo, icon_letter: val});
                          setInputErrors({ ...inputErors, error2: false });
                        }}
                        ref={inputRef}
                        placeholder='Edit Icon Letter'
                        className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                      />
                      {inputErors.error2
                      ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                      : null}
                        <div className="button-disabled">
                          <button
                            htmlFor='edit_modal'
                            type="submit"
                            className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                          >
                            {""}
                            Update{""}
                          </button>
                        </div>
                      </form>
                    </div>
                </div>
            </div>
          </div>
        </>
      ) : null}


       {/* view account List  */}
{/*
      {showModal ? (
        <>
          <div>
            <input type='checkbox' id='view_modal' className='modal-toggle' />
            <div className='modal'>
              <div className='modal-box max-w-[500px] p-0 bg-white'>
                <div className='flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]'>
                  <h4 className='font-nunito font-semibold text-lg '>Account List</h4>
                  <div className='modal-action'>
                    <label htmlFor='view_modal' className='cursor-pointer modal-action bg-red-600 text-white rounded-md' onClick={()=>handleOpen('view_modal')}>
                      <GrFormClose />
                    </label>
                  </div>
                </div>
                <div className='px-5 pt-4 pb-[22px] h-96 overflow-y-auto'>
                  <table className=" w-full text-sm text-left rounded-lg font-nunito">
                    <thead className="">
                      <tr>
                        <th className="font-semibold p-1 text-center">Company Name</th>
                        <th className="font-semibold text-center">Phone</th>
                        <th className="font-semibold text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody className="font-thin">
                      {viewAccountDetails?.delivery_infos.map((items, index) => (
                          <tr key={index} className="border-t border-[#005DAE1C] rounded-xl">
                            <td className="py-2 w-1/12 text-center">
                              <h4 className="font-nunito font-medium text-18px text-[#000232] p-[6px]">{items.company_name} </h4>
                            </td>
                            <td className="py-2 text-center">
                              <h4 className="font-nunito me-2 font-medium text-18px text-[#000232]">{items.phone_number} </h4>
                            </td>
                            <td className="py-2 text-center">
                              <label onClick={()=>{handleOpen('editAcount_modal'),setEditAccountDetails(items)}} htmlFor='editAcount_modal' className="cursor-pointer block border-[1px] border-solid border-[#407BFF] text-[#fff] hover:text-[#fff] bg-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"  >Edit</label>
                            </td>
                          </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}


       // nested view edit modal
      {showNestedModal ? (
        <>
          <div>
            <input type='checkbox' id='editAcount_modal' className='modal-toggle' />
            <div className='modal'>
              <div className='modal-box w-[390px] p-0 bg-white'>
                <div className='flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]'>
                  <h4 className='font-nunito font-semibold text-lg '>Account Info</h4>
                  <div className='modal-action'>
                    <label htmlFor='editAcount_modal' className='cursor-pointer modal-action bg-red-600 text-white rounded-md' onClick={()=>handleOpen('editAcount_modal')}>
                      <GrFormClose />
                    </label>
                  </div>
                </div>
                <div className='px-5 pt-5 pb-[22px]'>
                  <form
                    method="get"
                    className="col-sm-12"
                    data-autosubmit="false"
                    autoComplete="off"
                    onSubmit={handleUpdateAccountDetails}
                  >
                    <input
                      type='text'
                      name="name"
                      value={editAccountDetails.company_name}
                      onChange={(e) => {
                        const val = e.target.value;
                        setEditAccountDetails({...editAccountDetails, company_name: val});
                        setInputErrors({ ...inputErors, error1: false });
                      }}
                      ref={inputRef}
                      placeholder='Edit AC Name'
                      className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                    />
                    {inputErors.error1
                    ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                    : null}
                    <input
                      type='text'
                      name="name"
                      value={editAccountDetails.phone_number}
                      onChange={(e) => {
                        const val = e.target.value;
                        setEditAccountDetails({...editAccountDetails, phone_number: val});
                        setInputErrors({ ...inputErors, error2: false });
                      }}
                      ref={inputRef}
                      placeholder='Edit Account Number'
                      className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                    />
                    {inputErors.error2
                    ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                    : null}
                    <div className="button-disabled">
                      <button
                        htmlFor='editAcount_modal'
                        type="submit"
                        className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                      >
                        {""}
                        Save{""}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null} */}

       {/* view account List  */}

      {showModal ? (
        <>
          <div>
            <input type='checkbox' id='view_modal' className='modal-toggle' />
            <div className='modal'>
              <div className='modal-box max-w-[500px] p-0 bg-white'>
                <div className='flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]'>
                  <h4 className='font-nunito font-semibold text-lg '>Authorize Info List</h4>
                  <div className='modal-action'>
                    <label htmlFor='view_modal' className='cursor-pointer modal-action bg-red-600 text-white rounded-md' onClick={()=>handleOpen('view_modal')}>
                      <GrFormClose />
                    </label>
                  </div>
                </div>
                <div className='px-5 pt-4 pb-[22px] h-96 overflow-y-auto'>
                  <table className=" w-full text-sm text-left rounded-lg font-nunito">
                    <thead className="">
                      <tr>
                        <th className="font-semibold p-1 text-center">Company Name</th>
                        <th className="font-semibold text-center">Phone</th>
                        <th className="font-semibold text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody className="font-thin">
                      {deliverAccountyData.map((items, index) => (
                          (items.delivery_method_id==accountViewId) && (
                            <tr key={index} className="border-t border-[#005DAE1C] rounded-xl">
                              <td className="py-2 w-1/12 text-center">
                                <h4 className="font-nunito font-medium text-18px text-[#000232] p-[6px]">{items.company_name} </h4>
                              </td>
                              <td className="py-2 text-center">
                                <h4 className="font-nunito me-2 font-medium text-18px text-[#000232]">{items.phone_number} </h4>
                              </td>
                              <td className="py-2 text-center">
                                <label onClick={()=>{handleOpen('editAcount_modal'),setEditDeliveryAccount(items),setEditAccountDetails(items)}} htmlFor='editAcount_modal' className="cursor-pointer block border-[1px] border-solid border-[#407BFF] text-[#fff] hover:text-[#fff] bg-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"  >Edit</label>
                                <label onClick={()=>handleDeleteCondition(items.id)} className="cursor-pointer block border-[1px] border-solid border-[#f14343] text-[#f14343] rounded-[5px] px-[8px] pt-[2px] pb-[0px] my-[2px]"  >Delete</label>
                              </td>
                            </tr>
                          )
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}


       {/* nested view edit modal */}
      {showNestedModal ? (
        <>
          <div>
            <input type='checkbox' id='editAcount_modal' className='modal-toggle' />
            <div className='modal'>
              <div className='modal-box w-[390px] p-0 bg-white'>
                <div className='flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]'>
                  <h4 className='font-nunito font-semibold text-lg '>Account Info</h4>
                  <div className='modal-action'>
                    <label htmlFor='editAcount_modal' className='cursor-pointer modal-action bg-red-600 text-white rounded-md' onClick={()=>handleOpen('editAcount_modal')}>
                      <GrFormClose />
                    </label>
                  </div>
                </div>
                <div className='px-5 pt-5 pb-[22px]'>
                  <form
                    method="get"
                    className="col-sm-12"
                    data-autosubmit="false"
                    autoComplete="off"
                    onSubmit={handleUpdateAccountDetails2}
                  >
                    <input
                      type='text'
                      name="name"
                      value={editDeliveryAccount.company_name}
                      onChange={(e) => {
                        const val = e.target.value;
                        setEditDeliveryAccount({...editDeliveryAccount, company_name: val});
                        setInputErrors({ ...inputErors, error1: false });
                      }}
                      ref={inputRef}
                      placeholder='Edit AC Name'
                      className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                    />
                    {inputErors.error1
                    ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                    : null}
                    <input
                      type='text'
                      name="name"
                      value={editDeliveryAccount.phone_number}
                      onChange={(e) => {
                        const val = e.target.value;
                        setEditDeliveryAccount({...editDeliveryAccount, phone_number: val});
                        setInputErrors({ ...inputErors, error2: false });
                      }}
                      ref={inputRef}
                      placeholder='Edit Account Number'
                      className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                    />
                    {inputErors.error2
                    ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                    : null}
                    <div className="button-disabled">
                      <button
                        htmlFor='editAcount_modal'
                        type="submit"
                        className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                      >
                        {""}
                        Save{""}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      </div>
  );
};

export default DeliveryTableItems;