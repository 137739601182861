import React, { useEffect, useState, useRef } from "react";
import Select from 'react-select';

import './select.css';

let customStyles = {

  control: (provided) => ({
    ...provided,
    borderRadius: '10px',
    borderColor: '#C1CFEF',
    width: '100%',

    //boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#C1CFEF' : 'white',
    color: '#102048',
    '&:hover': {
      backgroundColor: '#C1CFEF',
    },
  }),
};

const SelectBox = ({ onInputChangeData,label, placeholder, className, name, options, value, onChange, error, setFormErrors = false, transparentBorder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);
  const handleOptionClick = (option) => {
    onChange(option.value);
    setIsOpen(false);
  };
  let customStyles = {

    control: (provided) => ({
      ...provided,
      borderRadius: '10px',
      borderColor: transparentBorder? "transparent":"#C1CFEF",
      width: '100%',

      //boxShadow: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#C1CFEF' : 'white',
      color: '#102048',
      '&:hover': {
        backgroundColor: '#C1CFEF',
      },
    }),
  };
  const handleDelayedOptions = () => {
    setTimeout(() => {
      setIsOpen(true);
    }, 200);
  };

  useEffect(() => {
    if (options.length > 10) {
      //handleDelayedOptions();
    }
  }, []);
  const errorClass = error ? " border-1 border-red-500" : " border ";
  let selectedOption = { label: placeholder, value: "" };
  if (value && options.length > 0) {
    selectedOption = options
      .map((option) => ({ value: option.transaction_status}))
      .find((option) => option.value === value);
  }
  const placeholderOption = {id: "", name: placeholder };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);

      }
      if (!isOpen) {
        setTimeout(() => {
          //setIsOpen(true);

        }, 500);
      }

    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {

      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);




  return(
    <div className={`${className} w-full relative`}>
      <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] px-1 bg-white font-semibold">
        {label}
      </label>
      <Select
        name={name}
        onChange={(selectedOption) => {
          onChange(selectedOption);
          // if (onInputChangeData) {
          //   onInputChangeData(event.target.value);
          // }
          //setIsOpen(true);
          // Clear the error message when the input value changes
          // if (setFormErrors) {
          //   setFormErrors({ name: null });
          // }
        }}
        className={`select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none px-[0px]  text-sm focus:outline-none ${
          error ? " border-1 border-red-500" : ""
        }`}
        // options={[
        //   placeholder ? { value: placeholderOption.id, label: placeholderOption.name } : { value: "", label: "Select" },
        //   ...(Array.isArray(options)
        //     ? options
        //         .slice(0, 10)
        //         .map((option) => ({ value: option.transaction_status}))
        //     : []),
        //   ...(isOpen && Array.isArray(options)
        //     ? options.slice(10).map((option) => ({ value: option.transaction_status}))
        //     : []),
        // ]}
        options={options.map((option) => ({ value: option, label: option }))}
        // value={selectedOption}
        value={value ? { value, label: value } : null}
        styles={customStyles}
        placeholder={placeholder}
        isSearchable
        onMenuOpen={() => handleDelayedOptions()}
        onMenuClose={() => setIsOpen(false)}
        onInputChange={(input) => {
          if (onInputChangeData) {
            onInputChangeData(input);
          }
          //setInputValue(input)
        }}
        //onMenuScrollToBottom={() => setIsOpen(true)}
        //menuPortalTarget={document.body}
      />
      {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
    </div>
  );


};
SelectBox.defaultProps = {
  className: ''
};

export default SelectBox;