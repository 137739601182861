import classNames from 'classnames';
import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import { useFlashMessage } from "../context/FlashMessageContext";
//import Select2 from "react-chosen";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { post } from "../services/apiService";
import { useNavigate } from 'react-router-dom';
import FlagIcon from "./FlagIcon";
import DialCodes from '../data/dialCodes.json';
import { set } from 'react-hook-form';
import SelectBox from './Select';
import { FlashMessage } from '../utils/FlashMessage';
import { ToastContainer, toast } from 'react-toastify';


const InvoiceVendorForm = props => {
  const {
    loadingCallback,
    setVendor,
    setWarningMessage,
    state,
    setShowModal,
    formId,
    setShowNestedModal,
    setNestedModalData,
    filteredVendor,
    setFilteredVendor
  } = props;
  const invoiceDate = new Date();
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  const day = String(invoiceDate.getDate()).padStart(2, '0');
  const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';


  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;

  //const [filteredVendor, setFilteredVendor] = useState([{'id':1}]);
  const { countryList, bloodGroups } = state;
  const [country, setCountry] = useState('');
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState('');
  const [cityList, setCityList] = useState([]);
  const { getFlashMessageStyle, setSuccessFlashMessage, flashMessage, setErrorFlashMessage } = useFlashMessage(); // Retrieve the dispatch function from your state management library
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [showNewVendorForm, setShowNewVendorForm] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isValid, setIsValid] = useState(true);
  const navigate = useNavigate();
  const page = 1;
  const perPage = 20;
  const token = localStorage.getItem("token");
  const getVendorListData = async (searchKeyword='') => {
    loadingCallback(true);
    //setFilteredVendor([]);
    const page1 = 1;
    if (filteredVendor?.length ===0 && searchKeyword === "") {
      const options = [];
      axios
      .get(
        `${process.env.REACT_APP_API_URL}vendor`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          const vendors = response?.data?.data?.data ? response?.data?.data?.data :response?.data?.data;
          // Append other options if there are vendors
          if (vendors.length > 0) {
            const vendorOptions = vendors.map((vendor) => ({
              id: vendor.id,
              name: vendor.name,
              proprietor_name: vendor?.proprietor_name,
              email: vendor.email,
              country_code: vendor.country_code,
              mobile_number: vendor.mobile_number,
              address: vendor.address,
              country: vendor.country,
              district: vendor.district,
              city: vendor.city,
              zipcode: vendor.zipcode,
            }));
            options.push(...vendorOptions);
          }
        } else {
          console.log("Data Not Found!");
        }
        setFilteredVendor(options);
      })
      .catch((error) => {
        loadingCallback(false);
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
      });
    } else {
      setFilteredVendor([]);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}search-vendor?page=${page1}&limit=${perPage}`,
          { keyword: searchKeyword },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          loadingCallback(false);
          const options = [];
          if (response.status === 200) {
            //setFilteredvendor(response.data.data.data);
            const vendors = response?.data?.data?.data ? response?.data?.data?.data :response?.data?.data;

            // Append other options if there are vendors
            if (vendors.length > 0) {
              const vendorOptions = vendors.map((vendor) => ({
                id: vendor.id,
                name: vendor.name,
                proprietor_name: vendor?.proprietor_name,
                email: vendor.email,
                country_code: vendor.country_code,
                mobile_number: vendor.mobile_number,
                address: vendor.address,
                country: vendor.country,
                district: vendor.district,
                city: vendor.city,
                zipcode: vendor.zipcode,
              }));
              options.push(...vendorOptions);
            }

          } else {
            console.log("Data Not Found!");
            const vendorOptions = [];
            options.push(vendorOptions);
          }
          setFilteredVendor(options);
        })
        .catch((error) => {
          loadingCallback(false);
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem('token', false);
            // Clear session and local storage
            localStorage.setItem('isPinVerify', false);

            navigate('/login');
          }
        });
    }
  };

  useEffect(() => {
    getVendorListData(searchKeyword);
  }, [searchKeyword]);
  useEffect(() => {
    if (selectedVendor && selectedVendor.country) {
      setCountry(selectedVendor.country);
      getDistrictList(selectedVendor.country);
      if (selectedVendor && selectedVendor.district) {
        setDistrict(selectedVendor.district);
        getCityList(selectedVendor.district);
      }
    } else {
      getDistrictList(country);
    }

  }, [country,selectedVendor]);

  const getDistrictList = (country) => {
    post("get-district-by-country", { country_id:country }, token)
      .then((response) => {
        if (response.success) {
          setDistrictList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCityList = (district) => {
    setDistrict(district);
    post("get-cities-by-district", { district_id:district }, token)
      .then((response) => {
        if (response.success) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDialCodeByCountryCode = (code) => {
    const country = DialCodes.find((item) => item.code === code);
    return country ? country.dial_code : '';
  };





  const handleValidation = (formData) => {
    const errors = {};
    let validate = true
    // if (formData.proprietor_email === '') {
    //   errors.proprietor_email = 'Email is required.';
    //   setIsValid(false);
    //   validate = false;
    // }
    if (formData.proprietor_email !== '') {

      if (formData.proprietor_email.indexOf('@') === -1) {
        errors.proprietor_email = 'Email is not valid.';
        setIsValid(false);
        validate = false;
      } else {
        //Email address cannot have only numbers before @
        const emailParts = formData.proprietor_email.split('@');
        if (emailParts[0].match(/^[0-9]+$/) !== null) {
          errors.proprietor_email = 'Email is not valid.';
          setIsValid(false);
          validate = false;
        }
      }

    }
    if (formData.vendor_name==='') {
      errors.vendor_name = 'Vendor name is required.';
      setIsValid(false);
      validate=false;

    }
    //proprietor_name
    if (formData.proprietor_name === '') {
      errors.proprietor_name = 'Proprietor name is required.';
      setIsValid(false);
      validate = false;
    }

    if (formData.vendor_phone==='') {
      errors.vendor_phone = 'Phone Number is required.';
      setIsValid(false);
      validate=false;
    }
    setFormErrors(errors);
    if (!validate) {
      // setErrorFlashMessage('Please fill all the required fields.');
      FlashMessage('Please fill all the required fields.','error');
    }

    return validate;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const formObject = {};

    formData.forEach((value, key) => {
      formObject[key] = value;
    });
    if (!selectedVendor) {
      formObject.cutomer_id = 0;
    }
    if (handleValidation(formObject)) {
      // console.log("============");
      // console.log(isValid);
      // console.log(formObject);
      // console.log(formErrors);
      //if (isValid) {
        const vendorData = {
          name: formObject.vendor_name ? formObject.vendor_name : null,
          email: formObject.proprietor_email ? formObject.proprietor_email : null,
          country_code: formObject.country_code ? formObject.country_code : null,
          mobile_number: formObject.vendor_phone ? formObject.vendor_phone : null,
          address: formObject.vendor_address ? formObject.vendor_address : null,
          country: formObject.country_id ? Number(formObject.country_id) : null,
          district: formObject.district_id ? Number(formObject.district_id) : null,
          city: formObject.city_id ? Number(formObject.city_id) : null,
          zipcode: formObject.vendor_zip ? Number(formObject.vendor_zip) : null,
          proprietor_name: formObject?.proprietor_name ? formObject.proprietor_name : 'squadinnovators'
        };
        if (selectedVendor) {
          vendorData.vendor_id = Number(formObject.vendor_id);
          updateVendor(vendorData);
        } else {
          createVendor(vendorData);
        }

    } else {
      console.error('Form validation failed.');
    }

  };

  const createVendor = (formData) => {
      loadingCallback(true);
      // setFlashMessage('Product saved successfully');
      // navigate('/products');
      // Use your API service functions to make API requests here
      //if(isValid){
        post("save-vendor", formData, token)
          .then((response) => {
            loadingCallback(false);
            if (response.status===200) {
              setVendor(response.data);
              setShowModal(false);
              // setSuccessFlashMessage('Vendor saved successfully');
              FlashMessage('Vendor saved successfully','success');
              setShowNewVendorForm(false);
            } else {
              console.log("Vendor insertion failed");

                setWarningMessage(true);
                const timer = setTimeout(() => {
                  setWarningMessage(false);
                }, 3000);
                return () => clearTimeout(timer);
            }
          })
          .catch((error) => {
            loadingCallback(false);
            if (error?.response?.status === 401) {
              localStorage.clear();
              localStorage.setItem('token', false);
              // Clear session and local storage
              localStorage.setItem('isPinVerify', false);

              navigate('/login');
            }
            console.log(error);
          });
      //}
  }
  const updateVendor = (formData) => {
    loadingCallback(true);
    // Use your API service functions to make API requests here
    //if(isValid){
      post("edit-vendor", formData, token)
        .then((response) => {
          loadingCallback(false);
          if (response.status === 200) {
            setVendor(response.data);
            setShowModal(false);
            // setSuccessFlashMessage('Vendor updated successfully');
            FlashMessage('Vendor updated successfully','success');
          } else {
            console.log("Vendor update failed");
            // setErrorFlashMessage('Vendor update failed');
            FlashMessage('Vendor update failed','error');

              setWarningMessage(true);
              const timer = setTimeout(() => {
                setWarningMessage(false);
              }, 3000);
              return () => clearTimeout(timer);
          }
        })
        .catch((error) => {
          loadingCallback(false);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem('token', false);
            // Clear session and local storage
            localStorage.setItem('isPinVerify', false);

            navigate('/login');
          }
          console.log(error);
        });
    //}
  }

  const handleNewVendorFormClick = () => {
    //setShowNewVendorForm(true);
    //setSelectedVendor(false);
    setNestedModalData({
      title: 'Add New Vendor',
      type: 'new-vendor',
      setData:setSelectedVendor

    });
    setShowNestedModal(true);
  };
  const handleErrorInputChange = (fieldName, value) => {
    if (value!=='') {
      //formErrors[fieldName] = null;
      // unset formErrors by fieldName
      const updatedFormErrors = { ...formErrors };
      delete updatedFormErrors[fieldName];
      setFormErrors(updatedFormErrors);
    }

  }
  const handleallInputChange = (fieldName, value) => {

    if (fieldName === 'country') {
      // Update the country state
      setCountry(value);
      setCityList([]);

      // Nullify the city and district values
      setSelectedVendor((prevVendor) => ({
        ...prevVendor,
        country: value,
        city: null,
        district: null,
      }));
    }
    else if (fieldName === 'district') {
      // Update the district state
      getCityList(value);

      // Update the selectedVendor state with the modified field
      setSelectedVendor((prevVendor) => ({
        ...prevVendor,
        city: null,
        [fieldName]: value,
      }));
    } else {
      // Update the selectedVendor state with the modified field
      setSelectedVendor((prevVendor) => ({
        ...prevVendor,
        [fieldName]: value,
      }));
    }
  };
  const handleSelectChange = (name, option) => {
    const { value, label } = option;
    if (value === "") {
      setSelectedVendor(null);
      return;
    }
    const vendorId = parseInt(value);
    const selectedVendor = filteredVendor.find((vendor) => vendor.id === vendorId);
    setSelectedVendor(selectedVendor);
    setShowNewVendorForm(false);
    // Update the value of the input field
    if (selectedVendor?.district) {
      getCityList(selectedVendor.district);
    } else{
      getCityList([]);
    }
  };
  const handleInputChange = (inputValue) => {
    //if (action && action === 'input-change') {
      // Perform an API call using the inputValue to fetch the filtered options
      // Update the options state with the fetched data
      setSearchKeyword(inputValue);
    //}
  };

  return (
    <form
        method="get"
        className="col-sm-12 min-h-[35vh]"
        data-autosubmit="false"
        autoComplete="off"
        onSubmit={handleSubmit}
    >
      <ToastContainer />
      {/* <div className="mb-3 font-nunito">
        <label htmlFor="vendorSearch" className="form-label">Search Vendor</label>
        <input
          type="text"
          id="vendorSearch"
          name="vendorSearch"
          className="form-control rounded-[15px] p-3"
          placeholder="Enter Vendor name"
          value={searchKeyword}
          onChange={(e) =>setSearchKeyword(e.target.value)}
        />
      </div> */}

      <div className="mb-3">
        <label htmlFor="vendorSelect" className="form-label">
          Select Vendor
        </label>
        <div className="d-flex align-items-center">
          <SelectBox
            name="vendor_id"
            options={filteredVendor}
            value={selectedVendor ? selectedVendor.id : ""}
            //onChange={handleCustomerSelect}
            onInputChangeData={handleInputChange}
            onChange={handleSelectChange.bind(this, 'vendor_id')}
            placeholder="Select Vendor"
            className={classNames(
              'w-full',
              'rounded-[15px]',
              'bg-white'
            )}
          />
          {/* <select
            id="vendor_id"
            name="vendor_id"
            className={classNames(
              'w-full',
              'rounded-[15px]',
              'p-3',
              'bg-white'
            )}
            style={{  border: "1px solid #ccc" }}
            //onChange={handleVendorSelect}
            onChange={handleSelectChange.bind(this, 'vendor_id')}
            value={selectedVendor ? selectedVendor.id : ""}
          >
            {filteredVendor.map((vendor) => (
              <option key={vendor.id} value={vendor.id}>
                {vendor.name}
              </option>
            ))}
          </select> */}
          {/* Add nested modal trigger */}
          <label
                htmlFor="nested_modal"
                onClick={handleNewVendorFormClick}
                className={classNames(
                  " w-[70px]",
                  "h-[56px]",
                  "rounded-[10px]",
                  "bg-[#99A3B7]",
                  "text-center",
                  "ml-2",
                  "cursor-pointer"
                )}
              >
              <FontAwesomeIcon className='text-center text-white  mt-[18px] ' icon={faPlus} />
          </label>
        </div>
      </div>

      {selectedVendor && (
        <>
          <div className="mb-3">
            <input
              type="text"
              id="proprietor_name"
              name="proprietor_name"
              className={classNames(
                'form-control',
                'rounded-[15px]',
                'p-3',
                'bg-white'

              )}
              placeholder="Proprietor Name"
              value={selectedVendor?.proprietor_name ? selectedVendor.proprietor_name : ''}
              onChange={(e) => {
                handleallInputChange("proprietor_name", e.target.value)
                handleErrorInputChange('proprietor_name', e.target.value)
              }}
            />
            {formErrors && formErrors.proprietor_name ? <p className="error-message text-red-500">{formErrors.proprietor_name}</p> : null}
          </div>
          <div className="mb-3">
            <input
              type="text"
              id="vendor_name"
              name="vendor_name"
              className={classNames(
                'form-control',
                'rounded-[15px]',
                'p-3',
                'bg-white'

              )}
              placeholder="Vendor Name"
              value={selectedVendor.name ? selectedVendor.name : ''}
              onChange={(e) => {
                handleallInputChange("name", e.target.value)
                handleErrorInputChange('vendor_name', e.target.value)
              }}
            />
            {formErrors && formErrors.vendor_name ? <p className="error-message text-red-500">{formErrors.vendor_name}</p> : null}
          </div>
          <div className="mb-3">
            <input
              type="text"
              id="proprietor_email"
              name="proprietor_email"
              className={classNames(
                'form-control',
                'rounded-[15px]',
                'p-3',
                'bg-white'

              )}
              placeholder="Proprietor Email"
              value={selectedVendor.email ? selectedVendor.email : ''}
              onChange={(e) => {
                handleallInputChange("email", e.target.value)
                handleErrorInputChange('proprietor_email', e.target.value)
              }}
            />
            {formErrors && formErrors.proprietor_email ? <p className="error-message text-red-500">{formErrors.proprietor_email}</p> : null}
          </div>
          <div className="mb-3 flex ">
            <select
              id="country_code"
              name="country_code"
              className={classNames(
                'w-2/4',
                'rounded-[15px]',
                'p-3',
                'bg-white',
                'mr-2'
              )}
              style={{  border: "1px solid #ccc" }}
              value={selectedVendor.country_code ? selectedVendor.country_code : ''}
              onChange={(e) => handleallInputChange("country_code", e.target.value)}
            >

              <option value="">Select</option>
              {countryList.map((country) => (
                <option key={country.id} className="text-[14px]" value={getDialCodeByCountryCode(country.code)}>
                  <FlagIcon countryCode={country.code} />{getDialCodeByCountryCode(country.code)}
                </option>
              ))}
            </select>
            <input
              type="text"
              id="vendor_phone"
              name="vendor_phone"
              className={classNames(
                'form-control',
                'rounded-[15px]',
                'p-3',
                'bg-white',
                'w-3/4'

              )}
              placeholder="Vendor Phone Number"
              value={selectedVendor.mobile_number ? selectedVendor.mobile_number : ''}
              onChange={(e) => {
                handleallInputChange("mobile_number", e.target.value)
                handleErrorInputChange('vendor_phone', e.target.value)
              }}
            />
            {formErrors && formErrors.vendor_phone ? <p className="error-message text-red-500">{formErrors.vendor_phone}</p> : null}
          </div>
          <div className="mb-3">
            <input
              type="text"
              id="vendor_address"
              name="vendor_address"
              className={classNames(
                'form-control',
                'rounded-[15px]',
                'p-3',
                'bg-white'

              )}
              placeholder="Vendor Address"
              value={selectedVendor.address ? selectedVendor.address : ''}
              onChange={(e) => handleallInputChange("address", e.target.value)}
            />
          </div>
          {/* {country select box} */}
          <div className="mb-3">
            <select
              id="country_id"
              name="country_id"
              className={classNames(
                'w-full',
                'rounded-[15px]',
                'p-3',
                'bg-white'
              )}
              style={{  border: "1px solid #ccc" }}
              value={selectedVendor.country ? selectedVendor.country : ''}
              onChange={(e) => handleallInputChange("country", e.target.value)}
            >
              <option value="">Select Country</option>
              {countryList.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <select
              id="district_id"
              name="district_id"
              className={classNames(
                'w-full',
                'rounded-[15px]',
                'p-3',
                'bg-white'
              )}
              style={{  border: "1px solid #ccc" }}
              value={selectedVendor.district ? selectedVendor.district : ''}
              onChange={(e) => handleallInputChange("district", e.target.value)}
            >
              <option value="">Select District</option>
              {districtList.length === 0 && (
                <option value={selectedVendor.district}>{selectedVendor.district}</option>
              )}
              {districtList.map((district) => (
                <option key={district.id} value={district.id}>
                  {district.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">

            <select
              id="city_id"
              name="city_id"
              className={classNames(
                'w-full',
                'rounded-[15px]',
                'p-3',
                'bg-white'
              )}
              style={{  border: "1px solid #ccc" }}
              value={selectedVendor?.city ? selectedVendor?.city : ''}
              onChange={(e) => handleallInputChange("city", e.target.value)}
            >
              <option value="">Select City</option>
              {cityList.length === 0 && (
                <option value={selectedVendor.city}>{selectedVendor.city}</option>
              )}
              {cityList.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
            <input
              type="text"
              id="vendor_zip"
              name="vendor_zip"
              className={classNames(
                'form-control',
                'rounded-[15px]',
                'p-3',
                'bg-white',
                'mt-2'

              )}
              placeholder="Zip Code"
              value={selectedVendor.zipcode ? selectedVendor.zipcode : ''}
              onChange={(e) => handleallInputChange("zipcode", e.target.value)}
            />
          </div>
          <div className="button-disabled">
            <button
              htmlFor={formId}
              type="submit"
              className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
            > Add Vendor
            </button>
          </div>
        </>
      )}


    </form>
  );
};

export default InvoiceVendorForm;
