

import React, { useState } from "react";

// import LogoAlignLeft from './smallLogo.png';
import { formatDate } from "../../utils/Common";
import TransactionHistoryPDFDetails from "./TransactionHistoryPDFDetails";
import { logDOM } from "@testing-library/react";
import LogoAlignLeft from './bigLogo.png';


const TransactionHistoryPDFDetail = (props) => {
  const {actTab,transactionData,fullApiData, paymentList, paymentAccountList, totalTransaction} = props;
  const methodData = paymentList.data ? paymentList.data : paymentList;
  const methodAccountData = paymentAccountList.data ? paymentAccountList.data : paymentAccountList;


  const createdDate = new Date(transactionData?.date);
  const totalAmount = transactionData.reduce((total, item) => {
    if(item?.completed) return total + item.total_paid;
    return total;
  }, 0);
  const totalInAmount = transactionData.reduce((total, item) => {
    if(item?.transaction_history?.transaction_status=='in' && item?.completed) return total + item.total_paid;
    else return total;
  }, 0);
  const totalOutAmount = transactionData.reduce((total, item) => {
    if(item?.transaction_history?.transaction_status=='out' && item.completed) return total + item.total_paid;
    return total;
  }, 0);
  const totalinvoice = transactionData.reduce((total, item) => {
    if(item.completed) return total +=1;
    return total;
  }, 0);

  // const [paymentAccountAmount, setPaymentAccountAmount] = useState([])
  const paymentAccountAmount = [];
  const temp = [];
  transactionData.map((item)=>{
    ( item.completed && item?.payment_method.map((nesmap)=>{
      paymentAccountAmount.push({...nesmap, status:  item.transaction_history.transaction_status});
    })
    )
  })

  const newPaymentAccountAmount = [...paymentAccountAmount]
  newPaymentAccountAmount.map((item, index)=>{
    if( item.payment_type_id==null || item.payment_type_category_id==null ) {
      paymentAccountAmount[index].method = "Cash"
      paymentAccountAmount[index].methodAccount = "Cash"
      paymentAccountAmount[index].payment_type_id = null
      paymentAccountAmount[index].payment_type_category_id = null
    }
    else{
      methodData.map((method)=>{
        (item.payment_type_id ==method.id &&  method?.payment_type_category.map((acc)=>{
          if( item.payment_type_category_id==acc.id){
            paymentAccountAmount[index].method = method?.type_name
            paymentAccountAmount[index].methodAccount = acc?.payment_category_name
            paymentAccountAmount[index].accountNumber = acc?.account_number
          }
        }))
      })
    }
  })


  const uniqueTrMap = new Set();
  const uniqueMap = paymentAccountAmount.reduce((acc, t) => {
    const key = `${t.payment_type_category_id}_${t.payment_type_id}`;
    if (!uniqueTrMap.has(key)) {
      uniqueTrMap.add(key);
      const total = paymentAccountAmount.reduce((innerAcc, item) => {
        if (
          t.payment_type_category_id === item.payment_type_category_id &&
          t.payment_type_id === item.payment_type_id
        ) {
          return innerAcc + (Number.parseFloat(item.payment_amount));
        } else {
          return innerAcc;
        }
      }, 0);
      acc.push({ ...t, amount: total });
    }
    return acc;
  }, []);


  const inTransactionMethod = paymentAccountAmount.filter((item)=> item.status=='in');
  const outTransactionMethod = paymentAccountAmount.filter((item)=> item.status=='out');

  const uniqueInMap = new Set();
  const uniqueInTransaction = inTransactionMethod.reduce((acc, t) => {
    const key = `${t.payment_type_category_id}_${t.payment_type_id}`;
    if (!uniqueInMap.has(key)) {
      uniqueInMap.add(key);
      const total = inTransactionMethod.reduce((innerAcc, item) => {
        if (
          t.payment_type_category_id === item.payment_type_category_id &&
          t.payment_type_id === item.payment_type_id
        ) {
          return innerAcc + (Number.parseFloat(item.payment_amount));
        } else {
          return innerAcc;
        }
      }, 0);
      acc.push({ ...t, amount: total });
    }
    return acc;
  }, []);

  const uniqueOutMap = new Set();
  const uniqueOutTransaction = outTransactionMethod.reduce((acc, t) => {
    const key = `${t.payment_type_category_id}_${t.payment_type_id}`;
    if (!uniqueOutMap.has(key)) {
      uniqueOutMap.add(key);
      const total = outTransactionMethod.reduce((innerAcc, item) => {
        if (
          t.payment_type_category_id === item.payment_type_category_id &&
          t.payment_type_id === item.payment_type_id
        ) {
          return innerAcc + (Number.parseFloat(item.payment_amount));
        } else {
          return innerAcc;
        }
      }, 0);
      acc.push({ ...t, amount: total });
    }
    return acc;
  }, []);

  const uniqueMethod = new Set();
  const totalMethod = uniqueMap.reduce((acc, t) => {
    const key = `${t.payment_type_id}`;
    if (!uniqueMethod.has(key)) {
      uniqueMethod.add(key);
      acc.push({ ...t });
    }
    return acc;
  }, []);

  const uniqueInMethodMap = new Set();
  const uniqueInTransactionMethod = uniqueInTransaction.reduce((acc, t) => {
    const key = `${t.payment_type_id}`;
    if (!uniqueInMethodMap.has(key)) {
      uniqueInMethodMap.add(key);
      const newMethod = uniqueInTransaction.filter((item) => t.payment_type_id == item.payment_type_id);
      acc.push({ ...t, accountInfo: newMethod });
    }
    return acc;
  }, []);
  const uniqueOutMethodMap = new Set();
  const uniqueOutTransactionMethod = uniqueOutTransaction.reduce((acc, t) => {
    const key = `${t.payment_type_id}`;
    if (!uniqueOutMethodMap.has(key)) {
      uniqueOutMethodMap.add(key);
      const newMethod = uniqueOutTransaction.filter((item) => t.payment_type_id == item.payment_type_id);
      acc.push({ ...t, accountInfo: newMethod });
    }
    return acc;
  }, []);

  // const uniqueBalanceMap = new Set();
  // const uniqueBalanceTransactionMethod = uniqueMap.reduce((acc, t) => {
  //   const key = `${t.payment_type_id}`;
  //   if (!uniqueBalanceMap.has(key)) {
  //     uniqueBalanceMap.add(key);
  //     const newMethod = uniqueMap.filter((item) => t.payment_type_id == item.payment_type_id && item.status=='out');
  //     const newMethod2 = uniqueMap.filter((item) => t.payment_type_id == item.payment_type_id && item.status=='in');
  //     acc.push({ ...t, accountInfo: [...newMethod, ...newMethod2] });
  //   }
  //   return acc;
  // }, []);

  uniqueTrMap.clear();
  const uniqueBalanceTransactionMethod = paymentAccountAmount.reduce((acc, t) => {
    const key = `${t.payment_type_category_id}_${t.payment_type_id}`;
    if (!uniqueTrMap.has(key)) {
      uniqueTrMap.add(key);
      const total = paymentAccountAmount.reduce((innerAcc, item) => {
        if (
          t.payment_type_category_id === item.payment_type_category_id &&
          t.payment_type_id === item.payment_type_id
        ) {
          if(item.status=='out'){
            return innerAcc - (Number.parseFloat(item.payment_amount));
          }
          else{
            return innerAcc + (Number.parseFloat(item.payment_amount));
          }
        }
        else {
          return innerAcc;
        }
      }, 0);
      acc.push({ ...t, amount: total });
    }
    return acc;
  }, []);


  console.log("unique balance", uniqueBalanceTransactionMethod);



  // console.log("Total payment", uniqueBalanceTransactionMethod );
  // console.log("InTransaction", inTransactionMethod, uniqueInTransaction, uniqueInTransactionMethod);
  // console.log("OutTransaction", outTransactionMethod, uniqueOutTransaction, uniqueInTransactionMethod);

  return (
    <div
      id="transactionHistory"
      className="bg-white rounded-3xl font-nunito p-[26px] mb-20"
    >
      <div className="flex justify-between items-center">
        {/* outlet logo */}
        <div  className="w-20 cursor-pointer -pb-[4px]">
          <img src={LogoAlignLeft} className="w-full h-full object-contain" />
        </div>
        {/* title and date */}
        <div className="text-[#000232] text-right">
          <h2 className="text-sm font-semibold">Transaction History</h2>
          {/* <p className="text-xs">{formatDate(createdDate)} - {formatDate(createdDate)}</p> */}
        </div>
      </div>
      {/* table head */}
      <div className="grid text-center mt-4 pt-[4px] pb-[18px] items-center text-xs font-bold bg-[#000232] text-white px-3 grid-cols-10 border-b-[1px] border-[#E7EBF4]">

        <div className="text-left col-span-1">No.</div>
        <div className="text-left col-span-2">Date/Times</div>
        <div className="col-span-2">Method</div>
        <div className="col-span-1">Status</div>
        <div className="col-span-1">Type</div>
        <div className="col-span-2">Invoice ID</div>
        <div className="text-right col-span-1">Amount</div>
      </div>
      {/* table body component */}
      {transactionData.map((cData, index) =>
        (cData.completed &&
          (
          <TransactionHistoryPDFDetails
            index={index}
            paymentList={paymentList}
            paymentAccountList={paymentAccountList}
            transactionData={cData}
            tabActive={actTab}
            key={index}
          />
          )
        )
      )
      }
      <hr className="border-[#000232] border-[1px]" />
      <div className="flex justify-center items-start">
        <div className="w-[40%] mr-auto text-xs font-[500]">
          <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
            <h1 className="text-[15px]">Tansaction Method  History</h1>
          </div>
          <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
            <p>Total Method</p>
            <p>{totalMethod?.length ? totalMethod.length : 0}</p>
          </div>

          <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
            <p>Total Account</p>
            <p>{uniqueMap?.length ? uniqueMap.length : 0}</p>
          </div>

          <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex flex-col">
            <div>
              <p className="text-[15px] font-semibold">Method Report{"(Cash In)"}</p>
            </div>
            { uniqueInTransactionMethod.map((item)=>{
                let total = 0;
                return (
                  <div className="flex flex-col mt-2 flex-1">
                    <div className="">
                      {(item.method=='Cash') ?
                        <div className="flex justify-between items-center py-1">
                          <p className="text-[13px] font-semibold">{item.method}</p>
                          {item.accountInfo && item.accountInfo.map((info)=>{ total += info.amount})}
                          <div className="place-items-end">{Number.parseFloat(item.amount).toFixed(2)}</div>
                        </div>
                        :
                        <div className="flex justify-between items-center flex-1">
                          <div className="flex-1">
                            <p className="text-md text-[13px] font-semibold">{item.method}</p>
                            {item.accountInfo && item.accountInfo.map((info)=>{
                              total += info.amount;
                              return (
                              <div className="flex !justify-between items-center flex-1">
                                <div>
                                  <p className="text-left">{info.methodAccount}</p>
                                  <p className="text-[#FF0000] text-left">AC: {info.accountNumber}</p>
                                </div>
                                <div className="place-items-end">{Number.parseFloat(info.amount).toFixed(2)}</div>
                              </div>)
                            })}
                          </div>
                        </div>
                      }
                    </div>
                    <div>
                      <p className="text-[13px] font-bold text-right">Total amount: { Number.parseFloat(total).toFixed(2)}</p>
                    </div>
                  </div>
                )
              })
            }
          </div>

          <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex flex-col">
            <div>
              <p className="text-[15px] font-semibold">Method Report{"(Cash Out)"}</p>
            </div>
            { uniqueOutTransactionMethod.map((item)=>{
                let total = 0;
                return (
                  <div className="flex flex-col mt-2 flex-1">
                    <div className="">
                      {(item.method=='Cash') ?
                        <div className="flex justify-between items-center py-1">
                          <p className="text-[13px] font-semibold">{item.method}</p>
                          {item.accountInfo && item.accountInfo.map((info)=>{ total += info.amount})}
                          <div className="place-items-end">{Number.parseFloat(item.amount).toFixed(2)}</div>
                        </div>
                        :
                        <div className="flex justify-between items-center flex-1">
                          <div className="flex-1">
                            <p className="text-md text-[13px] font-semibold">{item.method}</p>
                            {item.accountInfo && item.accountInfo.map((info)=>{
                              total += info.amount;
                              return (
                              <div className="flex !justify-between items-center flex-1">
                                <div>
                                  <p className="text-left">{info.methodAccount}</p>
                                  <p className="text-[#FF0000] text-left">AC: {info.accountNumber}</p>
                                </div>
                                <div className="place-items-end">{Number.parseFloat(info.amount).toFixed(2)}</div>
                              </div>)
                            })}
                          </div>
                        </div>
                      }
                    </div>
                    <div>
                      <p className="text-[13px] font-bold text-right">Total amount: { Number.parseFloat(total).toFixed(2)}</p>
                    </div>
                  </div>
                )
              })
            }
          </div>

          <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex flex-col">
            <div>
              <p className="text-[15px] font-semibold">Method Report{"(Balance History)"}</p>
            </div>
            { uniqueBalanceTransactionMethod.map((item)=>{
                let total = 0;
                return (
                  <div className="flex flex-col mt-2 flex-1">
                    <div className="">
                      {(item.method=='Cash') ?
                        <div className="flex justify-between items-center py-1">
                          <p className="text-[13px] font-semibold">{item.method}</p>
                          {/* {total += item.amount} */}
                          <div className="place-items-end">{Number.parseFloat(item.amount).toFixed(2)}</div>
                        </div>
                        :
                        <div className="flex justify-between items-center flex-1">
                          <div className="flex-1">
                            <p className="text-md text-[13px] font-semibold">{item.method}</p>
                              <div className="flex !justify-between items-center flex-1">
                                <div>
                                  <p className="text-left">{item.methodAccount}</p>
                                  <p className="text-[#FF0000] text-left">AC: {item.accountNumber}</p>
                                </div>
                                <div className="place-items-end">{Number.parseFloat(item.amount).toFixed(2)}</div>
                              </div>
                          </div>
                        </div>
                      }
                    </div>
                    <div>
                      <p className="text-[13px] font-bold text-right">Total amount: { Number.parseFloat(item.amount).toFixed(2)}</p>
                    </div>
                  </div>
                )
              })
            }
          </div>



          {/* <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
            <p className="text-[14px]">Method Report{"(Balance History)"}</p>

          </div> */}
        </div>


        <div className="w-[38%] ml-auto text-xs font-[500]">
          <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
            <h1 className="text-[15px]">Transaction History</h1>
          </div>
          <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
            <p>Total Transaction Amount</p>
            {/* <p>{fullApiData?.total_transaction_amount}</p> */}
            <p>{Number.parseFloat(totalTransaction.total_transaction_amount).toFixed(2)}</p>
          </div>
          <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
            <p>Total In Transaction Amount</p>
            {/* <p>{fullApiData?.total_transaction_in_amount}</p> */}
            <p>{Number.parseFloat(totalTransaction.total_transaction_in_amount).toFixed(2)}</p>
          </div>
          <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
            <p>Total Out Transaction Amount</p>
            {/* <p>{fullApiData?.total_transaction_out_amount}</p> */}
            <p>{Number.parseFloat(totalTransaction.total_transaction_out_amount).toFixed(2)}</p>
          </div>
          <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
            <p>Balance Amount</p>
            {/* <p>{fullApiData?.total_balance}</p> */}
            <p>{Number.parseFloat(totalTransaction.total_balance).toFixed(2)}</p>
          </div>
          <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
            <p>Total Transaction Number</p>
            <p>{Number.parseFloat(totalTransaction.total_transaction).toFixed(2)}</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default TransactionHistoryPDFDetail;