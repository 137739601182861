import React, { useEffect, useRef, useState } from 'react';
import pdfIcon from "../../assets/images/pdf-Icon.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";


import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Pagination from '../../utils/pagination';
import ContentLayout from '../../layout/ContentLayout';
import { AiOutlineSearch } from 'react-icons/ai';
import CategoryStockHistoryItem from '../Invoice/CategoryStockHistoryItem';
import CategoryStockDetails from '../../pages/CategoryStockDetails/CategoryStockDetails';

const BASE_URL = process.env.REACT_APP_ROOT_URL;
const CategoryStockHistory = () => {

const today = new Date();
today.setHours(0, 0, 0, 0);

  const perPage = 20;
  useEffect(() => {
    window.scrollTo(0, 100);
  }, []);
  const [CategoryStockList, setCategoryStockList] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [activeTab, setActiveTab] = useState("daily");
  const [allFetchedData, setAllFetchedData] = useState([]);
  const [allFetchedDataUnSelected, setAllFetchedDataUnSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [showPdf, setShowPdf] = useState(false);
  const [allApiData,setAllApiData] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [reportBtn, setReportBtn] = useState(false)
  const [total, setTotal] = useState(0.00);
  const [unSelected, setUnSelected] = useState(false)
  const [currentTotal, setCurrentTotal] = useState([]);


  const loadingCallback = (loading) => {
    setLoading(loading);
  };

  const handleTabClick = (index) => {
    setReportBtn(false)
    setSelectAllChecked(false)
    setSelectedItems([])
    setSelectAllChecked(false);

    setActiveTab(index);
  };


  // const [searchFilter, setSearchFilter] = useState({
  //   keyword: ''
  // })
  const handleChangeTable = () => {
    setSelectedItems([])
    const newdata = allFetchedData.map(item => ({ ...item, completed: false }));
    setAllFetchedData(newdata);
    setSelectAllChecked(false)
  };

  const CategoryStockDetailsRef = useRef(null);


  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const handleSelectAllChange = () => {
    const newdata = allFetchedData.map(item => ({ ...item, completed: !selectAllChecked }));
    setAllFetchedData(newdata);
    setSelectAllChecked(!selectAllChecked);
  };

  const getDateWiseCategoryStock = (page) =>{
    loadingCallback(true);
    const date = {
      start_date: startDate,
      end_date: endDate,
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}category-stock-report-date-wise?page=${page}&limit=${perPage}`, date,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        if (response.data.success) {
          const fetchedDatas= response.data;
          setAllApiData(fetchedDatas);
          setCategoryStockList(response.data.data)
          const fetchedData = response.data.data.data;
          const addCompleted = fetchedData.map(item => ({ ...item, completed: false }));
          setAllFetchedData(addCompleted);

          // const addCompleted2 = fetchedData.map(item => ({ ...item, completed: true }));
          // setAllFetchedDataUnSelected(addCompleted2)

          const totalMoney = fetchedData.reduce((total, item) => {
            return total + item.total_paid;
          }, 0);
          setTotal(totalMoney);
          setCurrentTotal(fetchedData.length);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          localStorage.setItem("isPinVerify", false);
          navigate("/login");
        }
      });
  }

  const getCategoryStockReport = (interval, page) => {
    if(reportBtn){
      getDateWiseCategoryStock(page)
    }
    else{
      loadingCallback(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}category-stock-report?interval=${interval}&page=${page}&limit=${perPage}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          loadingCallback(false);
          if (response.data.success) {
            const fetchedDatas= response.data;
            setAllApiData(fetchedDatas);
            setCategoryStockList(response.data.data)
            const fetchedData = response.data.data.data;
            const addCompleted = fetchedData.map(item => ({ ...item, completed: false }));
            setAllFetchedData(addCompleted);
            const totalMoney = fetchedData.reduce((total, item) => {
              return total + item.total_paid;
            }, 0)
            setTotal(totalMoney);
            setCurrentTotal(fetchedData.length);
          }
        })
        .catch((error) => {
          console.log(error);
          loadingCallback(false);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem("token", false);
            localStorage.setItem("isPinVerify", false);
            navigate("/login");
          }
        });
    }
  };

  // const handleCategoryStockSearch = (page, interval) =>{
  //   loadingCallback(true);
  //   const data = {
  //     start_date: startDate,
  //     end_date: endDate,
  //     keyword: searchFilter.keyword
  //   }
  //   console.log("search", interval, page, perPage, data.keyword);
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_URL}transaction-search?interval=${interval}&page=${page}&limit=${perPage}`, {
  //         keyword: data.keyword
  //       },
  //       {
  //         headers: {
  //           authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       loadingCallback(false);
  //       if (response.data.success) {
  //         const fetchedDatas= response.data;
  //         setAllApiData(fetchedDatas);
  //         setCategoryStockList(response.data.data)
  //         const fetchedData = response.data.data.data;
  //         const addCompleted = fetchedData.map(item => ({ ...item, completed: false }));
  //         setAllFetchedData(addCompleted);

  //         const totalMoney = fetchedData.reduce((total, item) => {
  //           return total + item.total_paid;
  //         }, 0);
  //         setTotal(totalMoney);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       loadingCallback(false);
  //       if (error?.response?.status === 401) {
  //         localStorage.clear();
  //         localStorage.setItem("token", false);
  //         localStorage.setItem("isPinVerify", false);
  //         navigate("/login");
  //       }
  //     });
  // }

  const handleReportButton = () => {
    setReportBtn(true);
    // setSelectedItems([])
    setSelectAllChecked(false)
    getDateWiseCategoryStock(1);
  }

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    // if(searchFilter.keyword){
    //   handleCategoryStockSearch(currentPage, activeTab);
    // }
    // else{
      getCategoryStockReport(activeTab,currentPage);
      setSelectAllChecked(false);
    // }
  }, [activeTab, currentPage]);
  // }, [activeTab, currentPage, searchFilter]);

  const originalContents = document.body.innerHTML;
  window.onafterprint = () => {
    document.body.innerHTML = originalContents;
    window.location.reload();
  };


  const handlePrintPdf = async (elementId) => {
    const total = allFetchedData.reduce((cnt, item) => {
      if (item.completed) {return cnt + 1;}
      return cnt;
    }, 0);
    if(total<1){
      handleSelectAllChange2(() => {
        handlePDFDownload(elementId);
      });
    }
    else{
      handlePDFDownload(elementId)
    }
  };
  const handleSelectAllChange2 = (callback) => {
    const newdata = allFetchedData.map(item => ({ ...item, completed: true }));
    setAllFetchedDataUnSelected(newdata);
    setUnSelected(true);
    setTimeout(() => {
      callback()
    },0);
  };



  const handlePDFDownload = (elementId) =>{
    // window.html2canvas = html2canvas;
    // const pdf = new jsPDF("p", "mm", "a4");
    // const content = CategoryStockDetailsRef?.current?.innerHTML;
    // pdf.html(content, {
    //   html2canvas: {
    //     scale: .285,
    //   },
    //   margin: [6, 5, 6, 5],
    //   width: 700,
    //   windowWidth: 700,
    //   callback: () => {
    //     pdf.save("Category_Stock_history_details.pdf");
    //   },
    // });
    // const newdata = allFetchedData.map(item => ({ ...item, completed: false }));
    // setAllFetchedData(newdata);
    // setSelectAllChecked(false)
    // setUnSelected(false)
    const printContents = document.getElementById(elementId).innerHTML;
    document.body.innerHTML = printContents;
    window.print();
  }
  // selected transaction items
  const handleItemSelect = ( index) => {
    const updatedData = [...allFetchedData];
    updatedData[index].completed =  !updatedData[index].completed ;
    setAllFetchedData(updatedData)
    setSelectAllChecked(false);
  };

  useEffect(()=>{
    setSelectedItems([])
    // setSearchFilter({...searchFilter, keyword: ''})
  },[activeTab]);
   const shouldRenderPagination = allApiData?.data?.total != currentTotal && allApiData?.data?.total > perPage;

  return (
    <>
    <div className={`${showPdf?'hidden':'block'}`}>
      <ContentLayout>
        {/* time period menu */}
        <div className="flex flex-row gap-[1%] pt-32 md:pt-0 pb-3 md:!pb-0 fixed md:static bg-[#EBF1FF] md:bg-transparent md:translate-x-0 top-0 left-0 pl-[30px] md:pl-0 w-full z-[9998]">
          <div
            onClick={() => handleTabClick("daily")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${activeTab === "daily"
              ? "border-[#407BFF] text-[#407BFF]"
              : "text-[#1C2F48] border-transparent"
              }`}
          >
            Daily
          </div>
          <div
            onClick={() => handleTabClick("weekly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${activeTab === "weekly"
              ? "border-[#407BFF] text-[#407BFF]"
              : "text-[#1C2F48] border-transparent"
              }`}
          >
            Weekly
          </div>
          <div
            onClick={() => handleTabClick("monthly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${activeTab === "monthly"
              ? "border-[#407BFF] text-[#407BFF]"
              : "text-[#1C2F48] border-transparent"
              }`}
          >
            Monthly
          </div>
          <div
            onClick={() => handleTabClick("yearly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${activeTab === "yearly"
              ? "border-[#407BFF] text-[#407BFF]"
              : "text-[#1C2F48] border-transparent"
              }`}
          >
            Yearly
          </div>
        </div>

        {/* filter and report section */}
        <div className="bg-white rounded-3xl mt-3 font-nunito p-[26px] grid grid-cols-1 gap-y-4 md:gap-y-0 md:gap-x-4 md:grid-cols-12 justify-between items-center">
          {/* card */}
          <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] w-full col-span-3 items-stretch bg-[#FF7A00] text-white flex justify-between py-7 px-3 rounded-2xl">
            <div className="space-y-1">
              <p className="font-semibold whitespace-nowrap text-xl">
                {allFetchedData.map(clist =>{
                  clist?.total_sales_amount;
                })}<sub className="text-[9px] font-extralight ml-1">BDT</sub>
              </p>
              <p className="text-xs font-normal">Category Stock Report</p>
            </div>
          </div>
          {/* date pickers */}
          <div className="flex justify-between w-full col-span-7 items-center mt-[12px] md:mt-0 flex-col md:flex-row font-nunito gap-4">
            <div className="relative border-[#C1CFEF] py-2.5 border w-full flex-1 rounded-xl">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="dd/MM/yyyy"
                className="w-full px-2 focus:outline-none"
              />
              <span className="bg-white absolute left-2 font-semibold px-1 text-xs top-0 text-[#102048] translate-y-[-50%]">
                Start Date
              </span>
              {/* <span className={`${startDate && startDate.toLocaleDateString() === "DD / MM / YYYY" ? "text-[#91919F]" : "text-[#232324]"} absolute left-[6px] px-1  text-sm lg:text-base bg-white`}>
              {startDate && startDate.toLocaleDateString()}
            </span> */}
            </div>
            <div className="relative border-[#C1CFEF] py-2.5  border w-full flex-1 rounded-xl">
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="dd/MM/yyyy"
                className="w-full px-2 focus:outline-none"
              />
              <span className="bg-white absolute left-2 px-1 font-semibold text-xs top-0 text-[#102048] translate-y-[-50%]">
                End Date
              </span>
              {/* <span className={`${endDate && endDate.toLocaleDateString() === "DD / MM / YYYY" ? "text-[#91919F]" : "text-[#232324]"} absolute bg-white text-sm lg:text-base left-[6px] px-1`}>
              {endDate && endDate.toLocaleDateString()}
            </span> */}
            </div>
          </div>
          {/* filter button */}
          <button
            onClick={ handleReportButton}
            // onClick={ ()=>setReportBtn(true)}
           className=" bg-[#034AFF] cursor-pointer col-span-2 font-nunito text-white rounded-xl px-3 xl:px-5 py-2 text-md">
            Report
          </button>
        </div>
        {/* main content */}
        <div className="bg-white rounded-3xl font-nunito mt-4 p-[26px]">
          <div className="flex justify-between items-center">
            {/* section title & switch,search & pdf button*/}
            <div className="flex gap-3 items-center">
              {/* section title */}
                  <h2 className="text-xl font-semibold">
                   Category Stock History
                  </h2>

              {/* switch button */}
            </div>
            <div className="flex gap-3 items-center">


              <div className="flex relative w-[95%]">
                    <input
                        autoFocus
                        type="text"
                        className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[45px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                        placeholder="Search Transaction"
                        // onChange={(e) => setSearchFilter({...searchFilter, keyword: e.target.value})}
                    />
                    <div className="absolute top-[17px] left-[10px]">
                        <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                    </div>

                    <div className="absolute top-[17px]  right-[10px]">
                        <div
                            // ref={menuRef}
                            // ref={buttonRef}
                            // onClick={handleButtonClick}
                            className=" relative flex flex-col items-center">
                            <svg  xmlns="http://www.w3.org/2000/svg"  width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M0.83268 12.6665C0.83268 12.3932 1.05935 12.1665 1.33268 12.1665L8.66602 12.1665C8.93935 12.1665 9.16602 12.3932 9.16602 12.6665C9.16602 12.9398 8.93935 13.1665 8.66602 13.1665L1.33268 13.1665C1.05935 13.1665 0.83268 12.9398 0.83268 12.6665Z" fill="#102048" />
                                <path d="M10.8327 12.6665C10.8327 12.3932 11.0594 12.1665 11.3327 12.1665L14.666 12.1665C14.9393 12.1665 15.166 12.3932 15.166 12.6665C15.166 12.9398 14.9393 13.1665 14.666 13.1665L11.3327 13.1665C11.0594 13.1665 10.8327 12.9398 10.8327 12.6665Z" fill="#102048" />
                                <path d="M0.832684 8C0.832684 7.72667 1.05935 7.5 1.33268 7.5L4.66602 7.5C4.93935 7.5 5.16602 7.72667 5.16602 8C5.16602 8.27333 4.93935 8.5 4.66602 8.5L1.33268 8.5C1.05935 8.5 0.832684 8.27333 0.832684 8Z" fill="#102048" />
                                <path d="M6.83268 8C6.83268 7.72667 7.05935 7.5 7.33268 7.5L14.666 7.5C14.9393 7.5 15.166 7.72667 15.166 8C15.166 8.27333 14.9393 8.5 14.666 8.5L7.33268 8.5C7.05935 8.5 6.83268 8.27333 6.83268 8Z" fill="#102048" />
                                <path d="M0.83268 3.3335C0.83268 3.06016 1.05935 2.8335 1.33268 2.8335L8.66602 2.8335C8.93935 2.8335 9.16602 3.06016 9.16602 3.3335C9.16602 3.60683 8.93935 3.8335 8.66602 3.8335L1.33268 3.8335C1.05935 3.8335 0.83268 3.60683 0.83268 3.3335Z" fill="#102048" />
                                <path d="M10.8327 3.3335C10.8327 3.06016 11.0594 2.8335 11.3327 2.8335L14.666 2.8335C14.9393 2.8335 15.166 3.06016 15.166 3.3335C15.166 3.60683 14.9393 3.8335 14.666 3.8335L11.3327 3.8335C11.0594 3.8335 10.8327 3.60683 10.8327 3.3335Z" fill="#102048" />
                                <path d="M8.16602 4.66667L8.16602 2C8.16602 1.72667 8.39268 1.5 8.66602 1.5C8.93935 1.5 9.16602 1.72667 9.16602 2L9.16602 4.66667C9.16602 4.94 8.93935 5.16667 8.66602 5.16667C8.39268 5.16667 8.16602 4.94 8.16602 4.66667Z" fill="#102048" />
                                <path d="M8.16602 14.0002L8.16602 11.3335C8.16602 11.0602 8.39268 10.8335 8.66602 10.8335C8.93935 10.8335 9.16602 11.0602 9.16602 11.3335L9.16602 14.0002C9.16602 14.2735 8.93935 14.5002 8.66602 14.5002C8.39268 14.5002 8.16602 14.2735 8.16602 14.0002Z" fill="#102048" />
                                <path d="M6.83398 9.33317L6.83398 6.6665C6.83398 6.39317 7.06065 6.1665 7.33398 6.1665C7.60732 6.1665 7.83398 6.39317 7.83398 6.6665L7.83398 9.33317C7.83398 9.6065 7.60732 9.83317 7.33398 9.83317C7.06065 9.83317 6.83398 9.6065 6.83398 9.33317Z" fill="#102048" />

                            </svg>
                        </div>
                    </div>

              </div>
              <div
                // onClick={() => handlePrintPdf()}
                onClick={() => handlePrintPdf('catehorystockHistory')}
                className="bg-[#EBF1FF] rounded-full w-[34px] h-[34px] flex items-center justify-center cursor-pointer transform hover:scale-110 duration-200"
              >
                <img className="w-[16px]" src={pdfIcon} alt="pdfIcon" />

              </div>
            </div>
          </div>


            <div>
              <div className=" grid text-left text-[14px] mt-3 py-[8px] font-nunito font-bold text-[#000232] grid-cols-7 border-b-[1px] border-[#E7EBF4]">
                <div className='text-left'>

                  <p className='text-[14px] font-bold justify-start items-center flex'>
                    <input
                    className='me-1'
                    type="checkbox"
                    checked={selectAllChecked}
                    onChange={handleSelectAllChange}
                  /> Select All </p>
                </div>
                <div className="text-left">Category Name</div>
                <div className="text-center">Product Stock Unit</div>
                <div className="text-center">Sale Unit</div>
                <div className="text-center">Purchase Unit</div>
                <div className="text-center">Due</div>
                <div className="text-right">Total Sale Amount</div>
              </div>

              {allFetchedData.map((cData, index) => {
                return <CategoryStockHistoryItem
                  index={index}
                  CStockData={cData}
                  tabActive={activeTab}
                  selectAllChecked={selectAllChecked}
                  onSelect={handleItemSelect}
                  setAllFetchedData={setAllFetchedData}
                  allFetchedData={allFetchedData}
                  key={index}

                 />
              })}

                {shouldRenderPagination && (
                // {invoiceData.length > 2 && (
                <Pagination
                  currentPage={currentPage}
                  lastPage={allApiData.data.last_page}
                  // currentPage={currentPage}
                  // lastPage={10}
                  onPageChange={onPageChange}
                />
              )}
            </div>
        </div>
        {loading && (

          <div className="custom-loader-logo">
            {/* <BeatLoader color={"#123abc"} size={20} /> */}
            <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
          </div>
        )}

      </ContentLayout>
      </div>
      <div className={`${!showPdf?'hidden':'block'}`}>
        <div ref={CategoryStockDetailsRef}>

              <CategoryStockDetails
                CStockData={unSelected ? allFetchedDataUnSelected : allFetchedData}
                fullApiData={allApiData}
                activeTab={activeTab}
              />


        </div>
      </div>
    </>
  );
};

export default CategoryStockHistory;
