import React, { useState } from "react";

import { formatDate } from "../../utils/Common";
import TransactionHistoryPDFDetails4Cols from "./TransactionHistoryPDFDetails4Cols";
import LogoAlignLeft from './bigLogo.png';


const TransactionHistoryPDFDetail4Cols = (props) => {
  const {actTab,transactionData,fullApiData, paymentList, paymentAccountList} = props;
  const methodData = paymentList.data ? paymentList.data : paymentList;
  const methodAccountData = paymentAccountList.data ? paymentAccountList.data : paymentAccountList;

  const createdDate = new Date(transactionData?.date);
  const totalAmount = transactionData.reduce((total, item) => {
    if(item?.completed) return total + item.total_paid;
    return total;
  }, 0);
  const totalInAmount = transactionData.reduce((total, item) => {
    if(item?.transaction_history?.transaction_status=='in' && item?.completed) return total + item.total_paid;
    else return total;
  }, 0);
  const totalOutAmount = transactionData.reduce((total, item) => {
    if(item?.transaction_history?.transaction_status=='out' && item.completed) return total + item.total_paid;
    return total;
  }, 0);
  const totalinvoice = transactionData.reduce((total, item) => {
    if(item.completed) return total +=1;
    return total;
  }, 0);

  return (
    <div
      className="bg-white rounded-3xl font-nunito p-[26px] mb-20"
    >
      <div className="flex justify-between items-center">
        {/* outlet logo */}
        <div  className="w-20 cursor-pointer -pb-[4px]">
          <img src={LogoAlignLeft} className="w-full h-full object-contain" />
        </div>
        {/* title and date */}
        <div className="text-[#000232] text-right">
          <h2 className="text-sm font-semibold">Transaction History</h2>
          {/* <p className="text-xs">{formatDate(createdDate)} - {formatDate(createdDate)}</p> */}
        </div>
      </div>
      {/* table head */}
      <div className="grid text-center mt-4 pt-[4px] pb-[18px] items-center text-xs font-bold bg-[#000232] text-white px-3 grid-cols-8 border-b-[1px] border-[#E7EBF4]">
        <div className="text-left col-span-1">No.</div>
        <div className="col-span-2">Transaction Status</div>
        <div className="col-span-2">Transaction Type</div>
        <div className="col-span-2">Invoice ID</div>
        <div className="text-right col-span-1">Amount</div>
      </div>
      {/* table body component */}
      {transactionData.map((cData, index) =>
        (cData.completed &&
          (
          <TransactionHistoryPDFDetails4Cols
            index={index}
            paymentList={paymentList}
            paymentAccountList={paymentAccountList}
            transactionData={cData}
            tabActive={actTab}
            key={index}
          />
          )
        )
      )
      }
      <hr className="border-[#000232] border-[1px]" />
      <div className="flex justify-center items-start">
        <div className="w-[38%] ml-auto text-xs font-[500]">
          <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
            <h1 className="text-[15px]">Transaction History</h1>
          </div>
          <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
            <p>Total In Transaction Amount</p>
            {/* <p>{fullApiData?.total_transaction_amount}</p> */}
            <p>{Number.parseFloat(totalInAmount).toFixed(2)}</p>
          </div>
          <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
            <p>Total Out Transaction Amountt</p>
            {/* <p>{fullApiData?.total_transaction_in_amount}</p> */}
            <p>{Number.parseFloat(totalOutAmount).toFixed(2)}</p>
          </div>
          <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
            <p>Total Transaction Amount</p>
            {/* <p>{fullApiData?.total_transaction_out_amount}</p> */}
            <p>{Number.parseFloat(totalAmount).toFixed(2)}</p>

          </div>
        </div>
      </div>

    </div>
  );
};

export default TransactionHistoryPDFDetail4Cols;