import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { AppContext } from "../../context/context";
import { formatPrice, formatDate } from '../../utils/Common';
const VendorListItem = (props) => {
  const { config, state } = useContext(AppContext);
  const { BASE_URL } = config;

  const {
    vendor
  } = props;
  return (
    <div>
      <div className="border-t-[1px] text-[11px] md:text-sm border-solid border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-5 items-start">
        <div className="">
          <h1 className="text-[#000232] font-[500]">#{vendor.id}</h1>
          <p className="text-[#87889E] font-[700]">{vendor.name}</p>
        </div>
        <p className="text-[#000] font-[500] text-center">{vendor.purchase_number}</p>
        <p className="text-[#000] font-[500] text-center">{vendor.purchase_amount}</p>
        <div className="text-[#000] font-[500] text-center">{vendor.due}</div>

        <div className="text-[12px] font-nunito font-[700] flex flex-col lg:flex-row gap-2 justify-end items-end">
         <Link className="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]" to={`/vendor-profile/${vendor.id}`}>VIEW</Link>
        </div>
      </div>

    </div>
  );
};

export default VendorListItem;