import React, { useState, useRef, useEffect } from "react";
import { AiOutlinePicLeft } from "react-icons/ai";
import { BsTextIndentLeft } from "react-icons/bs";
import { TbTargetArrow } from "react-icons/tb";
import { FaDraftingCompass, FaPercent } from "react-icons/fa";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { BiCommentError } from "react-icons/bi";
import { LuSettings, LuFileQuestion } from "react-icons/lu";
import CustomNavLink from "./CustomNavLink ";
import { MdOutlinePayment } from "react-icons/md";

import { TbTruckDelivery } from "react-icons/tb";
import toparrow from "../../../assets/images/toparrow.png";
import { Link } from "react-router-dom";
import menubarcss from "./MenuBar.module.css";
import { FaSignLanguage } from "react-icons/fa";
import warrantyIcon from "./../../../assets/images/warranty-icon.png"


const MenuBar = () => {

  const [showNavMenu , setShowNavMenu] = useState(false);
  const menuRef = useRef(null);

  const handleButtonClick = () => {
    setShowNavMenu((prevShowNavMenu) => !prevShowNavMenu);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowNavMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="flex gap-[5%] justify-center md:justify-normal md:gap-[158px] mt-6 mb-3">
        <div className="Service w-1/2 mt-1 ml-3 md:ml-0">
          <h3 className="pb-2.5 font-lato font-bold text-[13px] text-[#7282a096]">
            Service Setup & History
          </h3>
          <div className="flex-none md:flex grid grid-cols-2 md:grid-cols-none md:flex-row gap-6">
            <div className="flex flex-col items-center">
              <CustomNavLink
                to="/settings/category"
                menuName="Category"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 163, 238, 0.15)"
              >
                <p className="text-xl font-bold text-[#551FFF]">
                  <AiOutlinePicLeft></AiOutlinePicLeft>
                </p>
              </CustomNavLink>
            </div>
            <div className="flex flex-col items-center">
              <CustomNavLink
                to="/settings/subcategory"
                menuName="Subcategory"
                className="p-2.5 rounded-full"
                activeColor="rgba(253, 34, 83, 0.15)"
              >
                <p>
                  <BsTextIndentLeft className="text-xl font-bold text-[#FD2253]"></BsTextIndentLeft>
                </p>
              </CustomNavLink>
            </div>
            <div className="flex flex-col items-center">
              <CustomNavLink
                to="/settings"
                menuName="Invoice List"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 163, 238, 0.15)"
              >
                <p>
                  <RiMoneyDollarCircleLine className="text-xl font-bold text-[#00A3EE]"></RiMoneyDollarCircleLine>
                </p>
              </CustomNavLink>
            </div>

            <div className="flex flex-col items-center">
              <CustomNavLink
                to="/settings/brands"
                menuName="Brands"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 184, 148, 0.15)"
              >
                <p>
                  <TbTargetArrow className="text-xl font-bold text-[#00B894]"></TbTargetArrow>
                </p>
              </CustomNavLink>
            </div>
            <div className="flex flex-col items-center">
              <CustomNavLink
                to="/settings/units"
                menuName="Units"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 148, 255, 0.15)"
              >
                <p>
                  <FaDraftingCompass className="text-xl font-bold text-[#0094FF]"></FaDraftingCompass>
                </p>
              </CustomNavLink>
            </div>
            {/* <div className="flex flex-col items-center">
              <CustomNavLink
                to="/settings/discounts"
                menuName="Discounts"
                className="p-2.5 rounded-full"
                activeColor="rgba(255, 108, 2, 0.15)"
              >
                <p>
                  <FaPercent className="text-xl font-bold text-[#FF6C02] -scale-x-100"></FaPercent>
                </p>
              </CustomNavLink>
            </div> */}
          </div>
        </div>
        <div className="mt-1">
          <h3 className="pb-2.5 font-lato font-bold text-[13px] text-[#7282a096] ">Application</h3>
          <div className="flex flex-col md:flex-row gap-6">
            <div ref={menuRef} className="relative flex flex-col items-center">
              <CustomNavLink
                showNavigation={handleButtonClick}
                menuName="Settings"
                className="p-2.5 rounded-full"
                activeColor="rgba(18, 203, 196, 0.15)"
              >
                <p className="text-xl font-bold text-[#12CBC4]">
                  <LuSettings></LuSettings>
                </p>
              </CustomNavLink>

              {showNavMenu && (
                <div className="absolute z-50 w-[200px] left-[-100%] md:left-[-22px] top-[67px] bg-[#FFF] border-2 border-solid border-black p-3 rounded-[20px]">
                  <img className="absolute top-[-13px] left-[50%] md:left-[33px]" src={toparrow} width={15} alt="" />
                  <Link
                    className={`${menubarcss.menu} ${location.pathname === '/settings/invoice-settings' ? menubarcss.active : ''}`}
                    to={`/settings/invoice-settings`}
                    onClick={handleButtonClick}
                  >
                    Invoice Settings
                  </Link>
                  <Link
                    className={`${menubarcss.menu} ${location.pathname === '/settings/shop-settings' ? menubarcss.active : ''}`}
                    to={`/settings/shop-settings`}
                    onClick={handleButtonClick}
                  >
                    Shop Settings
                  </Link>
                </div>
              )}
            </div>
            <div className="flex flex-col items-center">
              <CustomNavLink
                to="/settings/payment"
                menuName="Payment"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 148, 255, 0.15)"
              >
                <p>
                  <MdOutlinePayment className="text-xl font-bold text-[#0094FF]"></MdOutlinePayment>
                </p>
              </CustomNavLink>
            </div>
            <div className="flex flex-col items-center">
              <CustomNavLink
                to="/settings/delivery"
                menuName="Delivery"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 148, 255, 0.15)"
              >
                <p>
                  <TbTruckDelivery className="text-xl font-bold text-[#0094FF]" />
                </p>
              </CustomNavLink>
            </div>
            <div className="flex flex-col items-center">
              <CustomNavLink
                to="/settings/warranty"
                menuName="Warranty"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 184, 148, 0.15)"
              >
                <p>
                <img src={warrantyIcon} alt="warrantyIcon" className="w-5"/>

                 {/* <FaSignLanguage  className="text-xl font-bold text-[#bf4444]" /> */}
                </p>
              </CustomNavLink>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
