import React, { useState, useRef, useEffect, useContext } from "react";
import ContentLayout from '../../layout/ContentLayout/index.jsx'
import Footer from "../../components/footer/Index.jsx";
import EditOrderProduct from "../../components/EditOrderProduct/EditOrderProduct.js";
import { AppContext } from '../../context/context.js';
import { useParams, useNavigate } from 'react-router-dom';
import { get, post } from "../../services/apiService.js";
import { BeatLoader } from 'react-spinners';

const InvoiceEditPage = () => {
  const { id } = useParams(); // Get the id parameter from the URL
  const {
      state,
  } = useContext(AppContext);

  const [discount, setDiscount] = useState(0)
  const [paidAmount, setPaidAmount] = useState(0);

  const { invoiceSetting,currentUser } = state;
  const token = localStorage.getItem("token");
  const invoice = {
    logo: "https://example.com/logo.png",
    name: "Acme Corporation",
    number: "INV-12345",
    date: "2023-10-04",
    created_at: "2023-10-04",
    invoice_id: "INV-12345",
    customer_name: "John Doe",
    customer_phone: "123213123",
    sales_details: [
      {
        id: 1,
        name: "Widget A",
        qty: 2,
        price: 10,
        total: 20,
        product_info: {
          name: "Widget A",
          price: 10
        }
      },
      {
        id: 2,
        name: "Widget B",
        qty: 1,
        price: 50,
        total: 50,
        product_info: {
          name: "Widget B",
          price: 50
        }
      },
      {
        id: 3,
        name: "Widget C",
        qty: 1,
        price: 100,
        total: 100,
        product_info: {
          name: "Widget C",
          price: 100
        }
      }
    ],
    sub_total: 170,
    tax: 25.5,
    paid_amount: 195.5,
    vat: 15
  };
  const [invoiceData, setInvoiceData] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };
  useEffect(() => {
      // Fetch invoice data based on the ID and populate
      fetchInvoiceData(id);
  }, [id]);

  const fetchInvoiceData = (invoiceId) => {

    loadingCallback(true);
    // Make an API request to fetch product data by ID
    // Once data is retrieved, update the form fields with the data
    post("invoice-details", { 'invoice_id': invoiceId }, token)
      .then((response) => {
        loadingCallback(false);
        const invoiceData = response.data; // Replace with your API response data
        setDiscount(invoiceData.discount);
        setPaidAmount(invoiceData.paid_amount)
        setInvoiceData(invoiceData);
      })
      .catch((error) => {
        loadingCallback(false);
        console.log(error);
        // if (error?.response?.status === 401) {
        //   localStorage.setItem('token', '');
        //   localStorage.setItem('token', '');
        //   // Clear session and local storage
        //   localStorage.setItem('isPinVerify', false);
        //   navigate('/login');
        // }
      });
  };
  return (
    <>
      <ContentLayout>
        {invoiceData && (
          <div className="">
            <EditOrderProduct
            type="billing"
            salesType=""
            initialDiscount={discount}
            setInitialDiscount={setDiscount}
            paidAmount={paidAmount}
            setPaidAmount={setPaidAmount}
            currentUser={currentUser ? currentUser : null}
            invoiceData={invoiceData ? invoiceData : null}
            ></EditOrderProduct>
            <Footer />
          </div>
        )}
        {loading && (

          <div className="custom-loader-logo">
            {/* <BeatLoader color={"#123abc"} size={20} /> */}
            <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
          </div>
        )}
      </ContentLayout>
    </>
  );
};


export default InvoiceEditPage