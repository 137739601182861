import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import uploadImage from '../../src/assits/upload-image.png'
import { MdUploadFile } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoCloseCircleSharp } from "react-icons/io5";

const ImageUploaderFrame = ({ isBig, onChange, name, image, images, noUpload }) => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [lastImageId, setLastImageId] = useState(0);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const totalImages = acceptedFiles.length + uploadedImages.length;
      if (totalImages > 4) {
        setErrorMessage("You can only upload a maximum of 4 images.");
        return;
      }


      setErrorMessage(""); // Clear any previous error message
      for (let i = 0; i < acceptedFiles.length; i++) {
        // Perform the upload to the API using the onChange prop
        try {
          const response = await onChange(acceptedFiles[i], lastImageId + i + 1);
          // Assuming the API returns the uploaded image path, add it to the uploadedImages state
          // setUploadedImages((prevImages) => [
          //   ...prevImages,
          //   response.imagePath,
          // ]);
        } catch (error) {
          // Handle any upload errors
          console.error("Error uploading image:", error);
        }
      }
      // Update the last image ID
      setLastImageId(lastImageId + acceptedFiles.length);
      // Add the accepted files to the uploadedImages state
      setUploadedImages((prevImages) => [
        ...prevImages,
        ...acceptedFiles.map((file) => URL.createObjectURL(file)),
      ]);
    },
  });

  const handleCloseImage = (index) => {
    const newUploadedImages = [...uploadedImages];
    newUploadedImages.splice(index, 1);

    // Decrease the IDs of the remaining images after the removed image
    for (let i = index; i < newUploadedImages.length; i++) {
      newUploadedImages[i].id -= 1;
    }

    setUploadedImages(newUploadedImages);
    setLastImageId(lastImageId - 1); // Decrease lastImageId by 1
    if (newUploadedImages.length < 4) {
      setErrorMessage(""); // Clear error message when the number of uploaded images is less than 4
    }
  };



  return (
    <div className="image-uploader-frame">
      {!noUpload && (
      <div
        {...getRootProps({
          className: "dropzone flex items-center justify-center",
        })}
      >
        <input {...getInputProps()} />
        <div className="border-2 cursor-pointer border-dashed border-gray-400 rounded-lg p-4 w-full">
          <p className="text-gray-500 text-center flex justify-center items-center">
            {/* Drag and drop an image here or click to browse */}
            <MdUploadFile className="w-6" />
            Upload Image
          </p>
        </div>
      </div>
      )}
      {errorMessage && (
        <p className="text-red-500 mt-2">{errorMessage}</p>
      )}

      {uploadedImages.length > 0 ? (
        <div className="uploaded-images mt-4 grid grid-cols-4 gap-4 py-3">
          {uploadedImages.map((image, index) => (
            <div className="relative">
              {/* <IoCloseCircleSharp onClick={()=>handleCloseImage(index)} className="absolute top-0 right-1 "/> */}
              <IoIosCloseCircleOutline onClick={()=>handleCloseImage(index)} className="absolute top-0 right-1 "/>
              <img
                key={index}
                src={image}
                alt={"Uploaded Image " + (index + 1)}
                className="rounded-lg border-2 w-[75px] h-[96px] mt-1"
              />
            </div>

          ))}
        </div>
      ) : images.length > 0 ? (
        <div className="uploaded-images mt-4 grid grid-cols-4 gap-4 rounded-lg py-3" >
          {images.map((image, index) => (
            <div className="w-[75px] h-[96px] rounded-[11px] border-2 border-dashed flex justify-center items-center">
              <img
              key={index}
              src={image && image!='' ? image : uploadImage}
              // alt={"Uploaded Image " + (index + 1)}
              className="w-[50px] h-[60px]"
            />
            </div>

          ))}
        </div>
      )
      : null}
    </div>
  );
};

export default ImageUploaderFrame;