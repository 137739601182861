import React from 'react';
import { numberToWords, formatPrice,invoiceCalculate } from '../../../utils/Common';
// import Watermark from '../Watermark/Watermark';
import './../../WholeSaleInvoiceDetailPage/PdfHtmlContent/PdfHtmlContent.css';



import QRCode from 'react-qr-code';
import ImageUploader3 from '../../ImageUploader3';
import classNames from 'classnames';

const PdfHtmlContent = props => {
  const { invoice, invoiceSetting, id, parentClassName } = props;
  const userInfo = invoiceSetting;
  const BASE_URL = process.env.REACT_APP_ROOT_URL;
  const invoiceDate = new Date(invoice?.carrier?.invoice_date);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  const day = String(invoiceDate.getDate()).padStart(2, '0');
  const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';
  const shop_logo = invoiceSetting?.shop_logo ? invoiceSetting?.shop_logo : `${BASE_URL}/layoutlogo.svg`;
  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
  let totalQty = 0;
  let totalItems = 0;
  let totalPrice = 0;
  const fetchImage = async () => {
    try {
      const imageResponse = await fetch("https://api-outlet-expense.squadinnovators.com/uploads/61-test/1700124528.jpg");

      if (!imageResponse.ok) {
        throw new Error(`Failed to fetch image: ${imageResponse.status}`);
      }

      const imageBlob = await imageResponse.blob();
      const reader = new FileReader();
      reader.onload = () => {
        const base64DataURL = `data:image/jpeg;base64,${reader.result}`;
        const imageElement = document.createElement('img');
        //imageElement.crossOrigin = 'anonymous';
        imageElement.className = 'h-[100px]';
        imageElement.src = base64DataURL;
        imageElement.alt = 'Invoice Logo';

        const invoiceLogoElement = document.querySelector('.invoice-logo-pdf');
        invoiceLogoElement.appendChild(imageElement);
      };
      reader.onerror = error => console.error('Failed to read image data:', error);
      reader.readAsDataURL(imageBlob);
    } catch (error) {
      console.error('Error fetching image:');
    }
  };
  //fetchImage();


  return (
    <div id="pdfConentAreas" className="pdfConentAreas">
      <div id="invoice-detail-page" className="invoice-detail-page font-nunito">
       {/* invoice header */}
       <div className="text-center text-xs md:text-sm lg:text-base">

          <img src={shop_logo} alt={invoice.invoice_id} className={parentClassName + ' mx-auto w-1/2 md-[5px] max-w-[220px]'} />
          <div>
            {!id &&
              <img src={shop_logo} alt={invoiceSetting.shop_name} className={parentClassName + ' excludeFromPdf mx-auto w-1/2 md:w-2/3 max-w-[320px]'} />
            }
            <div className=' leading-4 sm:leading-5 md:leading-6 mt-[16px] font-normal'>
              {userInfo?.shop_name &&
                <p className='capitalize text-[22px] font-semibold'>{userInfo?.shop_name} </p>
              }
              {userInfo?.shop_address &&
                <p>{userInfo?.shop_address}</p>
              }
              {userInfo?.mobile_number &&
                <p>{userInfo?.mobile_number}</p>
              }
            </div>
          </div>
        </div>
        {/* customer info and QR */}
        <div className="flex items-center mt-[20px] justify-between">
          <div className="text-black space-y-[2px] md:space-y-[6px] font-nunito text-xs md:text-base lg:text-lg">
            <p> <span className='font-semibold'>Name</span>  :  {invoice?.carrier?.carrier_name} </p>
            <p><span className='font-semibold'>Phone</span> :  {invoice?.carrier?.carrier_phone}</p>
            <p><span className='font-semibold'>ID </span> :#{invoice?.carrier?.carrier_nid}</p>
            <p><span className='font-semibold'> Invoice Date </span>: {formattedDateTime} </p>
          </div>
          <div className="w-[45px] sm:w-[65px] md:w-[100px]">
            <QRCode className="w-full object-contain" value={BASE_URL + '/invoice/' + invoice?.carrier?.invoice_id} size={100} />
          </div>
        </div>
        <div className='text-xs md:text-base lg:text-lg' style={{ width: '100%', backgroundColor: '#3056FA', padding: '10px', textAlign: 'center', margin: '15px auto' }}>
          <p className="text-white font-nunito py-1 text-14 font-bold uppercase">Invoice No: {invoice?.carrier?.invoice_id}</p>
        </div>
       {/* table */}
       <div className=' mx-auto md:w-full md:max-w-full overflow-x-auto'>
       <table className='table'>
            <thead id='pdfContentTHead'>
              <tr className="leading-normal text-sm md:text-base lg:text-lg font-nunito tracking-wide">
                <th className='font-nunito'>SL</th>
                <th colSpan={10} className='font-nunito '>Product</th>
                <th className='font-nunito text-right'>IMEI</th>
              </tr>
            </thead>
            <tbody>
              {/* <Watermark watermark_text={invoiceSetting?.watermark_text} watermark_type={invoiceSetting?.watermark_type}></Watermark> */}
              {invoice?.products?.map((item) => {
                //totalQty += Number(item.qty);
                //totalItems += 1;
                {/* const subtotalPrice = formatPrice(item.qty * item.price); */}
                //const subtotalPrice = item.qty * item.price;
               // totalPrice += Number(subtotalPrice);
                return (
                  <tr key={item.id} className=" font-nunito border-[#E7EBF4] font-normal text-sm md:text-base lg:text-lg relative">
                    <td className=''>1</td>
                    <td colSpan={10} className=''>{item?.product_name}</td>
                    {/* <td className='text-center'>{item?.product_info?.warrenty ? item?.product_info?.warrenty : '        '}</td> */}
                    <td className=' text-right font-semibold md:font-semibold'>{item?.imei}</td>
                  </tr>
                );
              }
              )}
            </tbody>
          </table>
       </div>

        {/* <DashedLine2 /> */}
        <br />
        {/* disclaimer & summary */}
        <div className='flex justify-between'>
        {/* disclaimer */}
          <div className="w-1/2 text-xs md:text-base pr-1 md:pr-0">
            {invoiceSetting?.condition ?
              invoiceSetting?.condition
            : (
              <p>Damage caused by misuse, abuse, or neglect.<br/>
                Unauthorized modifications or repairs.<br/>
                Acts of nature, such as floods, earthquakes,<br/>
                or other disasters.This may include proper use.</p>
            )}
          </div>
          {/* invoice sumary */}
          <div className="w-1/2 text-xs md:text-sm lg:text-base font-semibold pl-2  md:pl-8">
            <div className='space-y-[2px] mb-2'>
            <p className='flex justify-between'>
              <span>TOTAL ITEMS</span>
              <span>{invoice?.amount?.total_items}</span>
            </p>
            <p className='flex justify-between'>
              <span>Carrier Charge {''}</span>
              {/* <span>{invoice?.tax ? formatPrice(invoice.tax) : 0.00}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span> */}
              <span>{invoice?.amount?.carrier_charge ? invoice?.amount?.carrier_charge : 0.00}{invoiceSetting?.base_currency ? invoiceSetting?.base_currency : ' BDT'}</span>
            </p>
            </div>
            <hr></hr>
            <div className='my-2'>
            <p className='flex justify-between'>
              <span>Grand Total</span>
              {/* <span>{formatPrice(invoiceCalculate(invoice).grandTotal) }{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span> */}
              <span>{invoice?.amount?.grand_total }{invoiceSetting?.base_currency ? invoiceSetting?.base_currency : ' BDT'}</span>
            </p>
            <p className='flex justify-between'>
              <span>Paid Amount</span>
              {/* <span>{invoice.paid_amount && invoice.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(totalPrice)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span> */}
              <span>{invoice?.amount?.paid_amount && invoice?.amount?.paid_amount!='' ? invoice?.amount?.paid_amount: 0}{invoiceSetting?.base_currency ? invoiceSetting?.base_currency : ' BDT'}</span>
              {/* <span>{invoice?.paid_amount && invoice?.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(0)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span> */}
            </p>
            </div>
            <hr></hr>
            <p className='flex justify-between mt-2'>
              <span>Due Amount</span>
              {/* <span>{formatPrice(invoice.sub_total - invoice.paid_amount)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span> */}
              <span>{invoice?.amount?.due_amount }{invoiceSetting?.base_currency ? invoiceSetting?.base_currency : ' BDT'}</span>
              {/* <span>{formatPrice(invoiceCalculate(invoice).dueAmount) }{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span> */}
            </p>
          </div>
        </div>
        {/* amount in words */}
        <p className='text-gray-900 mt-4 font-nunito text-sm md:text-base lg:text-lg font-semibold leading-normal text-center'><span className='text-[#7282A0]'>In Word:</span>
        {numberToWords(formatPrice(invoice?.amount?.grand_total))} taka only</p>
        <div style={{ width: '100%', backgroundColor: '#034AFF', padding: '10px', textAlign: 'center', margin: '25px auto' }}>
          <p className="text-white font-nunito text-14 font-bold">You saved {invoice?.discount ? invoice.discount : 0}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'} in this purchase </p>
        </div>
        {/* signature */}
          <div className='text-xs md:text-sm lg:text-base'>
            <div className='flex justify-between'>
              <p className="invisible">.</p>
              {!id && invoiceSetting?.sign_authority ? <div className='w-[120px] h-[120px] rounded-lg'>
                <img src={invoiceSetting.sign_authority} className='object-cover excludeFromPdf' />
              </div> : null}
            </div>
            <div className="flex justify-between m-2">
              <p><hr></hr> Customer Signature</p>
              <p><hr></hr> Seller Signature </p>
            </div>
          </div>
        {/* footer */}
        <div className='text-center font-nunito mt-8 text-xs md:text-sm lg:text-base'>
          <h2 className='text-orange-500  text-xl font-semibold '>Thanks for purchase</h2>
          <p className='text-black mt-3 text-xs mb-5'>A Product of SQUAD INNOVATORS </p>
        </div>
      </div>
    </div>
  );
};

export default PdfHtmlContent;
