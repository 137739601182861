import React from "react";
import { AiOutlineSearch } from "react-icons/ai";

import ContentLayout from "../../layout/ContentLayout";
import ProductsList from "../../components/CustomerProfile/ProductsList/Index";

const WholesalerMostPurchaseProductPage = () => {
  return (
    <>
      <ContentLayout>
        <ProductsList type="wholesaler"/>
      </ContentLayout>
    </>
  );
};

export default WholesalerMostPurchaseProductPage;
