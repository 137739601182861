// import React from 'react';

// const BalanceGraph = () => {
//   return (
//     <div>

//     </div>
//   );
// };

// export default BalanceGraph;

import React, { useState } from 'react';
import "./BalanceGraph.css";

import ReactApexChart from 'react-apexcharts';

const BalanceGraph = ({balanceRevenueData, sales_chart}) => {
  return (
    <>
      <div className="chart-info w-full">
        <div className="chart-info-title">
          <p className='text-[24px]'>Sale vs Purchase Transaction Chart</p>
        </div>
      </div>
      <div id="category-chart">
        <ReactApexChart options={balanceRevenueData?.options} series={balanceRevenueData?.series} type="area" height={300} />
      </div>
    </>
  );
};

export default BalanceGraph;
