import React, { useEffect, useState, useContext } from "react";
import { useFlashMessage } from "../context/FlashMessageContext";
import axios from "axios";
import SelectBox from "./Select";
import Swal from 'sweetalert2';
import ExporterPaymentTypeForm from "../forms/ExporterPaymentTypeForm";
import { get, post } from "../services/apiService";
import { set } from 'react-hook-form';
import crose from "../assets/images/crose.svg";


const ExporterCarrierProductListForm = (props) => {
  const {
    formId,
    navigate,
    loadingCallback,
    setWarningMessage,
    setShowModal,
    updateModalTitle,
    exporterOrder,
    setSuccessFlashMessage,
    setPayment,
  } = props;
  const [loader, setLoader] = useState(true);
  const [totalCharge, setTotalCharge] = useState(0);
  const token = localStorage.getItem("token");
  const [carrierProductFormData, setCarrierProductFormData] = useState([]);

  const modalClose = () => setShowModal(false);
  const showModalWarning = (msg, data = null) => {
    Swal.fire({
      title: msg,
      text: "",
      icon: 'warning',
    });
  };
  const [productListForm, setProductListForm] = useState(true);
  const [carrierListForm, setCarrierListForm] = useState(false);
  const [imeiForm, setImeiForm] = useState(false);
  const [carrierRecivedForm, setCarrierRecivedForm] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [paymentAddForm, setPaymentAddForm] = useState(false);
  const [paymentConfirmForm, setPaymentConfirmForm] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ paymentAccountList,setPaymentAccountList] = useState([]);
  const [selectedPaymentAccountList, setSelectedPaymentAccountList] = useState(null)
  const [ filteredAccountList,setFilteredAccountList,] = useState({})
  const [paidAmount, setPaidAmount] = useState(0.00);
  const [paymentSubmitForm, setPaymentSubmitForm] = useState(null);
  const [exporterInfo, setExporterInfo] = useState({});
  const [exporterProducts, setExporterProducts] = useState([]);
  const [totalCost, setTotalCost] = useState([]);
  const [carrierProductList, setCarrierProductList] = useState([]);

  const [productFormData, setProductFormData] = useState({
    product_id: 0,
    imei: 0,
    exporter_carrier_id: 0,
  });
  const [formData, setFormData] = useState({
    name: "",
    method_id: 0,
    account_id: 0,
    account_number: "",
    methodLabel: "",
    accountLabel: "",
    type_name: "",
  });
  const [selectedCurrentProducts, setSelectedCurrentProducts] = useState(null);
  const [selectedCurrentProductsWithImei, setSelectedCurrentProductsWithImei] = useState([]);
  const [selectedCurrentProductsImei, setSelectedCurrentProductsImei] = useState(null);
  const [dueAmount, setDueAmount] = useState(1);


  const handleCheckboxChange = (item) => {
    // Check if the clicked checkbox is already selected
    const existingItem = selectedProducts.find(
      (selectedItem) => selectedItem.exporter_product_id === item.exporter_product_id
    );

    if (existingItem) {
      // If the item is already selected, unselect it
     setSelectedProducts(prevSelectedProducts =>
        prevSelectedProducts.filter((selectedItem, index) => {
          if (selectedItem.id === item.id) {
            return index === prevSelectedProducts.length - 1;
          }
          return true;
        })
      );
    } else {

      // If the item is not selected, select it
      // Check if the selected products have the same carrier_id
      const carrierIdMismatch = selectedProducts.some(
        selectedItem => selectedItem.carrier_id !== item.carrier_id
      );

      if (carrierIdMismatch) {
        showModalWarning("Cannot select products with different carriers.");
        return;
        //alert("Cannot select products with different carriers."); // Or a more visually appealing dialog
      } else {
        setSelectedProducts(prevSelectedProducts => [...prevSelectedProducts, item]);
      }

    }
  };
  const getPayment = () => {
    //loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}payment-type-list`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response.data.success){
        loadingCallback(false)
        setPaymentData([...paymentData, response?.data?.data?.data[0]])
        setPaymentList(response?.data?.data?.data);
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };
  const getPaymentAccountList = () => {
    //loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}payment-type-category-list`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response?.data.success){
        loadingCallback(false)
        setPaymentAccountList(response?.data?.data?.data);
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };
  useEffect(()=>{
    getPayment();
    getPaymentAccountList()
    getExporterDetail(exporterOrder)
    //setPayment(true)
    //getPaymentDetails(exporterOrder?.id)
  },[exporterOrder ])
  const [exporterOrderData, setExporterOrderData] = useState([]);

  const getExporterDetail = (id) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}exporter-order-details/${id}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          const fetchedData = response.data.data;
          setExporterOrderData(fetchedData);

        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };
  const [selectProductForSubmit, setSelectProductForSubmit] = useState([]);

  const handleSelectChange = (name, option) => {
    const { value, label } = option;



    if (name=='imei') {
      const selectedImei = selectedCurrentProducts?.exporter_product_imeis_received.find(imei => imei.id === value);
      // remove selected imei from the list
      setSelectedCurrentProductsImei(selectedCurrentProductsImei.filter(imei => imei.id !== value));

      if (selectedImei) {

        // Check if the product is not already in selectedProducts
        const isProductAlreadySelected = selectProductForSubmit.some(product => product.exporter_product_id === selectedCurrentProducts.exporter_product_id);
        // Check if the selected IMEI is not already associated with the product in selectedProducts
        const isImeiAlreadyAssociated = selectProductForSubmit.some(product => product.exporter_product_id === selectedCurrentProducts.exporter_product_id && product.selectedImei?.id === selectedImei.id);

        if (!isProductAlreadySelected || !isImeiAlreadyAssociated) {// Create the product object to add to selectedProducts array
          setProductFormData({
            ...productFormData,
            [name]: value,
          });
          const productToAdd = {
            exporter_product_id: selectedCurrentProducts.exporter_product_id,
            product_name: selectedCurrentProducts.product_name,
            selectedImei: selectedImei,
            id:selectedCurrentProducts.exporter_product_id
          };

          // Add the product to selectedProducts array
          setSelectProductForSubmit(prevProducts => [...prevProducts, productToAdd]);

          // Update the product form data
          setProductFormData({
            ...productFormData,
            [name]: value,
          });
        }
      }
    } else {
      const selectedProduct = selectedProducts.find(product => product.exporter_product_id === value);

      if (selectedProduct) {
        setProductFormData({
          ...productFormData,
          [name]: value,
        });
        setSelectedCurrentProducts(selectedProduct);
        setSelectedCurrentProductsImei(selectedProduct?.exporter_product_imeis_received ? selectedProduct?.exporter_product_imeis_received : []);
        // If selectedProduct is found, add it to the selectedProducts array
        //setSelectedProducts(prevProducts => [...prevProducts, selectedProduct]);
      }
    }
  };
  const getCarrierProductList = (id) => {
    //loadingCallback(true)
    setLoader(true);
    axios.get(
      `${process.env.REACT_APP_API_URL}exporter-carrier-receiver-list/${id}`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response?.data.success){
        setLoader(false);
        loadingCallback(false)
        setProductList(response?.data?.data);
        // setExporterInfo(response?.data?.data?.exporter_info[0]);
        // setExporterProducts(response?.data?.data?.exporter_products);
        // setTotalCost(response?.data?.data?.total_cost);
        //setShowProductViewModal(!showProductViewModal)
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };
  useEffect(() => {
    getCarrierProductList(exporterOrder)
  }, [exporterOrder]);
  const [groupedProducts, setGroupedProducts] = useState([]);

  useEffect(() => {
    if (selectProductForSubmit.length > 0) {
      // Create a Set to store unique combinations of exporter_carrier_id and exporter_product_id
      const uniqueCombinations = new Set();
      //update-exporter-carrier-product-receive-status

      // Filter out duplicates and create an array of objects with exporter_carrier_id and exporter_product_id properties
      const selectedProductIds = selectProductForSubmit.reduce((result, product) => {
        const combination = `${product.exporter_product_id}-${product.selectedImei?.id}`;
        if (!uniqueCombinations.has(combination)) {
          uniqueCombinations.add(combination);
          result.push({
            product_imei_id: product.selectedImei?.id,
          });
        }
        return result;
      }, []);

      setCarrierProductFormData(selectedProductIds);
    }

    if (selectProductForSubmit.length > 0) {
      let groupedProducts = selectProductForSubmit.reduce((groups, product) => {
        if (!groups[product.id]) {
          groups[product.id] = [];
        }
        groups[product.id].push(product);
        return groups;
      }, {});
      setGroupedProducts(groupedProducts);
    }
  }, [selectProductForSubmit]);

  const handleProductListSubmit = async (event) => {
    event.preventDefault();
    // Check if there are already selected items
    if (selectedProducts.length ===0) {
      showModalWarning('Please select one product at a time');
      return;
    }

    // console.log(exporterOrder);
    const productIdsObject = {
      exporter_order_id: exporterOrder,
      carrier_id: selectedProducts[0].carrier_id,
      product_ids: selectedProducts.map((product) => product.exporter_product_id),
      sending_qty: 0
    };
    //exporter-carrier-receiver-product-list

    addProductList(productIdsObject);


  };

  const addProductList = (formData) => {
    loadingCallback(true);
    // Use your API service functions to make API requests here
    //if(isValid){
    post("exporter-carrier-receiver-product-list", formData, token)
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          setSelectedProducts(response.data);
          setProductListForm(false);
          setCarrierListForm(true);
          setImeiForm(false);
          setCarrierRecivedForm(false);
          setPaymentAddForm(false);
          setPaymentConfirmForm(false);

          updateModalTitle("Carrier List");

        } else {
          console.log("Carrier update failed");
          //setErrorFlashMessage("carrier update failed");

          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
        console.log(error);
      });
    //}
  };

  const handleCarrierListSubmit = async (event) => {
    event.preventDefault();

    setProductListForm(false);
    setCarrierListForm(false);
    setImeiForm(true);
    setCarrierRecivedForm(false);
    setPaymentAddForm(false);
    setPaymentConfirmForm(false);

    updateModalTitle("Imei list");
  };

  const handleImeiListSubmit = async (event) => {
    event.preventDefault();
    const carrierProduct = {};
    carrierProduct.exporter_order_id = exporterOrder;
    carrierProduct.imeis = carrierProductFormData;

    updateCarrierProduct(carrierProduct);
    // setProductListForm(false);
    // setCarrierListForm(false);
    // setImeiForm(false);
    // setCarrierRecivedForm(true);
    // setPaymentAddForm(false);
    // setPaymentConfirmForm(false);

    // updateModalTitle("Carrier Received Info");
  };

  const updateCarrierProduct = (formData) => {
    loadingCallback(true);
    // Use your API service functions to make API requests here
    //if(isValid){
    post("update-exporter-carrier-product-receive-status", formData, token)
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {

          setCarrierProductList(response.data);
          setProductListForm(false);
          setCarrierListForm(false);
          setImeiForm(false);
          setCarrierRecivedForm(true);
          setPaymentAddForm(false);
          setPaymentConfirmForm(false);

          updateModalTitle("Carrier Received Info");

        } else {
          console.log("Carrier update failed");
          //setErrorFlashMessage("carrier update failed");

          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
        console.log(error);
      });
    //}
  };

  const handleCarrierRecivedSubmit = async (event) => {
    //save-exporter-carrier-charge
    setProductListForm(false);
    setCarrierListForm(false);
    setImeiForm(false);
    setCarrierRecivedForm(false);
    setPaymentAddForm(true);
    setPaymentConfirmForm(false);


    setPaymentSubmitForm(null)
    setSelectedPaymentAccountList(null)
    setPaymentAccountList([])
    updateModalTitle("Payment Info");
    event.preventDefault();
  };


  const handleCancelMethod = (id, par, index,imeiList) => {
    const updatedGroupedProducts = { ...groupedProducts };
    const updatedSelectedProducts = [...selectProductForSubmit];
    const indexToRemove = updatedGroupedProducts[id].findIndex(
      (item) => item.selectedImei?.id === par
    );
    const indexToRemoveProduct = updatedSelectedProducts.findIndex(
      (item) => item.selectedImei?.id === par
    );
    if (indexToRemove !== -1) {
      updatedGroupedProducts[id].splice(indexToRemove, 1);
      setGroupedProducts(updatedGroupedProducts);
    }
    if (indexToRemoveProduct !== -1) {
      selectProductForSubmit.splice(indexToRemoveProduct, 1);
      setSelectProductForSubmit(selectProductForSubmit);
       // Add the removed IMEI back to the selectedCurrentProductsImei array
      if (selectProductForSubmit.length > 0) {
        setSelectedCurrentProductsImei([...imeiList, updatedSelectedProducts[index].selectedImei]);
      } else {
        setSelectedCurrentProductsImei([...imeiList, updatedSelectedProducts[index].selectedImei]);
      }

    }
  };
  const updatedSelectedProducts = selectedProducts.map(product => ({
      ...product,
      id: product.exporter_product_id, // Change exporter_product_id to id
    }));


  const handlePaymetMethodSubmit = (paymentSubmitForm) => {
    console.log("==ssss=========");
    console.log(paymentSubmitForm);
    // console.log("============");
    // console.log(exporterInfo);
    // console.log(exporterOrder);
    if(paymentSubmitForm){
      setProductListForm(false);
      setCarrierListForm(false);
      setImeiForm(false);
      setCarrierRecivedForm(false);
      setPaymentAddForm(false);
      setPaymentConfirmForm(true);
      updateModalTitle("Payment Details");
    }
  };

  const handlePaymentConfirmSubmit = async (event) => {
    event.preventDefault();
    submitPayment(paymentSubmitForm);

    // setShowModal(false)
  };
  const submitPayment = (paymentSubmitForm) => {
    loadingCallback(true)
    paymentSubmitForm.carrier_id = carrierProductList[0].carrier_id;
    axios.post(
      `${process.env.REACT_APP_API_URL}save-exporter-carrier-charge`,paymentSubmitForm,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {

      if(response?.data.success){

        loadingCallback(false)
        setProductListForm(false);
        setCarrierListForm(false);
        setImeiForm(false);
        setCarrierRecivedForm(false);
        setPaymentAddForm(false);
        setPaymentConfirmForm(false);
        updateModalTitle("Payment Details");
        setSuccessFlashMessage('payment successfully added')
        setPayment(true)
        setShowModal(false)
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };
  const [charges, setCharges] = useState(Array(carrierProductList.length).fill(''));
  const [selectedCarrier, setSelectedCarrier] = useState([]);
  // Function to handle change in text box value
  const handleChangeCharge = (value, index, product) => {
    const updatedCharges = [...charges];
    updatedCharges[index] = value;
    setCharges(updatedCharges);


    const updatedCarrier = [...selectedCarrier];
    product.charge=value;
    updatedCarrier[index] = product;

    setSelectedCarrier(updatedCarrier);

    // Calculate sum of all charges
    const sum = updatedCharges.reduce((acc, cur) => acc + parseInt(cur || 0), 0);
    setTotalCharge(sum);
    console.log('Sum of charges:', sum);
  };
  useEffect(() => {
    if (selectProductForSubmit.length > 0) {
      setDueAmount(selectProductForSubmit.length >0 ? selectProductForSubmit.length : 1);
    }
  }, [selectProductForSubmit]);
  return (
    <>
      <style>
        {`
          .custom-scrollbar::-webkit-scrollbar {
            width: 7px;
          }

          .custom-scrollbar::-webkit-scrollbar-track {
            background-color: #F1F1FA;
          }

          .custom-scrollbar::-webkit-scrollbar-thumb {
            background-color: #034AFF;
            border-radius: 8px;
          }

          .custom-scrollbar::-webkit-scrollbar-thumb:hover {
            background-color: #6B7280;
          }
        `}
      </style>
      {productListForm ? (
        <form
          method="get"
          className="col-sm-12 min-h-[10vh]"
          data-autosubmit="false"
          autoComplete="off"
          onSubmit={handleProductListSubmit}
        >
          <div className="rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[12px] py-[15px] mb-4">
            <div className="font-lato font-bold text-[12px] text-[#000232]">
              Carrier List
            </div>

            <div className="max-h-[250px] overflow-y-auto custom-scrollbar">
              <div className="font-nunito font-bold text-[12px] text-[#000232] border-b-[1px] border-solid border-[#F1F1FA] flex flex-row gap-3">
                <div className="py-[8px] flex-1">
                  Carrier
                  <br />
                  Name
                </div>
                <div className="py-[8px] flex-1 text-center">
                  Carrier
                  <br />
                  Phone
                </div>
                <div className="py-[8px] flex-1 text-center">
                  Product
                  <br />
                  Name
                </div>
                <div className="py-[8px] flex-1 text-center">
                  Sending
                  <br />
                  Qty
                </div>
                <div className="py-[8px] flex-1 text-right pr-[7px]">
                  Received
                  <br />
                  Qty
                </div>
              </div>
              {loader && (
                <div className="w-full relative px-[27px] overflow-x-auto sm:rounded-lg">
                  {" "}
                  <div className="custom-loader"></div>{" "}
                </div>
              )}
              {productList.map((product, index) => (
                <div
                  className={`${
                        product?.remaining < 1
                          ? "text-[#ca1830]"
                          : "text-[#000232]"
                      } font-nunito font-[400] text-[14px]  border-b-[1px] border-solid border-[#F1F1FA] flex flex-row gap-3`}
                    >
                  <div className="py-[8px] flex-1 flex flex-row gap-2 justify-center">
                    <div>
                      <input
                        type="checkbox"
                        name="carrier_product"
                        id={`product_${product.product_id}`}
                        value=""
                        className="rounded-[6px]"
                        disabled={product.remaining < 1}
                        checked={product.remaining < 1 || selectedProducts.includes(product)}
                        onChange={() => handleCheckboxChange(product)}
                      />
                      {/* <input type="checkbox" name="carrier_product" value={product?.product_id} /> */}
                    </div>
                    <div>{product?.carrier_name}</div>
                  </div>
                  <div className="py-[8px] flex-1 text-center">{product?.carrier_phone_no}</div>
                  <div className="py-[8px] flex-1 text-center">{product?.product_name}</div>
                  <div className="py-[8px] flex-1 text-center">{product?.sending_qty}</div>
                  <div className="py-[8px] flex-1 text-right pr-[7px]">{product?.received_qty}</div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-row gap-3">
            <input
              onClick={modalClose}
              type="button"
              value="Back"
              className="btn w-[48%] text-[#034AFF] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none capitalize"
            />
            <input
              type="submit"
              value="Next"
              className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF] capitalize"
            />
          </div>
        </form>
      ) : carrierListForm ? (
        <form
          method="get"
          className="col-sm-12 min-h-[10vh]"
          data-autosubmit="false"
          autoComplete="off"
          //onSubmit={handleCarrierListSubmit}
          onSubmit={handleImeiListSubmit}
        >
          <div className="flex flex-row gap-2 mt-2 mb-2">
            <div className="w-[50%]">
              <div className="mb-3 relative">
                <SelectBox
                  name="product_id"
                  options={updatedSelectedProducts}
                  value={productFormData?.product_id ? productFormData?.product_id : ""}
                  onChange={handleSelectChange.bind(this, "product_id")}
                  placeholder="Product"
                />
                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Product
                </label>
              </div>
            </div>
            <div className="w-[50%]">
              <div className="mb-3 relative">
                <SelectBox
                  name="imei"
                  options={selectedCurrentProductsImei ? selectedCurrentProductsImei : []}
                  value={productFormData?.imei ? productFormData?.imei : ""}
                  onChange={handleSelectChange.bind(this, "imei")}
                  placeholder="select IMEI"
                />
                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  IMEI
                </label>
              </div>
            </div>
          </div>

            {Object.keys(groupedProducts).map(productId => (
              <div key={productId}>
                <div className="rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[10px] py-[12px] mb-2">
                  <div className="max-h-[15vh] overflow-y-auto">

                    <div className="flex flex-row justify-between border-b-[1px] border-solid border-[#E7EBF4] px-[10px] py-[12px]">
                      <div className="font-nunito font-[700] text-[12px] text-[#000232]">
                        Product Name
                      </div>
                      <div className="font-nunito font-[700] text-[12px] text-[#000232]">
                        IMEI
                      </div>
                    </div>
                    <div className="min-h-[70px]">
                      {groupedProducts[productId].map((product, index) => (
                        <div key={index} className="flex flex-row justify-between border-b-[1px] border-solid border-[#E7EBF4] px-[10px] py-[12px]">
                          <div
                            className="bg-red-600 text-white rounded-md cursor-pointer"
                            onClick={() => handleCancelMethod(productId, product.selectedImei?.id,index,selectedCurrentProductsImei)}
                          >
                            <img src={crose} width={23} height={20} alt="" />
                          </div>
                         <div className="font-nunito font-[500] text-[12px] text-[#000232]">
                            {product.product_name}
                          </div>
                          <div className="font-nunito font-[500] text-[12px] text-[#000232]">
                            {product.selectedImei?.imei}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}

          <div className="flex flex-row gap-3">
            <input
              onClick={() => {
                setProductListForm(true);
                setCarrierListForm(false);
                setImeiForm(false);
                setCarrierRecivedForm(false);
                setPaymentAddForm(false);
                setPaymentConfirmForm(false);

                updateModalTitle("Carrier with product list");
              }}
              type="button"
              value="Back"
              className="btn w-[48%] text-[#034AFF] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none capitalize"
            />
            <input
              type="submit"
              value="Next"
              className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF] capitalize"
            />
          </div>
        </form>
      ) : imeiForm ? (
        <form
          method="get"
          className="col-sm-12 min-h-[10vh]"
          data-autosubmit="false"
          autoComplete="off"
          onSubmit={handleImeiListSubmit}
        >
          <div className="flex flex-row gap-2 mt-2 mb-2">
            <div className="w-[50%]">
              <div className="mb-3 relative">
                <SelectBox
                  name="product"
                  options=""
                  value=""
                  onChange=""
                  placeholder="Product"
                />
                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Product
                </label>
              </div>
            </div>
            <div className="w-[50%]">
              <div className="mb-3 relative">
                <SelectBox
                  name="imei"
                  options=""
                  value=""
                  onChange=""
                  placeholder="select IMEI"
                />
                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  IMEI
                </label>
              </div>
            </div>
          </div>

          <div className="rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[10px] py-[10px] mb-4">
            <div className="max-h-[140px] overflow-y-auto custom-scrollbar">
              <div className="flex flex-row justify-between border-b-[1px] border-solid border-[#E7EBF4] px-[10px] py-[12px]">
                <div className="font-nunito font-[700] text-[12px] text-[#000232]">
                  Product Name
                </div>
                <div className="font-nunito font-[700] text-[12px] text-[#000232]">
                  IMEI
                </div>
              </div>

              <div className="flex flex-row justify-between border-b-[1px] border-solid border-[#E7EBF4] px-[10px] py-[12px]">
                <div className="font-nunito font-[400] text-[14px] text-[#000232]">
                  Nokia
                </div>
                <div className="font-nunito font-[400] text-[12px] text-[#00023s2]">
                  35464654654
                </div>
              </div>

              <div className="flex flex-row justify-between border-b-[1px] border-solid border-[#E7EBF4] px-[10px] py-[12px]">
                <div className="font-nunito font-[400] text-[14px] text-[#000232]">
                  Nokia
                </div>
                <div className="font-nunito font-[400] text-[12px] text-[#00023s2]">
                  35464654654
                </div>
              </div>

              <div className="flex flex-row justify-between px-[10px] py-[12px]">
                <div className="font-nunito font-[400] text-[14px] text-[#000232]">
                  Nokia
                </div>
                <div className="font-nunito font-[400] text-[12px] text-[#00023s2]">
                  35464654654
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[10px] py-[10px] mb-2">
            <div className="max-h-[140px] overflow-y-auto custom-scrollbar">
              <div className="flex flex-row justify-between border-b-[1px] border-solid border-[#E7EBF4] px-[10px] py-[12px]">
                <div className="font-nunito font-[700] text-[12px] text-[#000232]">
                  Product Name
                </div>
                <div className="font-nunito font-[700] text-[12px] text-[#000232]">
                  IMEI
                </div>
              </div>

              <div className="flex flex-row justify-between border-b-[1px] border-solid border-[#E7EBF4] px-[10px] py-[12px]">
                <div className="font-nunito font-[400] text-[14px] text-[#000232]">
                  Nokia
                </div>
                <div className="font-nunito font-[400] text-[12px] text-[#00023s2]">
                  35464654654
                </div>
              </div>

              <div className="flex flex-row justify-between border-b-[1px] border-solid border-[#E7EBF4] px-[10px] py-[12px]">
                <div className="font-nunito font-[400] text-[14px] text-[#000232]">
                  Nokia
                </div>
                <div className="font-nunito font-[400] text-[12px] text-[#00023s2]">
                  35464654654
                </div>
              </div>

              <div className="flex flex-row justify-between px-[10px] py-[12px]">
                <div className="font-nunito font-[400] text-[14px] text-[#000232]">
                  Nokia
                </div>
                <div className="font-nunito font-[400] text-[12px] text-[#00023s2]">
                  35464654654
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-3">
            <input
              onClick={() => {
                setProductListForm(false);
                setCarrierListForm(true);
                setImeiForm(false);
                setCarrierRecivedForm(false);
                setPaymentAddForm(false);
                setPaymentConfirmForm(false);

                updateModalTitle("Carrier List");
              }}
              type="button"
              value="Back"
              className="btn w-[48%] text-[#034AFF] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none capitalize"
            />
            <input
              type="submit"
              value="Next"
              className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF] capitalize"
            />
          </div>
        </form>
      ) : carrierRecivedForm ? (
        <>
          <form
            method="get"
            className="col-sm-12 min-h-[10vh]"
            data-autosubmit="false"
            autoComplete="off"
            onSubmit={handleCarrierRecivedSubmit}
          >
            <div className="rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[12px] py-[15px] mb-4">
              <div className="flex flex-row justify-between mb-3">
                <div className="font-lato font-[700] text-[12px] text-[#000232]">
                  Carrier Received Info
                </div>
                <div className="font-lato font-[500] text-[12px] text-[#000232] text-right">
                  Date : {carrierProductList.length > 0 ? carrierProductList[0]?.received_date : null}
                </div>
              </div>

              <div className="flex flex-row gap-2 font-bold text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4] py-[8px]">
                <div className="text-left py-[8px] flex-1">
                  Carrier
                  <br />
                  Name
                </div>
                <div className="text-center py-[8px] flex-1">
                  Product
                  <br />
                  Name
                </div>
                <div className="text-center  py-[8px] flex-1">
                  Received
                  <br />
                  Qty
                </div>
                <div className="text-right py-[8px] flex-1">
                  Received
                  <br />
                  Date
                </div>
                <div className="text-right py-[8px] flex-1">
                  Carrier
                  <br />
                  Charge
                </div>
              </div>
              {carrierProductList.length > 0 ? carrierProductList.map((product, index) => (
                <div className="flex flex-row font-[400] text-[14px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4] py-[8px]">
                  <div className="py-[8px] flex-1">{product?.carrier_name}</div>
                  <div className="py-[8px] flex-1 text-center">{product?.product_name}</div>
                  <div className="py-[8px] flex-1 text-center">
                    {product?.received_qty}
                  </div>
                  <div className="py-[8px] flex-1 text-right">{product?.received_date}</div>
                  <div className="py-[8px] flex-1 text-center">
                    <input
                      type="text"
                      name="charge"
                      value={charges[index]}
                      onChange={(e) => handleChangeCharge(e.target.value, index,product)}
                      className="border-[1px] border-solid border-[#000232] px-[4px] rounded-[5px] max-w-[42px] text-center"
                    />
                  </div>
                </div>
              )) : null}

            </div>

            <div className="rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[12px] py-[15px] mb-4">
              <div className="flex flex-row justify-between font-nunito font-[500] text-[14px] text-[#000232]">
                <div>Carrier Charge</div>
                <div className="text-right">{totalCharge}</div>
              </div>
            </div>

            {/* <div className="rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[12px] py-[15px] mb-4">
              <div className="flex flex-row justify-between font-nunito font-[500] text-[14px] text-[#000232]">
                <div>Paid Amount</div>
                <div className="text-right">
                  <div className="flex flex-row gap-3">
                    <input
                      type="text"
                      value="100"
                      className="border-[1px] border-solid border-[#000232] px-[4px] py-[2px] rounded-[5px] max-w-[110px] text-left"
                    />
                    BDT
                  </div>
                </div>
              </div>
            </div> */}

            <div className="flex flex-row gap-3">
              <input
                onClick={() => {
                  // setProductListForm(false);
                  // setCarrierListForm(false);
                  // setImeiForm(true);
                  // setCarrierRecivedForm(false);

                  // updateModalTitle("Imei list");

                  setProductListForm(false);
                  setCarrierListForm(true);
                  setImeiForm(false);
                  setCarrierRecivedForm(false);
                  setPaymentAddForm(false);
                  setPaymentConfirmForm(false);

                  updateModalTitle("Carrier List");
                }}
                type="button"
                value="Back"
                className="btn w-[48%] text-[#034AFF] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none capitalize"
              />
              <input
                type="submit"
                value="Save"
                className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF] capitalize"
              />
            </div>
          </form>
        </>
      ) : paymentAddForm ? (
        <ExporterPaymentTypeForm
          formId='my_modal_payment'
          dueAmount={dueAmount * totalCharge}
          selectedProducts={selectedProducts}
          paymentData={paymentData}
          paymentList={paymentList}
          paymentAccountList={paymentAccountList}
          setData={setPaymentData}
          formData={formData}
          exporterInfo={exporterInfo}
          exporterProducts={exporterProducts}
          totalCost={totalCost}
          handlePaymetMethodSubmit={handlePaymetMethodSubmit}
          formType='carrier'
          charges={selectedCarrier}
          //setPaymentDeatilsForm={setPaymentDeatilsForm}
          setProductListForm={setProductListForm}
          setCarrierListForm={setCarrierListForm}
          setImeiForm={setImeiForm}
          setCarrierRecivedForm={setCarrierRecivedForm}
          setPaymentAddForm={setPaymentAddForm}
          setPaymentConfirmForm={setPaymentConfirmForm}
          updateModalTitle={updateModalTitle}
          setFormData={setFormData}
          filteredAccountList={filteredAccountList}
          setFilteredAccountList={setFilteredAccountList}
          setPaidAmount={setPaidAmount}
          loadingCallback={loadingCallback}
          selectedAccountList={selectedPaymentAccountList}
          setSelectedAccountList={setSelectedPaymentAccountList}
          navigate={navigate}
          setShowModal={setPaymentAddForm}
          isOpen={paymentAddForm}
          title='payment info'
          setWarningMessage={setWarningMessage}
          type='payment'
          exporterOrderId={exporterOrder}
          paymentSubmitForm={paymentSubmitForm}
          setPaymentSubmitForm={setPaymentSubmitForm}
        />
      ) : paymentConfirmForm ? (
        <form
          method="get"
          className="col-sm-12 min-h-[10vh]"
          data-autosubmit="false"
          autoComplete="off"
          onSubmit={handlePaymentConfirmSubmit}
        >
          <div className="rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[12px] py-[15px] mb-4">
            <div className="flex flex-row justify-between mb-3">
              <div className="font-lato font-[700] text-[12px] text-[#000232]">
                Carrier Received Info
              </div>
              <div className="font-lato font-[500] text-[12px] text-[#000232] text-right">
                Date : {carrierProductList.length > 0 ? carrierProductList[0]?.received_date : null}
              </div>
            </div>

            <div className="flex flex-row gap-2 font-bold text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4] py-[8px]">
              <div className="text-left py-[8px] flex-1">
                Carrier
                <br />
                Name
              </div>
              <div className="text-center py-[8px] flex-1">
                Product
                <br />
                Name
              </div>
              <div className="text-center  py-[8px] flex-1">
                Received
                <br />
                Qty
              </div>
              <div className="text-right py-[8px] flex-1">
                Received
                <br />
                Date
              </div>
              <div className="text-right py-[8px] flex-1">
                Carrier
                <br />
                Charge
              </div>
            </div>

            {carrierProductList.length > 0 ? carrierProductList.map((product, index) => (
              <div className="flex flex-row font-[400] text-[14px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4] py-[8px]">
                <div className="py-[8px] flex-1">{product?.carrier_name}</div>
                <div className="py-[8px] flex-1 text-center">{product?.product_name}</div>
                <div className="py-[8px] flex-1 text-center">
                  {product?.received_qty}
                </div>
                <div className="py-[8px] flex-1 text-right">{product?.received_date}</div>
                <div className="py-[8px] flex-1 text-center">
                  <input
                    type="text"
                    readOnly
                    name="charge"
                    value={charges[index]}
                    onChange={(e) => handleChangeCharge(e.target.value, index,product)}
                    className="border-[1px] border-solid border-[#000232] px-[4px] rounded-[5px] max-w-[42px] text-center"
                  />
                </div>
              </div>
            )) : null}
          </div>

          <div className="rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[12px] py-[15px] mb-4">
            <div className="flex flex-row justify-between font-nunito font-[500] text-[14px] text-[#000232]">
              <div>Carrier Charge</div>
              <div className="text-right">{dueAmount * totalCharge}</div>
            </div>
          </div>

          <div className="rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[12px] py-[15px] mb-4">
            <div className="flex flex-row justify-between font-nunito font-[500] text-[14px] text-[#000232]">
              <div>Paid Amount</div>
              <div className="text-right">
                <div className="flex flex-row gap-3">
                  <input
                    readOnly
                    type="text"
                    value={paymentSubmitForm?.paidAmount}
                    className="border-[1px] border-solid border-[#000232] px-[4px] py-[2px] rounded-[5px] max-w-[110px] text-left"
                  />
                  BDT
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-3">
            <input
              onClick={() => {
                //setPaymentDeatilsForm(false);
                setPaymentAddForm(true);
                setPaymentConfirmForm(false);
                setPaymentSubmitForm(null)
                setSelectedPaymentAccountList(null)
                setPaymentAccountList([])
                updateModalTitle("Payment Info");
              }}
              type="button"
              value="Back"
              className="btn w-[48%] text-[#034AFF] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none capitalize"
            />
            <input
              type="submit"
              value="Confirm"
              className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF] capitalize"
            />
          </div>
        </form>
      ) : null}
    </>
  );
};

export default ExporterCarrierProductListForm;
