
import React, { useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import Resizer from "react-image-file-resizer";
import { validateData } from '../utils/Common';
import { useFlashMessage } from '../context/FlashMessageContext';
import ImageUploader2 from './ImageUploader2';
import TextArea from '../components/products/productsadd/TextArea';
import { useNavigate } from 'react-router-dom';
//import arrayMutators from 'final-form-arrays';
import SelectBox from "./Select";

const BranchSettingForm = (props) => {
  const {
    state,
    initialValues,
    districtList,
    cityList,
    handleChangeSelect,
    handleChange
  } = props;
  const { countryList, shopCategoryList } = state;
  const { setErrorFlashMessage } = useFlashMessage();
  const [isValid, setIsValid] = useState(true);
  const [fileLogo, setFile] = useState(null);
  const [watermarkFile, setWatermarkFile] = useState(
    props.initialValues?.BranchSetting?.watermark_type == "Logo"
      ? props.initialValues.BranchSetting.watermark_text
      : null
  );
  const navigate = useNavigate();

  const [selectedValue, setSelectedValue] = useState(
    props.initialValues.BranchSetting
      ? props.initialValues.BranchSetting.watermark_type
      : "text"
  );

  const handleSelectChange = (event) => {
    //event.preventDefault();

    setSelectedValue(event.target.value);
  };
  // const handleNavigate = ()=>{
  //     navigate('/settings/branch-list')
  // }

  return (
    <div className="px-3 pb-4">
      <FinalForm
        {...props}
        initialValues={props.initialValues}
        onSubmit={props.onSubmit}
        validate={(values) => ({
          branch_name: validateData(values.branch_name, 'Branch Name'),
          // shop_address: validateData(values.shop_address, 'Shop address'),
          // sign_authority: validateData(values.sign_authority, 'Sign of Authority'),
          // name_authority: validateData(values.name_authority, 'Name Authority'),
          // watermark_text: validateData(values.watermark_text, 'Text Watermark'),
        })}
        render={(formRenderProps) => {
          const { handleSubmit, file, currency } =
            formRenderProps;
          return (
            <>
              <form
                method="post"
                className="col-sm-12"
                data-autosubmit="false"
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
                encType="multipart/form-data"
              >
                <div className=" border-t-[1px] border-solid border-[#E7EBF4]">
                  <div className="flex flex-col sm:flex-row gap-3 mb-3">
                    <div className=" flex-1">
                      <Field name="branch_name">
                        {({ input, meta }) => (
                          <>
                            <input
                              {...input}
                              type="text"
                              placeholder="Branch Name"
                              onChange={handleChange}
                              className="bg-[#FFF] text-[#91919F] w-full h-[50px] rounded-[16px] px-3 focus:outline-none"
                            />
                            {meta.touched && meta.error && (
                              <div className="warning-message">
                                {meta.error}
                              </div>
                            )}
                          </>
                        )}
                      </Field>
                    </div>

                    {/* <div className="flex-1">
                                            <Field name="shop_type" component="select" className='w-full bg-[#FFF] rounded-[16px] px-3 py-[14px] focus:outline-none customSelect'>
                                                <option className="text-blue-500" disabled value="">
                                                    Shop Type
                                                </option>
                                                <option>Clothing Shop</option>
                                            </Field>
                                        </div> */}
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row gap-3 mb-3">
                  {/* <div className='flex-1'>
                                        <Field name="owner_name">
                                            {({ input, meta }) => (
                                                <>
                                                    <input
                                                        {...input}
                                                        type="text"
                                                        placeholder="Owner Name"
                                                        className='w-full h-[50px] rounded-[16px] px-3 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]'
                                                    />
                                                    {meta.touched && meta.error && (
                                                        <div className="warning-message">
                                                            {meta.error}
                                                        </div>
                                                    )}

                                                </>
                                            )}
                                        </Field>
                                    </div> */}

                  <div className="flex-1">
                    <Field name="address">
                      {({ input, meta }) => (
                        <>
                          {/* {selectedValue !== 'Logo' && ( */}
                          <div id="address">
                            <input
                              {...input}
                              type="text"
                              placeholder="Address"
                              onChange={handleChange}
                              className="w-full h-[50px] rounded-[16px] px-3 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]"
                            />
                            {meta.touched && meta.error && (
                              <div className="warning-message">
                                {meta.error}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="flex flex-col sm:flex-row gap-3 mb-3">
                  <div className="flex-1">
                    <SelectBox
                      name="country"
                      options={countryList}
                      value={Number(initialValues?.country)}
                      onChange={handleChangeSelect.bind(this, "country")}
                      label={false}
                      placeholder="Select country"
                      className="w-full bg-[#FFF] rounded-[16px] px-3 py-[14px] focus:outline-none customSelect"
                    />
                  </div>

                  <div className="flex-1">
                    <div className="flex justify-between gap-3">
                      <div className="flex-1">
                        <SelectBox
                          name="district"
                          options={districtList}
                          value={Number(initialValues?.district)}
                          onChange={handleChangeSelect.bind(this, "district")}
                          label={false}
                          placeholder="Select district"
                          className="w-full bg-[#FFF] rounded-[16px] px-3 py-[14px] focus:outline-none customSelect"
                        />
                      </div>
                      <div className="flex-1">
                        <SelectBox
                          name="city"
                          options={cityList}
                          value={Number(initialValues?.city)}
                          onChange={handleChangeSelect.bind(this, "city")}
                          label={false}
                          placeholder="Select City"
                          className="w-full bg-[#FFF] rounded-[16px] px-3 py-[14px] focus:outline-none customSelect"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col sm:flex-row gap-3 mb-3">
                  <div className="flex-1">
                    <Field name="zipcode">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            onChange={handleChange}
                            placeholder="Zip/Postcode"
                            className="w-full h-[50px] rounded-[16px] px-3 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="flex-1">
                    <div className="flex justify-between gap-3">
                      {/* <div style={{ flex: 1 }}>
                        <Field
                          name="countrye_code"
                          component="select"
                          className="w-full bg-[#FFF] rounded-[16px] px-3 py-[14px] focus:outline-none customSelect"
                        >
                          <option>
                            {"+880"} <i>{}</i>
                          </option>
                          <option></option>
                        </Field>
                      </div> */}
                      <div style={{ flex: 3 }}>
                        <Field name="phone">
                          {({ input, meta }) => (
                            <>
                              <input
                                {...input}
                                type="text"
                                onChange={handleChange}
                                placeholder="Phone Number"
                                className="w-full h-[50px] rounded-[16px] px-3 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]"
                              />
                            </>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col sm:flex-row gap-3 mb-3">
                  <div className="flex-1">
                    <Field name="email">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Email"
                            className="w-full h-[50px] rounded-[16px] px-3 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="mb-3 flex flex-col gap-3">
                  <button
                    type="submit"
                    className="w-full bg-[#034AFF] text-[#FFF] text-[18px] font-lato font-600 py-2 rounded-[16px]"
                  >
                    {""}
                    Save Branch{""}
                  </button>
                </div>
              </form>
            </>
          );
        }}
      />
    </div>
  );
};



export default BranchSettingForm;