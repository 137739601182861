import React from 'react'
// import secondsignupimg from "../../../../assets/registration/secondsignup.svg"
// import illustrationimg2 from "../../../../assets/registration/Illustration2.svg"
// import indicatorimg2 from "../../../../assets/registration/Indicator2.svg"
import "./index.css"
const BASE_URL = process.env.REACT_APP_ROOT_URL;
function SecondStep(props) {
    return (
        <section style={{ background: "#000" }} >
            <div className='second-container'>
                <div className="second-left">
                    <img src={`${BASE_URL}/assets/registration/webp/secondsignup.webp`} alt='secondimage' className="img-fluid" />
                    <div className="continue-button ">
                            <button className='continue-form-button' onClick={props.backToprev}>Back</button>
                        </div>
                        <div className="continue-button ">
                            <button className='continue-form-button' onClick={props.continueSubmit}>Continue</button>
                        </div>
                </div>
                <div className="second-right">
                    <div className="second-right-card ">
                        <div className="second-log-img">
                            <img src={`${BASE_URL}/assets/registration/webp/Illustration2.webp`} alt="logo" className="img-fluid" />
                        </div>
                        <div className='flex flex-col'>      
                            <div className="second-form-main">

                            <p>Know where Your <br></br>
                                Money Goes</p>
                        </div>
                            <div className="secondform-console">
                                <p>Track your transaction easily,
                                    <br></br> with categories and financial report </p>
                            </div>
                            </div>
                     
                        </div>
                        <div className="second-progressbar-signup">
                            {/* <img src={indicatorimg2} alt="logo" className="img-fluid" /> */}

                            <div className="indicator-two" onClick={props.backToprev}></div>
                            <div className="indicator-one"></div>
                            <div className="indicator-three" onClick={props.continueSubmit}></div>
                        </div>
                    </div>   
            </div>
        </section>
    )
}

export default SecondStep