import React from 'react';

const SolidLine = () => {
  const solidLineStyle = {
    border: '1px solid black', // Adjust thickness and color as needed
    width: '100%',
    height: '0',
    marginTop: '5px',
    marginBottom: '5px', // Adjust as needed
  };

  return <div style={solidLineStyle}></div>;
};

export default SolidLine;
