import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { MdModeEdit } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagination from "../../../utils/pagination";
import { useFlashMessage } from "../../../context/FlashMessageContext";
import { AppContext } from "../../../context/context";
import { formatNumber, useStateArray } from "../../../utils/Common";
import "./index.scss";

import { AiOutlineSearch } from "react-icons/ai";

const BASE_URL = process.env.REACT_APP_ROOT_URL;
const shop_logo = `${BASE_URL}/layoutlogo.svg`;

const ProductsList = (props) => {
  const {
    type
  } = props;
  const { state } = useContext(AppContext);
  const { currentUser, invoiceSetting } = state;
  const navigate = useNavigate();
  const { flashMessage, getFlashMessageStyle } = useFlashMessage();
  const [currentTotal, setCurrentTotal] = useState(0);
  const token = localStorage.getItem("token");
  const [products, setProducts] = useState([]);
  const [productsData, setProductsData] = useState([]);

  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const perPage = 10;
  const [prevSearchKeyword, setPrevSearchKeyword] = useState("");
  useEffect(() => {
    // Check if the search keyword has changed
    const isSearchKeywordChanged = searchKeyword !== prevSearchKeyword;

    // Update the previous search keyword
    setPrevSearchKeyword(searchKeyword);

    // If it's a new search keyword, set the page to 1
    if (isSearchKeywordChanged) {
      setCurrentPage(1);
    } else {
      getProductlist(currentPage, searchKeyword);
    }
  }, [currentPage, searchKeyword, prevSearchKeyword]);
  const { id } = useParams();

  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  const getFilteredProductlist = (page, searchKeyword) => {
    const url = type=='wholesaler' ? `whole-sellers-wise-product` : `customer-wise-product`;
    //const url = `customer-wise-product`;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}${url}/${id}?interval=yearly&product_name=${searchKeyword}&page=${page}&limit=${perPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          setProducts(response.data.data);
          setProductsData(response.data.data.data);
          setCurrentTotal(response.data.data.data.length);
        } else {
          console.log("Data Not Found!");
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };

  const getProductlist = (page, searchKeyword = null) => {
    setLoader(true);
    if (searchKeyword) {
      getFilteredProductlist(page, searchKeyword);
    } else {
      //const url = `customer-wise-product`;
      const url = type=='wholesaler' ? `whole-sellers-wise-product` : `customer-wise-product`;
      axios
        .get(
          `${process.env.REACT_APP_API_URL}${url}/${id}?interval=yearly&page=${page}&per_page=${perPage}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);

          if (response.data.success) {
            setLoader(false);
            setProducts(response.data.data);
            setProductsData(response.data.data.data);
            setCurrentTotal(response.data.data.data.length);
          }
        })
        .catch((error) => {
          console.log(error);
          loadingCallback(false);
          if (error?.response?.status === 401) {
            navigate("/login");
            localStorage.setItem("token", "");
            localStorage.setItem("token", "");
            // Clear session and local storage
            localStorage.setItem("isPinVerify", false);

            navigate("/login");
          }
        });
    }
  };

  //const shouldRenderPagination = products.total > perPage;
  const shouldRenderPagination = products?.total != currentTotal && products?.total > perPage;

  return (
    <div className="productsList-container">
      <div className="bg-white rounded-[16px] px-4 py-4">
        <div className="flex flex-col md:flex-row justify-between mb-4">
          <div>
            <h1 className="font-nunito font-[400] text-[12px] text-[#A0AEC0] mb-[8px]">
              Products / Products
            </h1>
            <h2 className="font-nunito font-bold text-[14px] text-[#2D3748] ">
              Product List
            </h2>
          </div>

          <div className="flex gap-2 justify-center">
            <div className="flex relative w-full">
              <input
                autoFocus
                type="text"
                className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[45px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                placeholder="Search Product"
                onChange={(e) => setSearchKeyword(e.target.value)}
              />

              <div className="absolute top-[37%] left-[10px]">
                <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
              </div>

              <div className="absolute top-[36%] right-[10px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M0.83268 12.6665C0.83268 12.3932 1.05935 12.1665 1.33268 12.1665L8.66602 12.1665C8.93935 12.1665 9.16602 12.3932 9.16602 12.6665C9.16602 12.9398 8.93935 13.1665 8.66602 13.1665L1.33268 13.1665C1.05935 13.1665 0.83268 12.9398 0.83268 12.6665Z"
                    fill="#102048"
                  />
                  <path
                    d="M10.8327 12.6665C10.8327 12.3932 11.0594 12.1665 11.3327 12.1665L14.666 12.1665C14.9393 12.1665 15.166 12.3932 15.166 12.6665C15.166 12.9398 14.9393 13.1665 14.666 13.1665L11.3327 13.1665C11.0594 13.1665 10.8327 12.9398 10.8327 12.6665Z"
                    fill="#102048"
                  />
                  <path
                    d="M0.832684 8C0.832684 7.72667 1.05935 7.5 1.33268 7.5L4.66602 7.5C4.93935 7.5 5.16602 7.72667 5.16602 8C5.16602 8.27333 4.93935 8.5 4.66602 8.5L1.33268 8.5C1.05935 8.5 0.832684 8.27333 0.832684 8Z"
                    fill="#102048"
                  />
                  <path
                    d="M6.83268 8C6.83268 7.72667 7.05935 7.5 7.33268 7.5L14.666 7.5C14.9393 7.5 15.166 7.72667 15.166 8C15.166 8.27333 14.9393 8.5 14.666 8.5L7.33268 8.5C7.05935 8.5 6.83268 8.27333 6.83268 8Z"
                    fill="#102048"
                  />
                  <path
                    d="M0.83268 3.3335C0.83268 3.06016 1.05935 2.8335 1.33268 2.8335L8.66602 2.8335C8.93935 2.8335 9.16602 3.06016 9.16602 3.3335C9.16602 3.60683 8.93935 3.8335 8.66602 3.8335L1.33268 3.8335C1.05935 3.8335 0.83268 3.60683 0.83268 3.3335Z"
                    fill="#102048"
                  />
                  <path
                    d="M10.8327 3.3335C10.8327 3.06016 11.0594 2.8335 11.3327 2.8335L14.666 2.8335C14.9393 2.8335 15.166 3.06016 15.166 3.3335C15.166 3.60683 14.9393 3.8335 14.666 3.8335L11.3327 3.8335C11.0594 3.8335 10.8327 3.60683 10.8327 3.3335Z"
                    fill="#102048"
                  />
                  <path
                    d="M8.16602 4.66667L8.16602 2C8.16602 1.72667 8.39268 1.5 8.66602 1.5C8.93935 1.5 9.16602 1.72667 9.16602 2L9.16602 4.66667C9.16602 4.94 8.93935 5.16667 8.66602 5.16667C8.39268 5.16667 8.16602 4.94 8.16602 4.66667Z"
                    fill="#102048"
                  />
                  <path
                    d="M8.16602 14.0002L8.16602 11.3335C8.16602 11.0602 8.39268 10.8335 8.66602 10.8335C8.93935 10.8335 9.16602 11.0602 9.16602 11.3335L9.16602 14.0002C9.16602 14.2735 8.93935 14.5002 8.66602 14.5002C8.39268 14.5002 8.16602 14.2735 8.16602 14.0002Z"
                    fill="#102048"
                  />
                  <path
                    d="M6.83398 9.33317L6.83398 6.6665C6.83398 6.39317 7.06065 6.1665 7.33398 6.1665C7.60732 6.1665 7.83398 6.39317 7.83398 6.6665L7.83398 9.33317C7.83398 9.6065 7.60732 9.83317 7.33398 9.83317C7.06065 9.83317 6.83398 9.6065 6.83398 9.33317Z"
                    fill="#102048"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        {flashMessage && (
          <div
            className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(
              flashMessage.type
            )}`}
          >
            {flashMessage.text}
          </div>
        )}
        {loading && (
          <div className="custom-loader-logo">
            <img
              className=""
              src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
              alt="logo"
            />
          </div>
        )}

        {/* Pagination component */}
        {shouldRenderPagination && (
          <Pagination
            currentPage={currentPage}
            lastPage={products.last_page}
            onPageChange={onPageChange}
          />
        )}

        {productsData.map((item, index) => {
          return (
            <div
              className={`border-1 border-solid productlist-table`}
              style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              key={item.product_id}
            >
              <div className="table-content">
                <div className="table-content-image w-full md:w-[160px] h-auto md:h-[160px]">
                  <img
                    className="w-[160px] h-[160px] md:w-[100%] md:max-h-[160px] md:min-h-[160px] m-auto md:m-0"
                    src={item?.product_info?.image_path ? item?.product_info?.image_path : shop_logo}
                    alt="img"
                  />
                </div>
                <div className="table-content-data">
                  <div className="mb-[12px]">
                    <div className="font-nunito">
                      <div className="text-[#2D3748] text-[16px] font-bold">
                        {item?.product_info?.name}
                      </div>
                      <div className="text-[#718096] text-[14px] font-extrabold">
                        #{item?.product_info?.barcode}
                      </div>
                    </div>
                  </div>
                  <div className="table-content-data-details">
                    <div className="left table-content-data-left grid grid-cols-[100px,1fr] gap-x-[5px] gap-y-[2px]">
                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                        Category
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        : {item?.product_info?.category ? item?.product_info?.category?.name : null}
                      </div>

                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                        Sub Category
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold whitespace-nowrap font-nunito">
                        : {item?.product_info?.sub_category ? item?.product_info?.sub_category.name : null}
                      </div>

                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                        Brand
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        : {item?.product_info?.brands ? item?.product_info?.brands.name : null}
                      </div>

                      <div
                        className={`${
                          item?.current_stock === 0
                            ? "text-[#F00000]"
                            : "text-[#718096]"
                        }  text-[14px] font-medium font-nunito`}
                      >
                        {item?.current_stock === 0 ? "Stock Out" : "Stock In"}
                      </div>
                      <div className="text-[#2D3748] text-[14px] whitespace-nowrap font-bold font-nunito">
                        :{" "}
                        {item?.product_info?.current_stock != ""
                          ? formatNumber(item?.product_info?.current_stock)
                          : "0"}{" "}
                        {item?.product_info?.unit_id ? item?.unit?.name : null}
                      </div>
                      <div className="text-[#2D3748] text-[14px] whitespace-nowrap font-bold font-nunito">
                        Serial/IMEI : {item?.serial ? item?.serial : null}
                      </div>
                    </div>
                    <div className="right table-content-data-left grid grid-cols-[100px,1fr] gap-x-[5px] gap-y-[2px]">
                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                        Unit
                      </div>
                      <div className="text-[#718096] text-[14px] font-bold font-nunito">
                        : {item?.product_info?.unit_id ? item?.unit?.name : null}
                      </div>

                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                        Purchase price
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        : {formatNumber(item?.product_info?.purchase_price)}{" "}
                        {invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info?.code
                          : "BDT"}
                      </div>

                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                        Retail price
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        : {formatNumber(item?.product_info?.retails_price)}{" "}
                        {invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info?.code
                          : "BDT"}
                      </div>

                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                        Wholesale price
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        : {formatNumber(item?.product_info?.wholesale_price)}{" "}
                        {invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info?.code
                          : "BDT"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col justify-between">
                <div className="table-action">
                  <div className="table-action-edit">
                    <Link
                      className="hover:text-gray-700"
                      to={`/editproduct/${item.product_id}`}
                    >
                      {" "}
                      <button className="flex justify-center items-center bg-[#E6E6E6] text-gray-700 text-[12px] rounded-[5px] px-3 pt-[3px] pb-[3px]">
                        <MdModeEdit />
                        <span>Edit</span>
                      </button>
                    </Link>
                  </div>

                  <div className="table-action-view">
                    <Link
                      className="hover:text-[#FFF]"
                      to={`/products/${item.product_id}`}
                    >
                      {" "}
                      <button className="flex justify-center items-center bg-[#407BFF] text-[#FFF] text-[12px] rounded-[5px] px-3 pt-[6px] pb-[4px]">
                        <span>View Product</span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="w-full relative px-[27px] overflow-x-auto sm:rounded-lg">
          {loader && <div className="custom-loader"></div>}
        </div>
      </div>
    </div>
  );
};

export default ProductsList;
