import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { FaUsers } from 'react-icons/fa';
import ProductSaleGraph from './ProductSaleGraph/ProductSaleGraph';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const revenueDataState = {
  series: [
    {
      name: "series1",
      data: [390, 400, 280, 351, 412, 109, 100, 390, 400, 280, 351, 412],
    },
  ],
  options: {
    chart: {
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    xaxis: {
      labels: {
        format: "MMM",
      },
      type: "datetime",
      categories: [
        "2018-01-19T00:00:00.000Z",
        "2018-02-19T01:30:00.000Z",
        "2018-03-19T02:30:00.000Z",
        "2018-04-19T03:30:00.000Z",
        "2018-05-19T04:30:00.000Z",
        "2018-06-19T05:30:00.000Z",
        "2018-07-19T06:30:00.000Z",
        "2018-08-19T06:30:00.000Z",
        "2018-09-19T06:30:00.000Z",
        "2018-10-19T06:30:00.000Z",
        "2018-11-19T06:30:00.000Z",
        "2018-12-19T06:30:00.000Z",
      ],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  },
};

const ProductCategoryReport = (props) => {
  const {
    handleNavigate,
    activeTab,
    loadingCallback,
    totalCategorySvg,
    categoryReportsSvg,
    dueAmountSvg,
    saleCategorySvg,
    WalletBlue,
    WalletGreen,
    WalletPurple,
    WalletOrrange,
  } = props;

  const [dropDownProduct, setDropDownProduct] = useState(false);
  const [allProductData, setAllProductData] = useState([])
  const token = localStorage.getItem("token");
  const salesProductData = allProductData.sales_chart;
  const [revenueData, setRevenueData] = useState(revenueDataState);
  const navigate = useNavigate();
  useEffect(() => {
    if (salesProductData && salesProductData.length > 0) {
      let xaxisCategories = [];
      let xaxisCategoriesAmount = [];

      let xaxisFormat = "dd"; // Default format
      let xaxisType = "category"; // Default type

      // Check the type and set format accordingly
      if (salesProductData && salesProductData.length > 0) {
        const firstItem = salesProductData[0];
        if (activeTab === "monthly") {
          xaxisCategories = salesProductData.map((item) => item?.name);
          xaxisFormat = "MMM"; // Monthly format
          xaxisCategoriesAmount = salesProductData.map((item) => item?.amount);
        } else if (activeTab === "yearly") {
          xaxisCategories = salesProductData.map((item) => item?.name);
          xaxisFormat = "yyyy"; // Yearly format
          xaxisCategoriesAmount = salesProductData.map((item) => item?.amount);
        } else if (activeTab === "weekly") {
          xaxisCategories = salesProductData.map((item) => item?.name);
          xaxisFormat = "dd"; // Default format for daily
          xaxisCategoriesAmount = salesProductData.map((item) => item?.amount);
        } else {
          xaxisCategories = salesProductData.map((item) => item?.name);
          xaxisCategoriesAmount = salesProductData.map((item) => item?.amount);
        }
        // Add more conditions as needed
      }

      const revenueDataState = {
        series: [
          {
            name: "series1",
            data: xaxisCategoriesAmount,
          },
        ],
        options: {
          chart: {
            type: "area",
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            labels: {
              format: xaxisFormat,
            },
            type: xaxisType,
            categories: xaxisCategories,
          },
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
        },
      };

      // Now, revenueDataState has the updated series.data and options.xaxis.categories
      console.log(revenueDataState);
      setRevenueData(revenueDataState);
    }
  }, [salesProductData]);
  const getProductReport = (activeTab) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}product-analysis?interval=${activeTab}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("==========");
        if (response.data.success) {
          loadingCallback(false);
          const fetchedData = response.data.data;
          setAllProductData(fetchedData);

        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };
  useEffect(() => {
    getProductReport(activeTab);
  }, [activeTab]);

  return (
    <>
      <div className="category-report rounded-[25px] bg-[#fff]   mt-4">
        <div className='flex justify-between items-center px-5 py-4 cursor-pointer ' onClick={() => setDropDownProduct(!dropDownProduct)}>
          <div className='text-[#000232] font-semibold '>
            <p className='text-[25px]'>Product Sale Report </p>
          </div>
          <div >
            <div className={`flex items-center justify-center w-[40px] h-[40px] transform duration-300 ${dropDownProduct ? "-rotate-180" : ""}   `}>
              <FontAwesomeIcon icon={faAngleDown} className="text-[25px]" />
            </div>

          </div>
        </div>
        {dropDownProduct && <div className='transform duration-500 px-5 py-4 '>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-2 '>
            <div className="left-cards p-3">
              <div className='grid grid-cols-1 md:grid-cols-2 gap-3 mb-3'>
                <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-transparent text-[#FF7A00] border-2 border-solid border-[#FF7A00] flex justify-between py-8  px-3 rounded-xl">
                  <div className="space-y-1">
                    <img src={`${WalletOrrange}`} />
                    <p>{allProductData?.Product_sale_amount?allProductData?.Product_sale_amount:0} <sub className="text-xs font-extralight ml-1">Tk</sub></p>
                    <p className="text-sm font-extralight">Product Sale Report</p>
                  </div>
                  <div className="text-right flex flex-col gap-2 justify-between">
                    <p className="">{allProductData?.Product_sale_amount_percent}</p>
                    <img
                      src={`${categoryReportsSvg}`}
                      alt="logo"
                    />
                  </div>
                </div>
                <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-transparent text-[#9B54E1] border-2 border-solid border-[#9B54E1] flex justify-between py-8  px-3 rounded-xl">
                  <div className="space-y-1">
                    <img src={`${WalletPurple}`} />
                    <p>{allProductData?.total_product?allProductData?.total_product:0}</p>
                    <p className="text-sm font-extralight">Total Products</p>
                  </div>
                  <div className="text-right flex flex-col gap-2 justify-between">
                    <p className="">{allProductData?.total_product_percent}</p>
                    <img
                      src={`${totalCategorySvg}`}
                      alt="logo"
                    />
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
                <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-transparent text-[#29B170] border-2 border-solid border-[#29B170] flex justify-between py-8  px-3 rounded-xl">
                  <div className="space-y-1">
                    <img src={`${WalletGreen}`} />
                    <p>{allProductData?.Product_sale?allProductData?.Product_sale:0}</p>
                    <p className="text-sm font-extralight">Sale Products</p>
                  </div>
                  <div className="text-right flex flex-col gap-2 justify-between">
                    <p className="">{allProductData?.Product_sale_percent}</p>
                    <img
                      src={saleCategorySvg}
                      alt="logo"
                    />
                  </div>
                </div>
                <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-transparent text-[#347AE2] border-2 border-solid border-[#347AE2] flex justify-between py-7  px-3 rounded-xl">
                  <div className="space-y-1">
                    <img src={`${WalletBlue}`} />
                    <p>{allProductData?.Product_purchase?allProductData?.Product_purchase:0}</p>
                    <p className="text-sm font-extralight">Purchased Products</p>
                  </div>
                  <div className="text-right flex flex-col gap-2 justify-between">
                    <p className="">{allProductData?.Product_purchase_percent}</p>
                    <img
                      src={dueAmountSvg}
                      alt="logo"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="  border-2 border-solid border-[#29B170]  rounded-[50px] p-3">
              <ProductSaleGraph
                allProductData={allProductData}
                revenueProductData={revenueData}
                type={activeTab}
                loadingCallback={loadingCallback}
              />
            </div>
          </div>
          <div>
            <div className='text-[#000232] font-semibold '>
              <p className='text-[25px]'>Highest Selling Products</p>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 '>

              <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] col-span-1 bg-transparent text-[#9B54E1] border-2 border-solid border-[#9B54E1] flex justify-between py-8  px-3 rounded-xl">
                <div className="space-y-1">
                <img src={`${WalletPurple}`}/>
                  <p>{allProductData?.highest_sales_amount?allProductData?.highest_sales_amount:0} <sub className="text-xs font-extralight ml-1">Tk</sub></p>
                  <p className="text-sm font-extralight">{allProductData?.highest_sales_product}</p>
                </div>
                <div className="text-right flex flex-col gap-2 justify-between">
                  <p className="">0%</p>
                  <img
                    src={totalCategorySvg}
                    alt="logo"
                  />
                </div>
              </div>

              <div className='font-lato text-[20px] col-span-2 p-3 rounded-lg border-2 shadow-[0_0_5px_rgba(0,0,0,0.1)]'>
                <div className='flex flex-col sm:flex-row items-center gap-2'>
                  <p className="w-full sm:w-[150px] whitespace-nowrap">Total Sale Unit</p>
                  <hr className='flex-grow bg-green-600 h-4 mx-3'></hr>
                  <p>{allProductData?.highest_sales_product_unit?allProductData?.highest_sales_product_unit:0}</p>
                </div>
                <div className='flex flex-col sm:flex-row items-center gap-2'>
                  <p className="w-full sm:w-[150px] whitespace-nowrap">Total Sale Amount</p>
                  <hr className='flex-grow bg-green-600 h-4 mx-3'></hr>
                  <p>{allProductData?.highest_sales_amount?allProductData?.highest_sales_amount:0} <sub className="text-xs font-extralight ml-1">BDT</sub></p>
                </div>
                <div className='flex flex-col sm:flex-row items-center gap-2'>
                  <p className="w-full sm:w-[150px]">Total Customers</p>
                  <hr className='flex-grow bg-green-600 h-4 mx-3'></hr>
                  <p>{allProductData?.total_customers?allProductData?.total_customers:0}</p>
                </div>
                <div className='flex flex-col sm:flex-row items-center gap-2'>
                  <p className="w-full sm:w-[150px] whitespace-nowrap">Total Purchase Unit</p>
                  <hr className='flex-grow bg-green-600 h-4 mx-3'></hr>
                  <p>{allProductData?.total_purchase_unit?allProductData?.total_purchase_unit:0}</p>
                </div>
              </div>

            </div>
          </div>

          <div className="flex justify-end my-3" onClick={() => handleNavigate('/product-report-history')}>
            <button className="border-b-2 cursor-pointer border-[#034AFF] text-[#034AFF] text-[16px]">View All</button>
          </div>
        </div>}
      </div>
    </>
  );
};

export default ProductCategoryReport;