import React, { useEffect, useState, useContext } from "react";
import { useFlashMessage } from "../context/FlashMessageContext";
import { get, post } from "../services/apiService";
import { AppContext } from "../context/context";
import SelectBox from "./Select";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import Input from "./Input";
import { set } from 'react-hook-form';
import Swal from "sweetalert2";
import crose from "../assets/images/crose.svg";
import OrderProduct from "../components/OrderProduct/OrderProduct";

const ExporterAddCarrierForm = (props) => {
  const {
    type,
    navigate,
    loadingCallback,
    setExporterCarrier,
    setWarningMessage,
    setShowModal,
    setExporterData,
    showNestedModal,
    setShowNestedModal,
    setNestedModalData,
    exporterCarrier,
    carrierId,
    exporterOrder,
    setAddedCarrier,
    updateModalTitle,
    productQty,
    receivedQty
  } = props;
 const {
    getFlashMessageStyle,
    setSuccessFlashMessage,
    flashMessage,
    setErrorFlashMessage,
  } = useFlashMessage(); // Retrieve the dispatch function from your state management library
  const [carrierProductFormData, setCarrierProductFormData] = useState([]);
  const [currency, setCurrency] = useState([]);
  console.log("exporterOrder", exporterOrder)

  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCurrentProducts, setSelectedCurrentProducts] = useState(null);
  const [selectedCurrentProductsImei, setSelectedCurrentProductsImei] = useState(null);
  const [carrierAddForm, setCarrierAddForm] = useState(true);
  const [carrierProductForm, setCarrierProductForm] = useState(false);
  const [carrierListForm, setCarrierListForm] = useState(false);
  const [carrierDescriptionForm, setCarrierDescriptionForm] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [startDate, setStartDate] = useState(new Date());

  const token = localStorage.getItem("token");
  const [loader, setLoader] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [exporterCarrierId, setExporterCarrierId] = useState(null);
  const [exporterCarrierData, setExporterCarrierData] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const getCurrency = () => {
    get('currency', token)
        .then((response) => {
            if (response.success) {
                setCurrency(response.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
  };
  useEffect(() => {
    getCurrency();
  }, []);
  const [formData, setFormData] = useState({
    name: "",
    exporter_order_id: Number(exporterOrder?.id),
    exporter_id: exporterOrder ? exporterOrder.exporter_id : null,
    id: exporterCarrier ? exporterCarrier.id : null,
    email: "",
    phone_no: "",
    received_qty: 0,
    flight_date: format(startDate, 'yyyy-MM-dd'),
  });
  const [productFormData, setProductFormData] = useState({
    exporter_product_id: 0,
    imei: 0,
    exporter_carrier_id: exporterCarrierId,
  });
  useEffect(() => {
    if (exporterCarrierId !== null) {
      // Create products with product_id based on exporterProductId
      // const updatedCarrier = formDataImei.map((product, index) => ({
      //   ...product,
      //   exporter_product_id: exporterProductId, // Example: product_id based on index
      // }));
      // setFormDataImei(updatedProducts);
    }
  }, [exporterCarrierId]);
  useEffect(() => {

    if (exporterCarrier) {
      // Assuming exporterProduct is an object with an 'id' property
      const { id, ...formDataWithoutId } = exporterCarrier;

      // Use formDataWithoutId when setting the form data
      setFormData(formDataWithoutId);
      setFormData((prevFormData) => ({
        ...prevFormData,
        exporter_order_id:Number(exporterOrder.id),
        exporter_id: exporterOrder ? exporterOrder.exporter_id : null,
        id: exporterCarrier ? exporterCarrier?.id : null,
      }));

    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        exporter_order_id:Number(exporterOrder.id),
        exporter_id: exporterOrder ? exporterOrder.exporter_id : null,
      }));
    }
  }, [showNestedModal,exporterCarrier,exporterOrder]);

  const handleValidation = (formData) => {
    const errors = {};
    let validate = true;
    if (formData.product_name === "") {
      errors.product_name = "name is required.";
      setIsValid(false);
      validate = false;
    }

    if (!formData.received_qty || formData.received_qty === "") {
      errors.received_qty = "qty is required.";

      setIsValid(false);
      validate = false;
    }
    if (formData.received_qty && formData.received_qty !== "" && formData.received_qty > productQty-receivedQty) {
      errors.received_qty = "qty should be less than this";
      setIsValid(false);
      validate = false;

    }
    // if (formData.flight_date === "") {
    //   errors.flight_date = "flight date is required.";
    //   setIsValid(false);
    //   validate = false;
    // }
    setFormErrors(errors);
    if (!validate) {
      setErrorFlashMessage("Please fill all the required fields.");
    }

    return validate;
  };
  const handleCarrierSubmit = async (event) => {
    event.preventDefault();

    const formattedDate = format(startDate, 'yyyy-MM-dd');

    formData.flight_date= formattedDate;

    formData.exporter_order_id= Number(exporterOrder?.id);
    if (handleValidation(formData)) {
      updateCarrier(formData);

    } else {
      setWarningMessage(true);
      //setErrorFlashMessage('Please fill all the required fields');
      const timer = setTimeout(() => {
        setWarningMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  };

  const hideNestedModal = () => {
    setShowModal(false);
  };

  const handleCarrierProductSubmit = async (event) => {
    event.preventDefault();

    // setCarrierAddForm(false);
    // setCarrierProductForm(false);
    // setCarrierListForm(false);
    // setCarrierDescriptionForm(true);
    // updateModalTitle("Description");


    //if (handleValidationProduct(carrierProductFormData)) {
      updateCarrierProduct(carrierProductFormData);

    // } else {
    //   setWarningMessage(true);
    //   //setErrorFlashMessage('Please fill all the required fields');
    //   const timer = setTimeout(() => {
    //     setWarningMessage(false);
    //   }, 3000);
    //   return () => clearTimeout(timer);
    // }


  };

  const handleCarrierListSubmit = async (event) => {
    event.preventDefault();

    setCarrierAddForm(false);
    setCarrierProductForm(false);
    setCarrierListForm(false);
    setCarrierDescriptionForm(true);
    updateModalTitle("Description");

  };



  // Function to handle changes in the input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
   // Function to handle changes in the input fields
  const handleChangeDate = (date,name) => {
    const formattedDate = format(date, 'yyyy-MM-dd');

    setFormData({
      ...formData,
      [name]: formattedDate,
    });
    if (name==="flight_date") {
      setStartDate(date);
    }
  };
  const [carrierProductList, setCarrierProductList] = useState([]);

  const updateCarrierProduct = (formData) => {
    loadingCallback(true);
    // Use your API service functions to make API requests here
    //if(isValid){
    post("save-exporter-carrier-product", formData, token)
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          // console.log("============");
          // console.log(response);
          // if (Array.isArray(response.data) && response.data.length > 0) {
          //   setCarrierProductList(response.data[0]);
          // } else {
            setCarrierProductList(response.data);
         // }
          setCarrierAddForm(false);
          setCarrierProductForm(false);
          setCarrierListForm(false);
          setCarrierDescriptionForm(true);
          updateModalTitle("Description");


          // setSuccessFlashMessage("Carrier product updated successfully");
          // //setProductAddForm(false);
          // if (response.data.id) {
          //   setExporterCarrierId(response.data.id);
          // }

          //navigate(`/add-exporter/${selectedExporter.id}`);
        } else {
          console.log("Carrier update failed");
          setErrorFlashMessage("carrier update failed");

          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
        console.log(error);
      });
    //}
  };


  const updateCarrier = (formData) => {
    loadingCallback(true);
    // Use your API service functions to make API requests here
    //if(isValid){
    post("save-exporter-carrier-with-carrier", formData, token)
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          setExporterCarrierData(response.data);
          //setShowModal(false);

          setCarrierAddForm(false);
          setCarrierProductForm(true);
          setCarrierListForm(false);

          updateModalTitle("Carrier Product Info");

          setSuccessFlashMessage("Carrier updated successfully");
          //setProductAddForm(false);
          if (response.data.id) {
            setExporterCarrierId(response.data.id);
          }

          //navigate(`/add-exporter/${selectedExporter.id}`);
        } else {
          console.log("Carrier update failed");
          setErrorFlashMessage("carrier update failed");

          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
        console.log(error);
      });
    //}
  };

  const handleInputChange = (inputValue) => {
    //if (action && action === 'input-change') {
    // Perform an API call using the inputValue to fetch the filtered options
    // Update the options state with the fetched data
    setSearchKeyword(inputValue);
    //}
  };
  const showModalWarning = (msg, data = null) => {
    Swal.fire({
      title: msg,
      text: "",
      icon: 'warning',
    });
  };
  const handleSelectChange = (name, option) => {
    const { value, label } = option;
    if (selectedProducts.length >= formData.received_qty) {
      showModalWarning("You can't select more");
      return;
    }

    if (name=='imei') {
      const selectedImei = selectedCurrentProducts?.product_imeis.find(imei => imei.id === value);
      // remove selected imei from the list
      setSelectedCurrentProductsImei(selectedCurrentProductsImei.filter(imei => imei.id !== value));
      console.log("Product", selectedCurrentProducts);
      if (selectedImei) {

        // Check if the product is not already in selectedProducts
        const isProductAlreadySelected = selectedProducts.some(product => product.id === selectedCurrentProducts.id);
        // Check if the selected IMEI is not already associated with the product in selectedProducts
        const isImeiAlreadyAssociated = selectedProducts.some(product => product.id === selectedCurrentProducts.id && product.selectedImei?.id === selectedImei.id);

        if (!isProductAlreadySelected || !isImeiAlreadyAssociated) {// Create the product object to add to selectedProducts array
          setProductFormData({
            ...productFormData,
            [name]: value,
          });
          const productToAdd = {
            id: selectedCurrentProducts.id,
            name: selectedCurrentProducts.product_name,
            selectedImei: selectedImei,
            ram: selectedCurrentProducts.ram,
            storage: selectedCurrentProducts.storage,
            product_name: selectedCurrentProducts.product_name,
            selectedImei: selectedImei
          };

          // Add the product to selectedProducts array
          setSelectedProducts(prevProducts => [...prevProducts, productToAdd]);

          // Update the product form data
          setProductFormData({
            ...productFormData,
            [name]: value,
          });
        }
      }
    } else {
      const selectedProduct = exporterOrder?.carrier_products.find(product => product.id === value);

      if (selectedProduct) {
        setProductFormData({
          ...productFormData,
          [name]: value,
        });
        setSelectedCurrentProducts(selectedProduct);
        setSelectedCurrentProductsImei(selectedProduct?.product_imeis ? selectedProduct?.product_imeis : []);
        // If selectedProduct is found, add it to the selectedProducts array
        //setSelectedProducts(prevProducts => [...prevProducts, selectedProduct]);
      }
    }
  };
  const [updateCarrierFormData, setUpdateCarrierFormData] = useState({
    id: exporterCarrierData ? exporterCarrierData.carrier_id : null,
    exporter_order_id: Number(exporterOrder.id),
    exporter_id: exporterOrder ? exporterOrder.exporter_id : null
    //currency: 'USD',
  });

  const handleCurrencyChangeSelect = (name, option) => {
    const { value, label } = option;

    setSelectedCurrency(value);
    setUpdateCarrierFormData({
      ...updateCarrierFormData,
      [name]: value,
    });
  };
  const handlePriceChange = (event) => {
    const { name, value } = event.target;
    setUpdateCarrierFormData({
      ...updateCarrierFormData,
      [name]: value,
    });
  };

  const [groupedProducts, setGroupedProducts] = useState([]);



  const [groupedProducts2, setGroupedProducts2] = useState([]);
  useEffect(() => {
    if (selectedProducts.length > 0) {
      // Create a Set to store unique combinations of exporter_carrier_id and exporter_product_id
      const uniqueCombinations = new Set();


      // Filter out duplicates and create an array of objects with exporter_carrier_id and exporter_product_id properties
      const selectedProductIds = selectedProducts.reduce((result, product) => {
        const combination = `${exporterCarrierId}-${product.id}-${product.selectedImei?.id}`;
        if (!uniqueCombinations.has(combination)) {
          uniqueCombinations.add(combination);
          result.push({
            exporter_carrier_id: exporterCarrierId, // Assuming exporter_carrier_id is a property of the product
            exporter_product_id: product.id, // Assuming exporter_product_id is a property of the product
            product_imei_id: product.selectedImei?.id,
            exporter_order_id: Number(exporterOrder.id)
          });
        }
        return result;
      }, []);

      setCarrierProductFormData(selectedProductIds);
    }
    if (selectedProducts.length > 0) {
      let groupedProducts = selectedProducts.reduce((groups, product) => {
        if (!groups[product.id]) {
          groups[product.id] = [];
        }
        groups[product.id].push(product);
        return groups;
      }, {});
      setGroupedProducts(groupedProducts);
    }
    // Group the products by their IDs
    const groupedProducts2 = selectedProducts.reduce((groups, product) => {
      // Check if a product with the same ID exists in the group
      if (groups[product.id]) {
        // If the product with the same ID exists, push the current product under the imeis key of the existing product
        groups[product.id].imeis.push(product.selectedImei);
      } else {
        // If the product with the same ID does not exist, create a new product object and initialize the imeis key with an array containing the current product's selectedImei
        groups[product.id] = {
          ...product,
          imeis: [product.selectedImei]
        };
      }
      return groups;
    }, {});
    setGroupedProducts2(groupedProducts2);
  }, [selectedProducts, exporterCarrierId]);

  const handleCarrierDescriptionSubmit = async (event) => {
    event.preventDefault();
    updateCarrierFormData.id = exporterCarrierData.carrier_id;
    // console.log("============");
    // console.log(updateCarrierFormData);
    updateCarrierCharge(updateCarrierFormData);
  };
  const updateCarrierCharge = (formData) => {
    //loadingCallback(true);
    setSuccessFlashMessage("carrier product added successfully");

          hideNestedModal();
          setAddedCarrier(true);
    // formData.id = exporterCarrierData.id;
    // post("update-exporter-carrier", formData, token)
    //   .then((response) => {
    //     loadingCallback(false);
    //     if (response.status === 200) {
    //       //setExporterCarrier(exporterCarrier);
    //       setSuccessFlashMessage("carrier product added successfully");

    //       hideNestedModal();
    //       setAddedCarrier(true);
    //       // console.log("============");
    //       // console.log(response);
    //       // setCarrierProductList(response.data);
    //       // setCarrierAddForm(false);
    //       // setCarrierProductForm(false);
    //       // setCarrierListForm(false);
    //       // setCarrierDescriptionForm(true);
    //       // updateModalTitle("Description");


    //       // setSuccessFlashMessage("Carrier product updated successfully");
    //       // //setProductAddForm(false);
    //       // if (response.data.id) {
    //       //   setExporterCarrierId(response.data.id);
    //       // }

    //       //navigate(`/add-exporter/${selectedExporter.id}`);
    //     } else {
    //       console.log("Carrier update failed");
    //       setErrorFlashMessage("carrier update failed");

    //       setWarningMessage(true);
    //       const timer = setTimeout(() => {
    //         setWarningMessage(false);
    //       }, 3000);
    //       return () => clearTimeout(timer);
    //     }
    //   })
    //   .catch((error) => {
    //     loadingCallback(false);
    //     if (error?.response?.status === 401) {
    //       localStorage.clear();
    //       localStorage.setItem("token", false);
    //       // Clear session and local storage
    //       localStorage.setItem("isPinVerify", false);

    //       navigate("/login");
    //     }
    //     console.log(error);
    //   });

  };
  // groupedProducts = selectedProducts.reduce((groups, product) => {
  //   if (!groups[product.id]) {
  //     groups[product.id] = [];
  //   }
  //   groups[product.id].push(product);
  //   return groups;
  // }, {});
  const handleCancelMethod = (id, par, index,imeiList) => {
    const updatedGroupedProducts = { ...groupedProducts };
    const updatedSelectedProducts = [...selectedProducts];
    const indexToRemove = updatedGroupedProducts[id].findIndex(
      (item) => item.selectedImei?.id === par
    );
    const indexToRemoveProduct = updatedSelectedProducts.findIndex(
      (item) => item.selectedImei?.id === par
    );
    if (indexToRemove !== -1) {
      updatedGroupedProducts[id].splice(indexToRemove, 1);
      setGroupedProducts(updatedGroupedProducts);
    }

    if (indexToRemoveProduct !== -1) {
      updatedSelectedProducts.splice(indexToRemoveProduct, 1);
      setSelectedProducts(updatedSelectedProducts);
      // Add the removed IMEI back to the selectedCurrentProductsImei array
      setSelectedCurrentProductsImei([...imeiList, selectedProducts[index].selectedImei]);
    }
  };

  // console.log("carrier data", OrderProduct);
  return (
    <>
      {carrierAddForm ? (
        <form
          method="get"
          className="col-sm-12 min-h-[10vh]"
          data-autosubmit="false"
          autoComplete="off"
          onSubmit={handleCarrierSubmit}
        >
          <div className="flex flex-col gap-3 mt-3">
            <div className="mb-3 relative">
              <Input
                label={`Name*`}
                placeholder={"Name"}
                name="name"
                value={formData.name} // Set the value from formData
                onChange={handleChange}
                error={formErrors.product_name} // Pass the error message for the 'name' field
                setFormErrors={setFormErrors}
              ></Input>

            </div>
            <div className="mb-3 relative">
              <Input
                label={`Email`}
                placeholder={"email"}
                name="email"
                value={formData.email} // Set the value from formData
                onChange={handleChange}
                //error={formErrors.email} // Pass the error message for the 'name' field
                //setFormErrors={setFormErrors}
              ></Input>
            </div>
            <div className="mb-3 relative">
              <Input
                label={`Phone Number`}
                placeholder={"Phone Number"}
                name="phone_no"
                value={formData.phone_no} // Set the value from formData
                onChange={handleChange}
                //error={formErrors.phone_no} // Pass the error message for the 'name' field
                //setFormErrors={setFormErrors}
              ></Input>
            </div>

            <div className="mb-3 relative">
              <Input
                label={`Address`}
                placeholder={"Address"}
                name="address"
                value={formData.address} // Set the value from formData
                onChange={handleChange}
                //error={formErrors.phone_no} // Pass the error message for the 'name' field
                //setFormErrors={setFormErrors}
              ></Input>
            </div>

            <div className="mb-3 relative">
              <Input
                label={`ID/Passport`}
                placeholder={"ID/Passport"}
                name="nid_passport_no"
                value={formData.nid_passport_no} // Set the value from formData
                onChange={handleChange}
                //error={formErrors.phone_no} // Pass the error message for the 'name' field
                //setFormErrors={setFormErrors}
              ></Input>
            </div>

            <div className="mb-3 relative">
              <Input
                label={`Received Quantity`}
                placeholder={"Received Quantity"}
                name="received_qty"
                value={formData.received_qty} // Set the value from formData
                onChange={handleChange}
                error={formErrors.received_qty} // Pass the error message for the 'name' field
                setFormErrors={setFormErrors}
              ></Input>
            </div>

            <div className="relative border-[#C1CFEF] py-2.5 border w-full flex-1 rounded-xl">
              <DatePicker
                selected={startDate}
                value={formData.flight_date} // Set the value from formData
                onChange={(date) => handleChangeDate(date, 'flight_date')}
                dateFormat="yyyy-MM-dd"
                className="w-full px-2 focus:outline-none"
              />
              <span className="bg-white absolute left-2 font-semibold px-1 text-xs top-0 text-[#102048] translate-y-[-50%]">
                Flight Date
              </span>
            </div>

            <div className="mt-4">
              <input
                type="submit"
                value="Next"
                className="btn btn-primary w-full text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF]"
              />
            </div>
          </div>
        </form>
      ) : carrierProductForm ? (
        <form
          method="get"
          className="col-sm-12 min-h-[10vh]"
          data-autosubmit="false"
          autoComplete="off"
          onSubmit={handleCarrierProductSubmit}
        >
          <div className="flex flex-row gap-2 mt-2 mb-2">
            <div className="w-[50%]">
              <div className="mb-3 relative">
                <SelectBox
                  name="exporter_product_id"
                  options={exporterOrder?.carrier_products}
                  value={productFormData?.exporter_product_id ? productFormData?.exporter_product_id : ""}
                  //onInputChangeData={handleInputChange}
                  onChange={handleSelectChange.bind(this, "exporter_product_id")}
                  placeholder="Product"
                />
                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Product
                </label>
              </div>
            </div>
            <div className="w-[50%]">
              <div className="mb-3 relative">
                <SelectBox
                  name="imei"
                  options={selectedCurrentProductsImei ? selectedCurrentProductsImei : []}
                  value={productFormData?.imei ? productFormData?.imei : ""}
                  onChange={handleSelectChange.bind(this, "imei")}
                  placeholder="select IMEI"
                />
                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  IMEI
                </label>
              </div>
            </div>
          </div>

          {Object.keys(groupedProducts).map(productId => (
            <div key={productId}>
              <div className="rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[10px] py-[12px] mb-2">
                <div className="max-h-[15vh] overflow-y-auto">

                  <div className="flex flex-row justify-between border-b-[1px] border-solid border-[#E7EBF4] px-[10px] py-[12px]">
                    <div className="font-nunito font-[700] text-[12px] text-[#000232]">
                      Product Name
                    </div>
                    <div className="font-nunito font-[700] text-[12px] text-[#000232]">
                      IMEI
                    </div>
                  </div>
                  <div className="min-h-[70px]">
                    { groupedProducts[productId].map((product, index) =>{

                      console.log("Product Data", product);
                      return (
                      <div key={index} className="flex flex-row justify-between border-b-[1px] border-solid border-[#E7EBF4] px-[10px] py-[12px]">

                        <div
                            className="bg-red-600 text-white rounded-md cursor-pointer"
                            onClick={() => handleCancelMethod(productId, product.selectedImei?.id,index,selectedCurrentProductsImei)}
                          >
                            <img src={crose} width={23} height={20} alt="" />
                          </div>
                        <div className="font-nunito font-[500] text-[12px] text-[#000232]">
                          {product.product_name}
                        </div>
                        <div className="font-nunito font-[500] text-[12px] text-[#000232]">
                          {product.selectedImei?.imei}
                        </div>
                      </div>
                    )}
                  )
                    }
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="flex flex-row gap-3">
            <input
              onClick={() => {
                setCarrierAddForm(true);
                setCarrierProductForm(false);
                setCarrierListForm(false);
                updateModalTitle("Carrier Info");
              }}
              type="button"
              value="Back"
              className="btn w-[48%] text-[#034AFF] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none capitalize"
            />
            <input
              type="submit"
              value="Next"
              className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF] capitalize"
            />
          </div>
        </form>
      ) : carrierDescriptionForm ? (
        <form
          method="get"
          className="col-sm-12 min-h-[10vh]"
          data-autosubmit="false"
          autoComplete="off"
          onSubmit={handleCarrierDescriptionSubmit}
        >
          <div className="font-nunito font-bold text-[#16px] tex-[#000232] mb-2">
            Product Description
          </div>
          {groupedProducts2 ? (

          Object.values(groupedProducts2).map((product, index) =>{
          console.log("all data", product, groupedProducts2, exporterCarrierData);
          return (
            <div key={index}>
              <div className="grid grid-cols-3 gap-3 mb-4">
                <div className="font-nunito text-[#12]">
                  <div className="font-[700] text-[#6F8EB6]">Product Name</div>
                  <div className="font-[400] text-[#000000]">{product?.product_name}</div>
                </div>

                <div className="font-nunito text-[#12]">
                  <div className="font-[700] text-[#6F8EB6]">Booking Date</div>
                  <div className="font-[400] text-[#000000]">{exporterCarrierData?.flight_date}</div>
                </div>

                <div className="font-nunito text-[#12]">
                  <div className="font-[700] text-[#6F8EB6]">Carrier Name</div>
                  <div className="font-[400] text-[#000000]">{exporterCarrierData?.carrier[0]?.name}</div>
                </div>

                <div className="font-nunito text-[#12]">
                  <div className="font-[700] text-[#6F8EB6]">QTY</div>
                  <div className="font-[400] text-[#000000]">{exporterCarrierData?.received_qty}</div>
                </div>

                <div className="font-nunito text-[#12]">
                  <div className="font-[700] text-[#6F8EB6]">Email</div>
                  <div className="font-[400] text-[#000000] break-words">{exporterCarrierData?.carrier[0].email}</div>
                </div>

                <div className="font-nunito text-[#12]">
                  <div className="font-[700] text-[#6F8EB6]">Phone Number</div>
                  <div className="font-[400] text-[#000000]">{exporterCarrierData?.carrier[0].phone_no}</div>
                </div>
              </div>

              <div className="rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[12px] py-[15px] mb-[20px]">
                <div className="font-lato font-[700] text-[12px] text-[#000232] mb-2">
                  List of IMEI
                </div>
                <div className="max-h-[140px] overflow-y-auto">
                  <div className="flex flex-row gap-2 font-nunito font-bold text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
                    <div className="py-[5px] flex-1">
                      SL
                      <br />
                      No
                    </div>
                    <div className="py-[5px] text-center flex-1">Ram</div>
                    <div className="py-[5px] text-center flex-1">Storage</div>
                    <div className="py-[5px] text-right w-[42%] pr-[5px]">
                      IMEI
                      <br />
                      Number
                    </div>
                  </div>
                   {product?.imeis.map((imei, index) => (
                    <div key={index} className="flex flex-row gap-2 font-nunito font-[400] text-[14px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
                      <div className="py-[5px] flex-1">{index+1}</div>
                      <div className="py-[5px] text-center flex-1">{product?.ram}</div>
                      <div className="py-[5px] text-center flex-1">{product?.storage}</div>
                      <div className="py-[5px] text-right w-[42%] pr-[5px]">
                        {imei?.imei}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}) ) : (
        <div>Loading...</div>
      )}

          {/* <div className="font-nunito font-[700] text-[16px] text-[#000232] mb-2">
            Carrier Charge
          </div>

          <div className="rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[12px] py-[15px] mb-[20px]">
            <div className="flex gap-5 justify-between items-center">
              <SelectBox
                name="currency"
                options={currency}
                value={selectedCurrency}
                onChange={handleCurrencyChangeSelect.bind(this, `currency`)}
                placeholder="Select Currency"
                style={{ width: "50%" }}
              />
              <Input
                label={``}
                placeholder={"Charge"}
                value={updateCarrierFormData.charge}
                name={`charge`}
                className="border-[1px] border-solid border-[#000000]"
                onChange={handlePriceChange}
              ></Input>
            </div>
          </div> */}

          <div className="flex flex-row gap-3">
            <input
              onClick={() => {
                setCarrierAddForm(false);
                setCarrierProductForm(true);
                setCarrierListForm(false);
                setCarrierDescriptionForm(false);
                updateModalTitle("Carrier Product Info");
              }}
              type="button"
              value="Back"
              className="btn w-[48%] text-[#034AFF] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none capitalize"
            />
            <input
              type="submit"
              value="Done"
              className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF] capitalize"
            />
          </div>
        </form>
      ) : null}
    </>
  );
};

export default ExporterAddCarrierForm;


// import React, { useEffect, useState, useContext } from "react";
// import { useFlashMessage } from "../context/FlashMessageContext";
// import { get, post } from "../services/apiService";
// import { AppContext } from "../context/context";
// import SelectBox from "./Select";
// import DatePicker from "react-datepicker";
// import { format } from 'date-fns';
// import Input from "./Input";
// import { set } from 'react-hook-form';
// import Swal from "sweetalert2";
// import crose from "../assets/images/crose.svg";

// const ExporterAddCarrierForm = (props) => {
//   const {
//     type,
//     navigate,
//     loadingCallback,
//     setExporterCarrier,
//     setWarningMessage,
//     setShowModal,
//     setExporterData,
//     showNestedModal,
//     setShowNestedModal,
//     setNestedModalData,
//     exporterCarrier,
//     carrierId,
//     exporterOrder,
//     setAddedCarrier,
//     updateModalTitle,
//     productQty,
//     receivedQty
//   } = props;
//  const {
//     getFlashMessageStyle,
//     setSuccessFlashMessage,
//     flashMessage,
//     setErrorFlashMessage,
//   } = useFlashMessage(); // Retrieve the dispatch function from your state management library
//   const [carrierProductFormData, setCarrierProductFormData] = useState([]);
//   const [currency, setCurrency] = useState([]);

//   const [searchKeyword, setSearchKeyword] = useState("");
//   const [selectedProducts, setSelectedProducts] = useState([]);
//   const [selectedCurrentProducts, setSelectedCurrentProducts] = useState(null);
//   const [selectedCurrentProductsImei, setSelectedCurrentProductsImei] = useState(null);
//   const [carrierAddForm, setCarrierAddForm] = useState(true);
//   const [carrierProductForm, setCarrierProductForm] = useState(false);
//   const [carrierListForm, setCarrierListForm] = useState(false);
//   const [carrierDescriptionForm, setCarrierDescriptionForm] = useState(false);
//   const [formErrors, setFormErrors] = useState({});
//   const [startDate, setStartDate] = useState(new Date());

//   const token = localStorage.getItem("token");
//   const [loader, setLoader] = useState(true);
//   const [isValid, setIsValid] = useState(true);
//   const [exporterCarrierId, setExporterCarrierId] = useState(null);
//   const [exporterCarrierData, setExporterCarrierData] = useState(null);
//   const [selectedCurrency, setSelectedCurrency] = useState(null);
//   const getCurrency = () => {
//     get('currency', token)
//         .then((response) => {
//             if (response.success) {
//                 setCurrency(response.data);
//             }
//         })
//         .catch((error) => {
//             console.log(error);
//         });
//   };
//   useEffect(() => {
//     getCurrency();
//   }, []);
//   const [formData, setFormData] = useState({
//     name: "",
//     exporter_order_id: Number(exporterOrder?.id),
//     exporter_id: exporterOrder ? exporterOrder.exporter_id : null,
//     id: exporterCarrier ? exporterCarrier.id : null,
//     email: "",
//     phone_no: "",
//     received_qty: 0,
//     flight_date: format(startDate, 'yyyy-MM-dd'),
//   });
//   const [productFormData, setProductFormData] = useState({
//     exporter_product_id: 0,
//     imei: 0,
//     exporter_carrier_id: exporterCarrierId,
//   });
//   useEffect(() => {
//     if (exporterCarrierId !== null) {
//       // Create products with product_id based on exporterProductId
//       // const updatedCarrier = formDataImei.map((product, index) => ({
//       //   ...product,
//       //   exporter_product_id: exporterProductId, // Example: product_id based on index
//       // }));
//       // setFormDataImei(updatedProducts);
//     }
//   }, [exporterCarrierId]);
//   useEffect(() => {

//     if (exporterCarrier) {
//       // Assuming exporterProduct is an object with an 'id' property
//       const { id, ...formDataWithoutId } = exporterCarrier;

//       // Use formDataWithoutId when setting the form data
//       setFormData(formDataWithoutId);
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         exporter_order_id:Number(exporterOrder.id),
//         exporter_id: exporterOrder ? exporterOrder.exporter_id : null,
//         id: exporterCarrier ? exporterCarrier?.id : null,
//       }));

//     } else {
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         exporter_order_id:Number(exporterOrder.id),
//         exporter_id: exporterOrder ? exporterOrder.exporter_id : null,
//       }));
//     }
//   }, [showNestedModal,exporterCarrier,exporterOrder]);

//   const handleValidation = (formData) => {
//     const errors = {};
//     let validate = true;
//     if (formData.name === "") {
//       errors.name = "name is required.";
//       setIsValid(false);
//       validate = false;
//     }

//     if (!formData.received_qty || formData.received_qty === "") {
//       errors.received_qty = "qty is required.";

//       setIsValid(false);
//       validate = false;
//     }
//     if (formData.received_qty && formData.received_qty !== "" && formData.received_qty > productQty-receivedQty) {
//       errors.received_qty = "qty should be less than this";
//       setIsValid(false);
//       validate = false;

//     }
//     // if (formData.flight_date === "") {
//     //   errors.flight_date = "flight date is required.";
//     //   setIsValid(false);
//     //   validate = false;
//     // }
//     setFormErrors(errors);
//     if (!validate) {
//       setErrorFlashMessage("Please fill all the required fields.");
//     }

//     return validate;
//   };


//   // const handleCarrierSubmit2 = (event) =>{
//   //   event.preventDefault();
//   //   const formattedDate = format(startDate, 'yyyy-MM-dd');
//   //   formData.flight_date= formattedDate;
//   //   formData.exporter_order_id= Number(exporterOrder?.id);
//   //   if (handleValidation(formData)) {
//   //     updateCarrier(formData);
//   //   } else {
//   //     setWarningMessage(true);
//   //     const timer = setTimeout(() => {
//   //       setWarningMessage(false);
//   //     }, 3000);
//   //     return () => clearTimeout(timer);
//   //   }
//   // }

//   const [carrierAddFormData, setCarrierAddFormData] = useState({});
//   const [updateProductImeiData, setUpdateProductImeiData] = useState({});
//   const updateCarrier2 = (formData) => {
//     console.log("Carrier data", formData);
//     setCarrierAddForm(false);
//     setCarrierProductForm(true);
//     setCarrierListForm(false);

//     updateModalTitle("Carrier Product Info");
//     setCarrierAddFormData(formData)
//     // setSuccessFlashMessage("Carrier updated successfully");
//     //setProductAddForm(false);
//     // if (response.data.id) {
//     //   setExporterCarrierId(response.data.id);
//     // }
//   }

//   const handleCarrierProductSubmit2 = (event) =>{
//     event.preventDefault();
//     updateCarrier(carrierAddFormData)
//   }

//   const handleCarrierSubmit = async (event) => {
//     event.preventDefault();

//     const formattedDate = format(startDate, 'yyyy-MM-dd');

//     formData.flight_date= formattedDate;

//     formData.exporter_order_id= Number(exporterOrder?.id);
//     if (handleValidation(formData)) {
//       // updateCarrier(formData);
//       console.log("data", formData);
//       updateCarrier2(formData)

//     } else {
//       setWarningMessage(true);
//       //setErrorFlashMessage('Please fill all the required fields');
//       const timer = setTimeout(() => {
//         setWarningMessage(false);
//       }, 3000);
//       return () => clearTimeout(timer);
//     }
//   };

//   const hideNestedModal = () => {
//     setShowModal(false);
//   };

//   const handleCarrierProductSubmit = async (event) => {
//     event.preventDefault();

//     // setCarrierAddForm(false);
//     // setCarrierProductForm(false);
//     // setCarrierListForm(false);
//     // setCarrierDescriptionForm(true);
//     // updateModalTitle("Description");


//     //if (handleValidationProduct(carrierProductFormData)) {
//       updateCarrierProduct(carrierProductFormData);

//     // } else {
//     //   setWarningMessage(true);
//     //   //setErrorFlashMessage('Please fill all the required fields');
//     //   const timer = setTimeout(() => {
//     //     setWarningMessage(false);
//     //   }, 3000);
//     //   return () => clearTimeout(timer);
//     // }


//   };

//   const handleCarrierListSubmit = async (event) => {
//     event.preventDefault();

//     setCarrierAddForm(false);
//     setCarrierProductForm(false);
//     setCarrierListForm(false);
//     setCarrierDescriptionForm(true);
//     updateModalTitle("Description");

//   };



//   // Function to handle changes in the input fields
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };
//    // Function to handle changes in the input fields
//   const handleChangeDate = (date,name) => {
//     const formattedDate = format(date, 'yyyy-MM-dd');

//     setFormData({
//       ...formData,
//       [name]: formattedDate,
//     });
//     if (name==="flight_date") {
//       setStartDate(date);
//     }
//   };
//   const [carrierProductList, setCarrierProductList] = useState([]);

//   const updateCarrierProduct = (exporterCarrierId) => {

//     // Use your API service functions to make API requests here
//     //if(isValid){
//     if (selectedProducts.length > 0) {
//     // Create a Set to store unique combinations of exporter_carrier_id and exporter_product_id
//       const uniqueCombinations = new Set();


//       // Filter out duplicates and create an array of objects with exporter_carrier_id and exporter_product_id properties
//       const selectedProductIds = selectedProducts.reduce((result, product) => {
//         const combination = `${exporterCarrierId}-${product.id}-${product.selectedImei?.id}`;
//         if (!uniqueCombinations.has(combination)) {
//           uniqueCombinations.add(combination);
//           result.push({
//             exporter_carrier_id: exporterCarrierId, // Assuming exporter_carrier_id is a property of the product
//             exporter_product_id: product.id, // Assuming exporter_product_id is a property of the product
//             product_imei_id: product.selectedImei?.id,
//             exporter_order_id: Number(exporterOrder.id)
//           });
//         }
//         return result;
//       }, []);


//     // console.log("add form Data", selectedProductIds);
//     loadingCallback(true);
//     post("save-exporter-carrier-product", selectedProductIds, token)
//       .then((response) => {
//         loadingCallback(false);
//         if (response.status === 200) {
//           // console.log("============");
//           // console.log(response);
//           // if (Array.isArray(response.data) && response.data.length > 0) {
//           //   setCarrierProductList(response.data[0]);
//           // } else {
//             setCarrierProductList(response.data);
//          // }
//           setCarrierAddForm(false);
//           setCarrierProductForm(false);
//           setCarrierListForm(false);
//           setCarrierDescriptionForm(true);
//           updateModalTitle("Description");


//           // setSuccessFlashMessage("Carrier product updated successfully");
//           // //setProductAddForm(false);
//           // if (response.data.id) {
//           //   setExporterCarrierId(response.data.id);
//           // }

//           //navigate(`/add-exporter/${selectedExporter.id}`);
//         } else {
//           console.log("Carrier update failed");
//           setErrorFlashMessage("carrier update failed");

//           setWarningMessage(true);
//           const timer = setTimeout(() => {
//             setWarningMessage(false);
//           }, 3000);
//           return () => clearTimeout(timer);
//         }
//       })
//       .catch((error) => {
//         loadingCallback(false);
//         if (error?.response?.status === 401) {
//           localStorage.clear();
//           localStorage.setItem("token", false);
//           // Clear session and local storage
//           localStorage.setItem("isPinVerify", false);

//           navigate("/login");
//         }
//         console.log(error);
//       });
//     }
//   };


//   const updateCarrier = (formData) => {
//     loadingCallback(true);
//     // Use your API service functions to make API requests here
//     //if(isValid){
//     post("save-exporter-carrier-with-carrier", formData, token)
//       .then((response) => {
//         loadingCallback(false);
//         if (response.status === 200) {
//           setExporterCarrierData(response.data);
//           //setShowModal(false);

//           // setCarrierAddForm(false);
//           // setCarrierProductForm(true);
//           // setCarrierListForm(false);

//           // updateModalTitle("Carrier Product Info");

//           setSuccessFlashMessage("Carrier updated successfully");
//           //setProductAddForm(false);
//           if (response.data.id) {
//             setExporterCarrierId(response.data.id);
//             // const updatedCarrierProductFormData = carrierProductFormData.map(e => ({
//             //     ...e,
//             //     exporter_carrier_id: response.data.id
//             // }));
//             // carrierProductFormData.exporter_carrier_id
//             updateCarrierProduct(response.data.id);

//           }

//           //navigate(`/add-exporter/${selectedExporter.id}`);
//         } else {
//           console.log("Carrier update failed");
//           setErrorFlashMessage("carrier update failed");

//           setWarningMessage(true);
//           const timer = setTimeout(() => {
//             setWarningMessage(false);
//           }, 3000);
//           return () => clearTimeout(timer);
//         }
//       })
//       .catch((error) => {
//         loadingCallback(false);
//         if (error?.response?.status === 401) {
//           localStorage.clear();
//           localStorage.setItem("token", false);
//           // Clear session and local storage
//           localStorage.setItem("isPinVerify", false);

//           navigate("/login");
//         }
//         console.log(error);
//       });
//     //}
//   };

//   const handleInputChange = (inputValue) => {
//     //if (action && action === 'input-change') {
//     // Perform an API call using the inputValue to fetch the filtered options
//     // Update the options state with the fetched data
//     setSearchKeyword(inputValue);
//     //}
//   };
//   const showModalWarning = (msg, data = null) => {
//     Swal.fire({
//       title: msg,
//       text: "",
//       icon: 'warning',
//     });
//   };
//   const handleSelectChange = (name, option) => {
//     const { value, label } = option;
//     if (selectedProducts.length >= formData.received_qty) {
//       showModalWarning("You can't select more");
//       return;
//     }

//     if (name=='imei') {
//       const selectedImei = selectedCurrentProducts?.product_imeis.find(imei => imei.id === value);
//       // remove selected imei from the list
//       setSelectedCurrentProductsImei(selectedCurrentProductsImei.filter(imei => imei.id !== value));
//       if (selectedImei) {

//         // Check if the product is not already in selectedProducts
//         const isProductAlreadySelected = selectedProducts.some(product => product.id === selectedCurrentProducts.id);
//         // Check if the selected IMEI is not already associated with the product in selectedProducts
//         const isImeiAlreadyAssociated = selectedProducts.some(product => product.id === selectedCurrentProducts.id && product.selectedImei?.id === selectedImei.id);

//         if (!isProductAlreadySelected || !isImeiAlreadyAssociated) {// Create the product object to add to selectedProducts array
//           setProductFormData({
//             ...productFormData,
//             [name]: value,
//           });
//           const productToAdd = {
//             id: selectedCurrentProducts.id,
//             name: selectedCurrentProducts.name,
//             selectedImei: selectedImei
//           };

//           // Add the product to selectedProducts array
//           setSelectedProducts(prevProducts => [...prevProducts, productToAdd]);

//           // Update the product form data
//           setProductFormData({
//             ...productFormData,
//             [name]: value,
//           });
//         }
//       }
//     } else {
//       const selectedProduct = exporterOrder?.carrier_products.find(product => product.id === value);

//       if (selectedProduct) {
//         setProductFormData({
//           ...productFormData,
//           [name]: value,
//         });
//         setSelectedCurrentProducts(selectedProduct);
//         setSelectedCurrentProductsImei(selectedProduct?.product_imeis ? selectedProduct?.product_imeis : []);
//         // If selectedProduct is found, add it to the selectedProducts array
//         //setSelectedProducts(prevProducts => [...prevProducts, selectedProduct]);
//       }
//     }
//   };
//   const [updateCarrierFormData, setUpdateCarrierFormData] = useState({
//     id: exporterCarrierData ? exporterCarrierData.carrier_id : null,
//     exporter_order_id: Number(exporterOrder.id),
//     exporter_id: exporterOrder ? exporterOrder.exporter_id : null
//     //currency: 'USD',
//   });

//   const handleCurrencyChangeSelect = (name, option) => {
//     const { value, label } = option;

//     setSelectedCurrency(value);
//     setUpdateCarrierFormData({
//       ...updateCarrierFormData,
//       [name]: value,
//     });
//   };
//   const handlePriceChange = (event) => {
//     const { name, value } = event.target;
//     setUpdateCarrierFormData({
//       ...updateCarrierFormData,
//       [name]: value,
//     });
//   };

//   const [groupedProducts, setGroupedProducts] = useState([]);


//   // Group the products by their IDs
//   const groupedProducts2 = selectedProducts.reduce((groups, product) => {
//     // Check if a product with the same ID exists in the group
//     if (groups[product.id]) {
//       // If the product with the same ID exists, push the current product under the imeis key of the existing product
//       groups[product.id].imeis.push(product.selectedImei);
//     } else {
//       // If the product with the same ID does not exist, create a new product object and initialize the imeis key with an array containing the current product's selectedImei
//       groups[product.id] = {
//         ...product,
//         imeis: [product.selectedImei]
//       };
//     }
//     return groups;
//   }, {});
//   useEffect(() => {
//     if (selectedProducts.length > 0) {
//       // Create a Set to store unique combinations of exporter_carrier_id and exporter_product_id
//       const uniqueCombinations = new Set();


//       // Filter out duplicates and create an array of objects with exporter_carrier_id and exporter_product_id properties
//       const selectedProductIds = selectedProducts.reduce((result, product) => {
//         const combination = `${exporterCarrierId}-${product.id}-${product.selectedImei?.id}`;
//         if (!uniqueCombinations.has(combination)) {
//           uniqueCombinations.add(combination);
//           result.push({
//             exporter_carrier_id: exporterCarrierId, // Assuming exporter_carrier_id is a property of the product
//             exporter_product_id: product.id, // Assuming exporter_product_id is a property of the product
//             product_imei_id: product.selectedImei?.id,
//             exporter_order_id: Number(exporterOrder.id)
//           });
//         }
//         return result;
//       }, []);

//       setCarrierProductFormData(selectedProductIds);
//     }
//     if (selectedProducts.length > 0) {
//       let groupedProducts = selectedProducts.reduce((groups, product) => {
//         if (!groups[product.id]) {
//           groups[product.id] = [];
//         }
//         groups[product.id].push(product);
//         return groups;
//       }, {});
//       setGroupedProducts(groupedProducts);
//     }
//   }, [selectedProducts, exporterCarrierId]);

//   const handleCarrierDescriptionSubmit = async (event) => {
//     event.preventDefault();
//     updateCarrierFormData.id = exporterCarrierData.carrier_id;
//     // console.log("============");
//     // console.log(updateCarrierFormData);
//     updateCarrierCharge(updateCarrierFormData);
//   };
//   const updateCarrierCharge = (formData) => {
//     //loadingCallback(true);
//     setSuccessFlashMessage("carrier product added successfully");

//           hideNestedModal();
//           setAddedCarrier(true);
//     // formData.id = exporterCarrierData.id;
//     // post("update-exporter-carrier", formData, token)
//     //   .then((response) => {
//     //     loadingCallback(false);
//     //     if (response.status === 200) {
//     //       //setExporterCarrier(exporterCarrier);
//     //       setSuccessFlashMessage("carrier product added successfully");

//     //       hideNestedModal();
//     //       setAddedCarrier(true);
//     //       // console.log("============");
//     //       // console.log(response);
//     //       // setCarrierProductList(response.data);
//     //       // setCarrierAddForm(false);
//     //       // setCarrierProductForm(false);
//     //       // setCarrierListForm(false);
//     //       // setCarrierDescriptionForm(true);
//     //       // updateModalTitle("Description");


//     //       // setSuccessFlashMessage("Carrier product updated successfully");
//     //       // //setProductAddForm(false);
//     //       // if (response.data.id) {
//     //       //   setExporterCarrierId(response.data.id);
//     //       // }

//     //       //navigate(`/add-exporter/${selectedExporter.id}`);
//     //     } else {
//     //       console.log("Carrier update failed");
//     //       setErrorFlashMessage("carrier update failed");

//     //       setWarningMessage(true);
//     //       const timer = setTimeout(() => {
//     //         setWarningMessage(false);
//     //       }, 3000);
//     //       return () => clearTimeout(timer);
//     //     }
//     //   })
//     //   .catch((error) => {
//     //     loadingCallback(false);
//     //     if (error?.response?.status === 401) {
//     //       localStorage.clear();
//     //       localStorage.setItem("token", false);
//     //       // Clear session and local storage
//     //       localStorage.setItem("isPinVerify", false);

//     //       navigate("/login");
//     //     }
//     //     console.log(error);
//     //   });

//   };
//   // groupedProducts = selectedProducts.reduce((groups, product) => {
//   //   if (!groups[product.id]) {
//   //     groups[product.id] = [];
//   //   }
//   //   groups[product.id].push(product);
//   //   return groups;
//   // }, {});
//   const handleCancelMethod = (id, par, index,imeiList) => {
//     const updatedGroupedProducts = { ...groupedProducts };
//     const updatedSelectedProducts = [...selectedProducts];
//     const indexToRemove = updatedGroupedProducts[id].findIndex(
//       (item) => item.selectedImei?.id === par
//     );
//     const indexToRemoveProduct = updatedSelectedProducts.findIndex(
//       (item) => item.selectedImei?.id === par
//     );
//     if (indexToRemove !== -1) {
//       updatedGroupedProducts[id].splice(indexToRemove, 1);
//       setGroupedProducts(updatedGroupedProducts);
//     }

//     if (indexToRemoveProduct !== -1) {
//       updatedSelectedProducts.splice(indexToRemoveProduct, 1);
//       setSelectedProducts(updatedSelectedProducts);
//       // Add the removed IMEI back to the selectedCurrentProductsImei array
//       setSelectedCurrentProductsImei([...imeiList, selectedProducts[index].selectedImei]);
//     }
//   };

//   return (
//     <>
//       {carrierAddForm ? (
//         <form
//           method="get"
//           className="col-sm-12 min-h-[10vh]"
//           data-autosubmit="false"
//           autoComplete="off"
//           onSubmit={handleCarrierSubmit}
//         >
//           <div className="flex flex-col gap-3 mt-3">
//             <div className="mb-3 relative">
//               <Input
//                 label={`Name*`}
//                 placeholder={"Name"}
//                 name="name"
//                 value={formData.name} // Set the value from formData
//                 onChange={handleChange}
//                 error={formErrors.name} // Pass the error message for the 'name' field
//                 setFormErrors={setFormErrors}
//               ></Input>

//             </div>
//             <div className="mb-3 relative">
//               <Input
//                 label={`Email`}
//                 placeholder={"email"}
//                 name="email"
//                 value={formData.email} // Set the value from formData
//                 onChange={handleChange}
//                 //error={formErrors.email} // Pass the error message for the 'name' field
//                 //setFormErrors={setFormErrors}
//               ></Input>
//             </div>
//             <div className="mb-3 relative">
//               <Input
//                 label={`Phone Number`}
//                 placeholder={"Phone Number"}
//                 name="phone_no"
//                 value={formData.phone_no} // Set the value from formData
//                 onChange={handleChange}
//                 //error={formErrors.phone_no} // Pass the error message for the 'name' field
//                 //setFormErrors={setFormErrors}
//               ></Input>
//             </div>

//             <div className="mb-3 relative">
//               <Input
//                 label={`Address`}
//                 placeholder={"Address"}
//                 name="address"
//                 value={formData.address} // Set the value from formData
//                 onChange={handleChange}
//                 //error={formErrors.phone_no} // Pass the error message for the 'name' field
//                 //setFormErrors={setFormErrors}
//               ></Input>
//             </div>

//             <div className="mb-3 relative">
//               <Input
//                 label={`ID/Passport`}
//                 placeholder={"ID/Passport"}
//                 name="nid_passport_no"
//                 value={formData.nid_passport_no} // Set the value from formData
//                 onChange={handleChange}
//                 //error={formErrors.phone_no} // Pass the error message for the 'name' field
//                 //setFormErrors={setFormErrors}
//               ></Input>
//             </div>

//             <div className="mb-3 relative">
//               <Input
//                 label={`Received Quantity`}
//                 placeholder={"Received Quantity"}
//                 name="received_qty"
//                 value={formData.received_qty} // Set the value from formData
//                 onChange={handleChange}
//                 error={formErrors.received_qty} // Pass the error message for the 'name' field
//                 setFormErrors={setFormErrors}
//               ></Input>
//             </div>

//             <div className="relative border-[#C1CFEF] py-2.5 border w-full flex-1 rounded-xl">
//               <DatePicker
//                 selected={startDate}
//                 value={formData.flight_date} // Set the value from formData
//                 onChange={(date) => handleChangeDate(date, 'flight_date')}
//                 dateFormat="yyyy-MM-dd"
//                 className="w-full px-2 focus:outline-none"
//               />
//               <span className="bg-white absolute left-2 font-semibold px-1 text-xs top-0 text-[#102048] translate-y-[-50%]">
//                 Flight Date
//               </span>
//             </div>

//             <div className="mt-4">
//               <input
//                 type="submit"
//                 value="Next"
//                 className="btn btn-primary w-full text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF]"
//               />
//             </div>
//           </div>
//         </form>
//       ) : carrierProductForm ? (
//         <form
//           method="get"
//           className="col-sm-12 min-h-[10vh]"
//           data-autosubmit="false"
//           autoComplete="off"
//           onSubmit={handleCarrierProductSubmit2}
//         >
//           <div className="flex flex-row gap-2 mt-2 mb-2">
//             <div className="w-[50%]">
//               <div className="mb-3 relative">
//                 <SelectBox
//                   name="exporter_product_id"
//                   options={exporterOrder?.carrier_products}
//                   value={productFormData?.exporter_product_id ? productFormData?.exporter_product_id : ""}
//                   //onInputChangeData={handleInputChange}
//                   onChange={handleSelectChange.bind(this, "exporter_product_id")}
//                   placeholder="Product"
//                 />
//                 <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
//                   Product
//                 </label>
//               </div>
//             </div>
//             <div className="w-[50%]">
//               <div className="mb-3 relative">
//                 <SelectBox
//                   name="imei"
//                   options={selectedCurrentProductsImei ? selectedCurrentProductsImei : []}
//                   value={productFormData?.imei ? productFormData?.imei : ""}
//                   onChange={handleSelectChange.bind(this, "imei")}
//                   placeholder="select IMEI"
//                 />
//                 <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
//                   IMEI
//                 </label>
//               </div>
//             </div>
//           </div>

//           {Object.keys(groupedProducts).map(productId => (
//             <div key={productId}>
//               <div className="rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[10px] py-[12px] mb-2">
//                 <div className="max-h-[15vh] overflow-y-auto">

//                   <div className="flex flex-row justify-between border-b-[1px] border-solid border-[#E7EBF4] px-[10px] py-[12px]">
//                     <div className="font-nunito font-[700] text-[12px] text-[#000232]">
//                       Product Name
//                     </div>
//                     <div className="font-nunito font-[700] text-[12px] text-[#000232]">
//                       IMEI
//                     </div>
//                   </div>
//                   <div className="min-h-[70px]">
//                     { groupedProducts[productId].map((product, index) =>{

//                       console.log("Product Data", product);
//                       return (
//                       <div key={index} className="flex flex-row justify-between border-b-[1px] border-solid border-[#E7EBF4] px-[10px] py-[12px]">

//                         <div
//                             className="bg-red-600 text-white rounded-md cursor-pointer"
//                             onClick={() => handleCancelMethod(productId, product.selectedImei?.id,index,selectedCurrentProductsImei)}
//                           >
//                             <img src={crose} width={23} height={20} alt="" />
//                           </div>
//                         <div className="font-nunito font-[500] text-[12px] text-[#000232]">
//                           {product.name}
//                         </div>
//                         <div className="font-nunito font-[500] text-[12px] text-[#000232]">
//                           {product.selectedImei?.imei}
//                         </div>
//                       </div>
//                     )}
//                   )
//                     }
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}

//           <div className="flex flex-row gap-3">
//             <input
//               onClick={() => {
//                 setCarrierAddForm(true);
//                 setCarrierProductForm(false);
//                 setCarrierListForm(false);
//                 updateModalTitle("Carrier Info");
//               }}
//               type="button"
//               value="Back"
//               className="btn w-[48%] text-[#034AFF] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none capitalize"
//             />
//             <input
//               type="submit"
//               value="Next"
//               className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF] capitalize"
//             />
//           </div>
//         </form>
//       ) : carrierDescriptionForm ? (
//         <form
//           method="get"
//           className="col-sm-12 min-h-[10vh]"
//           data-autosubmit="false"
//           autoComplete="off"
//           onSubmit={handleCarrierDescriptionSubmit}
//         >
//           <div className="font-nunito font-bold text-[#16px] tex-[#000232] mb-2">
//             Product Description
//           </div>
//           {groupedProducts2 ? (

//           Object.values(groupedProducts2).map((product, index) =>{
//             console.log("all data", product);
//           return (
//             <div key={index}>
//               <div className="grid grid-cols-3 gap-3 mb-4">
//                 <div className="font-nunito text-[#12]">
//                   <div className="font-[700] text-[#6F8EB6]">Product Name</div>
//                   <div className="font-[400] text-[#000000]">{product?.name}</div>
//                 </div>

//                 <div className="font-nunito text-[#12]">
//                   <div className="font-[700] text-[#6F8EB6]">Booking Date</div>
//                   <div className="font-[400] text-[#000000]">{exporterCarrierData?.flight_date}</div>
//                 </div>

//                 <div className="font-nunito text-[#12]">
//                   <div className="font-[700] text-[#6F8EB6]">Carrier Name</div>
//                   <div className="font-[400] text-[#000000]">{exporterCarrierData?.carrier[0]?.name}</div>
//                 </div>

//                 <div className="font-nunito text-[#12]">
//                   <div className="font-[700] text-[#6F8EB6]">QTY</div>
//                   <div className="font-[400] text-[#000000]">{exporterCarrierData?.received_qty}</div>
//                 </div>

//                 <div className="font-nunito text-[#12]">
//                   <div className="font-[700] text-[#6F8EB6]">Email</div>
//                   <div className="font-[400] text-[#000000] break-words">{exporterCarrierData?.carrier[0].email}</div>
//                 </div>

//                 <div className="font-nunito text-[#12]">
//                   <div className="font-[700] text-[#6F8EB6]">Phone Number</div>
//                   <div className="font-[400] text-[#000000]">{exporterCarrierData?.carrier[0].phone_no}</div>
//                 </div>
//               </div>

//               <div className="rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[12px] py-[15px] mb-[20px]">
//                 <div className="font-lato font-[700] text-[12px] text-[#000232] mb-2">
//                   List of IMEI
//                 </div>
//                 <div className="max-h-[140px] overflow-y-auto">
//                   <div className="flex flex-row gap-2 font-nunito font-bold text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
//                     <div className="py-[5px] flex-1">
//                       SL
//                       <br />
//                       No
//                     </div>
//                     <div className="py-[5px] text-center flex-1">Ram</div>
//                     <div className="py-[5px] text-center flex-1">Storage</div>
//                     <div className="py-[5px] text-right w-[42%] pr-[5px]">
//                       IMEI
//                       <br />
//                       Number
//                     </div>
//                   </div>
//                    {product?.imeis.map((imei, index) => (
//                     <div key={index} className="flex flex-row gap-2 font-nunito font-[400] text-[14px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
//                       <div className="py-[5px] flex-1">{index+1}</div>
//                       <div className="py-[5px] text-center flex-1">{imei?.ram}</div>
//                       <div className="py-[5px] text-center flex-1">{imei?.storage}</div>
//                       <div className="py-[5px] text-right w-[42%] pr-[5px]">
//                         {imei?.imei}
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           )}) ) : (
//         <div>Loading...</div>
//       )}

//           {/* <div className="font-nunito font-[700] text-[16px] text-[#000232] mb-2">
//             Carrier Charge
//           </div>

//           <div className="rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[12px] py-[15px] mb-[20px]">
//             <div className="flex gap-5 justify-between items-center">
//               <SelectBox
//                 name="currency"
//                 options={currency}
//                 value={selectedCurrency}
//                 onChange={handleCurrencyChangeSelect.bind(this, `currency`)}
//                 placeholder="Select Currency"
//                 style={{ width: "50%" }}
//               />
//               <Input
//                 label={``}
//                 placeholder={"Charge"}
//                 value={updateCarrierFormData.charge}
//                 name={`charge`}
//                 className="border-[1px] border-solid border-[#000000]"
//                 onChange={handlePriceChange}
//               ></Input>
//             </div>
//           </div> */}

//           <div className="flex flex-row gap-3">
//             <input
//               onClick={() => {
//                 setCarrierAddForm(false);
//                 setCarrierProductForm(true);
//                 setCarrierListForm(false);
//                 setCarrierDescriptionForm(false);
//                 updateModalTitle("Carrier Product Info");
//               }}
//               type="button"
//               value="Back"
//               className="btn w-[48%] text-[#034AFF] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none capitalize"
//             />
//             <input
//               type="submit"
//               value="Done"
//               className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF] capitalize"
//             />
//           </div>
//         </form>
//       ) : null}
//     </>
//   );
// };

// export default ExporterAddCarrierForm;
